import React, { useState, useEffect, useRef } from 'react';

import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

type PropsType = {
    before: Object,
    after: Object,
    heigth?: number,
    className?: string
}

const BeforeAfterImages: React.FC<PropsType> = ({ before, after, heigth, className = "" }) => {
    const [value, setValue] = useState(50);
    const parentRef = useRef<HTMLDivElement>(null)
    const currentPageDataSlice = useAppSelector(getCurrentPageData);
    const [parentWidth, setParentWidth] = useState(0)


    const fromStrings = (value: string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const getParentWidth = (parentElement: HTMLDivElement) => {
        setParentWidth(parentElement.clientWidth)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // e.stopPropagation()
        setValue(+e.target.value);
    }

    useEffect(() => {
        if (!parentRef.current) return;

        const parentElement = parentRef.current;
        window.addEventListener("resize", () => getParentWidth(parentElement));
        getParentWidth(parentElement)
        return () => {
            window.removeEventListener("resize", () => getParentWidth(parentElement));
        };
    }, [parentRef]);

    return (
        <div className={`slider-images ${className}`} ref={parentRef} style={heigth ? { height: heigth } : {}}>
            <div className="img background-img" style={{ ...before, backgroundSize: `${parentWidth}px 100%` }}>
                <span className="after-badge">{fromStrings("After")}</span>
            </div>
            <div className="img foreground-img" style={{ ...after, width: `${value}%`, backgroundSize: `${parentWidth}px 100%` }}>
                <span className="before-badge">{fromStrings("Before")}</span>
            </div>
            <input
                type="range" min="0" max="100" value={value}
                onChange={handleChange}
                className="slider ranges-images" name="slider"
            />
            <div className="slider-line" style={{ left: `${value}%` }}></div>
            <div className="slider-button" style={{ left: `${value}%`, pointerEvents: "none" }}></div>
        </div>
    );
};

export default BeforeAfterImages;