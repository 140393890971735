import axios from "axios";
import { axiosConfig, generationOptionsConfig } from "./config";
import { authApi } from "./parts/authApi";
import { pagesDataApi } from "./parts/pagesDataApi";
import { generationOptionsApi } from "./parts/generationOptionsApi";
import { profileApi } from "./parts/profileApi";
import { generationApi } from "./parts/generationApi";
import { teamApi } from "./parts/teamApi";

export const Api = () => {
	const instance = axios.create(axiosConfig);
	const generationOptionsInstance = axios.create(generationOptionsConfig);

	instance.interceptors.request.use(function (config) {
		const token = localStorage.getItem('token');
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		return config;
	});
	generationOptionsInstance.interceptors.request.use(function (config) {
		const token = localStorage.getItem('token');
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		return config;
	});

	return {
		auth: authApi(instance),
		pagesData: pagesDataApi(instance),
		generationOptions: generationOptionsApi(generationOptionsInstance),
		profile: profileApi(instance),
		generation: generationApi(instance),
		team: teamApi(instance),
	};
};