import { AxiosInstance } from "axios";
import { IGenerationOptionsData } from "../../store/GenerationOptions/types";

export const generationOptionsApi = (instance: AxiosInstance) => ({
    async getOptions() {
        let res = await instance.get<IGenerationOptionsData>('/api/ai/load-data');
        // res.data.property_type = res.data.property_type.map(item=>{
        //     item.interior_or_exterior = item.interior_or_exterior.map(item1=>{
        //         item1.type= item1.type.map(item2=>{
        //             item2.image = require(`../../assets/img/interior-1.png`)
        //             return item2
        //         })
        //         return item1
        //     })
        //     return item
        // })
        // res.data.render_styles = res.data.render_styles.map(item=>{
        //     return {
        //         ...item,
        //         image: require(`../../assets/img/img-render-1.png`)
        //     }
        // })
        // res.data.property_type[0].interior_or_exterior[0]
        return res 
    },
    async getSelectRenderStyle(id: string|number) {
        let res = await instance.get<any>(`/api/ai/render-styles?ie_id=${id}`);
        return res 
    },
});