import React, { useState, useEffect } from 'react'
import { RenderSwiper } from '../../../components/UI/RenderSwiper'
import { Icon } from '../../../components/UI/Icon'
import { saveAs } from "file-saver";
import { ReactComponent as IcoStars } from "../../../assets/img/icons/ico-stars.svg"

import { Api } from '../../../api/axios';

import { getProfile } from '../../../store/profile/ProfileSlice';
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { setNewPrediction, setRenderUpscaleLoading, updateRender, getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { fetchRenderStatus, } from '../../../store/pagesData/PagesDataThunk';
import { NotificationManager } from 'react-notifications';
import BeforeAfterImages from "../../../components/UI/BeforeAfterImages";
import Plyr from 'plyr';
import { getActiveGenerationTab } from '../../../store/Generation/GenerationSlice';

interface IProps {
    title: string | null,
    items: string[],
    backHandler: (event: React.MouseEvent) => void,
    // openUpgradeToPro: (event: React.MouseEvent) => void,
    openUpgradeToPro: (text: string) => void,
    openedFolder?: any,
}


export const MyRendersPhoto = ({ title, items, backHandler, openedFolder, openUpgradeToPro }: IProps) => {
    const dispatch = useAppDispatch();
    // (openedFolder?.video ? [...localItems, { type: 'video', poster: localItems[1] }] : localItems)
    const activeGenerationTab = useAppSelector(getActiveGenerationTab)
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value: string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const formatedItems = items.map(item => `${item}?time=${new Date().getTime()}`);

    const [swiperElem, setSwiperElem] = useState<any>(null);

    const [beforeAfterActive, setBeforeAfterActive] = useState<boolean>(false);
    const [beforeAfterImagesHeight, setBeforeAfterImagesHeight] = useState<number>(0);
    const [beforeAfterImagesWidth, setBeforeAfterImagesWidth] = useState<number>(0);

    const [videoDetails, setVideoDetails] = useState<any>(openedFolder?.video_details);
    const [openedFolderVideo, setOpenedFolderVideo] = useState<any>(videoDetails?.link);

    const [localItems, setLocalItems] = useState<any[]>(openedFolderVideo ? [...formatedItems, { type: 'video', poster: openedFolder?.all_renders[videoDetails?.prediction || 0] }] : formatedItems);

    const [videoInterval, setVideoInterval] = useState<any>(null);

    const [animCopy, setAnimCopy] = useState(false);

    const [upscaleLoading, setUpscaleLoading] = useState<null | number>(null);
    const [renderVideoLoading, setRenderVideoLoading] = useState<null | number | boolean>(false);

    const [activeSlideImg, setActiveSlideImg] = useState('');

    const [upscaled, setUpscaled] = useState<any>([]);
    const [portrait, setPortrait] = useState(true);
    // const [portrait, setPortrait] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const [beforeUpscaledCheckIndex, setBeforeUpscaledCheckIndex] = useState([]);

    const profile = useAppSelector(getProfile);

    const createCopyAnim = () => {
        setAnimCopy(true)
        setTimeout(() => {
            setAnimCopy(false)
        }, 1500)
    }

    const downloadFile = (url: string, name: string) => {
        // saveAs(url, name);
        let doc = document as any;
        doc.querySelector(".download-link-action").href = url;
        doc.querySelector(".download-link-action").click();
    }

    const findedIndex = localItems.findIndex(item => item === activeSlideImg);

    const checkRenderStatus = async () => {
        let statusRes = await Api().generation.getRenderStatus(openedFolder?.id);

        if (statusRes?.data?.upscale_in_progress?.length) {
            setBeforeUpscaledCheckIndex(statusRes?.data?.upscale_in_progress);
        }

        dispatch(setRenderUpscaleLoading({
            render_id: openedFolder?.id,
            upscale_index: findedIndex - 1,
            // beforeUpscaledCheckIndex?.length && !statusRes?.data?.upscale_in_progress?.length ? beforeUpscaledCheckIndex : 
            upscaled: statusRes?.data?.upscaled || []
        }))

        setUpscaled(statusRes?.data?.upscaled.map((item: any) => +item + 1));

        return statusRes.data;
    }

    const upscale = async (e: any) => {
        if (!profile?.is_premium && openUpgradeToPro) {
            openUpgradeToPro('Pro');
            return false;
        }

        setUpscaleLoading(findedIndex);
        if (!openedFolder && !activeSlideImg) return;

        dispatch(setRenderUpscaleLoading({
            render_id: openedFolder?.id,
            upscale_index: findedIndex - 1,
        }));

        let res = await Api().generation.upscale(openedFolder?.id, findedIndex - 1);

        if (res?.data?.success) {
            dispatch(setNewPrediction({
                id: openedFolder?.id,
                index: findedIndex - 1,
                uscale_img: res?.data?.result
            } as any));

            let copied = [...localItems];
            copied[findedIndex] = res?.data?.result;
            setActiveSlideImg(res?.data?.result);
            setLocalItems(copied);

            setUpscaled((prev: any) => ([...prev, findedIndex]));
        } else {
            NotificationManager.info(res?.data?.message);
        }

        setUpscaleLoading(null);
    }

    const renderPlayers = () => {
        const players = Array.from(document.querySelectorAll('.js-player')).map((p: any) => new Plyr(p, {
            controls: ['play-large']
        }));
    }

    const checkVideoRenderStatus = async () => {
        const selectedForRenderIndex = findedIndex;
        let statusRes = await Api().generation.getVideoRenderStatus(openedFolder?.id);

        dispatch(updateRender({
            id: openedFolder?.id,
            field: 'video_details',
            value: {
                link: statusRes.data.video || null,
                prediction: selectedForRenderIndex - 1,
                status: statusRes.data.status
            }
        } as any));

        setVideoDetails({
            link: statusRes.data.video || null,
            prediction: selectedForRenderIndex - 1,
            status: statusRes.data.status
        });

        if (statusRes.data.status === 'succeeded' && statusRes.data.video) {
            setRenderVideoLoading(false);

            // dispatch(updateRender({
            //     id: openedFolder?.id,
            //     field: 'video',
            //     value: statusRes.data.video
            // } as any));

            setLocalItems([...localItems, { type: 'video', poster: openedFolder?.all_renders[(selectedForRenderIndex - 1) < 0 ? 0 : selectedForRenderIndex - 1] }]);
            setOpenedFolderVideo(statusRes.data.video);

            setTimeout(() => {
                renderPlayers();
            }, 50);

            return true;
        } else if (statusRes.data.status === 'failed') {
            setRenderVideoLoading(false);
            return true;
        } else {
            return false;
        }
    }

    const renderVideo = async (e: any) => {
        if (!profile?.can_render_video && openUpgradeToPro) {
            openUpgradeToPro('Premium');
            return false;
        }

        if (!openedFolder && !activeSlideImg) return;

        setRenderVideoLoading(true);

        let res = await Api().generation.generateVideo(openedFolder?.id, findedIndex - 1);

        let videoInterval1 = setInterval(async () => {
            let checkRes = await checkVideoRenderStatus() as any;
            if (checkRes) {
                clearInterval(videoInterval1);
                setVideoInterval(null);
            }
        }, 1000);
        setVideoInterval(videoInterval1);

        if (res?.data?.success) {
            dispatch(updateRender({
                id: openedFolder?.id,
                field: 'video_details',
                value: {
                    link: null,
                    prediction: findedIndex - 1,
                    status: 'starting'
                }
            } as any));

        } else {
            NotificationManager.info(res?.data?.message);
        }

    }

    const shareHandler = () => {
        if (navigator.share) {
            navigator.share({
                title: "Render image share",
                text: "Rendered image",
                url: activeSlideImg
            }).then(function () {
                console.log("Shareing successfull")
            })
                .catch(function () {
                    console.log("Sharing failed")
                })
        }
    }

    const copyToClipboard = (text?: string) => {
        var textarea = document.createElement('textarea');
        textarea.value = activeSlideImg || '';
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
        } catch (err) {

        }
        document.body.removeChild(textarea);
    }

    const getImgSize = () => {
        var newImg = new Image();

        newImg.onload = function () {
            var width = newImg.width;
            var height = newImg.height;

            // setPortrait(+width < +height || +width === +height);
            setImagesLoaded(true);

            renderPlayers();
        }

        newImg.src = items[0];
    }

    const openBeforeAfterActive = () => {
        let findActiveImg = document.querySelector('.swiper.main-render-swiper .swiper-slide.swiper-slide-active img') as any;
        const clientRect = findActiveImg.getBoundingClientRect();
        setBeforeAfterImagesWidth(clientRect?.width);
        setBeforeAfterImagesHeight(clientRect?.height);

        setTimeout(() => {
            setBeforeAfterActive(true)
        }, 10);
    }

    useEffect(() => {
        setImagesLoaded(false);
        getImgSize();

        let interval = null as any;
        let videoInterval1 = null as any;

        if (openedFolder?.upscale_in_progress?.length) {
            interval = setInterval(async () => {
                let checkRes = await checkRenderStatus() as any;
                if (!checkRes?.upscale_in_progress?.length) {
                    setUpscaleLoading(null);
                    setUpscaled((prev: any) => ([...prev, findedIndex]));

                    clearInterval(interval);
                } else {
                    setUpscaleLoading(+checkRes?.upscale_in_progress[0] + 1);
                }
            }, 1000);
        }

        if (openedFolder?.video_details?.status && (openedFolder?.video_details?.status !== 'succeeded' && openedFolder?.video_details?.status !== 'failed') && !renderVideoLoading) {
            setRenderVideoLoading(true);

            videoInterval1 = setInterval(async () => {
                let checkRes = await checkVideoRenderStatus() as any;

                if (checkRes) {
                    clearInterval(videoInterval1);
                    setVideoInterval(null);
                    localStorage.removeItem('renderVideo');
                }
            }, 1000);
            setVideoInterval(videoInterval1);
        }

        return () => {
            clearInterval(interval);
            clearInterval(videoInterval1);

            setVideoInterval(null);
        }
    }, [])


    useEffect(() => {
        if (localItems?.length && !activeSlideImg) {
            setActiveSlideImg(localItems[0]);
        }
    }, [localItems]);

    useEffect(() => {
        swiperElem?.slideTo(1);
    }, [swiperElem]);

    const portraitImgStyles = {
        maxHeight: "calc(100vh - 270px)",
        minHeight: '300px',
        width: "initial",
        margin: "0 auto",
        display: "block",
        height: '100%',
    };

    return (
        <div className="full-page-section__content">
            <div className="section-scroll-container">
                {/* <div className="section-scroll-heading">
                    <a href="#" onClick={backHandler} className="btn btn--secondary-2 font-[600] btn--md pl-[8px] rounded-[10px] mr-[20px]">
                        <span className="icon mr-[10px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6665 6.66675L8.33317 10.0001L11.6665 13.3334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        Back
                    </a>
                    <h4 className="flex-auto">{title}</h4>
                </div> */}
                <div className="pb-[16px]">
                    <h4 className="flex-auto m-0">{title}</h4>
                    <a href="#" onClick={backHandler} className="close-modal-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.2">
                                <path d="M8 8L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 8L8 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>
                </div>
                <div className="fullpage-slider-content">
                    {/* {imagesLoaded ?  */}
                    <RenderSwiper
                        getActiveSlide={(value: string)=>{
                            setActiveSlideImg(value);
                        }}
                        bottomBtns={<>
                            <div className="fullpage-actions">
                                {
                                    beforeAfterActive && findedIndex !== 0 ? <>
                                        <a
                                            href="#" 
                                            className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px] min-w-[66px]"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setBeforeAfterActive(false);
                                            }}
                                            style={{ padding: "10px 15px" }}
                                            key={'Back-before-after'}
                                        >
                                            {fromStrings("Back")}
                                        </a>
                                    </> :
                                    <>
                                        <a
                                            // onClick={(e) => {e.preventDefault(); downloadFile(items[1], 'After_image.png')}}
                                            // onClick={download}
                                            href={activeSlideImg !== 'video' ? activeSlideImg : openedFolderVideo} download={activeSlideImg !== 'video' ? 'After_image.png' : openedFolderVideo.split('/').pop()} 
                                            className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px] min-w-[160px]"
                                            onClick={(e)=>{
                                                e.stopPropagation();
                                            }}
                                        >
                                            {fromStrings(`Download ${activeSlideImg !== 'video' ? 'Render' : 'Video'}`)}
                                        </a>
                                        <a
                                            href="#" onClick={(event)=>{event.preventDefault(); createCopyAnim(); copyToClipboard(); shareHandler()}}
                                            className={`btn btn--secondary-2 ${!animCopy ? '' : 'pointer-events-none animated pr-[8px]'}  rounded-[10px] copy-btn`}
                                        >
                                            {!animCopy && <svg className='m-[9px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.0012 8.99878H17.0021C18.1071 8.99878 19.0029 9.89458 19.0029 10.9996V19.0029C19.0029 20.108 18.1071 21.0038 17.0021 21.0038H6.9979C5.89287 21.0038 4.99707 20.108 4.99707 19.0029V10.9996C4.99707 9.89458 5.89287 8.99878 6.9979 8.99878H8.99874" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.0002 15.0013V2.99634" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.0015 5.99759L12.0003 2.99634L8.99902 5.99759" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg> }
                                            <svg id="icon1" className={`test-icon ${!animCopy && 'h-0 w-0'}`} width="42" height="42" viewBox="0 0 42 42" fill="none" stroke="white" 
                                                strokeWidth="3" strokeDasharray="100">
                                                <path className="icon-path" id="check" d="M 12,22 L 22,31 L 36,13"></path>
                                            </svg>
                                                
                                            {
                                                animCopy ? <span className="ml-[42px]">{fromStrings("Copied")}</span> : ''
                                            }
                                        </a>
                                        { activeSlideImg !== 'video' && findedIndex !== 0 ? <a
                                            href="#" className="btn btn--secondary-2 rounded-[10px] min-h-[44px] min-w-[44px]"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                openBeforeAfterActive();
                                            }}
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.57141L12 21.4286" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                                <path d="M15.4286 13.5L16.7144 12L15.4286 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8.57136 10.5L7.28564 12L8.57136 13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <rect x="3.51929" y="5.75" width="16.9615" height="12.5" rx="2.25" stroke="white" stroke-width="1.5"/>
                                            </svg>
                                        </a> : null }
                                        { activeSlideImg === 'video' || findedIndex !== 0 ? <a 
                                            href="#" className="btn btn--secondary-2 rounded-[10px] min-h-[44px] min-w-[44px]"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                if(swiperElem) {
                                                    swiperElem?.slideTo(0);
                                                }
                                            }}
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="2.75" y="4.75" width="18.5" height="14.5" rx="2.25" stroke="white" stroke-width="1.5"/>
                                                <path d="M9.75 13.125L7.5 10.875L9.75 8.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16.5 15.375C16.5 14.1815 16.0259 13.0369 15.182 12.193C14.3381 11.3491 13.1935 10.875 12 10.875H7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a> : null }
                                    </>
                                }
                            </div>
                            {
                                !beforeAfterActive  ? 
                                <div className="d-flex items-center gap-[16px] absolute right-[20px] bottom-[32px] show-only-big-slider" style={{ zIndex: '10' }}>
                                    {
                                        title?.toLocaleLowerCase() !== "upscale" && activeSlideImg !== localItems[0] && activeSlideImg !== 'video' && !openedFolderVideo && (!openedFolder?.video_details?.status || renderVideoLoading) ? <a 
                                        href="#" onClick={(event)=>{
                                            event.preventDefault();
                                            renderVideo(event);
                                        }}
                                        className={`btn--render-js-1 btn w-full max-w-[159px] text-[14px] btn--lg-2 rounded-[300px] whitespace-nowrap ${renderVideoLoading ? 'blur-btn font-[400] pointer-events-none' : 'btn--gradient-green font-[600]'}`}
                                    >
                                        <span className="ico ico-20 mr-[8px]">
                                            {
                                                !renderVideoLoading ? 
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_4_2403)">
                                                        <path d="M12.5 8.75L8.75 6.25V11.25L12.5 8.75Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V13.125C2.5 13.4702 2.77982 13.75 3.125 13.75H16.875C17.2202 13.75 17.5 13.4702 17.5 13.125V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M2.5 16.25H17.5" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4_2403">
                                                        <rect width="20" height="20" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg> :
                                                <div className="lds-spinner">
                                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                                </div>
                                            }
                                        </span>
                                        <span>
                                            {fromStrings(`${ !renderVideoLoading ? 'Render Video' : 'Rendering...' }`)}
                                        </span>
                                    </a> : null }
                                    { 
                                        activeSlideImg !== localItems[0] && !openedFolder?.upscaled.map((itemM:string|number) => +itemM).includes(findedIndex-1) &&
                                        !upscaled.includes(findedIndex) && activeSlideImg !== 'video' &&
                                        upscaleLoading === null ? <a 
                                        href="#" onClick={(event)=>{
                                            event.preventDefault();
                                            upscale(event);
                                        }}
                                        className={`btn--render-js-1 btn w-full max-w-[124px] btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] whitespace-nowrap`}
                                    >
                                        <span className="ico ico-20 mr-[8px]">
                                            <IcoStars/>
                                        </span>
                                        <span>
                                            {fromStrings("Upscale")}
                                        </span>
                                    </a> : null }
                                </div> : null
                            }
                        </>}
                        setSwiperElem={setSwiperElem}
                    >
                        {/* {(openedFolder?.video ? [...localItems, { type: 'video', poster: localItems[1] }] : localItems).map((item: any, index)=>{ */}
                        { localItems.map((item: any, index)=>{
                            return (
                                <div
                                    key={index}
                                    style={
                                        portrait || beforeAfterActive ? {
                                            height: '100%',
                                            backgroundColor: "#101014"
                                        } : {
                                            backgroundColor: "#101014"
                                        }
                                    }  
                                >
                                    {
                                        item?.type !== 'video' ? 
                                        <>
                                            { index !== 0 && beforeAfterActive ? 
                                                <>
                                                    <div className='show-only-big-slider' style={
                                                            portrait ? { 
                                                                ...portraitImgStyles,
                                                                maxWidth: `${beforeAfterImagesWidth}px`
                                                            } : {
                                                                width: "initial",
                                                                margin: "0 auto",
                                                                display: "block",
                                                                height: '100%',
                                                            }
                                                        }>
                                                        <BeforeAfterImages
                                                            before={{ backgroundImage: `url(${item})`, backgroundSize: `${beforeAfterImagesWidth}px 100%` }}
                                                            after={{ backgroundImage: `url(${localItems[0]})`, backgroundSize: `${beforeAfterImagesWidth}px 100%` }}
                                                            heigth={beforeAfterImagesHeight}
                                                        />
                                                    </div>
                                                    <img
                                                        className={`show-only-thumb-slider ${upscaleLoading === index ? 'upscale-blur-loading' : ''}`}
                                                        src={item} alt=""
                                                        style={
                                                            portrait ? portraitImgStyles : {}
                                                        }    
                                                    />
                                                </>
                                                : <img
                                                    className={`${upscaleLoading === index ? 'upscale-blur-loading' : ''}`}
                                                    src={item} alt=""
                                                    style={
                                                        portrait ? portraitImgStyles : {}
                                                    }    
                                                />
                                            }
                                        </> : <div>
                                            <div className='show-only-thumb-slider'>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="pos-absolute-center">
                                                    <g filter="url(#filter0_b_31_1406)">
                                                        <rect width="32" height="32" rx="16" fill="#17161D" fillOpacity="0.4"/>
                                                        <path d="M22.4614 15.467C22.8717 15.7039 22.8717 16.296 22.4614 16.5329L13.2307 21.8623C12.8204 22.0992 12.3076 21.8031 12.3076 21.3294L12.3076 10.6706C12.3076 10.1969 12.8204 9.90078 13.2307 10.1376L22.4614 15.467Z" fill="#D9D9D9"/>
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_b_31_1406" x="-24.6154" y="-24.6154" width="81.2308" height="81.2308" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12.3077"/>
                                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_31_1406"/>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_31_1406" result="shape"/>
                                                        </filter>
                                                    </defs>
                                                </svg>
                                                { item?.poster ? <img
                                                    src={item?.poster} alt=""
                                                    style={
                                                        portrait ? portraitImgStyles : {}
                                                    }    
                                                /> : null }
                                            </div>
                                            <div className='show-only-big-slider' style={
                                                portrait ? { 
                                                    ...portraitImgStyles,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                } : {}
                                            }>
                                                {/* controls */}
                                                { openedFolderVideo ? 
                                                    <video playsInline data-poster={localItems[1]} className='js-player w-full'>
                                                        <source src={openedFolderVideo} type="video/mp4" />
                                                    </video>
                                                : null }
                                            </div>
                                        </div>
                                    }
                                    <div className="slider-top-content-block">
                                        { (!beforeAfterActive && index !== 0) || index === 0 ?
                                        <div className="d-flex items-center">
                                            {
                                                <span className="slider-label mr-[8px]">{ item?.type === 'video' ? fromStrings('Video') : index === 0 ? fromStrings('Before') : fromStrings('After') }</span>
                                            }
                                            { openedFolder?.upscaled.map((itemM:string|number) => +itemM).includes(index-1) || upscaled?.includes(index) ? 
                                            <span className="slider-label d-flex items-center show-only-big-slider">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[4px]">
                                                    <g opacity="0.8">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.49861 1.7478C3.49861 3.037 2.45351 4.08211 1.16431 4.08211C2.45351 4.08211 3.49861 5.12721 3.49861 6.41641C3.49861 5.12721 4.54372 4.08211 5.83292 4.08211C4.54372 4.08211 3.49861 3.037 3.49861 1.7478Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.91788 8.75074C9.91788 7.13924 8.6115 5.83286 7 5.83286C8.6115 5.83286 9.91788 4.52648 9.91788 2.91498C9.91788 4.52648 11.2243 5.83286 12.8358 5.83286C11.2243 5.83286 9.91788 7.13924 9.91788 8.75074Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M9.91776 8.75073V9.33431" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M9.91776 2.91494V2.33136" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.8328 12.2522V12.8358" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.8328 8.16713V7.58356" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M3.49858 6.41638V6.99996" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M3.49858 1.16425V1.74782" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.83284 7.87537C5.83284 9.16457 4.78774 10.2097 3.49854 10.2097C4.78774 10.2097 5.83284 11.2548 5.83284 12.544C5.83284 11.2548 6.87794 10.2097 8.16715 10.2097C6.87794 10.2097 5.83284 9.16457 5.83284 7.87537Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                </svg>
                                                {fromStrings("HD Image")}
                                            </span> : null }
                                        </div> : <span className="slider-label mr-[8px] show-only-thumb-slider">{ item?.type === 'video' ? fromStrings('Video') : index === 0 ? fromStrings('Before') : fromStrings('After') }</span> }
                                    </div>

                                    { upscaleLoading === index ?
                                        <div className="slider-contetn-block">
                                            <div className="scaling-please-wait show-only-big-slider">
                                                <h3>{fromStrings("Please Wait")}</h3>
                                                <span>{fromStrings("upscaling")}...</span>
                                            </div> 
                                        </div>
                                    : null}
                                </div>
                            )
                        })}
                    </RenderSwiper>
                </div>
                
            </div>
        </div>
    )
}
