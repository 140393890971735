import React, { useEffect, useState } from 'react'
import SiteFooter from '../../components/Parts/SiteFooter/SiteFooter'
import BeforeAfterImages from '../../components/UI/BeforeAfterImages'
import { Icon } from '../../components/UI/Icon'
import { SwiperHome } from '../../components/UI/SwiperHome'
import FaqItem from '../../components/UI/FaqItem'
import { Header } from '../../components/Parts/Header/Header';
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { getHomePageData, getSelectedLanguage, setCurrentPageData } from '../../store/pagesData/PagesDataSlice'
import { fetchHomePageData } from '../../store/pagesData/PagesDataThunk'
import MetaTags from 'react-meta-tags'

import { ReactComponent as CrownIcon } from "../../assets/img/icons/icon-crown.svg"
import { setActiveGenerationTab } from '../../store/Generation/GenerationSlice'
import { FeatureType, IFaq } from '../../types'

const Shapes = ({ count, className = "" }: { count: number, className?: string }) => {
    return (
        <>
            {
                Array.from({ length: count }).map((_, index) => (
                    <span key={index} className={className} style={{ "--i": `${index}s` } as React.CSSProperties}></span>
                ))
            }
        </>
    )
}

const HomePage = () => {

    const dispatch = useAppDispatch();
    const selectedLanguage = useAppSelector(getSelectedLanguage);
    const navigate = useNavigate();

    const [openLogin, setOpenLogin] = useState(false)
    const [loading, setLoading] = useState(false)

    const languageFromStorage = localStorage.getItem('language');

    const fetchPage = async () => {
        setLoading(true);
        await dispatch(fetchHomePageData((selectedLanguage || languageFromStorage || '') as string));
        setLoading(false);
    }

    const getI = (sec: number) => {
        return { '--i': `${sec}s` } as React.CSSProperties
    }


    const getBackImg = (src: string) => {
        return { backgroundImage: `url(${require(`../../assets/img/${src}`)})` }
    }


    const home = useAppSelector(getHomePageData);
    const blocks = home?.page?.blocks;
    const faqs: IFaq[] = home?.faqs;
    const token = localStorage.getItem('token');

    const fromStrings = (value: string) => {
        return home?.strings?.[value] || value;
    }

    const showLogin = (ev: any, featureType: FeatureType = "render") => {
        ev.preventDefault();

        dispatch(setActiveGenerationTab(featureType))

        if (token && token.length) {
            navigate("/render-interior");
            return;
        }
        setOpenLogin(false);
        setTimeout(() => {
            setOpenLogin(true);
        }, 100)
    }


    useEffect(() => {
        fetchPage();
    }, [selectedLanguage])

    useEffect(() => {
        dispatch(setCurrentPageData(home));
    }, [home])

    if (!home || !blocks || !blocks?.length) {
        return (
            <>
                {/* <Header setLoginModal={openLogin}/> */}

                <div className="wrapper header-fixed">
                    <main className="content landing-content px-[16px]">
                        <section className="section mt-[16px] d-flex align-items-center justify-center" style={{ height: 'calc(100vh - 112px)' }}>
                            <div className="features-item__icon">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.999 30.005V22.5019C14.999 21.6731 15.6709 21.0013 16.4996 21.0013H19.5009" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.49463 15.8694L16.047 5.96717C17.171 5.00376 18.8295 5.00376 19.9535 5.96717L31.5059 15.8694" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.0003 30.005H4.49463" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M23.438 8.9539V5.61978C23.438 4.9982 23.9419 4.49431 24.5635 4.49431H28.4514C29.073 4.49431 29.5769 4.9982 29.5769 5.61978V14.2159" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.42365 14.216V30.005" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27.7541 31.5056C27.7541 28.6049 25.4027 26.2534 22.502 26.2534C25.4027 26.2534 27.7541 23.902 27.7541 21.0013C27.7541 23.902 30.1056 26.2534 33.0063 26.2534C30.1056 26.2534 27.7541 28.6049 27.7541 31.5056Z" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <div className="features-item__shape" style={getI(0)}></div>
                                <div className="features-item__shape" style={getI(1)}></div>
                                <div className="features-item__shape" style={getI(2)}></div>
                                <div className="features-item__shape" style={getI(3)}></div>
                                <div className="features-item__shape" style={getI(4)}></div>
                                <div className="features-item__shape" style={getI(5)}></div>
                            </div>
                        </section>
                    </main>
                </div>
            </>

        )
    }

    return (
        <>
            <MetaTags>
                <title>{home?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={home?.page?.meta_description || ""} />
            </MetaTags>

            <Header
                setLoginModal={openLogin}
            />

            <div className="wrapper header-fixed">
                <main className="content">
                    <section className="section">
                        <div className="container container-830">
                            <h1 className="section-title text-white--gradient mb-[60px] text-left">
                                {
                                    blocks[0].data.title.value
                                    ||
                                    "Elevate Interior Design with Our Cutting-Edge AI Tools"
                                }
                            </h1>
                            <SwiperHome className='swiper swiper-hero mb-[60px]'>
                                <img src={require("../../assets/img/img-25-04-24/home_hero_slide-1.jpg")} alt="img" />
                                <img src={require("../../assets/img/img-25-04-24/home_hero_slide-2.jpg")} alt="img" />
                                <img src={require("../../assets/img/img-25-04-24/home_hero_slide-3.jpg")} alt="img" />
                            </SwiperHome>
                            <div className="color-secondary-07 text-[18px]">
                                <p>
                                    {
                                        blocks[0].data.text.value
                                        ||
                                        "Discover the future of home decor with our revolutionary AI tools! Transform your spaces with upscale, personalized design solutions effortlessly. Whether you're updating a room or an entire home, our AI makes it easy to bring your vision to life. Start creating your dream space today!"
                                    }
                                </p>
                            </div>
                            <div className="w-full flex justify-start">
                                <div className="btn-shapes btn-shapes--xl btn-shapes--left !mx-[0px]">
                                    <div className="shadow-efect-2"></div>
                                    <div className="shadow-efect-1"></div>
                                    <div className="border-efects">
                                        <Shapes count={3} />
                                    </div>
                                    {/* <a href="" className="btn btn--gradient-green text-[18px] font-[600] btn--xl min-w-[250px] rounded-[300px]">
                                        Get Started Now
                                    </a> */}
                                    <a
                                        href=""
                                        className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]"
                                        onClick={(e) => { showLogin(e, "render") }}
                                    >
                                        {blocks[0].data.button.value || "Get Started Now"}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1024">
                            <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                <div>
                                    <h2 className="section-title text-left mb-[24px]">{blocks[1].data.title.value || "Interior Design with AI"}</h2>
                                    <p className="color-secondary-07 text-[18px]">
                                        Enhance your image's resolution and achieve crisp, clear quality with a single click.
                                    </p>
                                </div>
                                {/* <a href="#" className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">Try Now</a> */}
                                <a
                                    href=""
                                    className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]"
                                    onClick={(e) => showLogin(e, "upscale")}
                                >
                                    {"Try Now"}
                                </a>
                            </div>
                            <div className="row g-24">
                                <div className="col-md-5">
                                    <div className="features-item">
                                        <div className="features-item__icon">
                                            <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" />
                                            <Shapes className='features-item__shape' count={6} />

                                        </div>
                                        <div className="features-item__text">
                                            <h4 className="mb-[16px] font-[400]">{blocks[1].data.items.blocks[0].title.value || "Design Interior"}</h4>
                                            <p>{blocks[1].data.items.blocks[0].text.value || "Variety of settings to generate perfect interior for your needs."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="row g-24">
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    {/* <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" /> */}
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mb-[20px]">
                                                        <path d="M23.25 16.5H16.5" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <ellipse cx="14.25" cy="16.5" rx="2.25" ry="2.25" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.75 24H19.5" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <ellipse cx="21.75" cy="24" rx="2.25" ry="2.25" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5 25.5V16.65C31.5 14.5995 30.5679 12.6601 28.9667 11.3791L22.2167 5.97915C19.7515 4.00695 16.2485 4.00695 13.7833 5.97915L7.03329 11.3791C5.4321 12.6601 4.5 14.5995 4.5 16.65V25.5C4.5 28.8137 7.18629 31.5 10.5 31.5H25.5C28.8137 31.5 31.5 28.8137 31.5 25.5Z" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <Shapes className='features-item__shape' count={6} />
                                                </div>
                                                <div className="features-item__text">
                                                    <div className="features-item__text">
                                                        <h4 className="mb-[16px] font-[400]">{blocks[1].data.items.blocks[1].title.value || "Transform your existing room"}</h4>
                                                        <p>{blocks[1].data.items.blocks[1].text.value || "Upload an image of your room and our AI will restyle it with your chosen design preferences."}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    {/* <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" /> */}
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mb-[20px]">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5 18H31.5V24C28.1863 24 25.5 21.3137 25.5 18Z" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5 4.5C29.2614 4.5 31.5 6.73858 31.5 9.5V26.5C31.5 29.2614 29.2614 31.5 26.5 31.5H9.5C6.73858 31.5 4.5 29.2614 4.5 26.5V9.5C4.5 6.73858 6.73858 4.5 9.5 4.5L26.5 4.5Z" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 12V4.5" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 24V31.5" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M22.5 4.5V12" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M4.5 18H13.5" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <Shapes className='features-item__shape' count={6} />
                                                </div>
                                                <div className="features-item__text">
                                                    <h4 className="mb-[16px] font-[400]">{blocks[1].data.items.blocks[2].title.value || "Manage room type"}</h4>
                                                    <p>{blocks[1].data.items.blocks[2].text.value || "No matter what type of room you're designing, we've got you covered."}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section">
                        <div className="container container-1024">
                            <h2 className="section-title mb-[60px]">Check Examples of Our Tool:</h2>
                            <div className="row flex items-center g-24 slider-images-home">
                                <div className="col-12 relative slider-images-home--full">
                                    <div className="slider-images-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        before={{ backgroundImage: `url(${require(`../../assets/img/1-dining-after.png`)})` }}
                                        after={{ backgroundImage: `url(${require(`../../assets/img/1-dining-before.jpg`)})` }}
                                    />
                                </div>
                                <div className="col-lg-6 relative">
                                    <div className="slider-images-shapes --right-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        before={{ backgroundImage: `url(${require(`../../assets/img/bedroom-after.png`)})` }}
                                        after={{ backgroundImage: `url(${require(`../../assets/img/bedroom-before.jpg`)})` }}
                                    />
                                </div>
                                <div className="col-lg-6 relative">
                                    <div className="slider-images-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        before={{ backgroundImage: `url(${require(`../../assets/img/lobby-after.png`)})` }}
                                        after={{ backgroundImage: `url(${require(`../../assets/img/lobby-before.jpg`)})` }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section">
                        <div className="container container-1024">
                            <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                <div>
                                    <h2 className="section-title text-left mb-[24px]">{"Upscale Your Interior"}</h2>
                                    <p className="color-secondary-07 text-[18px]">
                                        Enhance your image's resolution and achieve crisp, clear quality with a single click.
                                    </p>
                                </div>
                                {/* <a href="#" className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">Try Now</a> */}
                                <a
                                    href=""
                                    className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]"
                                    onClick={(e) => showLogin(e, "upscale")}
                                >
                                    {"Try Now"}
                                </a>
                            </div>
                            <div className="row g-24">
                                <div className="col-md-5">
                                    <div className="features-item">
                                        <div className="features-item__icon">
                                            {/* <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" /> */}
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mb-[20px]">
                                                <path d="M20.3406 8.80719L17.674 6.14508L15.0059 8.80719" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.6741 15.4775V6.14508" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M27.0107 20.8122L29.6789 18.1501L27.0107 15.482" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21.6748 18.1501H29.6791" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33556 15.4775L5.66895 18.1501L8.33556 20.8122" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.6718 18.1501H5.66895" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16895 10.6469V8.01634C4.17143 6.15336 5.68237 4.64444 7.54535 4.64444H10.1714" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M25.1777 4.64444H27.8038C29.6686 4.64444 31.1802 6.15611 31.1802 8.02085V10.6469" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M31.1802 25.6532V28.2793C31.1794 30.1437 29.6682 31.6549 27.8038 31.6557H25.1777" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.1714 31.6557H7.54535C5.68061 31.6557 4.16895 30.144 4.16895 28.2793V25.6532" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0059 27.4825L17.674 30.1551L20.3406 27.487" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.6741 20.8122V30.1551" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <Shapes className='features-item__shape' count={6} />
                                        </div>
                                        <div className="features-item__text">
                                            <h4 className="mb-[16px] font-[400]">{"Upscale Pics with AI"}</h4>
                                            <p>{"Boost your photos with AI-powered upscaling."}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="row g-24">
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    {/* <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" /> */}
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mb-[20px]">
                                                        <rect x="4.49414" y="4.49438" width="27.0112" height="27.0112" rx="3.88889" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M14.2484 14.1734C14.2898 14.1734 14.3234 14.207 14.3234 14.2485C14.3234 14.2899 14.2898 14.3235 14.2484 14.3235C14.2069 14.3235 14.1733 14.2899 14.1733 14.2485C14.1733 14.207 14.2069 14.1734 14.2484 14.1734" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M18.0003 10.4219C18.0418 10.4219 18.0753 10.4555 18.0753 10.4969C18.0753 10.5383 18.0418 10.5719 18.0003 10.5719C17.9589 10.5719 17.9253 10.5383 17.9253 10.4969C17.9253 10.4555 17.9589 10.4219 18.0003 10.4219" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M21.7513 14.1734C21.7927 14.1734 21.8263 14.207 21.8263 14.2485C21.8263 14.2899 21.7927 14.3235 21.7513 14.3235C21.7099 14.3235 21.6763 14.2899 21.6763 14.2485C21.6763 14.2286 21.6842 14.2095 21.6982 14.1954C21.7123 14.1813 21.7314 14.1734 21.7513 14.1734" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.5714 10.4969C10.5714 10.5383 10.5378 10.5719 10.4964 10.5719C10.455 10.5719 10.4214 10.5383 10.4214 10.4969C10.4214 10.4555 10.455 10.4219 10.4964 10.4219C10.5378 10.4219 10.5714 10.4555 10.5714 10.4969" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M25.5032 10.4219C25.5447 10.4219 25.5783 10.4555 25.5783 10.4969C25.5783 10.5383 25.5447 10.5719 25.5032 10.5719C25.4618 10.5719 25.4282 10.5383 25.4282 10.4969C25.4282 10.4555 25.4618 10.4219 25.5032 10.4219" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M18.0003 18.075C17.9589 18.075 17.9253 18.0414 17.9253 18C17.9253 17.9586 17.9589 17.925 18.0003 17.925C18.0418 17.925 18.0753 17.9586 18.0753 18C18.0753 18.0414 18.0418 18.075 18.0003 18.075" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M25.4282 18C25.4282 17.9586 25.4618 17.925 25.5032 17.925C25.5447 17.925 25.5783 17.9586 25.5783 18C25.5783 18.0414 25.5447 18.075 25.5032 18.075C25.4618 18.075 25.4282 18.0414 25.4282 18" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.4964 18.075C10.455 18.075 10.4214 18.0414 10.4214 18C10.4214 17.9586 10.455 17.925 10.4964 17.925C10.5378 17.925 10.5714 17.9586 10.5714 18C10.5714 18.0414 10.5378 18.075 10.4964 18.075" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M14.2484 21.6765C14.2898 21.6765 14.3234 21.7101 14.3234 21.7516C14.3234 21.793 14.2898 21.8266 14.2484 21.8266C14.2069 21.8266 14.1733 21.793 14.1733 21.7516C14.1733 21.7101 14.2069 21.6765 14.2484 21.6765" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M21.7513 21.6765C21.7927 21.6765 21.8263 21.7101 21.8263 21.7516C21.8263 21.793 21.7927 21.8266 21.7513 21.8266C21.7099 21.8266 21.6763 21.793 21.6763 21.7516C21.6763 21.7317 21.6842 21.7126 21.6982 21.6985C21.7123 21.6844 21.7314 21.6765 21.7513 21.6765" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M18.0003 25.5782C17.9589 25.5782 17.9253 25.5446 17.9253 25.5032C17.9253 25.4617 17.9589 25.4281 18.0003 25.4281C18.0418 25.4281 18.0753 25.4617 18.0753 25.5032C18.0753 25.5446 18.0418 25.5782 18.0003 25.5782" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M25.4282 25.5032C25.4282 25.4617 25.4618 25.4281 25.5032 25.4281C25.5447 25.4281 25.5783 25.4617 25.5783 25.5032C25.5783 25.5446 25.5447 25.5782 25.5032 25.5782C25.4618 25.5782 25.4282 25.5446 25.4282 25.5032" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.4964 25.5782C10.455 25.5782 10.4214 25.5446 10.4214 25.5032C10.4214 25.4617 10.455 25.4281 10.4964 25.4281C10.5378 25.4281 10.5714 25.4617 10.5714 25.5032C10.5714 25.5446 10.5378 25.5782 10.4964 25.5782" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <Shapes className='features-item__shape' count={6} />
                                                </div>
                                                <div className="features-item__text">
                                                    <h4 className="mb-[16px] font-[400]">{"Fix Pixelation & Blur"}</h4>
                                                    <p>{"Smooth out pixelation and sharpen blurry images."}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    {/* <Icon src="img/feature-house.svg" className="mt-[10px] mb-[20px]" alt="" /> */}
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[10px] mb-[20px]">
                                                        <circle cx="18" cy="12.75" r="8.25" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M25.8724 15.2216C29.3132 16.0196 31.8635 18.9205 32.2143 22.4352C32.5651 25.9499 30.6385 29.2976 27.4233 30.76C24.2082 32.2224 20.4187 31.4747 18 28.9006" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.1273 15.2216C6.82873 15.9954 4.33996 18.7093 3.85404 22.0624C3.36813 25.4155 4.98411 28.7243 7.92733 30.4026C10.8706 32.0809 14.5411 31.7867 17.1794 29.661C19.8177 27.5353 20.886 24.0113 19.8722 20.7784" stroke="#9EE22E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <Shapes className='features-item__shape' count={6} />
                                                </div>
                                                <div className="features-item__text">
                                                    <h4 className="mb-[16px] font-[400]">{"Adjust Colors & Lighting"}</h4>
                                                    <p>{"Refine colors and optimize lights for enhanced quality."}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section">
                        <div className="container container-1024">
                            <h2 className="section-title mb-[60px]">Upscale Your Pictures</h2>
                            <div className="row flex items-center g-24 slider-images-home">
                                <div className="col-12 relative slider-images-home--full">
                                    <div className="slider-images-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        after={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-1-img-1.png`)})` }}
                                        before={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-1-img-2.png`)})` }}
                                    />
                                </div>
                                <div className="col-lg-6 relative">
                                    <div className="slider-images-shapes --right-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        after={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-2-img-1.png`)})` }}
                                        before={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-2-img-2.png`)})` }}
                                    />
                                </div>
                                <div className="col-lg-6 relative">
                                    <div className="slider-images-shapes">
                                        <Shapes className='features-item__shape' count={5} />
                                    </div>
                                    <BeforeAfterImages
                                        className='widthout-image-text'
                                        before={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-3-img-1.png`)})` }}
                                        after={{ backgroundImage: `url(${require(`../../assets/img/upscale-your-pictures-slide-3-img-2.png`)})` }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <h2 className="mb-[24px] text-center">Frequently Asked Questions</h2>
                            <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">You have questions. We have answers.</p>

                            <div className="faq-wrapper">
                                {faqs?.length && faqs?.map((faq, index) => {
                                    return (
                                        <FaqItem
                                            key={faq.id}
                                            initOpen={index === 0}
                                            title={faq.question}
                                            content={faq.answer}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="getting-section" style={{
                        background: `linear-gradient(360deg, #101014 0%, rgba(16, 16, 20, 0.00) 100%), url(${require('../../assets/img/get-section-image.png')}), #101014 50% / cover no-repeat`,
                        backgroundBlendMode: 'normal, luminosity',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}>
                        <div className="container">
                            <h2 className="section-title mb-[48px]">
                                {
                                    blocks[2].data.title.value
                                    ||
                                    "Get Design Sense PRO Account"
                                }
                            </h2>
                            <div className="section-text text-center">
                                <p className="mb-[16px]">
                                    {/* <Icon src="img/smale-1.png" className="mr-[10px]" alt="" />  */}
                                    {blocks[2].data.items.blocks[0].title.value
                                        ||
                                        "🤩 Download photos without watermarks"
                                    }
                                </p>
                                <p className="mb-[16px]">
                                    {/* <Icon src="img/smale-2.png" className="mr-[10px]" alt="" />  */}
                                    {
                                        blocks[2].data.items.blocks[1].title.value
                                        ||
                                        "🏡 Unlimited render amount"
                                    }
                                </p>
                                <p>
                                    {/* <Icon src="img/smale-3.png" className="mr-[10px]" alt="" />  */}
                                    {
                                        blocks[2].data.items.blocks[2].title.value
                                        ||
                                        "💎 The best quality of render"
                                    }
                                </p>
                            </div>
                            <div className="btn-shapes">
                                <div className="border-efects w-212">
                                    <span style={getI(0)}></span>
                                    <span style={getI(1)}></span>
                                    <span style={getI(2)}></span>
                                </div>
                                <a href=""
                                    className="btn btn--gradient-blue text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                    <span className="icon mr-[10px]">
                                        <CrownIcon />
                                    </span>
                                    {blocks[2].data.button.value
                                        ||
                                        "Try Pro Account"
                                    }
                                </a>
                            </div>
                        </div>
                    </section>
                </main>

            </div>
            <SiteFooter currentPageData={home} />
        </>
    )
}

export default HomePage