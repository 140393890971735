import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from '../../api/axios';
import {LoginDataType, RegisterDataType} from "../../components/Popups/Popup";

export const login = createAsyncThunk(
    '/login',
    async (loginDto: LoginDataType, { rejectWithValue }) => {
        try {
            const res = await Api().auth.login(loginDto);
            
            const token = res.data?.data?.token || '';
            localStorage.setItem('token', token);

            localStorage.setItem('last_login', new Date().toLocaleDateString());

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const register = createAsyncThunk(
    '/register',
    async (registerDto: RegisterDataType, { rejectWithValue }) => {
        try {
            const res = await Api().auth.register(registerDto);
            const token = res.data?.data?.token || '';
            localStorage.setItem('token', token);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const forgotPassword = createAsyncThunk(
    '/forgot-password',
    async (email: string, { rejectWithValue }) => {
        try {
            const res = await Api().auth.forgotPassword(email);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);