import React, {useState, useEffect} from 'react'
import { Icon } from '../../../components/UI/Icon'
import getI from '../../../helpers/getI'
import {ReactComponent as DotsMenuIcon} from "../../../assets/img/icons/dots-menu.svg"
import { Popup } from "../../../components/Popups/Popup";
import {AddMemberPopup} from "../../../components/Popups/AddMemberPopup";
import {NoRendersPopup} from "../../../components/Popups/NoRendersPopup";
import { NotificationManager} from 'react-notifications';
import { Api } from "../../../api/axios";
import { getProfile } from '../../../store/profile/ProfileSlice';
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";
import { useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

import { fetchMyTeamPageData } from '../../../store/pagesData/PagesDataThunk';
import { getMyTeamPageData, getSelectedLanguage, setCurrentPageData } from '../../../store/pagesData/PagesDataSlice';

enum Level {
    ADMIN = "Admin",
    USER = "User"
}

enum Status {
    ACTIVE = "active",
    INVITE_PENDING = "pending",
    SENDING_INVITE = "sending invite"
}

interface IUser{
    image: string,
    name: string, 
    email: string,
    level: Level,
    status: Status
}

export const MyTeam = () => {
    const dispatch = useAppDispatch();
    const initialTeam: any[] = [];
    const navigate = useNavigate();

    let pageData = useAppSelector(getMyTeamPageData);
    const [teams, setTeams] = useState(initialTeam);
    
    const profile = useAppSelector(getProfile);
    const [deleteLoading, setDeleteLoading] = useState<null|string|number>(null);
    const [teamsLoading, setTeamsLoading] = useState(false);
    const [upgradeToProOpened, setUpgradeToProOpened] = useState(false);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fromStrings = (value:string) => {
        return pageData?.strings?.[value] || value;
    }

    const getTeams = async() => {
        setTeamsLoading(true);
        await Api().team.getTeam()
            .then(r => {
                if(r.data && r.data.success) {
                    setTeams(r.data.members);
                }
            })
        setTeamsLoading(false);
    }

    useEffect(() => {
        dispatch(fetchMyTeamPageData((selectedLanguage || languageFromStorage || '') as string));
    }, [selectedLanguage])

    useEffect(() => {
		dispatch(setCurrentPageData(pageData));
	}, [pageData])

    useEffect(() => {
        getTeams()
    }, [])

    let initialsUsers:IUser[] = [
        {
            name: 'Wade Warren',
            email: 'tim.jennings@example.com',
            image: 'img/ava-1.png',
            level: Level.ADMIN,
            status: Status.ACTIVE
        },
        {
            name: 'Kristin Watson',
            email: 'bill.sanders@example.com',
            image: 'img/ava-2.png',
            level: Level.USER,
            status: Status.ACTIVE
        },
        {
            name: 'Brooklyn Simmons',
            email: 'curtis.weaver@example.com',
            image: 'img/ava-def.svg',
            level: Level.USER,
            status: Status.INVITE_PENDING
        },
    ]
    const [users, setUsers] = useState<IUser[]>(initialsUsers);

    const [addMemberPopupOpen, setAddMemberPopupOpen] = useState(false);
    const [inviteEmail, setInviteEmail] = useState<string|null>(null);

    const onAddUser = (email: string) => {
        setInviteEmail(email);
        setAddMemberPopupOpen(false);
        setConfirmPopupOpen(true);
    }

    const confirmInvite = async() => {
        setConfirmPopupOpen(false);
        const tempUser:any = {
            email: inviteEmail,
            member_id: Math.random() * 100,
            name: "New User",
            role: "member",
            status: Status.SENDING_INVITE
        };
        setTeams([...teams, tempUser])
        await Api().team.inviteToTeam(inviteEmail as string)
            .then(r => {
                if(r.data.success) {
                    getTeams();

                    NotificationManager.success("The price for the plan changes with each added user");
                } else {
                    setTeams(teams)
                    NotificationManager.error(r.data.errors[0] ? r.data.errors[0] : 'Something went wrong');
                }
            })

        setInviteEmail(null);
    }

    const deactivate = (ev:any) => {
        if(!ev.target.classList.contains('dots-menu-dropdown') && !ev.target.classList.contains('dots-menu')) {
            document.querySelector('.dots-menu.active')?.classList.remove('active');
            document.removeEventListener('click', deactivate);
        }
        
    }

    const dotsMenuClick = (event: any) => {
        document.querySelector('.dots-menu.active')?.classList.remove('active');

        event.preventDefault();
        event.target.classList.toggle('active');

        document.addEventListener('click', deactivate)
    }

    const deleteMemberClick = async(member_id: number) => {
        document.querySelector('.dots-menu.active')?.classList.remove('active');

        setDeleteLoading(member_id);
        await Api().team.removeFromteam(member_id)
            .then(r => {
                if(r.data.success) {
                    getTeams()
                } else {
                    NotificationManager.error(r.data.errors[0] ? r.data.errors[0] : 'Something went wrong');
                }
            });
        setDeleteLoading(null);
    }

    const onInviteMemberClick = (ev:any) => {
        ev.preventDefault();
        if(profile && profile.can_invite_teammembers) {
            setAddMemberPopupOpen(true);
        } else {
            setUpgradeToProOpened(true);

            //TODO: открыть попап вместо редиректа ?
            // NotificationManager.info('Please subscribe to Pro plan to use Teams');
            // setTimeout(() => {
            //     navigate("/pricing");
            // }, 2500)
        }
        
        
    }

    return (
        <>
            <MetaTags>
                <title>{ pageData?.page?.meta_title || "Design Sense AI" }</title>
                <meta name="description" content={ pageData?.page?.meta_description || "" } />
            </MetaTags>

            <div className="full-page-section__content">
                <div className="section-scroll-container">
                    <div className="settings">
                        <div className="flex items-center justify-between min-h-[51px]">
                            <h4 className="mb-[16px] mr-[20px]">{fromStrings("My Team")}</h4>
                            { (profile?.team_role === "user" && profile?.is_premium) || !profile ? null :
                            <a 
                                href="#" className={`btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[16px] ${teamsLoading ? 'disabled' : ''}`}
                                onClick={onInviteMemberClick}
                            >{fromStrings("+ Invite Member")}</a> }
                        </div>

                        { users.length
                        // table-wrapper
                            ? <div>
                                <table className="table">
                                    <thead className={teamsLoading ? `skeleton` : ''}>
                                        <tr>
                                            <th>
                                                {fromStrings("User")}
                                            </th>
                                            <th>
                                                {fromStrings("Level")}
                                            </th>
                                            <th>
                                                {fromStrings("Status")}
                                            </th>
                                            <th style={{width: '10px'}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                        teamsLoading ? <tr className="skeleton">
                                            <td>
                                                <span style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span>
                                            </td>
                                            <td>
                                                <span style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span>
                                            </td>
                                            <td>
                                                <span style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span>
                                            </td>
                                            <td></td>
                                        </tr> :
                                        
                                        // email: "1kruler1@gmail.com"
                                        // member_id: 2
                                        // name: "Test"
                                        // role: "admin"
                                        // status: "admin"
                                            teams.map((user:any, index)=>{
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="flex items-center">
                                                                <div className="user-box-36x36 mr-[12px]">
                                                                    { user.avatar ? <img src={user.avatar}/> : <img src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + user.name}/>}
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className="block text-[14px] mb-[4px]">{user.name}</span>
                                                                    <span className="block text-[12px] opacity-60">{user.email}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {fromStrings(user.role)}
                                                        </td>
                                                        <td className={`${user.status===Status.INVITE_PENDING && 'opacity-60'}`} style={{ textTransform: 'capitalize'}}>
                                                            {fromStrings(user.status)}
                                                        </td>
                                                        <td style={{ position: 'relative' }} >
                                                            { profile?.team_role === "user" && profile?.is_premium ? null :
                                                                deleteLoading === user.member_id || user.status===Status.SENDING_INVITE ?
                                                                <svg
                                                                    v-if="autofillLoading"
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg> : <>
                                                                <a className='dots-menu d-flex items-center' onClick={dotsMenuClick} style={{
                                                                    pointerEvents: (user.role === 'admin' || user.status === Status.SENDING_INVITE) ? 'none' : 'all',
                                                                    opacity: user.role === 'admin' ? '0' : '1'
                                                                }}>
                                                                    <DotsMenuIcon></DotsMenuIcon>
                                                                </a>
                                                                <div className="dots-menu-dropdown" onClick={() => deleteMemberClick(user.member_id)}>
                                                                    {fromStrings("Delete Team Member")}
                                                                </div>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :<>
                                <div className="empty-page">
                                    <div className="features-item__icon">
                                        <Icon src="img/icons/icon-green-group- user.svg" alt=""/>
                                        <div className="features-item__shape" style={getI(0)}></div>
                                        <div className="features-item__shape" style={getI(1)}></div>
                                        <div className="features-item__shape" style={getI(2)}></div>
                                        <div className="features-item__shape" style={getI(3)}></div>
                                        <div className="features-item__shape" style={getI(4)}></div>
                                        <div className="features-item__shape" style={getI(5)}></div>
                                    </div>
                                    <div className="max-w-[231px]">
                                        <h4 className="mb-[12px] font-[400]">{fromStrings("No Members")}</h4>
                                        <p className="text-[14px] color-secondary-07">{fromStrings("No members yet. You can add someone to your team.")}</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            <Popup
                modalDialogWidth="572"
                open={addMemberPopupOpen}
                onClose={() => setAddMemberPopupOpen(false)}
            >
                <AddMemberPopup
                    close={() => {setAddMemberPopupOpen(false); }}
                    add={onAddUser}
                />
            </Popup>

            { upgradeToProOpened ? <Popup
                modalDialogWidth="460"
                open={upgradeToProOpened}
                onClose={()=>{setUpgradeToProOpened(false)}} 
            >
                <NoRendersPopup
                    close={()=>{setUpgradeToProOpened(false)}} 
                ><div className="no-renders-popup-text text-[14px] color-secondary-07 no-renders-popup-text--margins pt-[50px]" dangerouslySetInnerHTML={{__html: fromStrings(`To access this feature, please upgrade your <br /> account.`) }}></div></NoRendersPopup>
            </Popup> : null }

            <Popup
                modalDialogWidth="460"
                open={confirmPopupOpen}
                onClose={()=>{setConfirmPopupOpen(false)}} 
            >
                <div className="modal__content">
                    <div className="modal__header mb-[32px] text-center items-center">
                        <h4 className="font-[400] mb-[12px]">{fromStrings("Confrim Send Invite")}</h4>
                        <p className="color-secondary opacity-70 text-[14px]">{fromStrings("Please note that upon acceptance of the invitation, you will be charged a prorate amount for the current billing cycle. This prorate charge will be calculated based on the number of days remaining in the current cycle.")}</p>
                    </div>

                    <div className="modal__footer justify-center">
                        <a
                            href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); setConfirmPopupOpen(false)}}
                            className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]"
                        >
                            {fromStrings("Cancel")}
                        </a>
                        <a
                            href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); confirmInvite()}}
                            className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]"
                        >
                            {fromStrings("Confrim")}
                        </a>
                    </div>
                </div>
            </Popup>
        </>
    )
}
