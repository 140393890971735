import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { IGenerationOptions } from './types'
import { fetchGenerationOptions } from './GenerationOptionsThunk'
// import { fetchUserById } from './'

// Define a type for the slice state

// Define the initial state using that type
const initialState: IGenerationOptions = {
    // data: null;
}

export const GenerationOptionsSlice = createSlice({
    name: 'generationOptions',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGenerationOptions.pending, (state, {payload}) => {
            state.loading = true;
        });
        builder.addCase(fetchGenerationOptions.fulfilled, (state, {payload}) => {
            state.data = payload;
            state.loading = false;
        });
        builder.addCase(fetchGenerationOptions.rejected, (state, {payload}) => {
            state.loading = false;
        });
    }
})

// actions
export const getGenerationOptions = (state:RootState) => state.generationOptions.data
export const getGenerationOptionsLoading = (state:RootState) => state.generationOptions.loading

export default GenerationOptionsSlice.reducer