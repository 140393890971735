import * as yup from 'yup'


export const emailSchema = yup.object().shape({
    email: yup.string()
        .email('Enter a valid email')
        .required('Email is required')
})

export const loginSchema = yup.object().shape({
    password: yup.string()
        .required('Password is required')
        .min(8, 'Min length is a 8 characters'),
}).concat(emailSchema)

export const registerSchema = yup.object().shape({
    email: yup.string().required('Email is required'), 
    password: yup.string().required('password is required'), 
    name: yup.string().default(() => ""), 
    privacy_policy: yup.number().default(() => 1),
    password_confirmation: yup
        .string()
        .required('Password is required')
        .min(8, 'Min length is a 8 characters')
        .oneOf([yup.ref('password')], 'Password does not match'),
})

export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required('Email is required'), 
    password: yup.string().required('password is required'), 
    password_confirmation: yup
        .string()
        .required('Password is required')
        .min(8, 'Min length is a 8 characters')
        .oneOf([yup.ref('password')], 'Password does not match'),
})