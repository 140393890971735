import React, {useEffect, useMemo, useState} from 'react'
import {PopupComponentType} from "./Popup";
import { ReactComponent as IconSearch } from '../../assets/img/icons/icon-search.svg';
import { Icon } from '../UI/Icon';
import { IRenderStyle } from '../../store/GenerationOptions/types';

import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

type IProps = {
    close: ()=>void,
    confirm: (item:IRenderStyle)=>void,
    items: IRenderStyle[],
    selectedItemFromSidebar?: IRenderStyle

} & PopupComponentType

export const SelectRenderStylePopup = ({close, confirm, items, selectedItemFromSidebar}: IProps) => {
    const [search, setSearch] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<IRenderStyle>(items[0])

    const setSelectedItemHandler = (item: IRenderStyle) => {
        setSelectedItem(item);
        confirm(item);
    }

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    let filteredItems = useMemo(()=>{
        return items.filter(item=>item.name.toLowerCase().includes(search.toLowerCase()))
    }, [items,search ]);

    useEffect(()=>{
        if(selectedItemFromSidebar && selectedItemFromSidebar.id!==selectedItem.id){
            setSelectedItem(selectedItemFromSidebar);
        }
    }, [selectedItemFromSidebar])
    return (
      
        <>
            <div className="modal__header">
                <h4 className="font-[400] mb-[12px]">{ fromStrings("Select Render Style") }</h4>
                <p className="color-secondary opacity-70">{ fromStrings("Select render style that you want to apply to your interior") }</p>
            </div>
            <div className="modal__body select-popup">
                <div className="input-custom mb-[16px] mt-[32px]">
                    
                    <div className="input-custom__wrap-input">
                        <div className="input-custom__icon">
                            <IconSearch></IconSearch>
                        </div>
                        <input 
                            className="input-custom__input" 
                            type="text" 
                            placeholder="" 
                            value={search}
                            onInput={(event:React.ChangeEvent<HTMLInputElement>)=>{setSearch(event.target.value)}}
                        />
                    </div>
                </div>
                <div className="select-popup__wrapper g-20 ">
                    <div className="row " key={selectedItem.id}>
                        {filteredItems && filteredItems.map((item, index)=>{
                            return (<div className="col-xxl-3 col-lg-3 col-md-4 col-sm-6" key={item.id}>
                                <label className="card-check" >
                                    <input type="radio" checked={selectedItem.id===item.id} name="interior" id={item.id+'id'} onChange={()=>{setSelectedItemHandler(item)}}/>
                                    <div className="card-photo">
                                        <span className="ico ico-28 ico-success">
                                            <Icon src="img/icons/ico-success--primary.svg" alt=""/>
                                        </span>
                                        <img src={item.image} alt=""/>
                                        <div className="card-wrapper-info items-center">
                                            <div className="card-photo-info">
                                                {fromStrings(item.name)}
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>)
                            })
                        }
                    </div>
                </div>

            
            </div>
            <div className="modal__footer mt-[32px]">
                <a href="#" onClick={(event)=>{event.preventDefault(); close()}} className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                    {fromStrings("Cancel")}
                </a>
                {/* <a href="#" onClick={(event)=>{event.preventDefault(); confirm(selectedItem)}} className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                   Submit
                </a> */}
            </div>
        </>
    )
}
