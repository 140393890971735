import React, { useEffect, useState } from 'react'
import { Icon } from '../../components/UI/Icon'
import { Header } from '../../components/Parts/Header/Header';
import { ReactComponent as CrownIcon } from "../../assets/img/icons/icon-crown.svg"
import { getActiveGenerationTab } from '../../store/Generation/GenerationSlice';
import { useAppSelector } from '../../hooks/storeHooks';

const renderInteriorPlansData: Plan[] = [
    {
        "id": 1,
        "price_mo": "Free",
        "price_y": "Free",
        "title": "Basic",
        "description": "A single license. 1 free image.",
        "is_popular": 0,
        "features": [
            {
                "image": "https://www.designsense.ai/storage/plan_icons/RPRGp2AH7PIcalWUNU0jAxsBn8w9RdTKk0FP4sOT.png",
                "title": "Watermark",
                "description": "Render have a watermark."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/gwVN0Pv2wig0PxjwQahqC9AafKK1ZHuKBuda5tvL.png",
                "title": "Render Interior",
                "description": "One free image"
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/NUk8PSb0TR1ZzeoF7Iwc5R5qftZodE19iSpfeQKI.png",
                "title": "Good Quality",
                "description": "Generate fast in good quality."
            }
        ]
    },
    {
        "id": 2,
        "price_mo": 15.99,
        "price_y": 9.99,
        "title": "Pro",
        "description": "A single license. Perfect for starters.",
        "is_popular": 1,
        "features": [
            {
                "image": "https://www.designsense.ai/storage/plan_icons/Vi3DIMi1AOdu4ZYKnAeShOo2ics1MlWXzXC1yxvd.png",
                "title": "No Watermarks",
                "description": "Photos without watermarks."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/kNV7UuawBA1hEs90u9A7sOXOCK4e8RKWiK262Hb6.png",
                "title": "Unbounded Render",
                "description": "Unlimited render amount."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/74WJ8m611shRJvHAZ0XUCxpxWygNNVZKep7QcwpJ.png",
                "title": "HQ Export",
                "description": "The best quality of render."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/zihI42d0UHyKPATJrN1AOkqP5y7xajvfOETtTnuO.png",
                "title": "Team Access",
                "description": "Create and manage your team"
            }
        ]
    },
    {
        "id": 3,
        "price_mo": 49.99,
        "price_y": 39.99,
        "title": "Premium",
        "description": "A single license. Perfect for starters.",
        "is_popular": 0,
        "features": [
            {
                "image": "https://www.designsense.ai/storage/plan_icons/Adw4CKXBPR2ylB1wNSeXZDoVZx7rKqFUvmIjsUfC.png",
                "title": "Render Videos",
                "description": "Add motion to your pictures."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/TWdGCC1uHTPsL0NtCap0o59ajDSfgZBURlG7qSw0.png",
                "title": "No Watermarks",
                "description": "Photos without watermarks."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/YuImdRNBaj0PP1B7h9a3RFXKI1Gb762bDLX4ZPTC.png",
                "title": "Unbounded Render",
                "description": "Unlimited render amount."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/GRRJjMnaV1NJTMVxTJqA8ToNbDdcC2V7MjIr58Rs.png",
                "title": "HQ Export",
                "description": "The best quality of render."
            },
            {
                "image": "https://www.designsense.ai/storage/plan_icons/3KxavH08wT2YPZicj1zHub8JgrpGJUIz7TFl0B9f.png",
                "title": "Team Access",
                "description": "Create and manage your team"
            }
        ]
    }
]
const upscalerPlansData: Plan[] = [
    {
        "id": 2,
        "price_mo": 15.99,
        "price_y": 9.99,
        "title": "Pro",
        "description": "A single license. Perfect for starters.",
        "is_popular": 1,
        "features": [
            {
                "title": "2,500 tokens/mo",
            },
            {
                "title": "200 normal upscales/mo",
            },
            {
                "title": "100 large upscales/mo",
            },
        ]
    },
    {
        "id": 3,
        "price_mo": 49.99,
        "price_y": 39.99,
        "title": "Premium",
        "description": "A single license. Perfect for starters.",
        "is_popular": 0,
        "features": [
            {
                "title": "20,000 tokens/mo",
            },
            {
                "title": "1,600 normal upscales/mo",
            },
            {
                "title": "800 large upscales/mo",
            },
        ]
    },
    {
        "id": 3,
        "price_mo": 129.99,
        "price_y": 99.99,
        "title": "Enterprise",
        "description": "Great for a big amount of content.",
        "is_popular": 0,
        "features": [
            {
                "title": "6,500 tokens/mo",
            },
            {
                "title": "550 normal upscales/mo",
            },
            {
                "title": "250 large upscales/mo",
            },
        ]
    }
]

interface Plan {
    id: number;
    price_mo: "Free" | number;
    price_y: "Free" | number;
    title: string;
    description: string;
    is_popular: number;
    features: Feature[];
}

interface Feature {
    image?: string;
    title: string;
    description?: string;
}

interface SwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const SwitchComponent: React.FC<SwitchProps> = ({ checked, onChange }) => {
    const [value, setValue] = useState(checked)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked)
        onChange(event.target.checked);
    };

    return (
        <div className="switch-decor mb-[40px]">
            <span className="color-secondary text-[14px] opacity-70">Bill Monthly</span>
            <label className="switch mx-[14px]">
                <input
                    type="checkbox"
                    className="switch__field switch-pricing"
                    checked={value}
                    onChange={handleChange}
                />
                <span className="switch__slider"></span>
            </label>
            <span className="color-secondary text-[14px] opacity-70">Bill Yearly</span>
        </div>
    );
};

interface PricingCardProps {
    plan: Plan
    isYearly: boolean
}

const PricingCard: React.FC<PricingCardProps> = ({ plan, isYearly }) => {
    const isFree = plan.price_y === "Free" || plan.price_mo === "Free"
    return (
        <div className="pricing__card">
            <h4 className="mb-[8px]">{plan.title}</h4>
            <p className="text-[14px] color-secondary opacity-70 mb-[20px]">A single license. Perfect for starters.</p>
            <span className="block text-[32px] leading-[38px] font-[500] pb-[20px] mb-[20px] border-bottom">
                {
                    isYearly ? plan.price_y : plan.price_mo
                }
                {
                    !isFree ?
                        "$ / month, per user"
                        :
                        ""
                }
            </span>
            <ul className="pricing__list">
                {plan.features.map((feature, index) => (
                    <li key={index}>
                        <div className="pricing__item">
                            {
                                feature.image ?
                                    <div className="pricing__img-box">
                                        <img src={feature.image} alt="" />
                                    </div>
                                    :
                                    <div className="pricing__img-check">
                                        <Icon src='img/icons/icon-done-check-2.svg' alt='' />
                                    </div>
                            }
                            <div className="pricing__item-group-text">
                                <span className="text-[14px]">{feature.title}</span>
                                {
                                    feature.description &&
                                    <span className="color-secondary text-[12px] opacity-70">{feature.description}</span>
                                }
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {
                isFree ?
                    <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-3 rounded-[300px] w-full">
                        Get Started
                    </a>
                    :
                    <a href="" className="btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full">
                        <span className="icon mr-[10px]">
                            <CrownIcon />
                        </span>
                        Upgrade to {plan.title}
                    </a>
            }
        </div>
    );
};


interface TabProps {
    tabs: string[];
    handleChange: (index: number, tab: string) => void
}

const TabComponent: React.FC<TabProps> = ({ tabs, handleChange }) => {
    const activeGenerationTab = useAppSelector(getActiveGenerationTab)
    const [activeTab, setActiveTab] = useState(activeGenerationTab === "render" ? 0 : 1);


    const handleTabClick = (index: number, tab: string) => {
        setActiveTab(index);
        handleChange && handleChange(index, tab)
    };

    return (
        <>
            <div className="page-menu-list mb-[36px]">
                {tabs.map((tab, index) => (
                    <a
                        key={index}
                        href="#"
                        className={`btn btn--lg ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab)}
                    >
                        {tab}
                    </a>
                ))}
            </div>
        </>
    );
};




export const PricingV2 = () => {
    const [plans, setPlans] = useState(renderInteriorPlansData)
    const [openLogin, setOpenLogin] = useState(false)
    const [isYearly, setIsYearly] = useState(false)
    const [tab, setTab] = useState('Render Interior')

    useEffect(() => {
        setPlans(tab === "Render Interior" ? renderInteriorPlansData : upscalerPlansData)
    }, [tab])

    return (
        <>
            <Header setLoginModal={openLogin} />
            <div className="wrapper header-fixed">
                <main className="content pricing-page">
                    <section className="section bg-decor">
                        <div className="container container-1100">
                            <h2 className="mb-[24px] text-center">Choose Your Perfect Plan</h2>
                            <p className="text-[18px] text-center color-secondary opacity-70 mb-[40px]">Flexible pricing to match your needs</p>
                            <TabComponent
                                tabs={["Render Interior", "Upscaler"]}
                                handleChange={(i, tab) => setTab(tab)}
                            />
                            <SwitchComponent
                                checked={isYearly}
                                onChange={(checked) => setIsYearly(checked)}
                            />
                            <div className="pricing">
                                <div className="row g-24">
                                    {plans.map(plan => (
                                        <div className='col-12 col-md-6 col-lg-4'>
                                            <PricingCard
                                                key={plan.id}
                                                plan={plan}
                                                isYearly={isYearly}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <h2 className="mb-[24px] text-center">Explore Our Mobile Apps</h2>
                            <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">Download Design Sense for your smartphone</p>

                            <div className="flex-col sm:flex-row flex items-center justify-center">
                                <div className="flex flex-col">
                                    <a href="#" className="mb-[20px]">
                                        <Icon src='img/icon-app-store.svg' alt="" />
                                    </a>
                                    <a href="#">
                                        <Icon src='img/icon-google-play.svg' alt="" />
                                    </a>
                                </div>
                                <div className="qr-code-wrap">
                                    <Icon src='img/icon-qr-code.svg' alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div>
        </>
    )
}
