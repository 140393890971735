import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as IcoCoin } from "../../../assets/img/ico-vertical-coin.svg"
import { ReactComponent as IcoDelete } from "../../../assets/img/icons/ico-delete.svg"
import { ReactComponent as IcoStars } from "../../../assets/img/icons/ico-stars.svg"


import Nouislider from 'nouislider-react'
import { NotificationManager } from 'react-notifications'
import { Tooltip } from 'react-tooltip'
import { convertImageToBase64Src } from '../../../helpers/ImageFileReader'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { useImageUpload } from '../../../hooks/useImageGenerate'
import { clearUpscaleErrors, setImageToUpscale, setIsUpscaleGenerating } from '../../../store/upscale/upscaleSlice'
import { NoUpscalePopup } from '../../Popups/NoUpscalePopup'
import { Popup } from '../../Popups/Popup'

interface RangeSliderProps {
    defaultValue: number | string;
    value: number | string;
    label: string
    onChange: (value: string | number) => void;
    min: number,
    max: number,
    step: number,
    tooltipBtn?: any
    convertedMin?: number
    convertedMax?: number
}


const RangeSlider: React.FC<RangeSliderProps> = ({ defaultValue, value, onChange, label, min, max, step, tooltipBtn, convertedMin = -10, convertedMax = 10 }) => {

    const createConverter = (value: number | string): number => {
        const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
        const convertedValue = convertedMin + ((parsedValue - min) / (max - min)) * (convertedMax - convertedMin);
        // return Math.floor(+convertedValue.toFixed(1));
        return +convertedValue.toFixed(1);

    };

    const [range, setRange] = useState(createConverter(value));
    const sliderRef = useRef<any>(null);


    const handleChange = (values: number[]) => {
        const value = Number(values[0])
        setRange(createConverter(value))
        onChange(value)
    }
    const handleUpdate = (values: number[]) => {
        const value = Number(values[0])
        setRange(createConverter(value))
        console.log(value, createConverter(value));

    }

    const handleReset = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!sliderRef.current) return
        sliderRef.current.noUiSlider.reset()
        setRange(createConverter(defaultValue))
        onChange(defaultValue)
    }
    return (
        <div className="form-group">
            <div className="form-group--header">
                <label className="input-label flex items-center gap-[6px]">
                    {label}
                    {tooltipBtn}
                </label>
                <a href="#" onClick={handleReset} className="btn"><span className="fw-400 opacity-20">Reset</span> </a>
            </div>
            <div className="range-slider-group">
                <Nouislider
                    instanceRef={instance => {
                        if (instance && !sliderRef.current) {
                            sliderRef.current = instance
                        }
                    }}
                    range={{ min: min, max: max }}
                    start={value}
                    connect={[true, false]}
                    onChange={handleChange}
                    onUpdate={handleUpdate}
                    step={step}
                />
                <span className="range-slider-group__value">{range}</span>
            </div>
        </div>
    );
};

export const Upscale = () => {
    const dispatch = useAppDispatch()
    const { profile, profileLoading } = useAppSelector(state => state.profile)
    const { imageToUpscale, isUpscaleGenerating, isLoading, isError, errors } = useAppSelector(state => state.upscale)
    const [remainingTokens, setRemainingTokens] = useState<number>(profile?.remaining_tokens)

    useEffect(() => {
        if (!profile) return;
        setRemainingTokens(profile?.remaining_tokens)
    }, [profileLoading])

    const [modelData, setModelData] = useState<IUpscaleModelData>({
        image: null,
        prompt: "",
        scale_factor: 2,
        creativity: 0.35,
        hdr: 6,
        resemblance: 0.6,
        fractality: 112,
        sharpen: 0.35,
        engine: "reborn",
    })
    const [imageData, setImageData] = useState({
        width: 0,
        height: 0,
        finalWidth: 0,
        finalHeight: 0,
        // tokenCost: 0
    })
    const [imageSizeError, setImageSizeError] = useState(false)
    const [noUpscalePopup, setNoUpscalePopup] = useState(false)


    const engineOptions = [
        { label: "Sharp", value: "sharp" },
        { label: "Reborn", value: "reborn" },
        { label: "Natural", value: "natural" }
    ]

    const { uploadImage } = useImageUpload(modelData)
    let inputRef = useRef<HTMLInputElement>(null);

    const [dragActive, setDragActive] = React.useState<boolean>(false);
    const [costTokens, setCostTokens] = React.useState<number>(0);

    const tooltipIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
            <circle cx="9" cy="9" r="6.75281" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.26352 7.26349C7.44129 6.49732 8.13911 5.9661 8.92496 5.99871C9.80446 5.95 10.5584 6.62019 10.6132 7.49934C10.6132 8.62774 8.99999 8.99996 8.99999 9.75027" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.09379 11.8136C9.09379 11.8654 9.0518 11.9074 9 11.9074C8.94821 11.9074 8.90621 11.8654 8.90621 11.8136C8.90621 11.7618 8.94821 11.7198 9 11.7198" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99998 11.7199C9.05178 11.7199 9.09377 11.7619 9.09377 11.8137" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>;

    const engineTooltipContent = {
        "sharp": `Sharp excels in delivering photographic clarity and precision that closely mirrors the vivid, detailed aesthetic of a digital illustration. It boasts an extraordinary ability to enhance image sharpness, making every detail pop as if it were part of a richly colored urban scene at dusk.`,
        "reborn": `Embark on a transformational journey with your images by starting with Reborn, the adept at smoothing out landscapes, illustrations, and nature scenes while eradicating JPEG artifacts. This technique is ideal for the initial stages of multiple upscale processes. Then, complete your artistic rebirth by transitioning to Sharpy for the final pass, ensuring your images emerge with unparalleled realism and detail. This method helps in discovering the perfect stylistic blend for your images.`,
        "natural": `Natural operates as the harmonizing element within image enhancement, perfectly positioned between Reborn serene artistry and Sharpy's vivid realism.`,
    } as any;

    const calcTokenCost = (width: number, height: number) => {
        function roundPixels(value = 0, round_to = 500) {
            let mod = value % round_to
            let val = value + (mod < (round_to / 2) ? -mod : round_to - mod);
            if (val < round_to) {
                return round_to;
            }
            return val;
        }

        var width = width, height = height;
        var pixels = roundPixels(Math.max(width, height));
        var tokens = pixels / 100;

        // console.log('tokens:', tokens);
        // console.log('modelData.scale_factor:', modelData.scale_factor);

        return tokens;
    }

    const checkImageSizeError = () => {
        setImageSizeError(imageData.finalWidth * imageData.finalHeight >= 10000 * 10000)
    }

    const convertImage = (fileList: FileList) => {
        if (!fileList || !fileList.length) return;
        const image = fileList[0]
        if (image instanceof File) {
            const img = new Image()
            const url = URL.createObjectURL(image)
            img.src = url;
            img.onload = function () {
                URL.revokeObjectURL(url)
                const width = img.width
                const height = img.height
                setModelData(prev => ({ ...prev, image }))
                if (!fileList) return;
                convertImageToBase64Src(fileList, (base64Data) => {
                    dispatch(setImageToUpscale(base64Data))
                    const finalWidth = width * (+modelData.scale_factor);
                    const finalHeight = height * (+modelData.scale_factor)
                    setImageData({
                        height,
                        width,
                        finalWidth,
                        finalHeight
                    })
                    setCostTokens(calcTokenCost(finalWidth, finalHeight))
                });
            };
        }
    }
    useEffect(() => {
        checkImageSizeError()
    }, [imageData, modelData])

    useEffect(() => {
        if (imageData.width && imageData.height) {
            setCostTokens(calcTokenCost(imageData.width * (+modelData.scale_factor), imageData.height * (+modelData.scale_factor)))
            const finalWidth = imageData.width * (+modelData.scale_factor);
            const finalHeight = imageData.height * (+modelData.scale_factor)
            setImageData(prev => ({ ...prev, finalWidth, finalHeight }))
        }
    }, [modelData.scale_factor])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        if (fileInput.files) {
            convertImage(fileInput.files)
        }
    }
    const handleDrag = function (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {

            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        const fileInput = inputRef?.current as any;
        fileInput.value = null;

        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let doc = document as any;
            if (doc.querySelector('#upload-or-drag')) {
                doc.querySelector('#upload-or-drag').files = e.dataTransfer.files;
            }
            convertImage(e.dataTransfer.files)
        }
    };
    const deleteImage = (event?: React.MouseEvent<HTMLOrSVGElement>) => {
        if (event) { event.preventDefault(); }
        setModelData((prevState) => ({ ...prevState, image: undefined }))
        dispatch(setImageToUpscale(null))
        setImageSizeError(false)
    }

    const handleSubmit = async (e: React.MouseEvent) => {
        e.preventDefault()
        if (!modelData.image || !profile) return
        const isEnoughTokens = (remainingTokens - costTokens) >= 0
        if (isEnoughTokens && profile?.can_upscale) {
            uploadImage()
            setRemainingTokens(prev => prev - costTokens)
            // deleteImage()
        } else {
            setNoUpscalePopup(true)
        }
    }

    const toggleContent = () => {
        const body = document.getElementsByTagName('body')[0] as any;
        body.style.overflow = null;
        document.querySelector(".page-generator-have-content")?.classList.toggle('to-close');
    }

    const handleChange = (name: string, value: string | number) => {
        setModelData(prev => ({ ...prev, [name]: value }))
        window.localStorage.setItem(name, typeof value === "string" ? value : JSON.stringify(value))
    }

    useEffect(() => {
        setModelData(prev => {
            const dataFromLocalStorage: Partial<IUpscaleModelData> = {}
            Object.entries(modelData).forEach(([key, value]) => {
                if (!key) return;
                const modelDataKey = key as keyof IUpscaleModelData;
                dataFromLocalStorage[modelDataKey] = window.localStorage.getItem(modelDataKey) || value
            })
            // console.log(dataFromLocalStorage);

            return { ...prev, ...dataFromLocalStorage }
        })
    }, [])

    useEffect(() => {
        return () => {
            dispatch(setImageToUpscale(null))
        }
    }, [])

    useEffect(() => {
        return () => {
            dispatch(setIsUpscaleGenerating(false));
        }
    }, [modelData.image])

    useEffect(() => {
        if (!isError) return;
        if (errors.length) {
            errors.forEach(err => {
                NotificationManager.error(err)
            })
        } else {
            NotificationManager.error("Something went wrong!")
        }
        return () => {
            dispatch(clearUpscaleErrors())
        }
    }, [isError])


    return (
        <>
            <div className="aside-body pl-[16px] pt-[16px] upscale-sidebar-body">
                <div className="row">
                    <div className="col-12 flex justify-end">
                        <a
                            href="#"
                            className="ml-[12px] section__content-sidebar-burger section__content-sidebar-burger-1"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleContent();
                            }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                                <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                            </svg>
                        </a>
                    </div>
                    <div className="col-12 mb-[16px]">
                        <div className="form-group">
                            <div className="form-group--header">
                                <label className="input-label">
                                    Input Image
                                </label>
                            </div>
                            <form onDragEnter={handleDrag}>
                                <label htmlFor="upload-or-drag" className={`upload-wrap ${dragActive && 'upload-wrap--dragged'}`} onClick={(event: any) => {
                                    const fileInput = inputRef?.current as any;
                                    fileInput.value = null;
                                }}>
                                    <input accept="image/*" type="file" ref={inputRef} id="upload-or-drag" name="photo" className="upload-wrap__input"
                                        onChange={handleFileChange}
                                    />
                                    {dragActive && <div
                                        id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        // onDrop={handleDrop}
                                        onDrop={(event: any) => {
                                            handleDrop(event);
                                        }}
                                    ></div>}
                                    {imageToUpscale ?
                                        <img
                                            src={imageToUpscale} className="mb-[24px]"
                                            alt="" style={{ maxHeight: "166px", maxWidth: "246px", borderRadius: "10px", overflow: 'hidden' }}
                                        />
                                        : null}

                                    <span className="upload-wrap__label mb-[12px]">{imageToUpscale ? 'Change Photo' : 'Upload a file'}</span>
                                    {imageToUpscale && <IcoDelete onClick={deleteImage} className="upload-wrap__delete"></IcoDelete>}
                                    <span className="upload-wrap__label-text">PNG, JPG, GIF up to 10MB</span>
                                </label>
                            </form>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <div className="form-group--header">
                                <label className="input-label">
                                    Scale Factor
                                </label>
                            </div>
                            <div className="row mb-[10px]">
                                <div className="col-4">
                                    <label className="custom-radio">
                                        <input type="radio" className="custom-radio__input"
                                            onChange={(e) => handleChange("scale_factor", e.target.value)}
                                            name="scale_factor"
                                            id="scale_factor"
                                            checked={modelData.scale_factor == 2}
                                            value={2}
                                        />
                                        <span className="custom-radio__input-fake">2x</span>
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label className="custom-radio">
                                        <input type="radio" className="custom-radio__input"
                                            onChange={(e) => handleChange("scale_factor", e.target.value)}
                                            name="scale_factor"
                                            id="scale_factor"
                                            checked={modelData.scale_factor == 4}
                                            value={4}
                                        />
                                        <span className="custom-radio__input-fake">4x</span>
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label className="custom-radio">
                                        <input type="radio" className="custom-radio__input"
                                            onChange={(e) => handleChange("scale_factor", e.target.value)}
                                            name="scale_factor"
                                            id="scale_factor"
                                            checked={modelData.scale_factor == 8}
                                            value={8}
                                        />
                                        <span className="custom-radio__input-fake">8x</span>
                                    </label>
                                </div>
                            </div>
                            {
                                profile && !profile?.upscale_plan && !profileLoading ?
                                    <div className="form-group--header mb-[16px]">
                                        <div className="input-label text-center w-full !mb-0">
                                            You’ve got <span className="color-primary">{remainingTokens} tokens</span> for free, <span className="color-primary underline">with watermark.</span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>

                    <div className="col-12  mb-[16px]">
                        <div className="form-group">
                            <div className="form-group--header">
                                <label className="input-label flex items-center gap-[6px]">
                                    Prompt
                                    <a
                                        href="#" className="flex"
                                        data-tooltip-id="prompt-tooltip"
                                        data-tooltip-html={`<div style="max-width: 400px;">To enhance upscaling with DesignSense, use a detailed prompt that reflects your desired outcome, including any theme or style inspiration. <br />
                                        Remember to add weigh on specific parts of the prompt, such as "(youthful appearance: 1.3)", "(charming and youthful features: 1.2)", or "(charismatic: 1.2)"</div>`}
                                    >
                                        {tooltipIcon}
                                    </a>
                                </label>

                            </div>
                            <div className="input-custom__wrap-input">
                                <textarea
                                    className="input-custom__input"
                                    rows={4}
                                    style={{ resize: "vertical" }}
                                    placeholder="Write your prompt here..."
                                    value={modelData.prompt}
                                    onChange={(e) => handleChange("prompt", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12  mb-[16px]">
                        <RangeSlider
                            onChange={(value) => handleChange("creativity", value)}
                            value={modelData.creativity}
                            defaultValue={0.35}
                            label='Creativity'
                            min={0}
                            max={1}
                            step={0.05}
                            convertedMin={-10}
                            convertedMax={10}
                            tooltipBtn={<a
                                href="#" className="flex"
                                data-tooltip-id="creativity-tooltip"
                                data-tooltip-html={`<div style="max-width: 600px;">The AI can generate extra details for added realism, moving away from the original image. This is where DesignSense excels, but beware: extreme values may result in unusual outcomes.</div>`}
                            >
                                {tooltipIcon}
                            </a>}
                        />
                    </div>
                    <div className="col-12  mb-[16px]">
                        <RangeSlider
                            onChange={(value) => handleChange("hdr", value)}
                            value={modelData.hdr}
                            defaultValue={6}
                            label='HDR'
                            min={1}
                            max={50}
                            step={1}
                            convertedMin={-10}
                            convertedMax={10}
                            tooltipBtn={<a
                                href="#" className="flex"
                                data-tooltip-id="hdr-tooltip"
                                data-tooltip-html={`<div style="max-width: 600px;">Boosts definition and detail, but beware of using very high values that may cause images to look artificial or show blotches.</div>`}
                            >
                                {tooltipIcon}
                            </a>}
                        />
                    </div>
                    <div className="col-12  mb-[16px]">
                        <RangeSlider
                            onChange={(value) => handleChange("resemblance", value)}
                            value={modelData.resemblance}
                            defaultValue={0.6}
                            label='Resemblance'
                            min={0}
                            max={3}
                            step={0.15}
                            convertedMin={-10}
                            convertedMax={10}
                            tooltipBtn={<a
                                href="#" className="flex"
                                data-tooltip-id="resemblance-tooltip"
                                data-tooltip-html={`<div style="max-width: 600px;">Higher values enhance image similarity to the original, but very high settings may create blotches or a less polished appearance. Lower values grant more creative freedom but may deviate further from the original image.</div>`}
                            >
                                {tooltipIcon}
                            </a>}
                        />
                    </div>
                    {/* <div className="col-12  mb-[16px]">
                        <RangeSlider
                            onChange={(value) => handleChange("fractality", value)}
                            value={modelData.fractality}
                            defaultValue={112}
                            label='Fractality'
                            min={16}
                            max={256}
                            step={16}
                            convertedMin={-10}
                            convertedMax={10}
                            tooltipBtn={<a
                                href="#" className="flex"
                                data-tooltip-id="resemblance-tooltip"
                                data-tooltip-html={`<div style="max-width: 600px;">Higher values enhance image similarity to the original, but very high settings may create blotches or a less polished appearance. Lower values grant more creative freedom but may deviate further from the original image.</div>`}
                            >
                                {tooltipIcon}
                            </a>}
                        />
                    </div> */}
                    <div className="col-12  mb-[16px]">
                        <RangeSlider
                            onChange={(value) => handleChange("sharpen", value)}
                            value={modelData.sharpen}
                            defaultValue={0}
                            label='Sharpen'
                            min={0}
                            max={10}
                            step={1}
                            convertedMin={0}
                            convertedMax={10}
                            tooltipBtn={<a
                                href="#" className="flex"
                                data-tooltip-id="sharpen-tooltip"
                                data-tooltip-html={`<div style="max-width: 600px;">Sharpen the image after upscaling. The higher the value, the more sharpening is applied. 0 for no sharpening.</div>`}
                            >
                                {tooltipIcon}
                            </a>}
                        />
                    </div>
                    <div className="col-12 mb-[16px]">
                        <div className="form-group">
                            <div className="form-group--header">
                                <label className="input-label flex items-center gap-[6px]">
                                    Engine
                                    <a
                                        href="#" className="flex"
                                        data-tooltip-id="engine-tooltip"
                                        data-tooltip-html={`<div style="max-width: 600px;">${engineTooltipContent[modelData.engine]}</div>`}
                                    >
                                        {tooltipIcon}
                                    </a>
                                </label>
                            </div>
                            <select className="select"
                                onChange={(e) => { handleChange('engine', e.target.value) }}>
                                {engineOptions.map(option => {
                                    return <option selected={option.value === modelData.engine} key={option.value} value={option.value}>{option.label}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aside-footer px-[16px]">
                <div className="btn--render-js-1">
                    <a href="#" onClick={handleSubmit} className={`btn w-full btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px]
                     ${!modelData.image || isLoading || imageSizeError || profileLoading ? "disabled" : ""}`}>
                        <span className="ico ico-20 mr-[8px]">
                            <IcoStars />
                        </span>
                        <span>Upscale</span>
                    </a>
                    {
                        !profileLoading && modelData.image && !imageSizeError ?
                            <ul className="render-list-sidebar justify-center">
                                <li>
                                    <p>Final size: {imageData.finalWidth} x {imageData.finalHeight}</p>
                                </li>
                                <li>
                                    <div className="info-render-item">
                                        <p>This will cost</p>
                                        <div className="render-count">
                                            <span className="ico color-primary">
                                                <IcoCoin />
                                            </span>
                                            <p>{costTokens}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-render-item">
                                        <p>Remaining:</p>
                                        <div className="render-count">
                                            <span className="ico color-primary">
                                                <IcoCoin />
                                            </span>
                                            <p>{remainingTokens}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            : imageSizeError ?
                                <div className='aside-alert'>
                                    <p>The resulting image size exceeds the 10k resolution limit (roughly 10000 * 10000 px).</p>
                                </div>
                                :
                                null
                    }
                </div>
            </div>

            <Tooltip id="prompt-tooltip" />
            <Tooltip id="creativity-tooltip" />
            <Tooltip id="hdr-tooltip" />
            <Tooltip id="resemblance-tooltip" />
            {/* <Tooltip id="fractality-tooltip" /> */}
            <Tooltip id="engine-tooltip" />
            <Tooltip id="sharpen-tooltip" />


            {noUpscalePopup ?
                <Popup
                    modalDialogWidth="460"
                    open={noUpscalePopup}
                    onClose={() => {
                        setNoUpscalePopup(false);
                    }}
                >
                    <NoUpscalePopup
                        currentPlan={profile && (profile?.upscale_plan?.title || "")}
                        close={() => { setNoUpscalePopup(false) }}
                    />
                </Popup> : null}
        </>
    )
}
