import React, { useState, useEffect } from 'react'
import { ReactComponent as LeftArrow } from '../../../assets/img/icons/left-arrow.svg'
import { Link } from 'react-router-dom'
import { AddCardPopup, ICard } from '../../../components/Popups/AddCardPopup'
import { Popup } from "../../../components/Popups/Popup";
import { ChangeToPopup } from "../../../components/Popups/ChangeToPopup";
import { ConfirmPopup } from "../../../components/Popups/ConfirmPopup";
import { getHiddenCardNumber } from '../../../helpers/card';
// import { ChangeToAnualPopup } from '../../../components/Popups/ChangeToAnualPopup'
// import { ChangeToFreePopup } from '../../../components/Popups/ChangeToFreePopup'

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getProfileCards, getProfile, getProfilePlan, getProfileLoading, IProfilePlan } from '../../../store/profile/ProfileSlice';
import { cancelSubscribtion, cancelUpscaleSubscribtion, fetchProfile, profileFetchCards, setDefaultCard, subscribeToPlan } from '../../../store/profile/ProfileThunk';

import { fetchSettingsPageData } from '../../../store/pagesData/PagesDataThunk';
import { getSettingsPageData, getSelectedLanguage, setCurrentPageData, getUpscalerPlans } from '../../../store/pagesData/PagesDataSlice';

import { formatAsDollar } from '../../../helpers/formatAsDollar';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import { Api } from '../../../api/axios';
import { NotificationManager } from 'react-notifications';
import MetaTags from 'react-meta-tags';
import { FeatureType } from '../../../types';
import { NoRendersPopup } from '../../../components/Popups/NoRendersPopup';

const getI = (sec: number) => {
    return { '--i': `${sec}s` } as React.CSSProperties
}

export const SettingsBilling = () => {
    const dispatch = useAppDispatch();
    const [selectedPlan, setSelectedPlan] = useState<FeatureType>()

    let pageData = useAppSelector(getSettingsPageData);

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fromStrings = (value: string) => {
        return pageData?.strings?.[value] || value;
    }

    const profileCards = useAppSelector<ICard[]>(getProfileCards);
    const profile = useAppSelector(getProfile);
    const profilePlan = useAppSelector(getProfilePlan);
    const currentUpscalerPlan: IProfilePlan = profile?.upscale_plan
    const profileLoading = useAppSelector(getProfileLoading)
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false)
    const [cardListLoading, setCardListLoading] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState({
        open: false,
        title: '',
        subtitle: '',
        id: ''
    });

    const [mobilePlanPopup, setMobilePlanPopup] = useState<boolean>(false);
    const [confirmPopupData, setConfirmPopupData] = useState<any>(null);
    const [continueConfirmPopupData, setContinueConfirmPopupData] = useState<any>(false);
    const [changePlanPeriodPopupData, setChangePlanPeriodPopupData] = useState<any>(false);


    const [popUp, setPopUp] = useState({ open: false, modal: '' })

    let [addCardPopupData, setAddCardPopupData] = useState<boolean>(false);
    const openAddCardPopup = (event: React.MouseEvent) => {
        event.preventDefault();
        setAddCardPopupData(true);
    }
    const closeAddCardPopup = () => {
        setAddCardPopupData(false);
    }
    const confirmAddCardPopup = async () => {
        await loadCardList();
        closeAddCardPopup();
    }


    const currenPeriodEnd = (plan: any) => {
        console.log(plan, "plan");

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (!plan?.current_period_end) {
            return '';
        } else {
            let date = new Date(plan?.current_period_end * 1000)
            return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        }
    }

    const cancelSubscribePlan = async (plan: any, type: FeatureType) => {
        const periodNameVal = profile?.plan?.period;
        const mobPlans = [
            "monthly01",
            "weekly01",
            "yearly01",
            "design.sense.pre.month.subscription",
            "design.sense.pre.week.subscription",
            "design.sense.pre.year.subscription",
        ]

        if (mobPlans.includes(periodNameVal)) {
            setMobilePlanPopup(true);
            return false;
        }

        const title = type === "render" ?
            "Change to Free Plan" :
            `Confirm Cancellation.`
        const subtitle = type === "render" ?
            "Do you really want to set Free Basic Plan?" :
            `If you confirm and end your subscription now, you can still access it until ${currenPeriodEnd(plan)}.`

        setConfirmPopupData({
            title,
            subtitle,
            cancelBtnText: 'Not Now',
            confirmBtnText: 'Confirm '
        });
    }
    const cancelSubscribePlanAction = async () => {
        setIsSubscriptionLoading(true)
        setConfirmPopupData(null);

        let result = null;
        if (selectedPlan === "render") {
            result = await dispatch(subscribeToPlan({ plan_id: 1 || '', type: profile?.plan })).unwrap();
        } else if (selectedPlan === "upscale") {
            result = await dispatch(cancelUpscaleSubscribtion()).unwrap();
        }

        await dispatch(fetchProfile());
        setIsSubscriptionLoading(false)

        if (result && result?.success) {
            NotificationManager.success('Subscription canceled');
        } else {
            NotificationManager.error(result.message ? result.message : 'Something went wrong');
        }
    }
    const continueSubscribePlan = async () => {
        setIsSubscriptionLoading(true)
        setContinueConfirmPopupData(false)

        let result = null;
        if (selectedPlan === "upscale") {
            result = await dispatch(subscribeToPlan({ plan_id: profile?.upscale_plan?.id || '', type: profile?.upscale_plan?.period || '' })).unwrap();
        } else if (selectedPlan === "render") {
            result = await dispatch(subscribeToPlan({ plan_id: profile?.plan.id || '', type: profile?.plan?.period || '' })).unwrap();
        }
        await dispatch(fetchProfile());
        setIsSubscriptionLoading(false)

        if (result.success) {
            NotificationManager.success('Subscription continue successfully');
        } else {
            NotificationManager.error(result.message ? result.message : 'Something went wrong');
        }
    }
    const changeSubscribePlanPeriod = async () => {
        const periods = ["month", "year"]
        let result = null;
        let currentPeriod = null;
        let targetPeriod = (current: any) => periods.find(period => period !== current)
        setIsSubscriptionLoading(true)
        setChangePlanPeriodPopupData(false)
        if (selectedPlan === "upscale") {
            currentPeriod = profile?.upscale_plan?.period
            result = await dispatch(subscribeToPlan({ plan_id: profile?.upscale_plan?.id || '', type: targetPeriod(currentPeriod) })).unwrap();
        } else if (selectedPlan === "render") {
            currentPeriod = profile?.plan?.period
            result = await dispatch(subscribeToPlan({ plan_id: profile?.plan.id || '', type: targetPeriod(currentPeriod) })).unwrap();
        }
        setIsSubscriptionLoading(false)
        console.log(targetPeriod(currentPeriod), currentPeriod);

        await dispatch(fetchProfile());

        if (result.success) {
            NotificationManager.success('Subscription plan successfully changed');
        } else {
            NotificationManager.error(result.message ? result.message : 'Something went wrong');
        }
    }

    // let [ changeToAnualPopupData, setChangeToAnualPopupData ] = useState<boolean>(false);
    const openChangeToAnualPopup = () => {
        setPopUp({ open: true, modal: '' })
    }
    const closeChangeToAnualPopup = () => {
        setPopUp({ open: false, modal: '' })
    }
    const confirmChangeToAnualPopup = () => {
        setPopUp({ open: false, modal: '' })
    }

    // let [ changeToFreePopupData, setChangeToFreePopupData ] = useState<boolean>(false);
    const openChangeToFreePopup = () => {
        setPopUp({ open: true, modal: 'free' })
    }
    const closeChangeToFreePopup = () => {
        setPopUp({ open: false, modal: '' })
    }
    const confirmChangeToFreePopup = () => {
        setPopUp({ open: false, modal: '' })
    }

    const loadCardList = async () => {
        setCardListLoading(true);
        let res = await dispatch(profileFetchCards())
        setCardListLoading(false);
        return res;
    }

    const setActiveCard = async (id: string) => {
        setCardListLoading(true);
        await dispatch(setDefaultCard(id));
        await dispatch(profileFetchCards());
        setCardListLoading(false);
    }

    const removeCard = async (id: string) => {
        setConfirmPopup({ open: false, title: '', subtitle: '', id: '' });
        const res = await Api().profile.deleteCard(id);
        let cardsAfterDelete = await loadCardList() as any;
        cardsAfterDelete = cardsAfterDelete?.payload?.cards;

        if (res?.data?.success) {
            NotificationManager.success(res?.data?.message);

            if (cardsAfterDelete?.length === 1 && cardsAfterDelete[0]?.is_default === 0) {
                setActiveCard(cardsAfterDelete[0].id as string);
            }
        } else {
            NotificationManager.error(res?.data?.errors[0]);
        }
    }

    useEffect(() => {
        dispatch(fetchSettingsPageData((selectedLanguage || languageFromStorage || '') as string));
    }, [selectedLanguage]);

    useEffect(() => {
        dispatch(setCurrentPageData(pageData));
    }, [pageData])

    useEffect(() => {
        loadCardList();
    }, []);

    return (
        <>
            <MetaTags>
                <title>{pageData?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={pageData?.page?.meta_description || ""} />
            </MetaTags>

            <div className="full-page-section__content">
                <div className="section-scroll-container">
                    <div className="settings">
                        <div className="flex items-center justify-between xs:flex-col">
                            <div className="flex items-center">
                                <Link to="/settings" className="btn btn--secondary-2 font-[400] btn--md pl-[8px] rounded-[10px] mb-[24px] mr-[20px]">
                                    <span className="icon mr-[10px]">
                                        <LeftArrow></LeftArrow>
                                    </span>
                                    {fromStrings("Back")}
                                </Link>
                                <h4 className="mb-[24px] mr-[20px] font-[400]">{fromStrings("Plan & Billing")}</h4>
                            </div>
                            {/* <div className="flex items-center">
                                {
                                    profile?.plan.period === "month" ?
                                    <a href="#" onClick={(event) => { event.preventDefault(); openChangeToAnualPopup() }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]">
                                    {fromStrings("Change to Annual")}
                                    </a> : <a href="#" onClick={(event) => { event.preventDefault(); openChangeToAnualPopup() }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]">
                                    {fromStrings("Change to Monthly")}
                                    </a>
                                    }
                                    
                                    <a href="#" onClick={(event) => { event.preventDefault(); openChangeToFreePopup() }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[24px]">
                                    {fromStrings("Change to Free")}
                                    </a>
                                    </div> */}
                        </div>
                        <>
                            {profilePlan &&
                                <div className='plan-billing-list mb-[21px]'>
                                    <div className="plan-billing-item">
                                        <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                            {fromStrings("Team members")}
                                        </span>
                                        <span className="block text-[16px]">{profilePlan.team_members}</span>
                                    </div>
                                    <div className="plan-billing-item">
                                        <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                            {fromStrings("Total payment")}
                                        </span>
                                        <span className="block text-[16px]">{!(typeof profilePlan.total_payment === 'number') ? profilePlan.total_payment : formatAsDollar(profilePlan.total_payment)}</span>
                                    </div>
                                </div>
                            }
                            {profilePlan &&
                                <>
                                    <div className="flex items-center justify-between xs:flex-col">
                                        <h4 className="text-[16px] font-[400] leading-[18px] mb-[15px] mr-[20px]">Render Interior</h4>
                                        <div className="flex items-center">
                                            {profile?.is_premium ?
                                                profile?.plan.period === "month" ?
                                                    <a href="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        setSelectedPlan("render")
                                                        setChangePlanPeriodPopupData("year");
                                                    }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]">
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Change to Annual")
                                                        }
                                                    </a>
                                                    :
                                                    <a href="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        setSelectedPlan("render")
                                                        setChangePlanPeriodPopupData("month");
                                                    }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]">
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Change to Monthly")
                                                        }
                                                    </a>
                                                : null
                                            }
                                            {
                                                profile?.is_premium && !profile?.plan?.cancel_at_period_end ?
                                                    <a href="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        setSelectedPlan("render")
                                                        cancelSubscribePlan(profile?.plan, "render");
                                                    }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[24px]">
                                                        {

                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Change to Free")
                                                        }
                                                    </a>
                                                    :
                                                    profile?.plan?.cancel_at_period_end ?
                                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[24px]" onClick={(e) => {
                                                            e.preventDefault();
                                                            setSelectedPlan("render")
                                                            setContinueConfirmPopupData(true);
                                                        }}>
                                                            {
                                                                (profileLoading || isSubscriptionLoading) ?
                                                                    <svg
                                                                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                    >
                                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                        </path>
                                                                    </svg>
                                                                    :
                                                                    fromStrings("Continue Subscribtion")
                                                            }
                                                        </a>
                                                        :
                                                        null
                                            }
                                        </div>
                                    </div>
                                    <div className="plan-billing-list mb-[21px]">
                                        <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Your current plan")}
                                            </span>
                                            <span className="block text-[16px]">{fromStrings(profilePlan.title)}</span>
                                        </div>
                                        {profilePlan.next_invoice && <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Next invoice")}
                                            </span>
                                            <span className="block text-[16px]">{profilePlan.next_invoice}</span>
                                        </div>}
                                        <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Billing period")}
                                            </span>
                                            <span className="block text-[16px]">{fromStrings(capitalizeFirstLetter(profilePlan.billing_period))}</span>
                                        </div>
                                        {/* <div className="plan-billing-item">
                                        <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                            {fromStrings("Team members")}
                                        </span>
                                        <span className="block text-[16px]">{profilePlan.team_members}</span>
                                    </div> */}
                                        {/* <div className="plan-billing-item">
                                <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                    {fromStrings("Total payment")}
                                </span>
                                <span className="block text-[16px]">{!(typeof profilePlan.total_payment === 'number') ? profilePlan.total_payment : formatAsDollar(profilePlan.total_payment)}</span>
                            </div> */}
                                    </div>
                                </>
                            }
                            {currentUpscalerPlan &&
                                <>
                                    <div className="flex items-center justify-between xs:flex-col">
                                        <h4 className="text-[16px] font-[400] leading-[18px] mb-[15px] mr-[20px]">Upscaler</h4>
                                        <div className="flex items-center">
                                            {
                                                profile?.upscale_plan.period === "month" ?
                                                    <a href="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        setSelectedPlan("upscale")
                                                        setChangePlanPeriodPopupData("year");
                                                    }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]"
                                                    >
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Change to Annual")
                                                        }
                                                    </a>
                                                    :
                                                    <a href="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        setSelectedPlan("upscale")
                                                        setChangePlanPeriodPopupData("month");
                                                    }} className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mr-[8px] mb-[24px]">
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Change to Monthly")
                                                        }
                                                    </a>
                                            }
                                            {
                                                profile.upscale_plan?.cancel_at_period_end ?
                                                    <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[24px]" onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedPlan("upscale")
                                                        setContinueConfirmPopupData(true);
                                                    }}>
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Continue Subscribtion")
                                                        }
                                                    </a> :
                                                    <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[24px]" onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedPlan("upscale")
                                                        cancelSubscribePlan(profile?.upscale_plan, "upscale");
                                                    }}>
                                                        {
                                                            (profileLoading || isSubscriptionLoading) ?
                                                                <svg
                                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                >
                                                                    <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                    </path>
                                                                </svg>
                                                                :
                                                                fromStrings("Cancel Subscribtion")
                                                        }
                                                    </a>
                                            }
                                        </div>
                                    </div>
                                    <div className="plan-billing-list mb-[21px]">
                                        <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Your current plan")}
                                            </span>
                                            <span className="block text-[16px]">{fromStrings(currentUpscalerPlan.title)}</span>
                                        </div>
                                        {currentUpscalerPlan.next_invoice && <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Next invoice")}
                                            </span>
                                            <span className="block text-[16px]">{currentUpscalerPlan.next_invoice}</span>
                                        </div>}
                                        <div className="plan-billing-item">
                                            <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                                {fromStrings("Billing period")}
                                            </span>
                                            <span className="block text-[16px]">{fromStrings(capitalizeFirstLetter(currentUpscalerPlan.billing_period))}</span>
                                        </div>
                                        {/* <div className="plan-billing-item">
                                        <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                            {fromStrings("Team members")}
                                        </span>
                                        <span className="block text-[16px]">{currentUpscalerPlan.team_members}</span>
                                    </div> */}
                                        {/* <div className="plan-billing-item">
                                <span className="block text-[12px] color-secondary opacity-70 mb-[8px]">
                                    {fromStrings("Total payment")}
                                </span>
                                <span className="block text-[16px]">{!(typeof currentUpscalerPlan.total_payment === 'number') ? currentUpscalerPlan.total_payment : formatAsDollar(currentUpscalerPlan.total_payment)}</span>
                            </div> */}
                                    </div>
                                </>
                            }



                            <div className="flex items-center justify-between">
                                <h4 className="text-[16px] font-[400] leading-[18px] mb-[15px] mr-[20px]">{fromStrings("Payment Method")}
                                </h4>
                                <a href="#"
                                    onClick={openAddCardPopup}
                                    className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[15px]">
                                    + {fromStrings("Add Card")}
                                </a>
                            </div>

                            <div className="border-bottom pb-[24px] mb-[24px]">
                                <div className="max-w-[500px]">
                                    <div className="row gx-16">
                                        {profileCards.length ? profileCards.map((item, index) => {
                                            return (<div className="col-6 mb-[16px]" key={index}>
                                                <div
                                                    className={`plan-billing-card cursor-pointer ${item.is_default === 1 && 'active'}`}
                                                    onClick={() => { item.is_default === 0 && setActiveCard(item.id as string) }}
                                                    title="Click will make this card the default"
                                                >
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            // removeCard(item.id as string);
                                                            setConfirmPopup({
                                                                open: true,
                                                                title: fromStrings('Confirm Delete'),
                                                                subtitle: fromStrings('Do you really want to delete this card'),
                                                                id: item.id as string
                                                            })
                                                        }}
                                                        className="remove-card-btn"
                                                        title="Delete this card"
                                                    >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <path d="M8 8L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M16 8L8 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                        </svg>
                                                    </a>
                                                    <span className="block text-[16px] mb-[4px]">•••• •••• •••• {item.last4}</span>
                                                    <span className="block text-[14px] opacity-40">{item.exp_month}/{item.exp_year}</span>
                                                </div>
                                            </div>)
                                        }) : cardListLoading ? <p>{fromStrings("Loading")}...</p> : <p>{fromStrings("Card list is empty")}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <h4 className="text-[16px] font-[400] leading-[18px] mb-[24px] mr-[20px]">{fromStrings("Billing History")}</h4>
                            {!!profilePlan?.invoices?.length ? <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{fromStrings("Date")}</th>
                                            {/* <th>Description</th>
                                            <th>Team</th> */}
                                            <th>{fromStrings("Status")}</th>
                                            <th>{fromStrings("Total")}</th>
                                            <th>{fromStrings("Actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profilePlan?.invoices.map((item, index) => {
                                            return (<tr key={index}>
                                                <td>
                                                    {item.date}
                                                </td>
                                                {/* <td>
                                                    {item.description}
                                                </td>
                                                <td>
                                                    {item.team}
                                                </td> */}
                                                <td>
                                                    {fromStrings(capitalizeFirstLetter(item.status))}
                                                </td>
                                                <td>
                                                    {item.total}
                                                </td>
                                                <td>
                                                    <a href={item.link} target="_blank" className="color-primary">{fromStrings("View Invoice")}</a>
                                                </td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div> : profileLoading ? <p>{fromStrings("Loading")}...</p> : <p>{fromStrings("Billing history is empty")}</p>}
                        </>
                    </div>
                </div>
            </div>

            <Popup
                modalDialogWidth="572"
                open={addCardPopupData}
                onClose={closeAddCardPopup}
            >
                <AddCardPopup
                    close={closeAddCardPopup}
                    confirm={confirmAddCardPopup}
                ></AddCardPopup>
            </Popup>
            <Popup open={confirmPopup.open} onClose={() => setConfirmPopup({ open: false, title: '', subtitle: '', id: '' })}>
                <ConfirmPopup
                    title={fromStrings(confirmPopup.title)}
                    subtitle={fromStrings(confirmPopup.subtitle)}
                    close={() => setConfirmPopup({ open: false, title: '', subtitle: '', id: '' })}
                    confirm={() => removeCard(confirmPopup.id)}
                />
            </Popup>
            <Popup open={popUp.open} onClose={() => setPopUp({ open: false, modal: '' })}>
                <>
                    {popUp.modal === 'free' ?
                        <ChangeToPopup close={closeChangeToFreePopup} confirm={confirmChangeToFreePopup} />
                        : <ChangeToPopup close={closeChangeToAnualPopup} confirm={confirmChangeToAnualPopup} />}
                </>
            </Popup>



            {mobilePlanPopup ?
                <Popup
                    modalDialogWidth="650"
                    open={mobilePlanPopup}
                    onClose={() => {
                        setMobilePlanPopup(false)
                    }}
                >
                    <NoRendersPopup
                        close={() => {
                            setMobilePlanPopup(false);
                        }}
                        title={null}
                        hideBtn={true}
                    >
                        {/* color-secondary-07 */}
                        <div className="no-renders-popup-text text-[14px] text-[#ffffff] no-renders-popup-text--margins pt-[30px] d-flex flex-col items-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="80" height="80" className="mb-[6px]">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11.25 13.5V8.25H12.75V13.5H11.25ZM11.25 15.75V14.25H12.75V15.75H11.25Z" fill="#de2323"></path> </g>
                            </svg>
                            {fromStrings("Your subscription was created on the mobile application.")} <br />
                            {fromStrings("Mobile subscriptions can be cancelled only on the same mobile application.")} <br />
                            {fromStrings("Please use your mobile app to cancel your subscription.")}
                        </div>
                    </NoRendersPopup>
                </Popup> : null
            }

            {confirmPopupData ?
                <Popup
                    modalDialogWidth="460"
                    open={confirmPopupData}
                    onClose={() => {
                        setConfirmPopupData(null)
                    }}
                >
                    <ConfirmPopup
                        close={() => { setConfirmPopupData(null) }}
                        confirm={() => { cancelSubscribePlanAction() }}
                        title={fromStrings(confirmPopupData.title)}
                        subtitle={fromStrings(confirmPopupData.subtitle)}
                        cancelBtnText={fromStrings(confirmPopupData.cancelBtnText)}
                        confirmBtnText={fromStrings(confirmPopupData.confirmBtnText)}
                    />
                </Popup> : null
            }

            {continueConfirmPopupData ?
                <Popup
                    modalDialogWidth="460"
                    open={continueConfirmPopupData}
                    onClose={() => {
                        setContinueConfirmPopupData(false)
                    }}
                >
                    <ConfirmPopup
                        close={() => { setContinueConfirmPopupData(false) }}
                        confirm={() => { continueSubscribePlan() }}
                        title={fromStrings('Continue Subscribtion')}
                        subtitle={fromStrings('Do you want to continue your subscription?')}
                        cancelBtnText={fromStrings('Cancel')}
                        confirmBtnText={fromStrings('Confirm')}
                    />
                </Popup> : null
            }

            {changePlanPeriodPopupData ?
                <Popup
                    modalDialogWidth="460"
                    open={changePlanPeriodPopupData}
                    onClose={() => {
                        setChangePlanPeriodPopupData(false)
                    }}
                >
                    <ConfirmPopup
                        close={() => { setChangePlanPeriodPopupData(false) }}
                        confirm={() => { changeSubscribePlanPeriod() }}
                        title={fromStrings(`Change to ${changePlanPeriodPopupData === "month" ? "Monthly" : "Yearly"}`)}
                        subtitle={fromStrings(`Do you really want to change your plan to ${changePlanPeriodPopupData === "month" ? "Monthly" : "Yearly"}`)}
                        cancelBtnText={fromStrings('Cancel')}
                        confirmBtnText={fromStrings('Confirm')}
                    />
                </Popup> : null
            }
        </>
    )
}
