import { createAsyncThunk } from "@reduxjs/toolkit"
//то что приходит в пейлоад
export interface ITest{
    userId:string
}
//то что возвращает fulfillWithValue
export interface ITestReturned{
    test:number
}
//то что возвращает rejectWithValue
export interface ITestError {
    rejectedError:string,
}
export const fetchUserById = createAsyncThunk<ITestReturned, ITest, { rejectValue:ITestError }>(
    'renderInterior/fetchById',
    // if you type your function argument here
    async ({userId}, {rejectWithValue}) => {
        try{
            const response = await fetch(`https://reqres.in/api/users/${userId}`)
            return (await response.json()) as ITestReturned
        }catch(error){
            return rejectWithValue({rejectedError: 'testing'})
        }
    }
  )
  

  