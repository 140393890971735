import { ReactNode, useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import 'swiper/css';

export const SwiperUpscaleLanding = ({ children, className, breakpoints }: { children: ReactNode[], className?: string, revers?: boolean, breakpoints?: any }) => {

    const swiperRef = useRef<SwiperRef>(null);

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={24}
            slidesPerView={2}
            loop={true}
            className={className}
            // observer={true}
            breakpoints={breakpoints || {
                320: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                },
                992: {
                    slidesPerView: 2,
                    slideToClickedSlide: true,
                },
            }}
        // centeredSlides={true}
        >
            {children.map((el: ReactNode, idx: number) => <SwiperSlide key={idx}>{el}</SwiperSlide>)}
        </Swiper>
    );
};