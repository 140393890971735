import { Link, NavLink } from 'react-router-dom'
import { getPricingPageData, getSelectedLanguage, getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchPricingPageData } from '../../../store/pagesData/PagesDataThunk';
import { useEffect } from 'react';

const SiteFooter = ({ currentPageData }: {currentPageData?:any}) => {
    const pricingPageData = useAppSelector(getPricingPageData);
    const dispatch = useAppDispatch();

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return (currentPageData || currentPageDataSlice)?.strings?.[value] || value;
    }

    useEffect(()=>{
        dispatch(fetchPricingPageData((selectedLanguage || languageFromStorage || '') as string));
	},[selectedLanguage]);

    return (
        <>
            <a href="#" className="scroll_to_top"></a>
            <footer className="footer">
                <div className="container container-1100">
                    <div className="footer-top">
                        <div className="footer-body">
                            <p className="mb-[10px]">{fromStrings('Copyright')} © {new Date().getFullYear()} Design Sense {fromStrings('All rights reserved')}.</p>
                            <div className="footer-top-actions">
                                <p className="mr-[16px] mb-[10px]">{ fromStrings('Download our apps') }:</p>
                                <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_apple_url?.value} className="btn mr-[12px] mb-[10px]">
                                    <img src={require("../../../assets/img/footer-app-logo.png")} alt="" />
                                </a>
                                <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_google_url?.value} className="btn mb-[10px]">
                                    <img src={require("../../../assets/img/footer-play-logo.png")} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <ul className="footer-list">
                            <li>
                                {/* <a href="#">Terms of Use</a> */}
                                <NavLink to="/terms">{ fromStrings('Terms of Use') }</NavLink>
                            </li>
                            <li>
                                {/* <a href="#">Privacy Policy</a> */}
                                <NavLink to="/privacy">{ fromStrings('Privacy Policy') }</NavLink>
                            </li>
                            <li>
                                <a href="https://designsense-ai.canny.io/" target="_blank">{ fromStrings('Feedback') }</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default SiteFooter;