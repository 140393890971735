import { createSlice } from '@reduxjs/toolkit'
import { fetchGenerateUpscale } from './upscaleThunks'
import { fetchRenderStatus } from '../pagesData/PagesDataThunk'

// Define a type for the slice state

interface IPayloadGeneration {
    success: boolean
    premium_required?: boolean
    data: {
        prediction_id: string
        render_engine: "string"
        render_id: number | null | string
        shell: string
        status: string
    }
    errors?: {
        [key: string]: string[];
    }
}
interface IPredictions {
    id: string | number
    url: string
}

interface IInitialState {
    imageToUpscale: ""
    isLoading: boolean
    isUpscaleGenerating: boolean
    isNoRendersError: boolean
    resultImage: string,
    percent: string
    renderId: number | null | string
    isError: boolean
    errors: string[]

}
// Define the initial state using that type
const initialState: IInitialState = {
    imageToUpscale: "",
    percent: "",
    resultImage: "",
    isUpscaleGenerating: false,
    isNoRendersError: false,
    renderId: null,
    isLoading: false,
    isError: false,
    errors: []
}

export const upscaleSlice = createSlice({
    name: 'upscale',
    initialState,
    reducers: {
        setImageToUpscale: (state, action) => {
            state.imageToUpscale = action.payload
            state.resultImage = ""
        },
        setIsUpscaleGenerating: (state, action) => {
            state.isUpscaleGenerating = action.payload
            state.resultImage = ""
        },
        setResultImage: (state, action) => {
            state.resultImage = action.payload
        },
        clearUpscaleErrors: (state) => {
            state.errors = []
            state.isError = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGenerateUpscale.pending, (state, { payload }) => {
            state.isLoading = true;
            state.isUpscaleGenerating = true
            state.resultImage = ""
        });
        builder.addCase(fetchGenerateUpscale.fulfilled, (state, { payload }: { payload: IPayloadGeneration }) => {
            if (!payload.success && payload.premium_required) {
                state.isNoRendersError = true;
                state.isUpscaleGenerating = false
            }
            if (!payload.success) {
                state.isError = true
                state.isUpscaleGenerating = false
                if (payload.errors) {
                    state.errors = Object.entries(payload.errors).map(([key, value]) => {
                        return value.join(",")
                    })
                }
            }
            if (payload.success && !payload.premium_required) {
                state.isUpscaleGenerating = true
                state.renderId = payload.data.render_id
                console.log(payload);
            }
            state.isLoading = false;
        });
        builder.addCase(fetchGenerateUpscale.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isUpscaleGenerating = false
            state.isError = true
            state.resultImage = ""
        });
        builder.addCase(fetchRenderStatus.fulfilled, (state, { payload }) => {
            if (!payload.success) {
                if (payload.errors) {
                    state.errors = Object.entries(payload.errors).map(([key, value]) => {
                        const errors = value as string[]
                        return errors.join(",")
                    })
                }
                state.isUpscaleGenerating = false
                state.isError = true
            }
        });
        builder.addCase(fetchRenderStatus.rejected, (state, { payload }) => {
            state.isError = true
            state.isUpscaleGenerating = false

        });
    }
})

export const { setImageToUpscale, setIsUpscaleGenerating, setResultImage, clearUpscaleErrors } = upscaleSlice.actions



export default upscaleSlice.reducer