import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { NavLink, useNavigate } from "react-router-dom";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
import BeforeAfterImages from "../../components/UI/BeforeAfterImages";
import { SwiperUpscaleLanding } from "../../components/UI/SwiperUpscaleLanding";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { getSelectedLanguage, getUpsaceLandingPageData, setCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { fetchUpscaleLandingPageData } from "../../store/pagesData/PagesDataThunk";
import { setActiveGenerationTab } from "../../store/Generation/GenerationSlice";
import { IFaq } from "../../types";
import FaqItem from "../../components/UI/FaqItem";

type FaqState = {
    [key: string]: boolean;
};

export const UpscaleLanding = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openLogin, setOpenLogin] = useState(false)
    const [activeTab, setActiveTab] = useState('small')
    const [isOpenFaq, setIsOpenFaq] = useState<FaqState>({ '': false })
    const [loading, setLoading] = useState(false)

    const [heroBeforeAfterActive, setHeroBeforeAfterActive] = useState(0);

    const getI = (sec: number) => {
        return { '--i': `${sec}s` } as React.CSSProperties
    }

    const getBackImg = (src: string, server: boolean = false) => {
        const locationOrigin = window.location.origin?.includes('localhost') ? "https://designsense2.webstaginghub.com" : window.location.origin;

        return server ?
            { backgroundImage: `url(${locationOrigin + '/storage/pages/' + src})` } :
            { backgroundImage: `url(${require(`../../assets/img/${src}`)})` }
    }

    const pageData = useAppSelector(getUpsaceLandingPageData);
    const blocks = pageData?.page?.blocks;
    const faqs: IFaq[] = pageData?.faqs;

    const token = localStorage.getItem('token');

    const showLogin = (ev: any) => {
        ev.preventDefault();
        dispatch(setActiveGenerationTab("upscale"))

        if (token && token.length) {
            navigate("/render-interior");
            return;
        }

        setOpenLogin(false);
        setTimeout(() => {
            setOpenLogin(true);
        }, 100)
    }

    const changeTab = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.target as HTMLInputElement;
        setActiveTab(target.id)
    }

    const openFaq = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const target = e.currentTarget as HTMLDivElement;
        const id = target.id;

        if (isOpenFaq && id) {
            setIsOpenFaq(prev => ({ ...prev, [id]: !isOpenFaq[id] }))
        }
    }

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fetchPage = async () => {
        setLoading(true);
        await dispatch(fetchUpscaleLandingPageData((selectedLanguage || languageFromStorage || '') as string));
        setLoading(false);
    }

    useEffect(() => {
        fetchPage();
    }, [selectedLanguage])

    useEffect(() => {
        dispatch(setCurrentPageData(pageData));
    }, [pageData])


    if (!pageData || !blocks || !blocks?.length) {
        return (
            <>
                <div className="wrapper header-fixed">
                    <main className="content landing-content px-[16px]">
                        <section className="section mt-[16px] d-flex align-items-center justify-center" style={{ height: 'calc(100vh - 112px)' }}>
                            <div className="features-item__icon">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.999 30.005V22.5019C14.999 21.6731 15.6709 21.0013 16.4996 21.0013H19.5009" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.49463 15.8694L16.047 5.96717C17.171 5.00376 18.8295 5.00376 19.9535 5.96717L31.5059 15.8694" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.0003 30.005H4.49463" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M23.438 8.9539V5.61978C23.438 4.9982 23.9419 4.49431 24.5635 4.49431H28.4514C29.073 4.49431 29.5769 4.9982 29.5769 5.61978V14.2159" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.42365 14.216V30.005" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27.7541 31.5056C27.7541 28.6049 25.4027 26.2534 22.502 26.2534C25.4027 26.2534 27.7541 23.902 27.7541 21.0013C27.7541 23.902 30.1056 26.2534 33.0063 26.2534C30.1056 26.2534 27.7541 28.6049 27.7541 31.5056Z" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <div className="features-item__shape" style={getI(0)}></div>
                                <div className="features-item__shape" style={getI(1)}></div>
                                <div className="features-item__shape" style={getI(2)}></div>
                                <div className="features-item__shape" style={getI(3)}></div>
                                <div className="features-item__shape" style={getI(4)}></div>
                                <div className="features-item__shape" style={getI(5)}></div>
                            </div>
                        </section>
                    </main>
                </div>
            </>

        )
    }


    return (
        <>
            <MetaTags>
                <title>{pageData?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={pageData?.page?.meta_description || ""} />
            </MetaTags>

            <Header
                setLoginModal={openLogin}
                loginRedirectUrl="/render-interior"
            />

            {blocks ?
                <div className="wrapper header-fixed">
                    <main className="content landing-content">

                        <section className="section">
                            <div className="container container-1024">
                                <h1 className="section-title">{blocks[0].data.title.value || "Upscale Your Interior"}</h1>
                                <div className="section-sub-title">
                                    <p>
                                        {blocks[0].data.text.value || "Enhance your image's resolution and achieve crisp, clear quality with a single click."}
                                    </p>
                                </div>
                                <div className="btn-shapes mb-[70px]">
                                    <div className="shadow-efect-2"></div>
                                    <div className="shadow-efect-1"></div>
                                    <div className="border-efects">
                                        <span style={getI(0)}></span>
                                        <span style={getI(1)}></span>
                                        <span style={getI(2)}></span>
                                    </div>
                                    <a
                                        href="#"
                                        className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]"
                                        onClick={showLogin}
                                    >
                                        {blocks[0].data.button.value || "Start Now"}
                                    </a>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home mb-[36px]">
                                    <div className="col-12 relative slider-images-home--full  max-w-[500px] mx-auto lg:max-w-none">
                                        <div className="slider-images-shapes">
                                            <div className="slider-images-shapes__item" style={getI(0)}></div>
                                            <div className="slider-images-shapes__item" style={getI(1)}></div>
                                            <div className="slider-images-shapes__item" style={getI(2)}></div>
                                            <div className="slider-images-shapes__item" style={getI(3)}></div>
                                            <div className="slider-images-shapes__item" style={getI(4)}></div>
                                        </div>
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('after-before-1.png')}
                                            // after={getBackImg('after-before-1.png')}
                                            after={getBackImg(blocks[1].data["Image before"].value, true)}
                                            before={getBackImg(blocks[1].data["Image after"].value, true)}
                                        />
                                        {/* <ul className="before-after-thumbnail">
                                            {
                                                new Array(4).fill("").map((item, index)=>{
                                                    return <li key={index}>
                                                        <a
                                                            href="#" className={heroBeforeAfterActive === index ? "active" : ""}
                                                            onClick={(e)=>{
                                                                e.preventDefault();
                                                                setHeroBeforeAfterActive(index);
                                                            }}
                                                        >
                                                            <img src={`https://designsense2.webstaginghub.com/storage/pages/${blocks[1].data["Image after"].value}`} alt="" />
                                                        </a>
                                                    </li>
                                                })
                                            }
                                        </ul> */}
                                    </div>
                                </div>
                                <div className="row gx-16">
                                    <div className="col-lg-4">
                                        <div className="features-item features-item--row">
                                            <div className="features-item__icon features-item__icon--md">
                                                <span className="ico">
                                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.8211 7.35007L13.7471 5.27954L11.6719 7.35007" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M13.7472 12.5381V5.27954" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M21.0093 16.6872L23.0845 14.6167L21.0093 12.5415" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M16.8589 14.6168H23.0845" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M6.4837 12.5381L4.40967 14.6168L6.4837 16.6873" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M10.6341 14.6168H4.40967" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M3.24268 8.78104V6.73502C3.24461 5.28604 4.41978 4.11243 5.86877 4.11243H7.91129" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M19.583 4.11243H21.6255C23.0759 4.11243 24.2516 5.28817 24.2516 6.73852V8.78104" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M24.2516 20.4525V22.495C24.251 23.9451 23.0756 25.1205 21.6255 25.1211H19.583" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M7.91129 25.1211H5.86877C4.41842 25.1211 3.24268 23.9454 3.24268 22.495V20.4525" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M11.6719 21.8752L13.7471 23.9539L15.8211 21.8787" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M13.7472 16.6873V23.9539" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <div className="features-item__shape" style={getI(0)}></div>
                                                <div className="features-item__shape" style={getI(1)}></div>
                                                <div className="features-item__shape" style={getI(2)}></div>
                                                <div className="features-item__shape" style={getI(3)}></div>
                                                <div className="features-item__shape" style={getI(4)}></div>
                                                <div className="features-item__shape" style={getI(5)}></div>
                                            </div>
                                            <div className="features-item__text">
                                                <h4 className="text-[16px] mb-[16px]">{blocks[2].data["Features 1"].blocks[0].title.value || "Upscale Pics with AI"}</h4>
                                                <p className="text-[14px]">{blocks[2].data["Features 1"].blocks[0].text.value || "Boost your photos with AI-powered upscaling."}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="features-item features-item--row">
                                            <div className="features-item__icon features-item__icon--md">
                                                <span className="ico">
                                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="3.49609" y="3.99573" width="21.0088" height="21.0088" rx="3.88889"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></rect>
                                                        <path
                                                            d="M11.0826 11.5239C11.1148 11.5239 11.141 11.55 11.141 11.5822C11.141 11.6145 11.1148 11.6406 11.0826 11.6406C11.0504 11.6406 11.0242 11.6145 11.0242 11.5822C11.0242 11.55 11.0504 11.5239 11.0826 11.5239"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M14.0006 8.60591C14.0328 8.60591 14.0589 8.63203 14.0589 8.66426C14.0589 8.69649 14.0328 8.72262 14.0006 8.72262C13.9683 8.72262 13.9422 8.69649 13.9422 8.66426C13.9422 8.63203 13.9683 8.60591 14.0006 8.60591"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M16.9185 11.5239C16.9508 11.5239 16.9769 11.55 16.9769 11.5822C16.9769 11.6145 16.9508 11.6406 16.9185 11.6406C16.8863 11.6406 16.8602 11.6145 16.8602 11.5822C16.8602 11.5668 16.8663 11.5519 16.8773 11.541C16.8882 11.53 16.9031 11.5239 16.9185 11.5239"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M8.22299 8.66426C8.22299 8.69649 8.19686 8.72262 8.16463 8.72262C8.1324 8.72262 8.10627 8.69649 8.10627 8.66426C8.10627 8.63203 8.1324 8.60591 8.16463 8.60591C8.19686 8.60591 8.22299 8.63203 8.22299 8.66426"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M19.836 8.60591C19.8682 8.60591 19.8944 8.63203 19.8944 8.66426C19.8944 8.69649 19.8682 8.72262 19.836 8.72262C19.8038 8.72262 19.7777 8.69649 19.7777 8.66426C19.7777 8.63203 19.8038 8.60591 19.836 8.60591"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M14.0006 14.5583C13.9683 14.5583 13.9422 14.5322 13.9422 14.5C13.9422 14.4677 13.9683 14.4416 14.0006 14.4416C14.0328 14.4416 14.0589 14.4677 14.0589 14.5C14.0589 14.5322 14.0328 14.5583 14.0006 14.5583"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M19.7777 14.5C19.7777 14.4677 19.8038 14.4416 19.836 14.4416C19.8682 14.4416 19.8944 14.4677 19.8944 14.5C19.8944 14.5322 19.8682 14.5583 19.836 14.5583C19.8038 14.5583 19.7777 14.5322 19.7777 14.5"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M8.16463 14.5583C8.1324 14.5583 8.10627 14.5322 8.10627 14.5C8.10627 14.4677 8.1324 14.4416 8.16463 14.4416C8.19686 14.4416 8.22299 14.4677 8.22299 14.5C8.22299 14.5322 8.19686 14.5583 8.16463 14.5583"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M11.0826 17.3596C11.1148 17.3596 11.141 17.3857 11.141 17.4179C11.141 17.4502 11.1148 17.4763 11.0826 17.4763C11.0504 17.4763 11.0242 17.4502 11.0242 17.4179C11.0242 17.3857 11.0504 17.3596 11.0826 17.3596"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M16.9185 17.3596C16.9508 17.3596 16.9769 17.3857 16.9769 17.4179C16.9769 17.4502 16.9508 17.4763 16.9185 17.4763C16.8863 17.4763 16.8602 17.4502 16.8602 17.4179C16.8602 17.4024 16.8663 17.3876 16.8773 17.3767C16.8882 17.3657 16.9031 17.3596 16.9185 17.3596"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M14.0006 20.3941C13.9683 20.3941 13.9422 20.368 13.9422 20.3358C13.9422 20.3035 13.9683 20.2774 14.0006 20.2774C14.0328 20.2774 14.0589 20.3035 14.0589 20.3358C14.0589 20.368 14.0328 20.3941 14.0006 20.3941"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M19.7777 20.3358C19.7777 20.3035 19.8038 20.2774 19.836 20.2774C19.8682 20.2774 19.8944 20.3035 19.8944 20.3358C19.8944 20.368 19.8682 20.3941 19.836 20.3941C19.8038 20.3941 19.7777 20.368 19.7777 20.3358"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M8.16463 20.3941C8.1324 20.3941 8.10627 20.368 8.10627 20.3358C8.10627 20.3035 8.1324 20.2774 8.16463 20.2774C8.19686 20.2774 8.22299 20.3035 8.22299 20.3358C8.22299 20.368 8.19686 20.3941 8.16463 20.3941"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <div className="features-item__shape" style={getI(0)}></div>
                                                <div className="features-item__shape" style={getI(1)}></div>
                                                <div className="features-item__shape" style={getI(2)}></div>
                                                <div className="features-item__shape" style={getI(3)}></div>
                                                <div className="features-item__shape" style={getI(4)}></div>
                                                <div className="features-item__shape" style={getI(5)}></div>
                                            </div>
                                            <div className="features-item__text">
                                                <h4 className="text-[16px] mb-[16px]">{blocks[2].data["Features 1"].blocks[1].title.value || "Fix Pixelation &amp; Blur"}</h4>
                                                <p className="text-[14px]">{blocks[2].data["Features 1"].blocks[1].text.value || "Smooth out pixelation and sharpen blurry images."}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="features-item features-item--row">
                                            <div className="features-item__icon features-item__icon--md">
                                                <span className="ico">
                                                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="13.9997" cy="10.4167" r="6.41667" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></circle>
                                                        <path
                                                            d="M20.1228 12.339C22.7989 12.9597 24.7825 15.216 25.0554 17.9496C25.3282 20.6832 23.8297 23.287 21.329 24.4245C18.8283 25.5619 15.8809 24.9803 13.9998 22.9783"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M7.87685 12.339C5.31129 12.9408 3.37558 15.0517 2.99764 17.6596C2.61971 20.2676 3.87659 22.8411 6.16576 24.1465C8.45493 25.4518 11.3098 25.223 13.3618 23.5697C15.4138 21.9163 16.2447 19.1755 15.4562 16.661"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <div className="features-item__shape" style={getI(0)}></div>
                                                <div className="features-item__shape" style={getI(1)}></div>
                                                <div className="features-item__shape" style={getI(2)}></div>
                                                <div className="features-item__shape" style={getI(3)}></div>
                                                <div className="features-item__shape" style={getI(4)}></div>
                                                <div className="features-item__shape" style={getI(5)}></div>
                                            </div>
                                            <div className="features-item__text">
                                                <h4 className="text-[16px] mb-[16px]">{blocks[2].data["Features 1"].blocks[2].title.value || "Adjust Colors &amp; Lighting"}</h4>
                                                <p className="text-[14px]">{blocks[2].data["Features 1"].blocks[2].text.value || "Refine colors and optimize lights for enhanced quality."}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.49984 23.0189V24.5" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M15.1665 15.75V14C15.1665 12.067 13.5995 10.5 11.6665 10.5H8.1665C6.23351 10.5 4.6665 12.067 4.6665 14V15.75" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M16.3333 23.0168V24.5" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.25016 23.3333H15.1668C16.4555 23.3333 17.5002 22.2887 17.5002 21V17.5C17.5002 16.5335 16.7167 15.75 15.7502 15.75H15.1668C14.2003 15.75 13.4168 16.5335 13.4168 17.5V19.8333H6.41683V17.5C6.41683 16.5335 5.63333 15.75 4.66683 15.75H4.0835C3.117 15.75 2.3335 16.5335 2.3335 17.5V21C2.3335 22.2887 3.37817 23.3333 4.66683 23.3333H5.25016Z" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M22.1668 23.3333V10.5" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M23.9991 2.33337H20.3338C19.8129 2.33338 19.3552 2.67868 19.2121 3.17953L17.5454 9.01287C17.4448 9.36487 17.5153 9.74367 17.7358 10.0359C17.9562 10.3282 18.3011 10.5 18.6672 10.5H25.6657C26.0318 10.5 26.3767 10.3282 26.5972 10.0359C26.8176 9.74367 26.8881 9.36487 26.7875 9.01287L25.1209 3.17953C24.9778 2.67868 24.52 2.33338 23.9991 2.33337Z" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">{blocks[3].data["Title"].value || "Interior Designs"}</h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p className="color-secondary-07 text-[18px] max-w-[700px]" dangerouslySetInnerHTML={{
                                                __html: blocks[3].data["Content"].value || `Elevate your AI-generated interior designs by upscaling their resolution.
                                            <br/>
                                            <br/>
                                            Transform your visuals into high-definition masterpieces that highlight fine
                                            details and impress clients with a polished, professional appearance.` }}>
                                            </p>
                                            <a href="#" onClick={showLogin} className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">
                                                {blocks[3].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('after-before-2.png')}
                                            // after={getBackImg('after-before-2.png')}
                                            after={getBackImg(blocks[3].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[3].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <div className="slider-images-shapes --right-shapes">
                                            <div className="slider-images-shapes__item" style={getI(0)}></div>
                                            <div className="slider-images-shapes__item" style={getI(1)}></div>
                                            <div className="slider-images-shapes__item" style={getI(2)}></div>
                                            <div className="slider-images-shapes__item" style={getI(3)}></div>
                                            <div className="slider-images-shapes__item" style={getI(4)}></div>
                                        </div>
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('after-before-3.png')}
                                            // after={getBackImg('after-before-3.png')}
                                            after={getBackImg(blocks[3].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[3].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">

                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.337 12.3029V24.5043" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M25.6717 14L14.7296 6.04585C14.3212 5.7501 13.769 5.7501 13.3605 6.04585L2.32861 14" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M22.7536 11.3657V5.24636C22.7536 4.92406 22.4923 4.66278 22.17 4.66278H18.6685C18.3462 4.66278 18.085 4.92406 18.085 5.24636V8.29146" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M25.6717 24.5044H2.32861" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M4.66268 12.317V24.5044" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <rect x="15.7505" y="17.5014" width="4.66861" height="3.50146" rx="0.5" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></rect>
                                                        <path d="M8.16406 24.5043V18.085C8.16406 17.7627 8.42534 17.5014 8.74764 17.5014H11.6655C11.9878 17.5014 12.2491 17.7627 12.2491 18.085V24.5043" stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">{blocks[4].data["Title"].value || "Exterior Designs"}</h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[3].data["Content"].value || `Upscaling transforms both interior and exterior designs. Enhance the
                                            resolution of your exteriors to capture intricate details for a
                                            sophisticated, professional look.
                                            <br/>
                                            <br/>
                                            This approach ensures your designs captivate and impress at first glance.` }}
                                            >
                                            </p>
                                            <a href="#" onClick={showLogin} className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">
                                                {blocks[3].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('after-before-2.png')}
                                            // after={getBackImg('after-before-2.png')}
                                            after={getBackImg(blocks[4].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[4].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <div className="slider-images-shapes --right-shapes">
                                            <div className="slider-images-shapes__item" style={getI(0)}></div>
                                            <div className="slider-images-shapes__item" style={getI(1)}></div>
                                            <div className="slider-images-shapes__item" style={getI(2)}></div>
                                            <div className="slider-images-shapes__item" style={getI(3)}></div>
                                            <div className="slider-images-shapes__item" style={getI(4)}></div>
                                        </div>
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('after-before-3.png')}
                                            // after={getBackImg('after-before-3.png')}
                                            after={getBackImg(blocks[4].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[4].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M6.8833 23.3333C8.74997 21.5833 11.2 20.4167 14 20.4167C16.6833 20.4167 19.25 21.4667 21.1166 23.3333"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M16.4502 10.9667C17.8502 12.3667 17.8502 14.5833 16.4502 15.8667C15.0502 17.15 12.8335 17.2667 11.5502 15.8667C10.2668 14.4667 10.1502 12.25 11.5502 10.9667C12.9502 9.68332 15.0502 9.56666 16.4502 10.9667"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M4.66667 19.8333C3.96667 18.4333 3.5 16.8 3.5 15.1667C3.5 9.33332 8.16667 4.66666 14 4.66666C19.8333 4.66666 24.5 9.33332 24.5 15.1667C24.5 16.8 24.0333 18.4333 23.3333 19.8333"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">{blocks[5].data["Title"].value || "Portraits"}</h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[5].data["Content"].value || `Experience the transformative power of advanced detail enhancement.
                                            <br/>
                                            <br/>
                                            Our upscaling technology breathes new life into every portrait, ensuring
                                            that even the subtlest features are captured with striking clarity and
                                            vibrancy.` }}
                                            >
                                            </p>
                                            <a
                                                href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]"
                                            >{blocks[5].data["Button"].value || "Try Now"}</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            heigth={750}
                                            // before={getBackImg('img-25-04-24/after-before-10.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-10.jpg')}
                                            after={getBackImg(blocks[5].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[5].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            heigth={750}
                                            // before={getBackImg('img-25-04-24/after-before-22.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-22.jpg')}
                                            after={getBackImg(blocks[5].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[5].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M25.6665 24.5L17.9471 9.9531C17.7447 9.57174 17.3482 9.33331 16.9165 9.33331C16.4848 9.33331 16.0883 9.57174 15.886 9.9531L8.1665 24.5"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M21.0939 15.8826L19.0054 17.0492" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M16.9165 15.8826L19.005 17.0492" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M14.8281 17.0492L16.9166 15.8826" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M12.7397 15.8826L14.8283 17.0492" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M26.8332 24.5H1.1665" stroke="#9EE22E" strokeWidth="1.2"
                                                            strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M9.8409 5.27181C10.9197 5.38396 11.7213 6.3221 11.6637 7.40522C11.6062 8.48834 10.7096 9.33622 9.625 9.33335H5.25C4.2835 9.33335 3.5 8.54985 3.5 7.58335C3.5 6.61686 4.2835 5.83335 5.25 5.83335C5.24798 4.65476 6.12662 3.66037 7.2965 3.51728C8.46638 3.37418 9.5588 4.12748 9.8409 5.27181Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M11.6198 17.9932L9.7745 14.6083C9.57013 14.2333 9.17721 13.9999 8.75016 13.9999C8.32311 13.9999 7.93019 14.2333 7.72583 14.6083L2.3335 24.5"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">{blocks[6].data["Title"].value || "Nature and Landscape"}</h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[6].data["Content"].value || `Unlock the true beauty of nature and landscapes with our upscaling
                                            technology.
                                            <br/>
                                            <br/>
                                            Watch as every element, from the delicate textures of foliage to the grand
                                            vistas of mountains, is enhanced to display breathtaking detail and vibrant
                                            colors.` }}
                                            >
                                            </p>
                                            <a
                                                href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">
                                                {blocks[6].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div
                                        className="col-lg-12 relative slider-images-home--full max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-11.png')}
                                            // after={getBackImg('img-25-04-24/after-before-11.png')}
                                            after={getBackImg(blocks[6].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[6].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-20.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-20.jpg')}
                                            after={getBackImg(blocks[6].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[6].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-21.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-21.jpg')}
                                            after={getBackImg(blocks[6].data.Compare.blocks[2]["Image before"].value, true)}
                                            before={getBackImg(blocks[6].data.Compare.blocks[2]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.9839 9.33068C22.1139 8.34242 21.5637 7.39008 20.6426 7.00912L20.5962 6.98995C20.056 6.7665 19.6268 6.33734 19.4034 5.79712L19.3841 5.75043C19.0032 4.82948 18.051 4.27936 17.0629 4.40937L17.0094 4.41638C16.429 4.49275 15.842 4.33536 15.3777 3.97886L15.3409 3.95075C14.5505 3.34389 13.4509 3.34389 12.6605 3.95075L12.6189 3.98277C12.1561 4.33807 11.571 4.49491 10.9926 4.41878L10.9373 4.41151C9.94912 4.28146 8.9969 4.83199 8.61653 5.7533L8.598 5.79819C8.37469 6.33909 7.94506 6.76871 7.40416 6.99201L7.35927 7.01055C6.43796 7.39091 5.88742 8.3431 6.01745 9.33133"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M5.83024 9.33142H22.1699C22.5104 9.33142 22.834 9.48015 23.0557 9.73861C23.2775 9.99706 23.3753 10.3395 23.3235 10.6761L21.3483 23.5147C21.2607 24.0841 20.7708 24.5044 20.1947 24.5044H7.80543C7.22936 24.5044 6.73945 24.0841 6.65185 23.5147L4.67667 10.6761C4.62488 10.3395 4.72268 9.99706 4.94442 9.73861C5.16615 9.48015 5.48971 9.33142 5.83024 9.33142Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M16.918 24.5044L18.0851 9.33142" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M11.0822 24.5044L9.91504 9.33142" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">{blocks[7].data["Title"].value || "Films and Photography"}</h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p className="color-secondary-07 text-[18px] max-w-[700px]" dangerouslySetInnerHTML={{
                                                __html: blocks[7].data["Content"].value || `Transform your films and photography with our cutting-edge upscaling
                                            technology. Experience enhanced clarity, richer textures, and vibrant colors
                                            that bring every frame to life, ensuring your visual content stands out with
                                            cinematic quality.` }}>
                                            </p>
                                            <a
                                                href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]"
                                            >
                                                {blocks[7].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home slider-images-576-mobile">
                                    <div
                                        className="col-lg-12 relative slider-images-home--full max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-19.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-19.jpg')}
                                            after={getBackImg(blocks[7].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[7].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div
                                        className="col-lg-12 relative slider-images-home--full max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-18.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-18.jpg')}
                                            after={getBackImg(blocks[7].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[7].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.32861 21.003H25.6717" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M3.49561 21.0029L4.13071 22.9083C4.44845 23.8615 5.34048 24.5044 6.34523 24.5044H21.6548C22.6595 24.5044 23.5516 23.8615 23.8693 22.9083L24.5044 21.0029"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M13.539 15.4529C12.4474 13.8648 12.732 11.7087 14.1983 10.4582C16.4587 8.4077 19.3807 7.92785 20.6018 9.14901C21.879 10.4262 21.2954 13.5643 19 15.8597C18.2561 16.6033 17.2251 16.9859 16.1762 16.9078C15.1274 16.8296 14.1645 16.2985 13.539 15.4529V15.4529Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M24.0607 5.96991C24.947 5.96991 25.6656 6.68842 25.6656 7.57475C25.6656 8.46107 24.947 9.17958 24.0607 9.17958H23.0103C22.6983 9.18078 22.3999 9.30689 22.1816 9.52973L21.2245 10.4751C21.1648 9.9772 20.9481 9.5112 20.6059 9.14457C20.2393 8.8024 19.7733 8.58575 19.2754 8.52598L20.2208 7.56891C20.4436 7.35063 20.5697 7.05217 20.5709 6.74023V5.6898C20.5709 4.80347 21.2894 4.08496 22.1758 4.08496C23.0621 4.08496 23.7806 4.80347 23.7806 5.6898V5.96991H24.0607Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M21.0494 12.4826C22.4508 13.8125 23.2726 15.6405 23.337 17.5714V18.0849C23.3365 19.6962 22.0304 21.0022 20.4192 21.0028H7.58048C5.96922 21.0022 4.66318 19.6962 4.6626 18.0849V17.5714C4.6626 13.0195 9.7864 9.3313 13.9998 9.3313C14.5094 9.33089 15.0177 9.38172 15.5171 9.48303"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>

                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">
                                                {blocks[8].data["Title"].value || "Food Photoshoots"}
                                            </h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[8].data["Content"].value || `Take your food photography to the next level with our upscaling technology.
                                            See every detail—from the glisten of fresh ingredients to the subtle
                                            textures of cooked dishes—brought to life with stunning clarity and vibrant
                                            colors, making each photo look as tantalizing as the dish itself.` }}
                                            >
                                            </p>
                                            <a href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]"
                                            >
                                                {blocks[8].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-16.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-16.jpg')}
                                            after={getBackImg(blocks[8].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[8].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-17.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-17.jpg')}
                                            after={getBackImg(blocks[8].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[8].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M24.5 9.34147V18.6585C24.5 19.495 24.052 20.2673 23.3263 20.6826L15.1597 25.3575C14.441 25.7681 13.559 25.7681 12.8403 25.3575L4.67367 20.6826C3.948 20.2673 3.5 19.495 3.5 18.6585V9.34147C3.5 8.50497 3.948 7.73264 4.67367 7.31731L12.8403 2.64247C13.559 2.23181 14.441 2.23181 15.1597 2.64247L23.3263 7.31731C24.052 7.73264 24.5 8.50497 24.5 9.34147Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M3.83838 8.13184L8.75005 10.9668" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M24.1617 8.13184L19.25 10.9668" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M24.0102 20.0667H3.99023" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M13.7201 2.36816L3.7334 19.6465" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M14.2798 2.36816L24.2665 19.6465" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M14 20.0622L8.75 10.9692H19.25L14 20.0622Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path d="M13.9998 20.0669V25.6669" stroke="#9EE22E"
                                                            strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">
                                                {blocks[9].data["Title"].value || "Enhancing 3D Renders"}
                                            </h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[9].data["Content"].value || `Elevate your 3D renders with our advanced upscaling technology. Achieve
                                            unparalleled detail and realism, enhancing textures and lighting to bring
                                            your digital creations closer to life. Perfect for architects, designers,
                                            and artists seeking to present their work with the highest quality.` }}
                                            >
                                            </p>
                                            <a href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]">
                                                {blocks[9].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            heigth={750}
                                            // before={getBackImg('img-25-04-24/after-before-14.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-14.jpg')}
                                            after={getBackImg(blocks[9].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[9].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            heigth={750}
                                            // before={getBackImg('img-25-04-24/after-before-15.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-15.jpg')}
                                            after={getBackImg(blocks[9].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[9].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <div className="px-[36px]  w-full">
                                        <div className="flex items-center gap-[20px] max-lg:flex-wrap max-lg:gap-3">
                                            <div className="features-item__icon features-item__icon--md border-gradient">
                                                <div className="ico">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M11.6785 17.5002H16.2565L19.7729 21.3967C20.2153 21.887 20.8448 22.1668 21.5052 22.1668H23.332C24.0547 22.1668 24.7367 21.8319 25.1785 21.26C25.6204 20.688 25.7722 19.9436 25.5897 19.2443L23.0002 9.32183C22.464 7.26714 20.6082 5.83351 18.4847 5.8335H9.51509C7.39162 5.8335 5.53589 7.26709 4.99967 9.32174L2.4101 19.2443C2.22761 19.9436 2.37948 20.688 2.82132 21.26C3.26316 21.8319 3.94509 22.1668 4.66782 22.1668H6.48389C7.15054 22.1668 7.78535 21.8817 8.22815 21.3833L11.6785 17.5002Z"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M9.3335 5.8335L10.2343 8.53576C10.3931 9.01216 10.8389 9.33349 11.341 9.3335H16.6593C17.1614 9.33349 17.6073 9.01216 17.7661 8.53576L18.6668 5.8335"
                                                            stroke="#9EE22E" strokeWidth="1.2" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <h2 className="section-title text-left mb-[24px]">
                                                {blocks[10].data["Title"].value || "Gaming Images"}
                                            </h2>
                                        </div>
                                        <div className="flex items-end justify-between w-full flex-wrap gap-[12px]">
                                            <p
                                                className="color-secondary-07 text-[18px] max-w-[700px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: blocks[10].data["Content"].value || `Enhance your gaming visuals with our sophisticated upscaling technology.
                                            Discover sharpened details and vibrant colors in everything from character
                                            designs to expansive environments, showcasing your content at its very best.` }}
                                            >
                                            </p>
                                            <a
                                                href="#" onClick={showLogin}
                                                className="btn btn--gradient-green text-[18px] font-[600] btn--lg-2 rounded-[300px]"
                                            >
                                                {blocks[10].data["Button"].value || "Try Now"}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="row flex items-center g-24 slider-images-home">
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-12.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-12.jpg')}
                                            after={getBackImg(blocks[10].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[10].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                    <div className="col-lg-6 relative max-w-[500px] mx-auto lg:max-w-none">
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-13.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-13.jpg')}
                                            after={getBackImg(blocks[10].data.Compare.blocks[1]["Image before"].value, true)}
                                            before={getBackImg(blocks[10].data.Compare.blocks[1]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section bg-[#14141A] lg:pt-[120px] lg:pb-[50px]">
                            <div className="container container-1024">
                                <div className="flex items-center justify-center mb-[60px] gap-[16px] flex-wrap">
                                    <h2 className="section-title text-center mb-[24px]">
                                        {blocks[11].data["Title"].value || "Showcasing Upscaling Versatility"}
                                    </h2>
                                    <p className="color-secondary-07 text-[18px] max-w-[700px]">
                                        {blocks[11].data["Content"].value || "Detail-oriented transformation across simple to complex designs"}
                                    </p>
                                </div>
                                <div className="max-w-[471px] mx-auto">
                                    <div className="flex flex-wrap items-center gap-[14px]">

                                        <label className="custom-radio mb-[32px]">
                                            <input key={activeTab + '1'} type="radio" className="custom-radio__input"
                                                name="render" id="small" readOnly
                                                onClick={changeTab} checked={activeTab === 'small'} />
                                            <span className="custom-radio__input-fake">Small Detalization</span>
                                        </label>
                                        <label className="custom-radio mb-[32px]">
                                            <input key={activeTab + '2'} type="radio" className="custom-radio__input"
                                                name="render" id="middle" readOnly
                                                onClick={changeTab} checked={activeTab === 'middle'} />
                                            <span className="custom-radio__input-fake">Middle Detalization</span>
                                        </label>
                                        <label className="custom-radio mb-[32px]">
                                            <input key={activeTab + '3'} type="radio" className="custom-radio__input"
                                                name="render" id="high" readOnly
                                                onClick={changeTab} checked={activeTab === 'high'} />
                                            <span className="custom-radio__input-fake">High Detalization</span>
                                        </label>

                                    </div>
                                </div>

                                <div className="row flex items-center g-24 slider-images-home mb-[36px]">
                                    <div
                                        className="col-12 relative slider-images-home--full max-w-[500px] mx-auto lg:max-w-none">
                                        <div className="slider-images-shapes">
                                            <div className="slider-images-shapes__item" style={getI(0)}></div>
                                            <div className="slider-images-shapes__item" style={getI(1)}></div>
                                            <div className="slider-images-shapes__item" style={getI(2)}></div>
                                            <div className="slider-images-shapes__item" style={getI(3)}></div>
                                            <div className="slider-images-shapes__item" style={getI(4)}></div>
                                        </div>
                                        <BeforeAfterImages
                                            className="widthout-image-text"
                                            // before={getBackImg('img-25-04-24/after-before-24.jpg')}
                                            // after={getBackImg('img-25-04-24/after-before-24.jpg')}
                                            after={getBackImg(blocks[11].data.Compare.blocks[0]["Image before"].value, true)}
                                            before={getBackImg(blocks[11].data.Compare.blocks[0]["Image after"].value, true)}
                                        />
                                    </div>
                                </div>

                                <div className="btn-shapes mb-[70px]">
                                    <div className="shadow-efect-2"></div>
                                    <div className="shadow-efect-1"></div>
                                    <div className="border-efects">
                                        <span style={getI(0)}></span>
                                        <span style={getI(1)}></span>
                                        <span style={getI(2)}></span>
                                    </div>
                                    <a
                                        href="#" onClick={showLogin}
                                        className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                        {blocks[11].data["Button"].value || "Upscale Your Interior"}
                                    </a>
                                </div>

                            </div>
                        </section>

                        <section className="section lg:pt-[120px]">
                            <div className="container container-1024">
                                <div className="flex items-end justify-between mb-[60px] gap-[16px] flex-wrap">
                                    <h2 className="section-title text-left mb-[24px]">
                                        {blocks[12].data["Title"].value || "Explore Real Upscaling Successes"}
                                    </h2>
                                    <p className="color-secondary-07 text-[18px] max-w-[700px]">
                                        {blocks[12].data["Content"].value || "Discover user transformations through authentic examples of upscaling impact"}
                                    </p>
                                </div>

                                <SwiperUpscaleLanding className="swiper explore-real">
                                    {
                                        blocks[12].data.Reviews.blocks?.map((item: any, index: number) => {
                                            // max-w-[500px]
                                            return <div key={index}>
                                                <div className="swiper-slide__img mb-[32px]">
                                                    <BeforeAfterImages
                                                        className="widthout-image-text"
                                                        // heigth={310}
                                                        after={getBackImg(item["Image before"].value, true)}
                                                        before={getBackImg(item["Image after"].value, true)}
                                                    // before={getBackImg(blocks[11].data.Compare.blocks[0]["Image before"].value, true)}
                                                    // after={getBackImg(blocks[11].data.Compare.blocks[0]["Image after"].value, true)}
                                                    />
                                                </div>
                                                <div className="flex gap-[14px]">
                                                    <div className="img-decor-20x20">
                                                        <img className="rounded-[16px]" src={require("../../assets/img/img-25-04-24/bxs-quote-alt-left.svg").default} />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <h4 className="text-[16px] mb-[12px]">{item.Name.value}</h4>
                                                        <p className="text-[14px] color-secondary-07">{item.Comment.value}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </SwiperUpscaleLanding>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container container-1100">
                                <h2 className="mb-[24px] text-center">Frequently Asked Questions</h2>
                                <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">You have questions. We have answers.</p>

                                <div className="faq-wrapper">
                                    {faqs?.length && faqs?.map((faq, index) => {
                                        return (
                                            <FaqItem
                                                key={faq.id}
                                                initOpen={index === 0}
                                                title={faq.question}
                                                content={faq.answer}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </section>

                        <section className="getting-section" style={{
                            background: `linear-gradient(360deg, #101014 0%, rgba(16, 16, 20, 0.00) 100%), url(${require('../../assets/img/get-section-image.png')}), #101014 50% / cover no-repeat`,
                            backgroundBlendMode: 'normal, luminosity',
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}>
                            <div className="container">
                                <h2 className="section-title mb-[48px]">
                                    {/*{blocks[2].data.title.value}*/}
                                    Get Design Sense PRO Account
                                </h2>
                                <div className="section-text text-center">
                                    <p className="mb-[16px]">
                                        {/* <Icon src="img/smale-1.png" className="mr-[10px]" alt="" />  */}
                                        {/*{blocks[2].data.items.blocks[0].title.value}*/}
                                        🤩 Download photos without watermarks
                                    </p>
                                    <p className="mb-[16px]">
                                        {/* <Icon src="img/smale-2.png" className="mr-[10px]" alt="" />  */}
                                        {/*{blocks[2].data.items.blocks[1].title.value}*/}
                                        🏡 Unlimited render amount
                                    </p>
                                    <p>
                                        {/* <Icon src="img/smale-3.png" className="mr-[10px]" alt="" />  */}
                                        {/*{blocks[2].data.items.blocks[2].title.value}*/}
                                        💎 The best quality of render
                                    </p>
                                </div>
                                <div className="btn-shapes">
                                    <div className="border-efects w-212">
                                        <span style={getI(0)}></span>
                                        <span style={getI(1)}></span>
                                        <span style={getI(2)}></span>
                                    </div>
                                    <NavLink
                                        to="/pricing"
                                        className="btn btn--gradient-blue text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                        <span className="icon mr-[10px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z"
                                                    fill="currentColor"></path>
                                                <path
                                                    d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        {/*{blocks[2].data.button.value}*/}
                                        Try Pro Account
                                    </NavLink>
                                </div>
                            </div>
                        </section>
                    </main>

                    <SiteFooter currentPageData={pageData} />
                </div> : null}

        </>
    )
}