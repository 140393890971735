export const getMaxWidth = (normalStyle:string) => {
    let res:string = '';
    let styleArray = normalStyle.split(';');
    styleArray.forEach((styleString:string)=>{
        // styleString.split(':')[0].trim()==="max-width"
        if(styleString.split(':')[0]){
            res = styleString.split(':')[1]
        }
    })
    return { maxWidth : res} as React.CSSProperties
}
export const setSkeletonActive = (isActive:boolean):void => {
    if(isActive){
        document.body.classList.add('skeleton-active')
    }else{
        document.body.classList.remove('skeleton-active')
    }
}