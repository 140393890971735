import { axiosConfig, stripeKey } from "../api/config";

export class Payment{
    auth_token: any;
    stripe: any;
    card: any;
    exp: any;
    cvc: any;
    windowLocal: any;

    userEmail: any;
    userName: any;

    constructor(auth_token: any){
        this.windowLocal = window;
        this.auth_token = auth_token;
        this.stripe = this.windowLocal.Stripe(stripeKey);
        this.userEmail = "";
        this.userName = "";
    }

    async init(){
        await this.createElements();
        this.windowLocal.stripe = this.stripe;
    }

    setEmail(value: string) {
        console.log('setEmail:', value);
        
        this.userEmail = value
    }

    getEmail() {
        return this.userEmail
    }

    setUserName(value: string) {
        this.userName = value
    }

    getUserName() {
        return this.userName
    }
    
    async createElements(){
        let elements = this.stripe.elements();
        let style = { style:
            {
                base: {
                    color: '#FFFFFF',
                    fontSize: '14px',
                    '::placeholder': {
                        color: 'rgba(255, 255, 255, 0.6)',
                    },
                }
            }
        };
        this.card = elements.create('cardNumber', style);
        this.exp = elements.create('cardExpiry', style);
        this.cvc = elements.create('cardCvc', style);
        this.card.mount("#cardNumber");
        this.exp.mount("#cardExp")
        this.cvc.mount("#cardCVC");
    }
    
    async saveCard(){
        let isDefault = this.windowLocal.document.querySelector(".is_default_checkbox").checked ? 1 : 0;
        document.querySelector('.add-card-submit')?.classList.add('hidden');
        document.querySelector('.add-card-loading')?.classList.remove('hidden');

        var stripe_data = null as any;

        console.log("createPaymentMethod payload:", {
            name: this.userName,
            email: this.userEmail,
        });
        

        try{
            stripe_data = await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card,
                billing_details: {
                    name: this.userName || null,
                    email: this.userEmail || null,
                }
            });
        }catch(e){
            console.log('e:', e);
            
        }

        if(stripe_data.error){
            let event = new Event("setSubscribeloadingFalse", {bubbles: true});
            document.dispatchEvent(event);

            document.dispatchEvent(new CustomEvent("openNotificationManager", {
                detail: { status: false, message: stripe_data?.error?.message || "Something went wrong" }
            }));

            document.querySelector('.add-card-submit')?.classList.remove('hidden');
            document.querySelector('.add-card-loading')?.classList.add('hidden');

            // alert(stripe_data.error.message);
            return false;
        }

        var intent_data = (await this.sendAjax(`${axiosConfig.baseURL}/api/stripe/setup-intent`, {})).data;
        let stripe_confirm = await this.stripe.confirmCardSetup(intent_data.client_secret, {
            payment_method: stripe_data.paymentMethod.id,
            // billing_details: {
            //     email: this.userEmail,
            // }
        });

        console.log('stripe_confirm:', stripe_confirm);
        
        
        if(stripe_data?.error?.message || stripe_confirm?.error?.message){
            let event = new Event("setSubscribeloadingFalse", {bubbles: true});
            document.dispatchEvent(event);

            document.dispatchEvent(new CustomEvent("openNotificationManager", {
                detail: { status: false, message: stripe_data?.error?.message || stripe_confirm?.error?.message || "Something went wrong" }
            }));
            // if(this.windowLocal?.setSubscribeLoading){
            //     this.windowLocal.setSubscribeLoading(null);
            // }
            // setTimeout(() => {
            //     alert(stripe_confirm?.error?.message || "error"); 
            // }, 300);

            document.querySelector('.add-card-submit')?.classList.remove('hidden');
            document.querySelector('.add-card-loading')?.classList.add('hidden');

            return false;
        }
        let card_data = {
            "stripe_id": stripe_data.paymentMethod.id,
            "last4": stripe_data.paymentMethod.card.last4,
            "exp_month": stripe_data.paymentMethod.card.exp_month,
            "exp_year": stripe_data.paymentMethod.card.exp_year,
            "is_default": isDefault,
        };

        let resp = await this.sendAjax(`${axiosConfig.baseURL}/api/stripe/save-card`, card_data, "POST");
        if(this.windowLocal.confirmAddCardPopup){
            await this.windowLocal.confirmAddCardPopup(this.stripe);
        }

        document.querySelector('.add-card-submit')?.classList.remove('hidden');
        document.querySelector('.add-card-loading')?.classList.add('hidden');

        return stripe_data;
    }

    async sendAjax(url:any, data={}, method="POST", headers={}){
        try{
            let additional = {};
            if(method=='POST'){ additional = { body: JSON.stringify(data) }; };

            const updateToken = this.auth_token ? this.auth_token : localStorage.getItem('token')

            return await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "Authorization": `Bearer ${updateToken}`,
                    ...headers
                },
                ...additional
            }).then((res)=>{ return res.json(); });
        }catch(e){
            let event = new Event("setSubscribeloadingFalse", {bubbles: true});
            document.dispatchEvent(event);

            setTimeout(() => {
                alert("error");
            }, 300);
            return false;
        }
    }
}