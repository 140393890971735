import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { AppContext } from './AppContext';
import { setSkeletonActive } from '../helpers/skeletonHelper';


const AppContextProvider  = ({ children }:{ children:ReactNode }) => {
    let [loading, setLoading] = useState<boolean>(false);
    let [data, setData] = useState<boolean>(false);

    let fetchData = async () => {
        setData(false)

        setLoading(true);
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(true)
            }, 3000)
        })
        // data = {}
        setLoading(false)
        setData(true)
    }
    useEffect(()=>{
        setSkeletonActive(loading)
    }, [loading])
    const defaultProps = useMemo(() => ({
        loading,
        data, 
        fetchData: fetchData,
    }), [loading]);

    return (
        <AppContext.Provider value={defaultProps}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
