import { ReactNode, useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import 'swiper/css';

export const SwiperHome = ({ children, className, breakpoints }: { children: ReactNode[], className?: string, revers?: boolean, breakpoints?: any }) => {

    const swiperRef = useRef<SwiperRef>(null);

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={24}
            slidesPerView={1}
            loop={true}
            className={className}
            observer={true}
            breakpoints={breakpoints}
            centeredSlides={true}
        >
            {children.map((el: ReactNode, idx: number) => <SwiperSlide key={idx}>{el}</SwiperSlide>)}
        </Swiper>
    );
};