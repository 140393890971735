import { AxiosInstance } from "axios";
import { IGenerationDataResponse, IGenerationPayload } from "../../store/Generation/types";
// import { IGenerationOptionsData } from "../../store/GenerationOptions/types";

export const generationApi = (instance: AxiosInstance) => ({
    generation(generationData: IGenerationPayload) {
        return instance.post<IGenerationDataResponse>('/api/ai/generate', generationData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    selectPrediction(renderId: number, predictionId: number) {
        return instance.get<any>(`/api/ai/select-prediction/${renderId}/${predictionId}`);
    },
    getRenderStatus(renderId: number | string) {
        return instance.get<any>(`/api/ai/render-status/${renderId}`);
    },
    upscale(renderId: number | string, predictionNumber: number | string) {
        return instance.post<any>(`/api/ai/upscale-prediction/${renderId}/${predictionNumber}`);
    },
    generateVideo(renderId: number | string, predictionNumber: number | string) {
        return instance.post<any>(`/api/ai/generate-video/${renderId}/${predictionNumber}`);
    },
    getVideoRenderStatus(renderId: number | string) {
        return instance.get<any>(`/api/ai/video-render-status/${renderId}`);
    },
    generateUpscale(generationData: IUpscaleModelData) {
        return instance.post<IPayloadUpascaleGeneration>('/api/ai/generate-upscale', generationData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
});