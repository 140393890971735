import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from "../../api/axios";

export const fetchGenerateUpscale = createAsyncThunk(
    'upscale/fetchGenerateUpscale',
    async (payload: IUpscaleModelData, { rejectWithValue, dispatch }) => {
        try {
            const res = await Api().generation.generateUpscale(payload);
            return res.data
        } catch (error) {
            console.log('error:', error);
            return rejectWithValue({ rejectedError: 'error' })
        }
    }
)