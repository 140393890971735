import 'swiper/css';
import { Link } from 'react-router-dom';
import { ReactNode, useRef } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

export const SwiperRender = ({ children, className = "", revers = false, breakpoints }: { children: ReactNode[], className?: string, revers?: boolean, breakpoints?: any }) => {

    const swiperRef = useRef<SwiperRef>(null);

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={40}
            slidesPerView={1}
            className={`${className} h-full w-full`}
            observer={true}
            breakpoints={breakpoints}
            wrapperClass='h-full w-full'
        >
            {children.map((el: ReactNode, idx: number) => <SwiperSlide className='h-full' key={idx}>{el}</SwiperSlide>)}
        </Swiper>
    );
};