import React, { useState, useEffect } from 'react'
import { Icon } from '../../../components/UI/Icon'
import { convertImageToBase64Src } from '../../../helpers/ImageFileReader'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { clearToken } from "../../../store/auth/AuthSlice";

import { clearProfile, getProfile, getProfileLoading } from '../../../store/profile/ProfileSlice';
import { fetchProfile, updateProfile, updatePassword, cancelSubscribtion, subscribeToPlan, cancelUpscaleSubscribtion } from '../../../store/profile/ProfileThunk';

import { fetchPlans, fetchUpscalerPlans, fetchSettingsPageData } from '../../../store/pagesData/PagesDataThunk';
import { getPlans, getUpscalerPlans, getSettingsPageData, getSelectedLanguage, setCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import MetaTags from 'react-meta-tags';
import { NotificationManager } from 'react-notifications';

import { Popup } from '../../../components/Popups/Popup'
import { NoRendersPopup } from "../../../components/Popups/NoRendersPopup";
import { ConfirmPopup } from "../../../components/Popups/ConfirmPopup";
import { FeatureType } from '../../../types';
import { setActiveGenerationTab } from '../../../store/Generation/GenerationSlice';

interface IUser {
	avatar: string | boolean | null,
	name: string,
	email: string,
}
interface IPassword {
	currentPassword: string,
	newPassword: string,
	newPasswordRepeat: string
}

export const Settings = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [selectedPlan, setSelectedPlan] = useState<FeatureType>()

	let pageData = useAppSelector(getSettingsPageData);

	const languageFromStorage = localStorage.getItem('language');
	const selectedLanguage = useAppSelector(getSelectedLanguage);

	const fromStrings = (value: string) => {
		return pageData?.strings?.[value] || value;
	}

	const profile = useAppSelector(getProfile);
	const profileLoading = useAppSelector(getProfileLoading)
	const [isLoading, setIsLoading] = useState(false)

	const plans = useAppSelector<{ title: string, features: any[], price_mo: string | number, price_y: string | number, }[]>(getPlans);
	const upscalerPlans = useAppSelector<{ title: string, features: any[], price_mo: string | number, price_y: string | number, cancel_at_period_end: any }[]>(getUpscalerPlans);

	const currentPlan = plans.find((item: any) => item?.id === profile?.plan?.id);
	const currentUpscalerPlan = upscalerPlans.find((item: any) => item?.id === profile?.upscale_plan?.id);

	let initialUser: IUser = {
		avatar: false,
		name: '',
		email: ''
	}
	const [changeBasicInfoLoading, setChangeBasicInfoLoading] = useState<boolean>(false);
	const [changeAvatarLoading, setChangeAvatarLoading] = useState<boolean>(false);
	const [passwordChangeLoading, setPasswordChangeLoading] = useState<boolean>(false);

	const [mobilePlanPopup, setMobilePlanPopup] = useState<boolean>(false);
	const [confirmPopupData, setConfirmPopupData] = useState<any>(null);
	const [continueConfirmPopupData, setContinueConfirmPopupData] = useState<any>(false);

	const [user, setUser] = useState<IUser>(initialUser);
	const changeUserData = (key: string, value: string | null) => {
		setUser({
			...user,
			[key]: value
		})
	}
	let initialPassword: IPassword = {
		currentPassword: '',
		newPassword: '',
		newPasswordRepeat: ''
	}
	const [password, setPassword] = useState<IPassword>(initialPassword);
	const changePasswordData = (key: string, value: string) => {
		setPassword({
			...password,
			[key]: value
		})
	}

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files as FileList | [];

		setChangeAvatarLoading(true);

		await dispatch(updateProfile({
			name: user.name,
			email: user.email,
			avatar: files[0]
		}));
		await dispatch(fetchProfile());

		setChangeAvatarLoading(false);
	}

	const removeAvatar = async () => {
		setChangeAvatarLoading(true);

		await dispatch(updateProfile({
			name: user.name,
			email: user.email,
			avatar: ""
		}));
		await dispatch(fetchProfile());

		setChangeAvatarLoading(false);
	}

	const logOut = () => {
		dispatch(clearToken());
		localStorage.removeItem('token');
		localStorage.removeItem('last_login');
		dispatch(clearProfile());
		navigate("/");
	}

	const changeBasicInfo = async () => {
		setChangeBasicInfoLoading(true);

		await dispatch(updateProfile({
			name: user.name,
			email: user.email
		}));
		await dispatch(fetchProfile());

		setChangeBasicInfoLoading(false);
	}

	const changePassword = async () => {
		setPasswordChangeLoading(true);
		let result = await dispatch(updatePassword({
			current_password: password.currentPassword,
			password: password.newPassword,
			password_confirmation: password.newPasswordRepeat
		}));

		setPassword({
			currentPassword: '',
			newPassword: '',
			newPasswordRepeat: ''
		});

		setPasswordChangeLoading(false);
	}

	const currenPeriodEnd = (plan: any) => {
		console.log(plan, "plan");

		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		if (!plan?.current_period_end) {
			return '';
		} else {
			let date = new Date(plan?.current_period_end * 1000)
			return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
		}
	}

	const cancelSubscribePlan = async (plan: any) => {
		const periodNameVal = profile?.plan?.period;
		const mobPlans = [
			"monthly01",
			"weekly01",
			"yearly01",
			"design.sense.pre.month.subscription",
			"design.sense.pre.week.subscription",
			"design.sense.pre.year.subscription",
		]

		if (mobPlans.includes(periodNameVal)) {
			setMobilePlanPopup(true);
			return false;
		}

		setConfirmPopupData({
			title: 'Confirm Cancellation',
			subtitle: `If you confirm and end your subscription now, you can still access it until ${currenPeriodEnd(plan)}.`,
			cancelBtnText: 'Not Now',
			confirmBtnText: 'Confirm '
		});
	}
	const cancelSubscribePlanAction = async () => {
		setConfirmPopupData(null);
		setIsLoading(true)
		let result = null;
		if (selectedPlan === "render") {
			result = await dispatch(cancelSubscribtion()).unwrap();
		} else if (selectedPlan === "upscale") {
			result = await dispatch(cancelUpscaleSubscribtion()).unwrap();
		}

		await dispatch(fetchProfile());
		setIsLoading(false)

		if (result && result?.success) {
			NotificationManager.success('Subscription canceled');
		} else {
			NotificationManager.error(result.message ? result.message : 'Something went wrong');
		}
	}
	const continueSubscribePlan = async () => {
		setContinueConfirmPopupData(null);
		setIsLoading(true)

		let result = null;
		if (selectedPlan === "upscale") {
			result = await dispatch(subscribeToPlan({ plan_id: profile?.upscale_plan?.id || '', type: profile?.upscale_plan?.period || '' })).unwrap();
		} else if (selectedPlan === "render") {
			result = await dispatch(subscribeToPlan({ plan_id: profile?.plan.id || '', type: profile?.plan?.period || '' })).unwrap();
		}
		await dispatch(fetchProfile());
		setIsLoading(false)

		if (result.success) {
			NotificationManager.success('Subscription continue successfully');
		} else {
			NotificationManager.error(result.message ? result.message : 'Something went wrong');
		}
	}
	const getPlanName = (plan: any) => {
		const periodNameVal = plan;

		if (!periodNameVal) {
			return "";
		}

		if (periodNameVal === 'month' || periodNameVal === 'monthly' || periodNameVal === 'monthly01' || periodNameVal === 'design.sense.pre.month.subscription') {
			return "Monthly"
		} else if (periodNameVal === 'week' || periodNameVal === 'weekly' || periodNameVal === 'weekly01' || periodNameVal === 'design.sense.pre.week.subscription') {
			return "Weekly"
		} else {
			return "Yearly"
		}
	}

	const handleUpdgradeToPro = (e: React.MouseEvent) => {
		e.preventDefault()
		dispatch(setActiveGenerationTab('render'))
		navigate("/pricing")
	}

	useEffect(() => {
		dispatch(fetchSettingsPageData((selectedLanguage || languageFromStorage || '') as string));
	}, [selectedLanguage]);

	useEffect(() => {
		dispatch(setCurrentPageData(pageData));
	}, [pageData])

	useEffect(() => {
		if (!plans?.length) {
			dispatch(fetchPlans((selectedLanguage || languageFromStorage || '') as string));
		}
		if (!upscalerPlans?.length) {
			dispatch(fetchUpscalerPlans((selectedLanguage || languageFromStorage || '') as string));
		}
	}, []);

	useEffect(() => {
		if (profile) setUser(profile);
	}, [profile]);

	return (
		<div className="full-page-section__content">
			<MetaTags>
				<title>{pageData?.page?.meta_title || "Design Sense AI"}</title>
				<meta name="description" content={pageData?.page?.meta_description || ""} />
			</MetaTags>

			<div className="section-scroll-container">
				<div className="settings">
					<div className="flex items-center justify-between">
						<h4 className="mb-[18px] mr-[20px] font-[400]">{fromStrings("Profile Settings")}</h4>
						<a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[18px]" onClick={(e) => {
							e.preventDefault();
							logOut();
						}}>
							{fromStrings("Log Out")}
						</a>
					</div>
					<div className="flex items-center pb-[24px] mb-[24px] border-bottom">
						<div className="setting__user-img mr-[20px]">
							{changeAvatarLoading ?
								<svg
									version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "60px", margin: "auto" }}
								>
									<path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
										<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
									</path>
								</svg> :
								user?.avatar ?
									<img
										src={profile.avatar} alt=""
									/> : <div className="avatar-placeholder" style={{
										minWidth: "100%",
										minHeight: "100%",
									}}>{user?.name[0]}</div>
							}
						</div>
						<div className="flex flex-col">
							<div className="flex items-center avatar-actions">
								<label htmlFor="change-image" className="change-image">
									<input
										type="file"
										id="change-image"
										name="avatar"
										className="upload-wrap__input"
										onChange={handleFileChange}
									/>
									<span className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[10px] mr-[8px]">
										{fromStrings("Change Image")}
									</span>
								</label>

								<a
									href="#" onClick={(e) => { e.preventDefault(); removeAvatar(); }}
									className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[10px]"
								>
									{fromStrings("Remove")}
								</a>
							</div>
							<span className="text-[12px] leading-[14px] opacity-40">{fromStrings("We support PNG, JPEG and GIF under 10MB")}</span>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<h4 className="text-[16px] leading-[18px] mb-[24px] mr-[20px]">Basic Info</h4>
						<a
							href="#" className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[24px] min-w-[132px] min-h-[36px]"
							onClick={(e) => {
								e.preventDefault();
								changeBasicInfo();
							}}
						>
							{
								changeBasicInfoLoading ?
									<svg
										version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
										viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
									>
										<path fill="#101014" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
											<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
										</path>
									</svg> : fromStrings("Save Changes")
							}
						</a>
					</div>

					<div className="border-bottom pb-[24px] mb-[15px]">
						<div className="max-w-[500px]">
							<div className="input-custom mb-[16px]">
								<label className="input-label mb-[6px]">{fromStrings("Full Name")}</label>
								<div className="input-custom__wrap-input">
									<input
										className="input-custom__input"
										type="text"
										placeholder=""
										value={user.name}
										onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changeUserData('name', event.target.value) }}
									/>
								</div>
							</div>

							<div className="input-custom">
								<label className="input-label mb-[6px]">{fromStrings("Email")}</label>
								<div className="input-custom__wrap-input">
									<input
										className="input-custom__input"
										type="text"
										placeholder=""
										value={user.email}
										onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changeUserData('email', event.target.value) }}
									/>
								</div>
							</div>
						</div>
					</div>

					{(profile?.team_role === "user" && profile?.is_premium) || !profile ? null :
						<>
							<div className="flex items-center justify-between plan-billing-block">
								<h4 className="text-[16px] leading-[18px] mb-[15px] mr-[20px]">{fromStrings("Plan & Billing")}</h4>

								<div className="flex items-center plan-billing-actions">
									{/* {
										!profile?.is_premium ?
											<NavLink to="/pricing" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]">
												<span className="icon mr-[10px]">
													<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
														<path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor" />
														<path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor" />
													</svg>
												</span>
												{fromStrings("Upgrade to Pro")}
											</NavLink> :
											profile.plan.cancel_at_period_end ?
												<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
													e.preventDefault();
													setContinueConfirmPopupData(true);
												}}>
													{fromStrings("Continue Subscribtion")}
												</a> :
												<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
													e.preventDefault();
													cancelSubscribePlan(profile.plan);
												}}>
													{fromStrings("Cancel Subscribtion")}
												</a>
									} */}

									<Link to="/settings/billing" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[15px]">
										{fromStrings("Show More")}
									</Link>
								</div>
							</div>

							<div className="border-bottom pb-[24px] mb-[24px]">
								<div className="max-w-[758px]">
									{
										currentPlan && profile ?
											<div className="row gx-16 gy-4">
												<div className="col-md-6">
													<div className="plan-billing-card">
														<div className='flex items-center justify-between'>
															<span className="block text-[14px] opacity-40 mb-[4px]">Render Interior</span>
															{/* {
																profile.plan.cancel_at_period_end ?
																	<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																		e.preventDefault();
																		setSelectedPlan("render")
																		setContinueConfirmPopupData(true);
																	}}>
																		{fromStrings("Continue Subscribtion")}
																	</a> :
																	<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																		e.preventDefault();
																		setSelectedPlan("render")
																		cancelSubscribePlan(profile.plan);
																	}}>
																		{fromStrings("Cancel Subscribtion")}
																	</a>
															} */}
															{
																!profile?.is_premium ?
																	<a href='#' onClick={handleUpdgradeToPro} className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]">
																		<span className="icon mr-[10px]">
																			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
																				<path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor" />
																				<path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor" />
																			</svg>
																		</span>
																		{fromStrings("Upgrade to Pro")}
																	</a> :
																	profile.plan.cancel_at_period_end ?
																		<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																			e.preventDefault();
																			setSelectedPlan("render")
																			setContinueConfirmPopupData(true);
																		}}>
																			{
																				(profileLoading || isLoading) ?
																					<svg
																						version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
																						viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
																					>
																						<path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
																							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
																						</path>
																					</svg>
																					:
																					fromStrings("Continue Subscribtion")
																			}
																		</a> :
																		<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																			e.preventDefault();
																			setSelectedPlan("render")
																			cancelSubscribePlan(profile.plan);
																		}}>
																			{
																				(profileLoading || isLoading) ?
																					<svg
																						version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
																						viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
																					>
																						<path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
																							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
																						</path>
																					</svg>
																					:
																					fromStrings("Cancel Subscribtion")
																			}
																		</a>
															}
														</div>
														{
															!currentPlan?.title ? <span className="block text-[14px] opacity-40">
																No Subscription
															</span> : <>
																<span className="block text-[16px] mb-[4px]">{fromStrings(currentPlan.title)}</span>
																<span className="block text-[14px] opacity-40">
																	{getPlanName(profile?.plan?.period)}{profile?.plan?.current_period_end ? `, end ${currenPeriodEnd(profile?.plan)}` : ''}
																</span>
															</>
														}
													</div>
												</div>
												{
													upscalerPlans && profile && currentUpscalerPlan ?
														<div className="col-md-6">
															<div className="plan-billing-card">
																<div>
																	<div className='flex items-center justify-between'>
																		<span className="block text-[14px] opacity-40 mb-[4px]">Upscaler</span>
																		{
																			profile.upscale_plan?.cancel_at_period_end ?
																				<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																					e.preventDefault();
																					setSelectedPlan("upscale")
																					setContinueConfirmPopupData(true);
																				}}>
																					{
																						(profileLoading || isLoading) ?
																							<svg
																								version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
																								viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
																							>
																								<path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
																									<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
																								</path>
																							</svg>
																							:
																							fromStrings("Continue Subscribtion")
																					}
																				</a> :
																				<a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]" onClick={(e) => {
																					e.preventDefault();
																					setSelectedPlan("upscale")
																					cancelSubscribePlan(profile?.upscale_plan);
																				}}>
																					{
																						(profileLoading || isLoading) ?
																							<svg
																								version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
																								viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
																							>
																								<path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
																									<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
																								</path>
																							</svg>
																							:
																							fromStrings("Cancel Subscribtion")
																					}
																				</a>
																		}
																	</div>
																</div>
																{
																	!currentUpscalerPlan?.title ? <span className="block text-[14px] opacity-40">
																		No Subscription
																	</span> : <>
																		<span className="block text-[16px] mb-[4px]">{currentUpscalerPlan ? currentUpscalerPlan?.title : "No Subscription"}</span>
																		<span className="block text-[14px] opacity-40">
																			{getPlanName(profile?.upscale_plan?.period)}{profile?.upscale_plan?.current_period_end ? `, end ${currenPeriodEnd(profile?.upscale_plan)}` : ''}
																		</span>
																	</>
																}
															</div>
														</div>
														: null}
												{/* <div className="col-4">
										<div className="plan-billing-card">
											<span className="block text-[16px] mb-[4px]">{fromStrings("Billing Period")}</span>
											<span className="block text-[14px] opacity-40">{ getPlanName() }{ profile.plan.cancel_at_period_end && profile?.plan?.current_period_end ? `, end ${currenPeriodEnd()}` : '' }</span>
										</div>
									</div> */}
											</div> : <div>{fromStrings("Loading")}...</div>
									}
								</div>
							</div>
						</>}

					<div className="flex items-center justify-between">
						<h4 className="text-[16px] leading-[18px] mb-[24px] mr-[20px]">{fromStrings("Change Password")}</h4>
						<a
							href="#" className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[24px] min-w-[132px] min-h-[36px]"
							onClick={(e) => {
								e.preventDefault();
								changePassword();
							}}
						>
							{
								passwordChangeLoading ?
									<svg
										version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
										viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
									>
										<path fill="#101014" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
											<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
										</path>
									</svg> : fromStrings("Save Changes")
							}
						</a>
					</div>

					<div className="max-w-[500px] settings-password-fields">
						<div className="row gx-16">
							<div className="col-6">
								<div className="input-custom mb-[16px]">
									<label className="input-label mb-[6px]">{fromStrings("Current Password")}</label>
									<div className="input-custom__wrap-input">
										<input
											className="input-custom__input"
											type="password"
											placeholder=""
											value={password.currentPassword}
											onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('currentPassword', event.target.value) }}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row gx-16">
							<div className="col-6">
								<div className="input-custom mb-[16px]">
									<label className="input-label mb-[6px]">{fromStrings("New Password")}</label>
									<div className="input-custom__wrap-input">
										<input
											className="input-custom__input"
											type="password"
											placeholder=""
											value={password.newPassword}
											onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('newPassword', event.target.value) }}
										/>
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="input-custom mb-[16px]">
									<label className="input-label mb-[6px]">{fromStrings("Repeat New Password")}</label>
									<div className="input-custom__wrap-input">
										<input
											className="input-custom__input"
											type="password"
											placeholder=""
											value={password.newPasswordRepeat}
											onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('newPasswordRepeat', event.target.value) }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			{mobilePlanPopup ?
				<Popup
					modalDialogWidth="650"
					open={mobilePlanPopup}
					onClose={() => {
						setMobilePlanPopup(false)
					}}
				>
					<NoRendersPopup
						close={() => {
							setMobilePlanPopup(false);
						}}
						title={null}
						hideBtn={true}
					>
						{/* color-secondary-07 */}
						<div className="no-renders-popup-text text-[14px] text-[#ffffff] no-renders-popup-text--margins pt-[30px] d-flex flex-col items-center">
							<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="80" height="80" className="mb-[6px]">
								<g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11.25 13.5V8.25H12.75V13.5H11.25ZM11.25 15.75V14.25H12.75V15.75H11.25Z" fill="#de2323"></path> </g>
							</svg>
							{fromStrings("Your subscription was created on the mobile application.")} <br />
							{fromStrings("Mobile subscriptions can be cancelled only on the same mobile application.")} <br />
							{fromStrings("Please use your mobile app to cancel your subscription.")}
						</div>
					</NoRendersPopup>
				</Popup> : null
			}

			{confirmPopupData ?
				<Popup
					modalDialogWidth="460"
					open={confirmPopupData}
					onClose={() => {
						setConfirmPopupData(null)
					}}
				>
					<ConfirmPopup
						close={() => { setConfirmPopupData(null) }}
						confirm={() => { cancelSubscribePlanAction() }}
						title={fromStrings(confirmPopupData.title)}
						subtitle={fromStrings(confirmPopupData.subtitle)}
						cancelBtnText={fromStrings(confirmPopupData.cancelBtnText)}
						confirmBtnText={fromStrings(confirmPopupData.confirmBtnText)}
					/>
				</Popup> : null
			}

			{continueConfirmPopupData ?
				<Popup
					modalDialogWidth="460"
					open={continueConfirmPopupData}
					onClose={() => {
						setContinueConfirmPopupData(false)
					}}
				>
					<ConfirmPopup
						close={() => { setContinueConfirmPopupData(false) }}
						confirm={() => { continueSubscribePlan() }}
						title={fromStrings('Continue Subscribtion')}
						subtitle={fromStrings('Do you want to continue your subscription?')}
						cancelBtnText={fromStrings('Cancel')}
						confirmBtnText={fromStrings('Confirm')}
					/>
				</Popup> : null
			}
		</div>
	)
}
