import React, { useState, useEffect } from 'react';
import { Icon } from '../../components/UI/Icon';
import { SwiperLanding } from "../../components/UI/Swiper";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
import { Popup } from "../../components/Popups/Popup";
import BeforeAfterImages from "../../components/UI/BeforeAfterImages";
import { NavLink, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { getHomePageData, getSelectedLanguage, setCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { fetchHomePageData } from "../../store/pagesData/PagesDataThunk";
import MetaTags from 'react-meta-tags';
import { setActiveGenerationTab } from '../../store/Generation/GenerationSlice';
import { IFaq } from '../../types';
import FaqItem from '../../components/UI/FaqItem';

const Landing = () => {
    const dispatch = useAppDispatch();
    const [openLogin, setOpenLogin] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fetchPage = async () => {
        setLoading(true);
        await dispatch(fetchHomePageData((selectedLanguage || languageFromStorage || '') as string));
        setLoading(false);
    }

    const getI = (sec: number) => {
        return { '--i': `${sec}s` } as React.CSSProperties
    }

    const getBackImg = (src: string) => {
        return { backgroundImage: `url(${require(`../../assets/img/${src}`)})` }
    }


    const home = useAppSelector(getHomePageData);
    const blocks = home?.page?.blocks;
    // document.title = home?.page?.meta_title;
    const faqs: IFaq[] = home?.faqs;


    const token = localStorage.getItem('token');

    const fromStrings = (value: string) => {
        return home?.strings?.[value] || value;
    }

    const showLogin = (ev: any) => {
        ev.preventDefault();
        dispatch(setActiveGenerationTab("render"))

        if (token && token.length) {
            navigate("/render-interior");
            return;
        }

        setOpenLogin(false);
        setTimeout(() => {
            setOpenLogin(true);
        }, 100)
    }

    useEffect(() => {
        fetchPage();
    }, [selectedLanguage])

    useEffect(() => {
        dispatch(setCurrentPageData(home));
    }, [home])

    if (!home || !blocks || !blocks?.length) {
        return (
            <>
                {/* <Header setLoginModal={openLogin}/> */}

                <div className="wrapper header-fixed">
                    <main className="content landing-content px-[16px]">
                        <section className="section mt-[16px] d-flex align-items-center justify-center" style={{ height: 'calc(100vh - 112px)' }}>
                            <div className="features-item__icon">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.999 30.005V22.5019C14.999 21.6731 15.6709 21.0013 16.4996 21.0013H19.5009" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.49463 15.8694L16.047 5.96717C17.171 5.00376 18.8295 5.00376 19.9535 5.96717L31.5059 15.8694" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.0003 30.005H4.49463" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M23.438 8.9539V5.61978C23.438 4.9982 23.9419 4.49431 24.5635 4.49431H28.4514C29.073 4.49431 29.5769 4.9982 29.5769 5.61978V14.2159" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.42365 14.216V30.005" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27.7541 31.5056C27.7541 28.6049 25.4027 26.2534 22.502 26.2534C25.4027 26.2534 27.7541 23.902 27.7541 21.0013C27.7541 23.902 30.1056 26.2534 33.0063 26.2534C30.1056 26.2534 27.7541 28.6049 27.7541 31.5056Z" stroke="#9EE22E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <div className="features-item__shape" style={getI(0)}></div>
                                <div className="features-item__shape" style={getI(1)}></div>
                                <div className="features-item__shape" style={getI(2)}></div>
                                <div className="features-item__shape" style={getI(3)}></div>
                                <div className="features-item__shape" style={getI(4)}></div>
                                <div className="features-item__shape" style={getI(5)}></div>
                            </div>
                        </section>
                    </main>
                </div>
            </>

        )
    }

    return (
        <>
            <MetaTags>
                <title>{home?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={home?.page?.meta_description || ""} />
            </MetaTags>

            <Header setLoginModal={openLogin} currentPageData={home} />

            <div className="wrapper header-fixed">
                <main className="content landing-content">
                    <section className="section">
                        <div className="container container-1100">
                            {/* Elevating Interior Design with AI */}
                            <h1 className="section-title">{blocks[0].data.title.value} </h1>
                            <div className="section-sub-title">
                                <p> {blocks[0].data.text.value}
                                    {/* Harnessing the Power of Artificial Intelligence to Redefine Interior Aesthetics and Functionality */}
                                </p>
                            </div>
                            <div className="btn-shapes">
                                <div className="shadow-efect-2"></div>
                                <div className="shadow-efect-1"></div>
                                <div className="border-efects">
                                    <span style={getI(0)}></span>
                                    <span style={getI(1)}></span>
                                    <span style={getI(2)}></span>
                                </div>
                                {/* <a href="#" className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                    Generate Now
                                </a> */}
                                <a href="#" className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]" onClick={showLogin}>
                                    {blocks[0].data.button.value}
                                </a>
                                {/* <NavLink to="/render-interior" className="btn btn--gradient-green text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                    { blocks[0].data.button.value }
                                </NavLink> */}
                            </div>
                            <Icon src="img/latest-image.svg" className="mt-[10px] mb-[20px]" alt="" />
                            <div className="latest-sliders">
                                <SwiperLanding
                                    className='swiper-right-dir swiper-initialized swiper-horizontal swiper-backface-hidden'
                                    breakpoints={{
                                        319: {
                                            slidesPerView: 1,
                                            spaceBetween: 40
                                        },
                                        575: {
                                            slidesPerView: 2,
                                            spaceBetween: 40
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40
                                        }
                                    }}
                                >
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-2.png')} >
                                        <div className="latest-sliders-text">Japanese Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-3.png')}>
                                        <div className="latest-sliders-text">Minimalist Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-1.png')}>
                                        <div className="latest-sliders-text">Modern Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-2.png')}>
                                        <div className="latest-sliders-text">Minimalist Living Room</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-prev" style={getBackImg('right-slider-img-3.png')}>
                                        <div className="latest-sliders-text">Art Deco Living Room</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-active" style={getBackImg('right-slider-img-1.png')}>
                                        <div className="latest-sliders-text">Japanese Living Room</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-next" style={getBackImg('right-slider-img-1.png')}>
                                        <div className="latest-sliders-text">Minimalist Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-2.png')}>
                                        <div className="latest-sliders-text">Modern Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-3.png')}>
                                        <div className="latest-sliders-text">Minimalist Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-1.png')}>
                                        <div className="latest-sliders-text">Art Deco Living Room</div>
                                    </div>
                                </SwiperLanding>

                                <SwiperLanding
                                    revers className='swiper-left-dir swiper-initialized swiper-horizontal'
                                    breakpoints={{
                                        319: {
                                            slidesPerView: 1,
                                            spaceBetween: 40
                                        },
                                        575: {
                                            slidesPerView: 2,
                                            spaceBetween: 40
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40
                                        }
                                    }}
                                >
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-5.png')} role="group" aria-label="2 / 12" data-swiper-slide-index="1">
                                        <div className="latest-sliders-text">Biophilic Living Room</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-prev" style={getBackImg('right-slider-img-6.png')} role="group" aria-label="3 / 12" data-swiper-slide-index="2">
                                        <div className="latest-sliders-text">Scandinavian Kitchen</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-active" style={getBackImg('right-slider-img-7.png')} role="group" aria-label="4 / 12" data-swiper-slide-index="3">
                                        <div className="latest-sliders-text">Ski Chalet Kitchen</div>
                                    </div>
                                    <div className="swiper-slide swiper-slide-next" style={getBackImg('right-slider-img-4.png')} role="group" aria-label="5 / 12" data-swiper-slide-index="4">
                                        <div className="latest-sliders-text">Tribal Outdoor Patio</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-5.png')} role="group" aria-label="6 / 12" data-swiper-slide-index="5">
                                        <div className="latest-sliders-text">Biophilic Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-6.png')} role="group" aria-label="7 / 12" data-swiper-slide-index="6">
                                        <div className="latest-sliders-text">Scandinavian Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-7.png')} role="group" aria-label="8 / 12" data-swiper-slide-index="7">
                                        <div className="latest-sliders-text">Ski Chalet Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-4.png')} role="group" aria-label="9 / 12" data-swiper-slide-index="8">
                                        <div className="latest-sliders-text">Tribal Outdoor Patio</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-5.png')} role="group" aria-label="10 / 12" data-swiper-slide-index="9">
                                        <div className="latest-sliders-text">Biophilic Living Room</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-6.png')} role="group" aria-label="11 / 12" data-swiper-slide-index="10">
                                        <div className="latest-sliders-text">Scandinavian Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-7.png')} role="group" aria-label="12 / 12" data-swiper-slide-index="11">
                                        <div className="latest-sliders-text">Ski Chalet Kitchen</div>
                                    </div>
                                    <div className="swiper-slide" style={getBackImg('right-slider-img-4.png')} role="group" aria-label="1 / 12" data-swiper-slide-index="0">
                                        <div className="latest-sliders-text">Tribal Outdoor Patio</div>
                                    </div>
                                </SwiperLanding>

                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <h2 className="section-title mb-[60px]">
                                {blocks[1].data.title.value}
                                {/* Design Sense Features */}
                            </h2>
                            <div className="row g-24">
                                <div className="col-md-5">
                                    <div className="features-item">
                                        <div className="features-item__icon">
                                            <Icon src="img/feature-house.svg" alt="" />
                                            <div className="features-item__shape" style={getI(0)}></div>
                                            <div className="features-item__shape" style={getI(1)}></div>
                                            <div className="features-item__shape" style={getI(2)}></div>
                                            <div className="features-item__shape" style={getI(3)}></div>
                                            <div className="features-item__shape" style={getI(4)}></div>
                                            <div className="features-item__shape" style={getI(5)}></div>
                                        </div>
                                        <div className="features-item__text">
                                            <h4 className="mb-[16px]">
                                                {blocks[1].data.items.blocks[0].title.value}
                                                {/* Design Interior */}
                                            </h4>
                                            <p>
                                                {blocks[1].data.items.blocks[0].text.value}
                                                {/* Variety of settings to generate perfect interior for your needs. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="row g-24">
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    <Icon src="img/home-house-real-estate-setting.svg" alt="" />
                                                    <div className="features-item__shape" style={getI(0)}></div>
                                                    <div className="features-item__shape" style={getI(1)}></div>
                                                    <div className="features-item__shape" style={getI(2)}></div>
                                                    <div className="features-item__shape" style={getI(3)}></div>
                                                    <div className="features-item__shape" style={getI(4)}></div>
                                                    <div className="features-item__shape" style={getI(5)}></div>
                                                </div>
                                                <div className="features-item__text">
                                                    <h4 className="mb-[16px]">
                                                        {blocks[1].data.items.blocks[1].title.value}
                                                        {/* Transform your existing room */}
                                                    </h4>
                                                    <p>
                                                        {blocks[1].data.items.blocks[1].text.value}
                                                        {/* Upload an image of your room and our AI will restyle it with your chosen design preferences. */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="features-item">
                                                <div className="features-item__icon">
                                                    <Icon src="img/flat-variant-square.svg" alt="" />
                                                    <div className="features-item__shape" style={getI(0)}></div>
                                                    <div className="features-item__shape" style={getI(1)}></div>
                                                    <div className="features-item__shape" style={getI(2)}></div>
                                                    <div className="features-item__shape" style={getI(3)}></div>
                                                    <div className="features-item__shape" style={getI(4)}></div>
                                                    <div className="features-item__shape" style={getI(5)}></div>
                                                </div>
                                                <div className="features-item__text">
                                                    <h4 className="mb-[16px]">
                                                        {blocks[1].data.items.blocks[2].title.value}
                                                        {/* Manage room type */}
                                                    </h4>
                                                    <p>
                                                        {blocks[1].data.items.blocks[2].text.value}
                                                        {/* No matter what type of room you're designing, we've got you covered. */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section">
                        <div className="container container-1100">
                            <div className="row flex items-center gx-70 gy-30">
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <BeforeAfterImages
                                        before={getBackImg('after-before-1-after.png')}
                                        after={getBackImg('after-before-1.png')}
                                    />
                                </div>
                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">
                                        {blocks[1].data.items.blocks[3].title.value}
                                        {/* Upload your room */}
                                    </h2>
                                    <div className="section-text">
                                        <p>
                                            {blocks[1].data.items.blocks[3].text.value}
                                            {/* Take a photo of your current room. For best results make sure it shows the entire room in a 90° straight angle facing a wall or window horizontally.  */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <div className="row flex items-center gx-70 gy-30">

                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">
                                        {blocks[1].data.items.blocks[4].title.value}
                                        {/* Different render styles */}
                                    </h2>
                                    <div className="section-text">
                                        <p>
                                            {blocks[1].data.items.blocks[4].text.value}
                                            {/* Take a photo of your current room. For best results make sure it shows the entire room in a 90° straight angle facing a wall or window horizontally.  */}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes --right-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <BeforeAfterImages
                                        before={getBackImg('after-before-2-after.png')}
                                        after={getBackImg('after-before-2.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <div className="row flex items-center gx-70 gy-30">
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <BeforeAfterImages
                                        before={getBackImg('after-before-3-after.png')}
                                        after={getBackImg('after-before-3.png')}
                                    />
                                </div>
                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">
                                        {blocks[1].data.items.blocks[5].title.value}
                                        {/* Explore users renders */}
                                    </h2>
                                    <div className="section-text">
                                        <p>
                                            {blocks[1].data.items.blocks[5].text.value}
                                            {/* Take a photo of your current room. For best results make sure it shows the entire room in a 90° straight angle facing a wall or window horizontally.  */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section latest-section">
                        <div className="container container-1100">
                            <div className="row flex items-center gx-70 gy-30">

                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">
                                        {fromStrings("Render Videos")}
                                    </h2>
                                    <div className="section-text">
                                        <p>
                                            {fromStrings("Transform your interior photos into videos to get a better understanding of your new design.")}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes --right-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <div className="slider-images">
                                        <div className='img background-img after-video-text' style={getBackImg('landing-video-img.png')}>
                                            <span className="after-badge">{fromStrings('Video')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container-1100">
                            <h2 className="mb-[24px] text-center">Frequently Asked Questions</h2>
                            <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">You have questions. We have answers.</p>

                            <div className="faq-wrapper">
                                {faqs?.length && faqs?.map((faq, index) => {
                                    return (
                                        <FaqItem
                                            key={faq.id}
                                            initOpen={index === 0}
                                            title={faq.question}
                                            content={faq.answer}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                    <section className="getting-section" style={{
                        background: `linear-gradient(360deg, #101014 0%, rgba(16, 16, 20, 0.00) 100%), url(${require('../../assets/img/get-section-image.png')}), #101014 50% / cover no-repeat`,
                        backgroundBlendMode: 'normal, luminosity',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}>
                        <div className="container">
                            <h2 className="section-title mb-[48px]">
                                {blocks[2].data.title.value}
                                {/* Get Design Sense PRO Account */}
                            </h2>
                            <div className="section-text text-center">
                                <p className="mb-[16px]">
                                    {/* <Icon src="img/smale-1.png" className="mr-[10px]" alt="" />  */}
                                    {blocks[2].data.items.blocks[0].title.value.split('Icon').join('')}
                                    {/* Download photos without watermarks */}
                                </p>
                                <p className="mb-[16px]">
                                    {/* <Icon src="img/smale-2.png" className="mr-[10px]" alt="" />  */}
                                    {blocks[2].data.items.blocks[1].title.value.split('Icon').join('')}
                                    {/* Unlimited render amount */}
                                </p>
                                <p>
                                    {/* <Icon src="img/smale-3.png" className="mr-[10px]" alt="" />  */}
                                    {blocks[2].data.items.blocks[2].title.value.split('Icon').join('')}
                                    {/* The best quality of render */}
                                </p>
                            </div>
                            <div className="btn-shapes">
                                <div className="border-efects w-212">
                                    <span style={getI(0)}></span>
                                    <span style={getI(1)}></span>
                                    <span style={getI(2)}></span>
                                </div>
                                <a href="#" className="btn btn--gradient-blue text-[18px] font-[600] px-[20px] py-[10px] rounded-[300px]">
                                    <span className="icon mr-[10px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                            <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    {blocks[2].data.button.value}
                                    {/* Try Pro Account */}
                                </a>
                            </div>
                        </div>
                    </section>
                </main>

                {/* <a href="#" className="scroll_to_top"></a> */}
                <SiteFooter currentPageData={home} />
                {
                    false ? <>
                        <div id="login" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--500">
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <h4 className="font-[400] mb-[12px]">Welcome to Design Sense</h4>
                                        <p className="color-secondary opacity-70">Fill in the form to login</p>
                                    </div>
                                    <div className="modal__body">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] w-full my-[32px]">
                                            <span className="icon mr-[8px]">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107"></path>
                                                    <path d="M3.153 7.3455L6.4385 9.755C7.3275 7.554 9.4805 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.159 2 4.828 4.1685 3.153 7.3455Z" fill="#FF3D00"></path>
                                                    <path d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.5718 17.5742 13.3037 18.001 12 18C9.399 18 7.1905 16.3415 6.3585 14.027L3.0975 16.5395C4.7525 19.778 8.1135 22 12 22Z" fill="#4CAF50"></path>
                                                    <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2"></path>
                                                </svg>
                                            </span>
                                            Login with Google
                                        </a>

                                        <div className="input-custom mb-[16px]">
                                            <label className="input-label mb-[6px]">Email</label>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="email" placeholder="" value="tim.jennings@example.com" />
                                            </div>
                                        </div>

                                        <div className="input-custom mb-[32px]">
                                            <div className="flex items-center justify-between">
                                                <label className="input-label mb-[6px]">Password</label>
                                                <a href="#" className="color-primary text-[14px] mb-[6px]">Forgot password?</a>
                                            </div>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="password" placeholder="" value="tim.jennings@example.com" />
                                            </div>
                                        </div>

                                        <div className="flex items-center text-[14px] mb-[32px]">
                                            <span className="color-secondary">Don’t have an account yet?</span>
                                            <a href="#" className="color-primary ml-[12px]">Sign Up</a>
                                        </div>
                                    </div>
                                    <div className="modal__footer">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            Login
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="sign-up" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--500">
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <h4 className="font-[400] mb-[12px]">Sign Up</h4>
                                        <p className="color-secondary opacity-70">Fill in form to sign up the Design Sense</p>
                                    </div>
                                    <div className="modal__body">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] w-full my-[32px]">
                                            <span className="icon mr-[8px]">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107"></path>
                                                    <path d="M3.153 7.3455L6.4385 9.755C7.3275 7.554 9.4805 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.159 2 4.828 4.1685 3.153 7.3455Z" fill="#FF3D00"></path>
                                                    <path d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.5718 17.5742 13.3037 18.001 12 18C9.399 18 7.1905 16.3415 6.3585 14.027L3.0975 16.5395C4.7525 19.778 8.1135 22 12 22Z" fill="#4CAF50"></path>
                                                    <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2"></path>
                                                </svg>
                                            </span>
                                            Sign Up with Google
                                        </a>

                                        <div className="input-custom mb-[16px]">
                                            <label className="input-label mb-[6px]">Email</label>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="email" placeholder="" value="tim.jennings@example.com" />
                                            </div>
                                        </div>

                                        <div className="input-custom mb-[16px]">
                                            <div className="flex items-center justify-between">
                                                <label className="input-label mb-[6px]">Password</label>
                                                <a href="#" className="color-primary text-[14px] mb-[6px]">Forgot password?</a>
                                            </div>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="password" placeholder="" value="tim.jennings@example.com" />
                                            </div>
                                        </div>

                                        <div className="input-custom mb-[32px]">
                                            <label className="input-label mb-[6px]">Confirm Password</label>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="password" placeholder="" value="tim.jennings@example.com" />
                                            </div>
                                        </div>

                                        <div className="flex items-center text-[14px] mb-[32px]">
                                            <span className="color-secondary">Already have an account yet?</span>
                                            <a href="#" className="color-primary ml-[12px]">Login</a>
                                        </div>
                                    </div>
                                    <div className="modal__footer">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            Sign Up
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="add-card" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--500">
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <h4 className="font-[400] mb-[12px]">Add Card</h4>
                                        <p className="color-secondary opacity-70">Fill in the form to add new card.</p>
                                    </div>
                                    <div className="modal__body">

                                        <div className="input-custom mb-[16px] mt-[32px]">
                                            <label className="input-label mb-[6px]">Card Number</label>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="text" placeholder="" value="1234 1234 1234 1234" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-custom mb-[16px]">
                                                    <label className="input-label mb-[6px]">Date Due</label>
                                                    <div className="input-custom__wrap-input">
                                                        <input className="input-custom__input" type="password" placeholder="" value="02 / 26" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-custom mb-[16px]">
                                                    <label className="input-label mb-[6px]">CVV</label>
                                                    <div className="input-custom__wrap-input">
                                                        <input className="input-custom__input" type="password" placeholder="" value="tim" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <label className="custom-checkbox mb-[32px]">
                                            <input type="checkbox" className="custom-checkbox__input" />
                                            <span className="custom-checkbox__input-fake">
                                                <span className="icon-ico-check"></span>
                                            </span>
                                            <span className="custom-checkbox__label">Set as main payment method</span>
                                        </label>


                                    </div>
                                    <div className="modal__footer">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            + Add Card
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="change-to-manual" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--500">
                                <div className="modal__content">
                                    <div className="modal__header mb-[32px] text-center items-center">
                                        <h4 className="font-[400] mb-[12px]">Change to Annual</h4>
                                        <p className="color-secondary opacity-70 text-[14px]">Do you really want to change your plan to annual?</p>
                                    </div>

                                    <div className="modal__footer justify-center">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            Submit
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="change-to-free" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--500">
                                <div className="modal__content">
                                    <div className="modal__header mb-[32px] text-center items-center">
                                        <h4 className="font-[400] mb-[12px]">Change to Free Plan</h4>
                                        <p className="color-secondary opacity-70 text-[14px]">Do you really want to set Free Basic Plan?</p>
                                    </div>

                                    <div className="modal__footer justify-center">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            Submit
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="add-member" className="modal modal-1">
                            <div className="modal__dialog modal__dialog--572">
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <h4 className="font-[400] mb-[12px]">Add Member</h4>
                                        <p className="color-secondary opacity-70">Fill in email to send invite</p>
                                    </div>
                                    <div className="modal__body">

                                        <div className="input-custom my-[32px]">
                                            <label className="input-label mb-[6px]">Email</label>
                                            <div className="input-custom__wrap-input">
                                                <input className="input-custom__input" type="email" placeholder="" value="debra.holt@example.com" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal__footer">
                                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                                            Cancel
                                        </a>
                                        <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                                            Send Invite
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null
                }
            </div>
        </>
    );
};

export default Landing;