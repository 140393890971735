import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from '../../api/axios';
// import {LoginDataType} from "../../components/Popups/Popup";

export const fetchProfile = createAsyncThunk(
    '/api/profile/info',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Api().profile.getProfile();

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updateProfile = createAsyncThunk(
    '/api/profile/update',
    async (payload:any, { rejectWithValue }) => {
        try {
            const res = await Api().profile.updateProfile(payload);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updatePassword = createAsyncThunk(
    '/api/profile/update-password',
    async (payload:any, { rejectWithValue }) => {
        try {
            const res = await Api().profile.updatePassword(payload);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const profileFetchCards = createAsyncThunk(
    '/api/profile/cards',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Api().profile.profileFetchCards();

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const setDefaultCard = createAsyncThunk(
    '/api/stripe/set-default-card',
    async (id:string, { rejectWithValue }) => {
        try {
            const res = await Api().profile.setDefaultCard(id);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const subscribeToPlan = createAsyncThunk(
    '/api/stripe/subscribe',
    async (payload:any, { rejectWithValue }) => {
        try {
            const res = await Api().profile.subscribeToPlan(payload);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const cancelSubscribtion = createAsyncThunk(
    '/api/stripe/cancel-subscribtion',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Api().profile.cancelSubscribtion();

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const cancelUpscaleSubscribtion = createAsyncThunk(
    '/api/stripe/cancel-upscale-subscribtion',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Api().profile.cancelUpscaleSubscribtion();

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);