import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
import { Icon } from '../../components/UI/Icon'
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { fetchPlans, fetchUpscalerPlans, fetchPricingPageData } from '../../store/pagesData/PagesDataThunk';
import { getPlans, getUpscalerPlans, getPricingPageData, getSelectedLanguage, setCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { getProfile, getProfileCards } from '../../store/profile/ProfileSlice';
import { subscribeToPlan, cancelSubscribtion, cancelUpscaleSubscribtion, fetchProfile, profileFetchCards, } from '../../store/profile/ProfileThunk';

import { NotificationManager } from 'react-notifications';
import { Popup } from '../../components/Popups/Popup';
import { AddCardPopup } from '../../components/Popups/AddCardPopup';
import { UpgradeToProPopup } from '../../components/Popups/UpgradeToProPopup';
import { NoRendersPopup } from "../../components/Popups/NoRendersPopup";
import { ConfirmPopup } from "../../components/Popups/ConfirmPopup";

import MetaTags from 'react-meta-tags';
import { stripeKey } from "../../api/config";

import { Api } from '../../api/axios';
import { getActiveGenerationTab, setActiveGenerationTab } from '../../store/Generation/GenerationSlice';


interface TabProps {
    active?: string;
    tabs: string[];
    handleChange: (tab: string) => void
}

const TabComponent: React.FC<TabProps> = ({ active = "", tabs, handleChange }) => {
    const [activeTab, setActiveTab] = useState(active || tabs[0]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        handleChange && handleChange(tab)
    };

    return (
        <>
            <div className="page-menu-list mb-[36px]">
                {tabs.map((tab, index) => (
                    <a
                        key={index}
                        href="#"
                        className={`btn btn--lg ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab}
                    </a>
                ))}
            </div>
        </>
    );
};

const Pricing = ({ tabInit = "Render Interior" as string }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const profile = useAppSelector(getProfile);

    const activeGenerationTab = useAppSelector(getActiveGenerationTab)
    const [tab, setTab] = useState(activeGenerationTab === "render" ? "Render Interior" : "Upscaler");

    const [openLogin, setOpenLogin] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)

    const profileCards = useAppSelector(getProfileCards);
    const pricingPageData = useAppSelector(getPricingPageData);

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fromStrings = (value: string) => {
        return pricingPageData?.strings?.[value] || value;
    }


    const [perYear, setPerYear] = useState(true);
    const [subscribeLoading, setSubscribeLoading] = useState(false);

    const [selectPlanBeforeAddCard, setSelectPlanBeforeAddCard] = useState(null);

    const [mobilePlanPopup, setMobilePlanPopup] = useState<boolean>(false);

    let [addCardPopupData, setAddCardPopupData] = useState<boolean>(false);
    const [cardListLoading, setCardListLoading] = useState(false);
    const [confirmPopupData, setConfirmPopupData] = useState<any>(null);

    const loadCardList = async () => {
        setCardListLoading(true);
        await dispatch(profileFetchCards())
        setCardListLoading(false);
    }
    const openAddCardPopup = (event?: React.MouseEvent) => {
        if (event) {
            event?.preventDefault();
        }
        setAddCardPopupData(true);
    }
    const closeAddCardPopup = () => {
        setAddCardPopupData(false);
        setSelectPlanBeforeAddCard(null);
    }
    const confirmAddCardPopup = async (stripeElem: any) => {
        await loadCardList();
        let res = await selectPlan(selectPlanBeforeAddCard, false, stripeElem);

        if (res === 'card_declined') {
            return false;
        }

        closeAddCardPopup();
        setSelectPlanBeforeAddCard(null);
    }
    const showLogin = (ev?: any) => {
        if (ev) {
            ev.preventDefault()
        }
        setOpenLogin(false);
        setTimeout(() => {
            setOpenLogin(true);
        }, 100)
    }

    const [notificationKey, setNotificationKey] = useState(1);

    const [planForAfterLogin, setPlanForAfterLogin] = useState<any>(null)

    const plans = useAppSelector<{
        id: string | number,
        title: string,
        features: any[],
        price_mo: string | number,
        price_y: string | number,
        is_popular: number,
        description: string,
    }[]>(getPlans);

    const upscalerPlans = useAppSelector<{
        id: string | number,
        title: string,
        features: any[],
        price_mo: string | number,
        price_y: string | number,
        is_popular: number,
        description: string,
    }[]>(getUpscalerPlans);

    const selectPlan = async (plan: any, noCheck: boolean = false, stripeElem: any = null) => {
        if (!noCheck && !profile?.email) {
            showLogin();
            setPlanForAfterLogin(plan)
            return;
        }
        setSubscribeLoading(true);
        NotificationManager.listNotify.forEach((n: any) => NotificationManager.remove({ id: n.id }))

        let result = await dispatch(subscribeToPlan({ plan_id: plan.id, type: perYear ? 'year' : 'month' })).unwrap();

        if (result.status === "incomplete") {
            let windowLocal = window as any;
            let stripe = stripeElem || windowLocal.stripe;

            if (!stripeElem) {
                stripe = windowLocal.Stripe(stripeKey);
            }

            let confirmPaymentResult = null as any;

            try {
                confirmPaymentResult = await stripe.confirmPayment({
                    clientSecret: result.client_secret,
                    redirect: "if_required"
                });

                const afterSubscribeRes = await Api().profile.afterSubscribe({ plan_id: plan.id, type: perYear ? 'year' : 'month' });

                console.log('afterSubscribe Res:', afterSubscribeRes);
            } catch (error) {
                console.log('confirmPayment incomplete error:', error);
            }

            if (confirmPaymentResult?.error) {
                setSubscribeLoading(false);
                NotificationManager.error(confirmPaymentResult?.error?.message);

                if (confirmPaymentResult?.error?.code === "card_declined" && ((new Date().getTime() - (confirmPaymentResult?.error?.payment_method?.created * 1000)) < 296640)) {
                    let cardsBeforeDelete = await dispatch(profileFetchCards()) as any;

                    const findedCard = cardsBeforeDelete?.payload?.cards?.find((cardItem: any) => cardItem?.stripe_id === confirmPaymentResult?.error?.payment_method?.id);
                    if (findedCard) {
                        const res = await Api().profile.deleteCard(findedCard.id);

                        return 'card_declined';
                    }
                }

                await dispatch(fetchProfile());

                return false;
            };
        }


        await dispatch(fetchProfile());
        setSubscribeLoading(false);


        if (result.success) {
            try {
                let windowItem = window as any;

                if (windowItem?.gtag) {
                    windowItem.gtag('event', 'conversion', { 'send_to': 'AW-813685724/HemuCNG24PoYENy3_4MD', 'value': perYear ? plan.price_y : plan.price_mo, 'currency': 'USD', 'transaction_id': '' });
                }
            } catch (error) {
                console.log('gtag event conversion err:', error);
            }

            NotificationManager.success('Subscription completed successfully');

            navigate('/render-interior');
        } else {
            NotificationManager.error(result.message ? result.message : 'Something went wrong');
        }
    }

    const currenPeriodEnd = () => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if ((tab === 'Render Interior' ? !profile?.plan?.current_period_end : !profile?.upscale_plan?.current_period_end)) {
            return '';
        } else {
            let date = new Date((tab === 'Render Interior' ? profile?.plan?.current_period_end : profile?.upscale_plan?.current_period_end) * 1000)
            return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        }
    }

    const cancelSubscribePlan = async () => {
        const periodNameVal = (tab === 'Render Interior' ? profile?.plan?.period : profile?.upscale_plan?.period);

        const mobPlans = [
            "monthly01",
            "weekly01",
            "yearly01",
            "design.sense.pre.month.subscription",
            "design.sense.pre.week.subscription",
            "design.sense.pre.year.subscription",
        ]

        if (mobPlans.includes(periodNameVal)) {
            setMobilePlanPopup(true);
            return false;
        }

        setConfirmPopupData({
            title: 'Confirm Cancellation',
            subtitle: `If you confirm and end your subscription now, you can still access it until ${currenPeriodEnd()}.`,
            cancelBtnText: 'Not Now',
            confirmBtnText: 'Confirm '
        });
    }

    const cancelSubscribePlanAction = async () => {
        setConfirmPopupData(null);

        setSubscribeLoading(true);
        NotificationManager.listNotify.forEach((n: any) => NotificationManager.remove({ id: n.id }))

        let result = null;

        if (tab === 'Render Interior') {
            result = await dispatch(cancelSubscribtion()).unwrap();
        } else {
            result = await dispatch(cancelUpscaleSubscribtion()).unwrap();
        }

        await dispatch(fetchProfile());

        if (result.success) {
            NotificationManager.success('Subscription canceled');
        } else {
            NotificationManager.error(result.message ? result.message : 'Something went wrong');
        }

        setSubscribeLoading(false);
    }

    const addCardPlease = (plan: any) => {
        if (plan) setSelectPlanBeforeAddCard(plan);
        //NotificationManager.error('Add card please');
        openAddCardPopup()
        // setTimeout(() => {
        //     navigate('/settings/billing')
        // }, 2000);
    }

    const pageFetch = async () => {
        setPageLoading(true);

        const token = localStorage.getItem('token')
        if (token) {
            await dispatch(profileFetchCards());
            NotificationManager.listNotify.forEach((n: any) => NotificationManager.remove({ id: n.id }))
        }
        await dispatch(fetchPricingPageData((selectedLanguage || languageFromStorage || '') as string));

        if (!plans?.length) {
            await dispatch(fetchPlans((selectedLanguage || languageFromStorage || '') as string));
        }

        if (!upscalerPlans?.length) {
            await dispatch(fetchUpscalerPlans((selectedLanguage || languageFromStorage || '') as string));
        }

        setPageLoading(false);
    }

    useEffect(() => {
        document.addEventListener("setSubscribeloadingFalse", function (event) {
            setSubscribeLoading(false);
        });

        const notifShow = (event: any) => {
            if (event?.detail?.status === false) {
                NotificationManager.error(event?.detail?.message);
            }
        }

        document.addEventListener("openNotificationManager", notifShow);

        return () => {
            document.removeEventListener("openNotificationManager", notifShow)
        }
    }, []);

    useEffect(() => {
        pageFetch();
    }, [selectedLanguage]);

    useEffect(() => {
        dispatch(setCurrentPageData(pricingPageData));
    }, [pricingPageData])

    const onSuccessLogin = async () => {
        if (planForAfterLogin?.price_mo === 'Free') return

        setPageLoading(true)
        let res = await dispatch(profileFetchCards())
        setPageLoading(false)

        if (res.payload?.cards?.length) {
            selectPlan(planForAfterLogin, true)
            return
        }
        addCardPlease(planForAfterLogin)

    }

    return (
        <>
            <Header key={notificationKey} setLoginModal={openLogin} onSuccessLogin={onSuccessLogin} currentPageData={pricingPageData} />

            <MetaTags>
                <title>{pricingPageData?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={pricingPageData?.page?.meta_description || ""} />
            </MetaTags>

            <div className="wrapper header-fixed">
                <main className="content pricing-page">
                    {pageLoading ?
                        <>
                            <section className={`section bg-decor`}>
                                <div className="container container-1100">
                                    <h2 className="mb-[24px] text-center"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></h2>
                                    <p className="text-[18px] text-center color-secondary opacity-70 mb-[40px]"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></p>
                                    <div className="page-menu-list mb-[36px] h-[43px] max-w-[308px] w-full">
                                        <span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span>
                                    </div>
                                    {
                                        <div className="switch-decor mb-[40px]">
                                            <span className="color-secondary text-[14px] opacity-70"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></span>
                                            <label className="switch mx-[14px]" style={{ pointerEvents: "none", opacity: ".65" }}>
                                                <input
                                                    type="checkbox" className="switch__field swich-pricing" checked={true}
                                                />
                                                <span className="switch__slider"></span>
                                            </label>
                                            <span className="color-secondary text-[14px] opacity-70"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></span>
                                        </div>}

                                    <div className="pricing">
                                        <div className="row g-24">
                                            {
                                                ['', '', ''].map((item, index) => {
                                                    return <div className="col-12 col-md-4" key={index}>
                                                        <div className="pricing__card">
                                                            <h4 className="mb-[8px]"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></h4>
                                                            <p className="text-[14px] color-secondary opacity-70 mb-[20px]"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></p>
                                                            <span className="block text-[32px] leading-[38px] font-[500] pb-[20px] mb-[20px] border-bottom"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></span>
                                                            <ul className="pricing__list skeleton" style={{ height: '152px' }}>
                                                            </ul>

                                                            {index === 0 ?
                                                                <a href="#" className={`btn btn--gradient-green text-[14px] font-[600] btn--lg-3 rounded-[300px] w-full disabled`}>
                                                                    <svg
                                                                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                    >
                                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                        </path>
                                                                    </svg>
                                                                </a> :
                                                                <a href="#" className={`btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full disabled`}>
                                                                    <svg
                                                                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                    >
                                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                        </path>
                                                                    </svg>
                                                                </a>}
                                                        </div>
                                                    </div>
                                                })

                                            }
                                        </div>
                                    </div>

                                </div>
                            </section>
                            <section className="section">
                                <div className="container container-1100">
                                    <h2 className="mb-[24px] text-center"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></h2>
                                    <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]"><span className="skeleton" style={{ color: 'transparent' }}>{fromStrings("Loading")}...</span></p>

                                    <div className="flex-col sm:flex-row flex items-center justify-center">
                                        <div className="flex flex-col">
                                            <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_apple_url?.value} className="mb-[20px]">
                                                <Icon src="img/icon-app-store.svg" alt="" />
                                            </a>
                                            <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_google_url?.value}>
                                                <Icon src="img/icon-google-play.svg" alt="" />
                                            </a>
                                        </div>
                                        <div className="qr-code-wrap">
                                            <Icon src="img/pricing-qr.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </> :
                        <>
                            <section className={`section bg-decor`}>
                                <div className="container container-1100">
                                    <h2 className="mb-[24px] text-center">
                                        {tab === 'Render Interior' ? pricingPageData?.page?.blocks[0].data.title.value : "Upscale Pricing"}
                                    </h2>
                                    <p className="text-[18px] text-center color-secondary opacity-70 mb-[40px]">
                                        {tab === 'Render Interior' ? pricingPageData?.page?.blocks[0].data.text.value : "Select the best plan if you need upscale functionality"}
                                    </p>

                                    <TabComponent
                                        active={tab}
                                        tabs={["Render Interior", "Upscaler"]}
                                        handleChange={(tab) => {
                                            setTab(tab)
                                            dispatch(setActiveGenerationTab(tab === "Upscaler" ? "upscale" : "render"))
                                        }}
                                    />

                                    {
                                        // (profile?.team_role === "user" && profile?.is_premium) || !profile ? null : pricingPageData?.page ?
                                        <div className="switch-decor mb-[40px]">
                                            <span className="color-secondary text-[14px] opacity-70">{fromStrings('Bill Monthly')}</span>
                                            <label className="switch mx-[14px]">
                                                <input
                                                    type="checkbox" className="switch__field swich-pricing"
                                                    checked={perYear}
                                                    onChange={(e) => {
                                                        setPerYear(e.target.checked);
                                                    }}
                                                />
                                                <span className="switch__slider"></span>
                                            </label>
                                            <span className="color-secondary text-[14px] opacity-70">{fromStrings('Bill Yearly')}</span>
                                        </div>
                                    }

                                    <div className="pricing">
                                        <div className="row g-24">
                                            {
                                                (tab === 'Render Interior' ? plans : upscalerPlans).map((item, index) => {
                                                    return <div className="col-12 col-md-4" key={index}>
                                                        <div className="pricing__card">
                                                            {item.is_popular === 1 ? <span className="pricing__popular">{fromStrings('POPULAR')}</span> : null}
                                                            <h4 className="mb-[8px] font-[400] text-[20px]">{item.title}</h4>
                                                            <p className="text-[14px] color-secondary opacity-70 mb-[20px]">{item.description}</p>
                                                            {
                                                                item.price_y === 'Free' || item.price_mo === 'Free' ? <span className="block text-[32px] leading-[38px] font-[500] pb-[20px] mb-[20px] border-bottom">{fromStrings("Free")}</span> :
                                                                    <div className="flex items-baseline pb-[20px] mb-[20px] border-bottom">
                                                                        <span className="block text-[32px] leading-[38px] font-[500] mr-[8px] pro-price">${perYear ? (+item.price_y).toFixed(2) : item.price_mo}</span>
                                                                        <span className="color-secondary opacity-70 ">/&nbsp;
                                                                            <span className="price-period">
                                                                                {fromStrings('month')} {tab === 'Render Interior' ? "/ " + fromStrings('user') : null}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                            }
                                                            <ul className="pricing__list">
                                                                {
                                                                    item.features.map((feature: any, featureIndex: number) => {
                                                                        return <li key={featureIndex}>
                                                                            <div className="pricing__item">
                                                                                {
                                                                                    tab === 'Render Interior' ?
                                                                                        <div className="pricing__img-box">
                                                                                            <img src={feature.image} />
                                                                                        </div> :
                                                                                        <div className="pricing__img-check">
                                                                                            <Icon src='img/icons/icon-done-check-2.svg' alt='' />
                                                                                        </div>
                                                                                }
                                                                                <div className="pricing__item-group-text">
                                                                                    <span className="text-[14px]">{feature.title}</span>
                                                                                    {feature.description ? <span className="color-secondary text-[12px] opacity-70">{feature.description}</span> : null}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>


                                                            {
                                                                // (profile?.team_role === "user" && profile?.is_premium) || !profile
                                                                (profile?.team_role === "user" && profile?.is_premium) ? null : <>
                                                                    {item.price_mo !== 'Free' ?
                                                                        (tab === 'Render Interior' ? profile?.plan?.id : profile?.upscale_plan?.id) === item.id ?
                                                                            (tab === 'Render Interior' ? profile.plan.cancel_at_period_end : profile.upscale_plan.cancel_at_period_end) ? <a href="#" className={`btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full ${subscribeLoading ? 'disabled' : ''}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                selectPlan(item);
                                                                            }}>
                                                                                {
                                                                                    subscribeLoading ?
                                                                                        <svg
                                                                                            version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                                        >
                                                                                            <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                                            </path>
                                                                                        </svg> :
                                                                                        fromStrings("Continue Subscribtion")
                                                                                }
                                                                            </a> :
                                                                                <a href="#" className={`btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full ${subscribeLoading ? 'disabled' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    cancelSubscribePlan();
                                                                                }}>
                                                                                    {
                                                                                        subscribeLoading ?
                                                                                            <svg
                                                                                                version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                                            >
                                                                                                <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                                                </path>
                                                                                            </svg> :
                                                                                            fromStrings("Cancel Subscribtion")
                                                                                    }
                                                                                </a> :
                                                                            (profileCards?.length || !profile) ?
                                                                                <a href="#" className={`btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full ${subscribeLoading ? 'disabled' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    selectPlan(item);
                                                                                }}>
                                                                                    {
                                                                                        subscribeLoading ?
                                                                                            <svg
                                                                                                version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                                            >
                                                                                                <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                                                </path>
                                                                                            </svg> :
                                                                                            <>
                                                                                                <span className="icon mr-[10px]">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                                                                        <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                                                                    </svg>
                                                                                                </span>
                                                                                                {fromStrings(`Upgrade to ${item.title === 'Pro' ? 'PRO' : item.title}`)}
                                                                                            </>
                                                                                    }
                                                                                </a> : <a href="#" className={`btn btn--gradient-blue text-[15px] font-[600] btn--lg-3 rounded-[300px] w-full ${subscribeLoading ? 'disabled' : ''}`} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    addCardPlease(item);
                                                                                }}>
                                                                                    {
                                                                                        subscribeLoading ?
                                                                                            <svg
                                                                                                version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                                            >
                                                                                                <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                                                </path>
                                                                                            </svg> :
                                                                                            <>
                                                                                                <span className="icon mr-[10px]">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                                                                        <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                                                                    </svg>
                                                                                                </span>
                                                                                                {fromStrings(`Upgrade to ${item.title === 'Pro' ? 'PRO' : item.title}`)}
                                                                                            </>
                                                                                    }
                                                                                </a> :
                                                                        <a href="#" className={`btn btn--gradient-green text-[14px] font-[600] btn--lg-3 rounded-[300px] w-full ${subscribeLoading ? 'disabled' : ''}`} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            selectPlan(item);
                                                                        }}>
                                                                            {
                                                                                subscribeLoading ?
                                                                                    <svg
                                                                                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                                                                                    >
                                                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                                        </path>
                                                                                    </svg> :
                                                                                    fromStrings("Get Started")
                                                                            }
                                                                        </a>}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                })

                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section">
                                <div className="container container-1100">
                                    <h2 className="mb-[24px] text-center">{pricingPageData?.page.blocks[1].data.title.value}</h2>
                                    <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">{pricingPageData?.page.blocks[1].data.text.value}</p>

                                    <div className="flex-col sm:flex-row flex items-center justify-center">
                                        <div className="flex flex-col">
                                            <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_apple_url?.value} className="mb-[20px]">
                                                <Icon src="img/icon-app-store.svg" alt="" />
                                            </a>
                                            <a target="_blank" href={pricingPageData?.page.blocks[1].data.app_google_url?.value}>
                                                <Icon src="img/icon-google-play.svg" alt="" />
                                            </a>
                                        </div>
                                        <div className="qr-code-wrap">
                                            <Icon src="img/pricing-qr.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>}
                </main>

                <SiteFooter currentPageData={pricingPageData} />
                {/* <Popup
                    modalDialogWidth="572"
                    open={addCardPopupData}
                    onClose={closeAddCardPopup}
                >
                    <AddCardPopup
                        close={closeAddCardPopup} 
                        confirm={confirmAddCardPopup}
                        fromPricing={true}
                    ></AddCardPopup>
                </Popup> */}

                {addCardPopupData ? <Popup
                    modalDialogWidth="897"
                    open={addCardPopupData}
                    onClose={closeAddCardPopup}
                >
                    <UpgradeToProPopup
                        selectPlanBeforeAddCard={selectPlanBeforeAddCard}
                        plans={plans}
                        perYear={perYear}
                        subscribeLoading={subscribeLoading}
                        setPerYear={(val) => setPerYear(val)}
                        close={closeAddCardPopup}
                        confirm={confirmAddCardPopup}
                        fromPricing={true}
                        setSubscribeLoading={setSubscribeLoading}
                    ></UpgradeToProPopup>
                </Popup> : null}

                {mobilePlanPopup ?
                    <Popup
                        modalDialogWidth="650"
                        open={mobilePlanPopup}
                        onClose={() => {
                            setMobilePlanPopup(false)
                        }}
                    >
                        <NoRendersPopup
                            close={() => {
                                setMobilePlanPopup(false);
                            }}
                            title={null}
                            hideBtn={true}
                        >
                            {/* color-secondary-07 */}
                            <div className="no-renders-popup-text text-[14px] text-[#ffffff] no-renders-popup-text--margins pt-[30px] d-flex flex-col items-center">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="80" height="80" className="mb-[6px]">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11.25 13.5V8.25H12.75V13.5H11.25ZM11.25 15.75V14.25H12.75V15.75H11.25Z" fill="#de2323"></path> </g>
                                </svg>
                                Your subscription was created on the mobile application. <br />
                                Mobile subscriptions can be cancelled only on the same mobile application. <br /> Please use your mobile app to cancel your subscription.
                            </div>
                        </NoRendersPopup>
                    </Popup> : null
                }
                {confirmPopupData ?
                    <Popup
                        modalDialogWidth="460"
                        open={confirmPopupData}
                        onClose={() => {
                            setConfirmPopupData(null)
                        }}
                    >
                        <ConfirmPopup
                            close={() => { setConfirmPopupData(null) }}
                            confirm={() => { cancelSubscribePlanAction() }}
                            title={confirmPopupData.title}
                            subtitle={confirmPopupData.subtitle}
                            cancelBtnText={confirmPopupData.cancelBtnText}
                            confirmBtnText={confirmPopupData.confirmBtnText}
                        />
                    </Popup> : null
                }
            </div>
        </>
    );
};

export default Pricing;