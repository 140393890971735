import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { login } from "./AuthThunk";
import {RootState} from "../index";


interface IAuthState {
    token: string
}

const initialState: IAuthState = {
    token: "",
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearToken: (state) => {
            state.token = "";
        },
    },
    extraReducers: (builder) =>{
        builder.addCase(login.fulfilled, (state, action) => {            
            state.token = action.payload.token;
        });

        builder.addCase(login.rejected, (state, action) => {
            state.token = "";
        });
    }
})

export const getAuthToken = (state: RootState) => state.auth.token;

export const { clearToken } = authSlice.actions;

export default authSlice.reducer;