import { AxiosInstance } from "axios";

export const teamApi = (instance: AxiosInstance) => ({
    getTeam() {
        return instance.get('/api/profile/team');
    },

    inviteToTeam(email: string) {
        return instance.post('/api/profile/invite-to-team', {
            email
        });
    },

    removeFromteam(member_id: number) {
        return instance.post('/api/profile/remove-from-team', {
            member_id
        });
    },

});