import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom'
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {forgotPasswordSchema} from "../../schemas/auth";
import {PopupComponentType, ForgotPasswordDataType} from "./Popup";
import { axiosConfig } from "../../api/config";
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

export const ResetPasswordPopup = ({setPopUp, onClose}: PopupComponentType) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const email = query.get('email');
    const token = query.get('token');
    //const { token } = useParams();

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const [resetResult, setResetResult] = useState<{ success: boolean, message: string }>({ success: false, message: '' });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ForgotPasswordDataType>({
        resolver: yupResolver(forgotPasswordSchema)
    });

    const onSubmit = (data: ForgotPasswordDataType) => {
        var myHeaders = new Headers();
        myHeaders.append("X-Requested-With", "XMLHttpRequest");
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                ...data,
                "token": token
            }),
            redirect: 'follow'
        };

        fetch(`${axiosConfig.baseURL}/reset-password`, requestOptions as any)
        .then(response => response.text())
        .then(result => setResetResult(JSON.parse(result) as any))
        .catch(error => setResetResult(error as any));
    }

    useEffect(()=>{
        if(email){
            setValue("email", email);
        }
    }, [email])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__content">
                <div className="modal__header">
                    <h4 className="font-[400] mb-[12px]">{fromStrings("Reset Password")}</h4>
                </div>
                {
                    resetResult.message ?
                    <div className="modal__body my-[32px]">
                        <p className="color-secondary opacity-70">{ fromStrings(resetResult.message) }</p>
                    </div> : 
                    <div className="modal__body mt-[32px]">
                        <div className="input-custom mb-[16px]">
                            <label className="input-label mb-[6px]">{fromStrings("Email")}</label>
                            <div className="input-custom__wrap-input">
                                <input {...register("email")} className={`input-custom__input ${errors.email?.message && 'error'}`} type="email" />
                            </div>
                        </div>
                        <div className="input-custom mb-[16px]">
                            <label className="input-label mb-[6px]">{fromStrings("New Password")}</label>
                            <div className="input-custom__wrap-input">
                                <input {...register("password")} className={`input-custom__input ${errors.password?.message && 'error'}`} type="password" />
                            </div>
                        </div>
                        <div className="input-custom mb-[32px]">
                            <label className="input-label mb-[6px]">{fromStrings("Confirm New Password")}</label>
                            <div className="input-custom__wrap-input">
                                <input {...register("password_confirmation")} className={`input-custom__input ${errors.password_confirmation?.message && 'error'}`} type="password" />
                            </div>
                        </div>
                    </div>
                }
                <div className="modal__footer">
                    {
                        resetResult?.success !== true ? 
                        <a
                            onClick={(e) => {e.preventDefault(); onClose && onClose()}} href="#"
                            className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal=""
                        >
                            {fromStrings("Cancel")}
                        </a> : <Link
                            to="/" onClick={() => {onClose && onClose()}}
                            className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal=""
                        >
                            {fromStrings("Close")}
                        </Link>
                    }
                    {
                        resetResult?.success !== true ? 
                        <button type='submit' className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                            {fromStrings("Submit")}
                        </button> : null
                    }
                </div>
            </div>
        </form>
    )
}