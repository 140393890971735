import React, { useEffect, useState, useRef} from 'react';
import { Icon } from '../../../components/UI/Icon';

import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";
import { fetchLatestRenders, fetchLatestRendersPageData} from '../../../store/pagesData/PagesDataThunk';
import { getLatestRenders, getLatestRendersPageData, getSelectedLanguage, setCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { LatestRendersPhoto } from './LatestRendersPhotos';
import { useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

export const LatestRenders = () => {
	const navigate = useNavigate();

	let pageData = useAppSelector(getLatestRendersPageData);

	interface LatestRenderType {
		original_image: string,
		selected_image: string,
		title: string,
		id: string,
	}

	const dispatch = useAppDispatch();
	const data: LatestRenderType[] = useAppSelector(getLatestRenders);
	let [rendersFetchLoading, setRendersFetchLoading] = useState(false);
	let [scrollPosition, setScrollPosition] = useState(0);
	let [portraitCheck, setPortraitCheck] = useState<any[]>([]);	

	const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

	const fromStrings = (value:string) => {
        return pageData?.strings?.[value] || value;
    }

	const rendersFetch = async() => {
        setRendersFetchLoading(true);
        await dispatch(fetchLatestRenders());
        setRendersFetchLoading(false);
    }

	const ContainterRef = useRef<any>();

	const setPageScroll = () => {
		setTimeout(() => {
			if(scrollPosition){
				ContainterRef.current.scrollTop = scrollPosition;			
			}
	
			setScrollPosition(0);
		}, 50);
	}

	const getImgSize = (imgSrc: string) => {
        var newImg = new Image();
		newImg.src = imgSrc;
    
        newImg.onload = function() {
            var width = newImg.width;
            var height = newImg.height;
            
            setPortraitCheck((prevState: any)=>{
				return [...prevState, {
					src: imgSrc,
					portrait: +width < +height,
				}]
			});
        }
    }

	useEffect(()=>{
		dispatch(fetchLatestRendersPageData((selectedLanguage || languageFromStorage || '') as string));
	}, [selectedLanguage])
	
	useEffect(() => {
		dispatch(setCurrentPageData(pageData));
	}, [pageData])

	useEffect(()=>{
		rendersFetch();
	}, [])

	useEffect(()=>{
		for (let index = 0; index < data.length; index++) {
			if(!portraitCheck.map(item => item.src).includes(data[index].original_image)){
				getImgSize(data[index].original_image);
			}
		}
	}, [data])

	const [selectedImages, setSelectedImages] = useState<string[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>(null);
	if(selectedImages.length){
		return <LatestRendersPhoto 
			selectedItem={selectedItem}
			items={selectedImages} 
			backHandler={(event)=>{
				setSelectedImages([]);
				event.preventDefault();
				setPageScroll();
				setSelectedItem(null);
			}}
		></LatestRendersPhoto>
	};
	return (
		<div className="full-page-section__content latest-renders-page">
			<MetaTags>
				<title>{ pageData?.page?.meta_title || "Design Sense AI" }</title>
                <meta name="description" content={ pageData?.page?.meta_description || "" } />
            </MetaTags>

			<div className="section-scroll-container" ref={ContainterRef}>
				<div className="section-scroll-heading">
					<h4 className="flex-auto">{fromStrings("Explore User Latest Renders")}</h4>
					<div className="max-w-[245px] w-full">
						{/* <select className="select">
							<option>All interiors</option>
							<option>Interiors 1</option>
							<option>Interiors 2</option>
							<option>Interiors 3</option>
						</select> */}
					</div>
				</div>
				{
					rendersFetchLoading ? <>
					<div className="col-sm-12">
						<a
							href="#" className="render-exzample-item skeleton"
							style={{ backgroundColor: "transparent" }}
						>
						</a>
					</div>
					</> : data.map((item, index)=>{
					return (
						<div className="row g-20 mb-[20px]" id={`render-${item.id}`} key={index}>
							<div className="col-md-6" >
							{/* { String(getImgSize(item.original_image as string)) } */}
								<div className="latest-render-exzample" style={{ paddingBottom: "60%" }} onClick={(e)=>{
									setScrollPosition(ContainterRef.current.scrollTop);
									setSelectedImages([item.original_image, item.selected_image])
									setSelectedItem(item);
								}}>
									{/* <Icon src={"img/latest-render-1.png"} alt=""/> */}
									{/*  ${getImgSize(item.original_image as string) ? 'img-portrait' : ''} */}
									<img className={`render-img ${portraitCheck.find(itemF => itemF.src === item.original_image)?.portrait ? 'img-portrait' : ''}`} src={item.original_image} alt="" />
									<div className="overlay-content-block">
										<span className="latest-render-label">{fromStrings("Before")}</span>
										<span className="latest-render-name">{item.title}</span>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="latest-render-exzample" style={{ paddingBottom: "60%" }} onClick={(e)=>{
									setScrollPosition(ContainterRef.current.scrollTop);
									setSelectedImages([item.original_image, item.selected_image])
									setSelectedItem(item);
								}}>
									{/* <Icon src={} alt=""/> */}
									<img className={`render-img ${portraitCheck.find(itemF => itemF.src === item.original_image)?.portrait ? 'img-portrait' : ''}`} src={item.selected_image} alt="" />
									<div className="overlay-content-block">
										<span className="latest-render-label">{fromStrings("After")}</span>
										<span className="latest-render-name">{item.title}</span>
									</div>
								</div>
							</div>
						</div>
					)
				}) }
			</div>
		</div>
	)
}
