import {Swiper, SwiperSlide, SwiperRef} from "swiper/react";
import {ReactNode, useRef} from "react";
import {Autoplay} from "swiper/modules";

import 'swiper/css';

export const SwiperLanding = ({children, className, revers=false, breakpoints}: {children: ReactNode[], className?: string, revers?: boolean, breakpoints?: any}) => {
    
    const newsListSwiperRef = useRef<SwiperRef>(null);

    return (
        <Swiper
            ref={newsListSwiperRef}
            spaceBetween={40}
            slidesPerView={3}
            modules={[Autoplay]}
            speed={5000}
            loop={true}
            autoplay={{ delay: 0, disableOnInteraction: false, reverseDirection: revers }}
            className={className}
            observer={true}
            breakpoints={breakpoints}
        >
            {children.map((el: ReactNode, idx: number) => <SwiperSlide key={idx}>{el}</SwiperSlide>)}
        </Swiper>
    );
};