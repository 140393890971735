import { createContext } from 'react';



export interface ContextProps {
    loading: boolean;
    data:any;
    fetchData: () => void;
}

export const AppContext = createContext<ContextProps>({loading:false, data:null, fetchData: ()=>{}});

