import React, { useEffect, useRef } from 'react';

import { createBrowserRouter, createRoutesFromElements, Route, RouteProps, useLocation, useParams, useNavigate, Outlet } from "react-router-dom";
import { Pages } from "./index";
import { AppLayout } from "./App/AppLayout";
import { ReactNode } from "react";

interface CustomRouteProps {
    headerName: string | null,
    icon?: ReactNode,
    hideSidebars?: Boolean
}

export const AppRoutes: Array<CustomRouteProps & RouteProps> = [
    {
        path: "/render-interior",
        element: <Pages.RenderInterior />,
        headerName: "Render",
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99781 2.49686C4.99781 4.33857 3.5048 5.83158 1.66309 5.83158C3.5048 5.83158 4.99781 7.32458 4.99781 9.1663C4.99781 7.32458 6.49081 5.83158 8.33253 5.83158C6.49081 5.83158 4.99781 4.33857 4.99781 2.49686Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1684 12.501C14.1684 10.1989 12.3021 8.33262 10 8.33262C12.3021 8.33262 14.1684 6.46636 14.1684 4.16422C14.1684 6.46636 16.0347 8.33262 18.3368 8.33262C16.0347 8.33262 14.1684 10.1989 14.1684 12.501Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1683 12.501V13.3347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1683 4.16422V3.33054" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33237 17.5031V18.3368" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33237 11.6674V10.8337" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.99789 9.16632V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.99789 1.66318V2.49686" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.33228 11.2505C8.33228 13.0922 6.83927 14.5852 4.99756 14.5852C6.83927 14.5852 8.33228 16.0782 8.33228 17.92C8.33228 16.0782 9.82529 14.5852 11.667 14.5852C9.82529 14.5852 8.33228 13.0922 8.33228 11.2505Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    },
    {
        path: "/latest-renders",
        element: <Pages.LatestRenders />,
        headerName: "Latest Renders",
        hideSidebars: true,
    },
    {
        path: "/my-renders",
        element: <Pages.MyRenders />,
        headerName: "My Renders",
        hideSidebars: true,
    },
    // {
    //     path: "/my-renders-v2",
    //     element: <Pages.MyRendersV2 />,
    //     headerName: "My Renders",
    //     hideSidebars: true,
    // },
    {
        path: "/my-team",
        element: <Pages.MyTeam />,
        headerName: "My Team",
        hideSidebars: true,
    },
    {
        path: "/pricing",
        // element: <Pages.Pricing />,
        headerName: "Pricing",
        hideSidebars: true,
    },
    // {
    //     path: "/pricing-upscale",
    //     // element: <Pages.Pricing />,
    //     headerName: "Pricing",
    //     hideSidebars: true,
    // },
    {
        path: "/faq",
        // element: <Pages.FAQ />,
        headerName: "FAQ",
        hideSidebars: true,
    },
    {
        path: "/settings",
        element: <Pages.Settings />,
        headerName: null,
        hideSidebars: true,
    },
    {
        path: "/settings/billing",
        element: <Pages.SettingsBilling />,
        headerName: null,
        hideSidebars: true,
    },
    // {
    //     path: "/settings-v2",
    //     element: <Pages.SettingsV2 />,
    //     headerName: null,
    //     hideSidebars: true,
    // },
    {
        path: "/upscale",
        element: <Pages.Upscale />,
        headerName: null,
        hideSidebars: true,
    },

]

const RouteChangeHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const prevLoc = useRef(location);
    const { lang } = useParams();
    const prevLang = useRef(lang);


    useEffect(() => {
        // getLangData();
        if (prevLang.current && prevLoc.current.pathname.indexOf(lang as string) === -1) {
            navigate('/' + prevLang.current + location.pathname + location.search);
        }

        prevLoc.current = location;
        prevLang.current = lang;

    }, [location]);

    return <Outlet />
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* <Route path='/' element={<Pages.Landing />} />
            <Route path='/reset-password/:token' element={<Pages.Landing />} />
            <Route path='/invite-to-team-link/:invite_hash' element={<Pages.Landing />} />

            <Route path='/upscale-landing' element={<Pages.UpscaleLanding />} />

            <Route path='/pricing' element={<Pages.Pricing />} />

            <Route path='/faq' element={<Pages.FAQ />} />

            <Route path='/terms' element={<Pages.Terms />} />
            <Route path='/privacy' element={<Pages.Privacy />} />
            <Route path='/delete-account' element={<Pages.DeleteAccount />} />

            <Route path='/about-us' element={<Pages.AboutUs />} />

        
            <Route path='/home-page' element={<Pages.HomePage />} />
            <Route path='/pricing-v2' element={<Pages.PricingV2 />} />

            {AppRoutes.filter(item=>item.element).map(item=>{
                return <Route path={item.path} key={item.path} element={
                    <AppLayout hideSidebars={item.hideSidebars || false}>
                        {item.element}
                    </AppLayout>
                }></Route>
            })} */}


            <Route element={<RouteChangeHandler />}>
                <Route path={"/:lang" + '/render-interior-landing'} element={<Pages.Landing />} />
                <Route path={"/:lang" + '/reset-password/:token'} element={<Pages.Landing />} />
                <Route path={"/:lang" + '/invite-to-team-link/:invite_hash'} element={<Pages.Landing />} />

                {/* <Route path={"/:lang" + '/pricing'} element={<Pages.Pricing tabInit="Render Interior" />} />
                <Route path={"/:lang" + '/pricing-upscale'} element={<Pages.Pricing tabInit="Upscaler" />} /> */}
                <Route path={"/:lang" + '/pricing'} element={<Pages.Pricing />} />

                <Route path={"/:lang" + '/faq'} element={<Pages.FAQ />} />

                <Route path={"/:lang" + '/terms'} element={<Pages.Terms />} />
                <Route path={"/:lang" + '/privacy'} element={<Pages.Privacy />} />
                <Route path={"/:lang" + '/delete-account'} element={<Pages.DeleteAccount />} />

                <Route path={"/:lang" + '/about-us'} element={<Pages.AboutUs />} />

                <Route path={"/:lang" + '/upscale-landing'} element={<Pages.UpscaleLanding />} />
                <Route path={'/:lang' + '/'} element={<Pages.HomePage />} />
                {/* <Route path={'/:lang' + '/pricing-v2'} element={<Pages.PricingV2 />} /> */}


                {AppRoutes.filter(item => item.element).map(item => {
                    return <Route path={"/:lang" + item.path} key={item.path} element={
                        <AppLayout hideSidebars={item.hideSidebars || false}>
                            {item.element}
                        </AppLayout>
                    }></Route>
                })}
            </Route>
            <Route element={<RouteChangeHandler />}>
                <Route path='/render-interior-landing' element={<Pages.Landing />} />
                <Route path='/reset-password/:token' element={<Pages.Landing />} />
                <Route path='/invite-to-team-link/:invite_hash' element={<Pages.Landing />} />

                {/* <Route path='/pricing' element={<Pages.Pricing tabInit="Render Interior" />} />
                <Route path='/pricing-upscale' element={<Pages.Pricing tabInit="Upscaler" />} /> */}

                <Route path={"/pricing"} element={<Pages.Pricing />} />

                <Route path='/faq' element={<Pages.FAQ />} />

                <Route path='/terms' element={<Pages.Terms />} />
                <Route path='/privacy' element={<Pages.Privacy />} />
                <Route path='/delete-account' element={<Pages.DeleteAccount />} />

                <Route path='/about-us' element={<Pages.AboutUs />} />
                <Route path='/upscale-landing' element={<Pages.UpscaleLanding />} />
                <Route path='/' element={<Pages.HomePage />} />
                {/* <Route path='/pricing-v2' element={<Pages.PricingV2 />} /> */}

                {AppRoutes.filter(item => item.element).map(item => {
                    return <Route path={item.path} key={item.path} element={
                        <AppLayout hideSidebars={item.hideSidebars || false}>
                            {item.element}
                        </AppLayout>
                    }></Route>
                })}
            </Route>
        </>
    ), { basename: '/' }
)