import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { setGenerationLoading } from '../store/Generation/GenerationSlice';
import { fetchMyRenders, fetchRenderStatus } from '../store/pagesData/PagesDataThunk';
import { setImageToUpscale, setIsUpscaleGenerating, setResultImage } from '../store/upscale/upscaleSlice';
import { fetchGenerateUpscale } from '../store/upscale/upscaleThunks';
import { useAppDispatch } from './storeHooks';

export const useImageUpload = (modelData: IUpscaleModelData) => {
    const [status, setStatus] = useState<string | null>(null);
    const [renderId, setRenderId] = useState<number | null | string>(null);
    const [data, setData] = useState<IPayloadUpscaleResult | null>()
    const dispatch = useAppDispatch()

    const uploadImage = async () => {

        try {

            dispatch(setGenerationLoading(true))
            const uploadResponse = await dispatch(fetchGenerateUpscale(modelData)).unwrap();
            const renderId = uploadResponse?.data?.render_id
            dispatch(setGenerationLoading(false))
            if (renderId) {
                dispatch(fetchRenderStatus(renderId))
                dispatch(fetchMyRenders(1))
            }

            if (uploadResponse.success && uploadResponse.data.render_id)
                setRenderId(uploadResponse.data.render_id);

            //     const fetch = () => {
            //         return new Promise((resolve, reject) => {
            //             setTimeout(() => {
            //                 const mockData = {
            //                     success: true,
            //                     data: {
            //                         render_id: 12345 // Mock render ID
            //                     }
            //                 };
            //                 resolve(mockData);
            //             }, 1000); 
            //         });
            //     };
            //    await fetch()

        } catch (error) {
            console.error('Error uploading image:', error);
            dispatch(setGenerationLoading(false))
        }
    };

    const chechStatus = async () => {
        try {

            if (!renderId || status === 'succeeded' || status === 'failed') {
                dispatch(setIsUpscaleGenerating(false));

                if (status === 'failed') {
                    // dispatch(setImageToUpscale(null));
                    NotificationManager.error("Something went wrong!")
                }

                return;
            }
            dispatch(setIsUpscaleGenerating(true))
            const statusResponse = await dispatch(fetchRenderStatus(renderId)).unwrap() as IPayloadUpscaleResult;

            if (!statusResponse.success || !statusResponse?.status) {
                dispatch(setIsUpscaleGenerating(false))
                return
            }

            setStatus(statusResponse.status);
            if (statusResponse.status === 'failed') {
                dispatch(setIsUpscaleGenerating(false))
                return;
            }

            if (statusResponse.status === 'succeeded') {
                dispatch(setIsUpscaleGenerating(false))
                dispatch(setImageToUpscale(statusResponse.original_image))
                dispatch(setResultImage(statusResponse.predictions[0].url))
                setData(statusResponse)
                return;
            }
            setTimeout(chechStatus, 5000);
        } catch (error) {
            NotificationManager.error("Something went wrong!")
            console.error('Error checking image status:', error);
        }
    }

    useEffect(() => {
        // chechStatus()
    }, [renderId, modelData.image]);



    return { data, uploadImage };
};
