//import { useEffect } from "react";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";

//import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
// import { getFaqPageData, getTermsPageData } from "../../store/pagesData/PagesDataSlice";
// import { fetchFaqPageData, fetchTermsPageData } from "../../store/pagesData/PagesDataThunk";
import MetaTags from 'react-meta-tags';

const DeleteAccount = () => {
    const deleteImg1 = require("../../assets/img/delete-account/delete1.JPEG");
    const deleteImg2 = require("../../assets/img/delete-account/delete2.JPEG");
    const deleteImg3 = require("../../assets/img/delete-account/delete3.JPEG");

    return (
        <>
            <MetaTags>
                <title>Design Sense AI</title>
                <meta name="description" content="Delete Account"/>
            </MetaTags>

            <Header />
            <div className="wrapper header-fixed">
                <main className="content">
                    <section className="section">
                        <div className="container container-1100">
                            <h3>To delete your account in the Design Sense application you need to:</h3>
                            <br/>
                            <p>
                                1. On the main screen, go to <strong>“Setting”</strong>, the button is located in the lower right corner of the screen.
                            </p>
                            <br />
                            <img src={deleteImg1} alt="Delete account step one" style={{width: '340px', maxWidth: '100%', marginBottom: '30px'}}/>
                            <br />
                            <p>
                                2. On the <strong>“Setting”</strong> screen, select <strong>“Delete Account</strong>”. (If the <strong>“Delete Account”</strong> button is not visible on the screen. You need to scroll to the very bottom of the screen.)
                            </p>
                            <br />
                            <div className="flex flex-wrap">
                                <img src={deleteImg2} alt="Delete account step two" style={{width: '340px', maxWidth: '100%', marginBottom: '20px', marginRight: '10px'}}/>
                                <img src={deleteImg3} alt="Delete account step three" style={{width: '340px', maxWidth: '100%', marginBottom: '20px'}}/>
                            </div>
                            <br />
                            <p>
                                After which your account data (name, email, password, and all files will be deleted) will be completely deleted.
                            </p>
                        </div>
                    </section>
                </main>

                <SiteFooter />
            </div>
        </>
    );
};

export default DeleteAccount;