import Landing from "./Landing/Landing";
import { RenderInterior } from "./App/RenderInterior/RenderInterior";
import { LatestRenders } from "./App/LatestRenders/LatestRenders";
import { MyRenders } from "./App/MyRenders/MyRenders";
import { MyTeam } from "./App/MyTeam/MyTeam";
import { Settings } from "./App/Settings/Settings";
import { SettingsBilling } from "./App/Settings/SettingsBilling";
import Pricing from "./Pricing/Pricing";
import FAQ from "./FAQ/FAQ";
import Terms from "./Terms/Terms";
import Privacy from "./Privacy/Privacy";
import AboutUs from "./AboutUs/AboutUs";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import HomePage from "./HomePage/HomePage";
import { PricingV2 } from "./Pricing/PricingV2";
import SettingsV2 from "./App/Settings/SettingsV2";
import {UpscaleLanding} from "./Landing/UpscaleLanding";
import {Upscale} from "./App/Upscale/Upscale";
import MyRendersV2 from "./App/MyRenders/MyRendersV2";

export const Pages = {
    Landing,
    UpscaleLanding,
    Pricing,
    FAQ,
    AboutUs,
    RenderInterior,
    LatestRenders,
    MyRenders,
    MyTeam,
    Settings,
    SettingsBilling,
    Terms,
    Privacy,
    DeleteAccount,
    HomePage,
    PricingV2,
    SettingsV2,
    Upscale,
    MyRendersV2
}