import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
// getLanguages
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getLanguages, setSelectedLanguage } from '../../../store/pagesData/PagesDataSlice';

export const LanguageSelect = (props:any) => {
    const { lang }= useParams();

    const dispatch = useAppDispatch();

    const languages = useAppSelector(getLanguages);
    // const languageFromStorage = localStorage.getItem('language');

    const [selectedLanguage, setLocalSelectedLanguage] = useState(lang ? lang : 'en');
    const [open, setOpen] = useState(false);
    
    const clickOutside = (e:any) => {
        if(!e.target.closest('.language-select')){
            setOpen(false);
        }
    }

    const onLangChange = (item:any) => {
        setLocalSelectedLanguage(item.code);
        localStorage.setItem('language', item.code);
        dispatch(setSelectedLanguage(item.code));
        
        if(lang !== item.code){
            if(item.code === 'en'){
                window.location.replace(`/`);
            } else {
                window.location.replace(`/${item.code}`);
            }
        }
    }

    useEffect(()=>{
        if(lang){
			const findedLangInList = languages.find((langItem:any) => langItem.code === lang);
			if(findedLangInList){
				onLangChange(findedLangInList);
			}
		}

        window.addEventListener('click', clickOutside);
        
        // if(languageFromStorage){
        //     dispatch(setSelectedLanguage(languageFromStorage));
        // }
        
        return () => {
            window.removeEventListener('click', clickOutside);
        }
    },[]);

    return (<div className='relative text-[15px] language-select'>
        <a href="#" className="d-flex items-center justify-center py-[0px] px-[12px]" onClick={(e)=>{
            e.preventDefault();
            setOpen(!open);
        }}>
            <span className='d-flex w-[18px] min-w-[18px] max-w-[18px] h-[12px] min-h-[12px] max-h-[12px] mr-[8px] flex-1'>
                <img src={require(`../../../assets/img/country-flags/${selectedLanguage}.svg`)} alt="" className='w-full h-[12px] min-h-[12px] max-h-[12px]' />
            </span>
            <span className="uppercase">{ selectedLanguage }</span> 
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ml-[8px] ${open ? 'transform-rotate-180' : ''}`}>
                <g clipPath="url(#clip0_115_647)">
                    <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_115_647">
                        <rect width="14" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </a>
        { open ? 
            <ul className='language-list'>
                {
                    languages.map((item:any, index:number) => {
                        return <li key={index}>
                            <a 
                                href="#"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setOpen(false);
                                    onLangChange(item);
                                }}
                                className={selectedLanguage === item.code ? 'active' : ''}
                            >
                                <span className='d-flex w-[18px] min-w-[18px] max-w-[18px] h-[12px] min-h-[12px] max-h-[12px] mr-[8px] flex-1'>
                                    <img src={require(`../../../assets/img/country-flags/${item.code}.svg`)} alt="" className='w-full h-[12px] min-h-[12px] max-h-[12px]' />
                                </span>
                                <span>{ item.name }</span>
                            </a>
                        </li>
                    })
                }
            </ul>
        : null }
    </div>
    )
}
