import React, { ChangeEvent, useEffect, useState } from 'react'
import { Icon } from '../../../components/UI/Icon'
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/icon-spinner.svg"
import { ReactComponent as CrownIcon } from "../../../assets/img/icons/icon-crown.svg"

interface IUser {
    avatar: string | boolean | null,
    name: string,
    email: string,
}
interface IProfile {
    name: string;
    email: string;
    avatar: string;
    is_premium: boolean;
    can_render_image: boolean;
    can_render_video: boolean;
    now_render: any[];
    plan: {
        id: number;
        title: string;
        period: string;
        next_invoice: boolean;
        current_period_end: boolean;
        cancel_at_period_end: boolean;
        billing_period: string;
        team_members: number;
    };
    team_role: string;
}
interface IPassword {
    currentPassword: string,
    newPassword: string,
    newPasswordRepeat: string
}

interface AvatarUploaderProps {
    profile: IProfile;
    handleChange: (files: FileList | []) => void;
    handleRemove: () => void;
    isLoading: boolean
}
const AvatarUploader: React.FC<AvatarUploaderProps> = ({ profile, handleChange, handleRemove, isLoading = false }) => {
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        handleChange(files || [])
    }
    const handleFileRemove = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        handleRemove()
    }

    return (
        <div className="flex items-center pb-[24px] mb-[24px] border-bottom">
            <div className="setting__user-img mr-[20px]">
                {isLoading ? (
                    <svg
                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "60px", margin: "auto" }}
                    >
                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                        </path>
                    </svg>
                ) : profile?.avatar ? (
                    <img src={profile.avatar} alt="" />
                ) : (
                    <div className="avatar-placeholder" style={{
                        minWidth: "100%",
                        minHeight: "100%",
                    }}>{profile?.name[0]}</div>
                )}
            </div>
            <div className="flex flex-col">
                <div className="flex items-center avatar-actions">
                    <label htmlFor="change-image" className="change-image">
                        <input
                            type="file"
                            id="change-image"
                            name="avatar"
                            className="upload-wrap__input"
                            onChange={handleFileChange}
                        />
                        <span className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[10px] mr-[8px]">
                            Change Image
                        </span>
                    </label>

                    <a
                        href="#"
                        onClick={handleFileRemove}
                        className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[10px]"
                    >
                        Remove
                    </a>
                </div>
                <span className="text-[12px] leading-[14px] opacity-40">We support PNG, JPEG and GIF under 10MB</span>
            </div>
        </div>
    );
};






const SettingsV2 = () => {
    const profile = {
        "name": "User",
        "email": "yelodow424@idsho.com",
        "avatar": "https:\/\/www.designsense.ai\/storage\/users\/RisEbHuIAkl2l1wOgHcg25TRwcDbSfPWFVVbf0np.png",
        "is_premium": false,
        "can_render_image": true,
        "can_render_video": false,
        "now_render": [],
        "plan": {
            "id": 1,
            "title": "Basic",
            "period": "month",
            "next_invoice": false,
            "current_period_end": false,
            "cancel_at_period_end": false,
            "billing_period": "month",
            "team_members": 1
        },
        "team_role": "admin"
    };
    const currentPlan = {
        "id": 1,
        "price_mo": "Free",
        "price_y": "Free",
        "title": "Basic",
        "description": "A single license. 1 free image.",
        "is_popular": 0,
        "features": [
            {
                "image": "https:\/\/www.designsense.ai\/storage\/plan_icons\/RPRGp2AH7PIcalWUNU0jAxsBn8w9RdTKk0FP4sOT.png",
                "title": "Watermark",
                "description": "Render have a watermark."
            },
            {
                "image": "https:\/\/www.designsense.ai\/storage\/plan_icons\/gwVN0Pv2wig0PxjwQahqC9AafKK1ZHuKBuda5tvL.png",
                "title": "Render Interior",
                "description": "One free image"
            },
            {
                "image": "https:\/\/www.designsense.ai\/storage\/plan_icons\/NUk8PSb0TR1ZzeoF7Iwc5R5qftZodE19iSpfeQKI.png",
                "title": "Good Quality",
                "description": "Generate fast in good quality."
            }
        ]
    };
    let initialUser: IUser = {
        avatar: false,
        name: '',
        email: ''
    }

    const [changeBasicInfoLoading, setChangeBasicInfoLoading] = useState<boolean>(false);
    const [changeAvatarLoading, setChangeAvatarLoading] = useState<boolean>(false);
    const [passwordChangeLoading, setPasswordChangeLoading] = useState<boolean>(false);

    const [user, setUser] = useState<IUser>(initialUser);
    const changeUserData = (key: string, value: string | null) => {
        setUser({
            ...user,
            [key]: value
        })
    }
    let initialPassword: IPassword = {
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    }
    const [password, setPassword] = useState<IPassword>(initialPassword);
    const changePasswordData = (key: string, value: string) => {
        setPassword({
            ...password,
            [key]: value
        })
    }

    const handleAvatarChange = async (files: FileList | []) => {
        console.log(files);
        setChangeAvatarLoading(true);
        setChangeAvatarLoading(false);
    }

    const handleAvatarRemove = async () => {
        setChangeAvatarLoading(true);
        setTimeout(() => {
            setChangeAvatarLoading(false);
        }, 100)
    }

    const logOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    }

    const handleChangeBasicInfo = async (e: React.MouseEvent) => {
        e.preventDefault()
        setChangeBasicInfoLoading(true);
        setTimeout(() => {
            setChangeBasicInfoLoading(false);
        }, 100)
    }

    const changePassword = async (e: React.MouseEvent) => {
        e.preventDefault()
        setPasswordChangeLoading(true);
        setPassword({
            currentPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        });

        setPasswordChangeLoading(false);
    }

    const cancelSubscribePlan = async () => {
    }

    return (
        <div className="full-page-section__content">
            <div className="section-scroll-container">
                <div className="settings">
                    <div className="flex items-center justify-between">
                        <h4 className="mb-[18px] mr-[20px] font-[400]">Profile Settings</h4>
                        <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[18px]" onClick={logOut}>
                            Log Out
                        </a>
                    </div>
                    <AvatarUploader
                        handleChange={handleAvatarChange}
                        handleRemove={handleAvatarRemove}
                        isLoading={changeAvatarLoading}
                        profile={profile}
                    />
                    <div className="flex items-center justify-between">
                        <h4 className="text-[16px] leading-[18px] mb-[24px] mr-[20px]">Basic Info</h4>
                        <a href="#" className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[24px]" onClick={handleChangeBasicInfo}>
                            {changeBasicInfoLoading ? <SpinnerIcon /> : "Save Changes"}
                        </a>
                    </div>

                    <div className="border-bottom pb-[24px] mb-[15px]">
                        <div className="max-w-[500px]">
                            <div className="input-custom mb-[16px]">
                                <label className="input-label mb-[6px]">Full Name</label>
                                <div className="input-custom__wrap-input">
                                    <input
                                        className="input-custom__input"
                                        type="text"
                                        placeholder=""
                                        value={user.name}
                                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changeUserData('name', event.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className="input-custom">
                                <label className="input-label mb-[6px]">Email</label>
                                <div className="input-custom__wrap-input">
                                    <input
                                        className="input-custom__input"
                                        type="text"
                                        placeholder=""
                                        value={user.email}
                                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changeUserData('email', event.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <h4 className="text-[16px] leading-[18px] mb-[15px] mr-[20px]">Plan & Billing</h4>
                        <div className="flex items-center">
                            <a href="#" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px] mb-[15px] mr-[8px]">
                                <span className="icon mr-[10px]">
                                    <CrownIcon />
                                </span>
                                Upgrade to PRO
                            </a>
                            <a href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--md rounded-[300px] mb-[15px]">
                                Show More
                            </a>
                        </div>
                    </div>

                    <div className="border-bottom pb-[24px] mb-[24px]">
                        <div className="max-w-[758px]">
                            <div className="row g-16">
                                <div className="col-md-4">
                                    <div className="plan-billing-card">
                                        <span className="block text-[16px] mb-[4px]">Free Plan</span>
                                        <span className="block text-[14px] opacity-40">1 free image</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="plan-billing-card">
                                        <span className="block text-[16px] mb-[4px]">Upscale</span>
                                        <span className="block text-[14px] opacity-40">No Subscription</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="plan-billing-card">
                                        <span className="block text-[16px] mb-[4px]">Billing Period</span>
                                        <span className="block text-[14px] opacity-40">Monthly (renews 02.03.2023)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <h4 className="text-[16px] leading-[18px] mb-[24px] mr-[20px]">Change Password</h4>
                        <a
                            href="#" className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px] mb-[24px] min-w-[132px] min-h-[36px]"
                            onClick={changePassword}
                        >
                            {passwordChangeLoading ? <SpinnerIcon /> : "Save Changes"}
                        </a>
                    </div>

                    <div className="max-w-[500px]">
                        <div className="row gx-16">
                            <div className="col-6">
                                <div className="input-custom mb-[16px]">
                                    <label className="input-label mb-[6px]">Current Password</label>
                                    <div className="input-custom__wrap-input">
                                        <input
                                            className="input-custom__input"
                                            type="password"
                                            placeholder=""
                                            value={password.currentPassword}
                                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('currentPassword', event.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-16">
                            <div className="col-6">
                                <div className="input-custom mb-[16px]">
                                    <label className="input-label mb-[6px]">New Password</label>
                                    <div className="input-custom__wrap-input">
                                        <input
                                            className="input-custom__input"
                                            type="password"
                                            placeholder=""
                                            value={password.newPassword}
                                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('newPassword', event.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="input-custom mb-[16px]">
                                    <label className="input-label mb-[6px]">New Password</label>
                                    <div className="input-custom__wrap-input">
                                        <input
                                            className="input-custom__input"
                                            type="password"
                                            placeholder=""
                                            value={password.newPasswordRepeat}
                                            onInput={(event: React.ChangeEvent<HTMLInputElement>) => { changePasswordData('newPasswordRepeat', event.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SettingsV2