import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';

import { saveAs } from "file-saver";
import { useEffect, useState } from 'react';
import { NoRendersPopup } from '../../../components/Popups/NoRendersPopup';
import { Popup } from '../../../components/Popups/Popup';
import BeforeAfterImages from "../../../components/UI/BeforeAfterImages";
import { Icon } from '../../../components/UI/Icon';
import { SwiperRender } from '../../../components/UI/SwiperRender';
import getI from '../../../helpers/getI';
import { useNavigate } from 'react-router-dom';
import { getMyRenders, } from '../../../store/pagesData/PagesDataSlice';
import { fetchMyRenders, } from '../../../store/pagesData/PagesDataThunk';

const getBackImg = (src: string) => {
	return { backgroundImage: `url(${src})` }
}

export const Upscale = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [isSwiperView, setIsSwiperView] = useState<boolean>(false)
	const [noRenderPopupOpened, setNoRenderPopupOpened] = useState<boolean>(false);
	const { imageToUpscale, resultImage, isUpscaleGenerating, isNoRendersError } = useAppSelector(state => state.upscale)

	const dataMyRenders: any[] = useAppSelector(getMyRenders);

	const [animCopy, setAnimCopy] = useState(false);
	const createCopyAnim = () => {
		setAnimCopy(true)
		setTimeout(() => {
			setAnimCopy(false)
		}, 1500)
	}
	const shareHandler = () => {
		if (navigator.share) {
			navigator.share({
				title: "Render image share",
				text: "Rendered image",
				url: resultImage
			}).then(function () {
				console.log("Shareing successfull")
			})
				.catch(function () {
					console.log("Sharing failed")
				})
		}
	}
	const handleDownload = (url: string) => {
		saveAs(url)
	}

	useEffect(() => {
		setNoRenderPopupOpened(isNoRendersError)
	}, [isNoRendersError])

	useEffect(() => {
		if (!imageToUpscale && !isUpscaleGenerating) {
			navigate("/my-renders")
		}
	}, [imageToUpscale, isUpscaleGenerating])

	useEffect(() => {
		dispatch(fetchMyRenders(1));
	}, [])

	if (!imageToUpscale && !isUpscaleGenerating) {
		return null
	}

	return (
		<>
			{(imageToUpscale && !resultImage) || (isUpscaleGenerating && dataMyRenders[0].status === "processing" && dataMyRenders[0].original_image) ? <div className="full-page-section__content">
				<div className="section-scroll-container">
					<div className="section-scroll-heading justify-center">
						<label className="input-label text-center mb-[42px]">Upscale Image</label>
					</div>
					<div className="relative slider-images-home--full max-w-[775px] w-full m-auto max-h-[546px] overflow-hidden">
						{imageToUpscale ?
							<img src={imageToUpscale as string} className="w-full h-full rounded-2xl" alt="" /> :
							dataMyRenders[0].status === "processing" && dataMyRenders[0].original_image ?
								<img src={dataMyRenders[0].original_image as string} className="w-full h-full rounded-2xl" alt="" />
								: null}
						<div className="slider-contetn-block">
							<span className="slider-label">Render</span>
						</div>
						{isUpscaleGenerating && <div className="message-block-center">
							<span>Please Wait</span>
							<span className="fs-12 opacity-60 font-normal">upscaling...</span>
						</div>
						}
					</div>
				</div>
			</div> : null}

			{imageToUpscale && resultImage && !isUpscaleGenerating ? <div className="full-page-section__content">
				<div className="section-scroll-container">
					<div className="section-scroll-heading justify-center">
						<label className="input-label text-center mb-[42px]">Upscale Image</label>
					</div>
					<div className="flex-auto flex overflow-hidden">
						<div className=" relative slider-images-home--full max-w-[775px] w-full m-auto max-h-[546px] h-full">
							<div className="info-block-left-top">
								<span className="slider-label">Render</span>
								<span className="slider-label flex items-center gap-[4px]">
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g opacity="0.8">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M3.49861 1.7478C3.49861 3.037 2.45351 4.08211 1.16431 4.08211C2.45351 4.08211 3.49861 5.12721 3.49861 6.41641C3.49861 5.12721 4.54372 4.08211 5.83292 4.08211C4.54372 4.08211 3.49861 3.037 3.49861 1.7478Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path fill-rule="evenodd" clip-rule="evenodd" d="M9.91788 8.7508C9.91788 7.1393 8.6115 5.83292 7 5.83292C8.6115 5.83292 9.91788 4.52654 9.91788 2.91504C9.91788 4.52654 11.2243 5.83292 12.8358 5.83292C11.2243 5.83292 9.91788 7.1393 9.91788 8.7508Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M9.91776 8.75073V9.33431" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M9.91776 2.91488V2.3313" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M5.8328 12.2522V12.8358" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M5.8328 8.16707V7.5835" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M3.49858 6.4165V7.00008" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M3.49858 1.16431V1.74788" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
											<path fill-rule="evenodd" clip-rule="evenodd" d="M5.83284 7.87524C5.83284 9.16445 4.78774 10.2095 3.49854 10.2095C4.78774 10.2095 5.83284 11.2547 5.83284 12.5439C5.83284 11.2547 6.87794 10.2095 8.16715 10.2095C6.87794 10.2095 5.83284 9.16445 5.83284 7.87524Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
										</g>
									</svg>
									HD Image
								</span>
							</div>
							{isSwiperView ?
								<SwiperRender>
									<img className='w-full h-full object-contain' src={imageToUpscale as string} alt="" />
									<img className='w-full h-full object-contain' src={resultImage} alt="" />
								</SwiperRender>
								:
								<BeforeAfterImages
									className='slider-images--4-3 widthout-image-text'
									after={getBackImg(imageToUpscale as string)}
									before={getBackImg(resultImage as string)}
								/>
							}
						</div>

					</div>
					<div className="fullpage-actions">
						<a href="#" onClick={() => handleDownload(resultImage)} className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px]">
							Download Render
						</a>
						<a
							href="#" onClick={(event) => { event.preventDefault(); createCopyAnim(); shareHandler() }}
							className={`btn btn--secondary-2 ${!animCopy ? '' : 'pointer-events-none animated pr-[8px]'}  rounded-[10px] copy-btn`}
						>
							{!animCopy && <svg className='m-[9px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15.0012 8.99878H17.0021C18.1071 8.99878 19.0029 9.89458 19.0029 10.9996V19.0029C19.0029 20.108 18.1071 21.0038 17.0021 21.0038H6.9979C5.89287 21.0038 4.99707 20.108 4.99707 19.0029V10.9996C4.99707 9.89458 5.89287 8.99878 6.9979 8.99878H8.99874" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M12.0002 15.0013V2.99634" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M15.0015 5.99759L12.0003 2.99634L8.99902 5.99759" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
							</svg>}
							<svg id="icon1" className={`test-icon ${!animCopy && 'h-0 w-0'}`} width="42" height="42" viewBox="0 0 42 42" fill="none" stroke="white"
								strokeWidth="3" strokeDasharray="100">
								<path className="icon-path" id="check" d="M 12,22 L 22,31 L 36,13"></path>
							</svg>
							{
								animCopy ? <span className="ml-[42px]">Copied</span> : ''
							}
						</a>
						<div className="btn btn--secondary-2 p-[9px] rounded-[10px] gap-[12px]">
							<button onClick={() => setIsSwiperView(false)} className={`btn btn flex ${!isSwiperView ? "" : "opacity-20"}`}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 2.57153L12 21.4287" stroke="white" stroke-width="1.5" stroke-linecap="round" />
									<path d="M15.4286 13.5L16.7144 12L15.4286 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<path d="M8.57136 10.5L7.28564 12L8.57136 13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<rect x="3.51904" y="5.75" width="16.9615" height="12.5" rx="2.25" stroke="white" stroke-width="1.5" />
								</svg>
							</button>
							<button onClick={() => setIsSwiperView(true)} className={`btn flex ${isSwiperView ? "" : "opacity-20"}`} >
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="3.51904" y="5.75" width="16.9615" height="12.5" rx="2.25" stroke="white" stroke-width="1.5" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div> : null}


			{
				isUpscaleGenerating && imageToUpscale ?
					<aside className="aside aside--controls-3 false">
						<div className="aside-container">
							<div className="aside-header px-[16px] pt-[16px]">
								<div className="row mb-[-16px]">
									<div className="col-12 mb-[16px]">
										<label className="input-label text-center">All Results</label>
									</div>
								</div>
							</div>
							<div className="aside-body pl-[16px] mt-[16px]">
								<div className="row">
									{imageToUpscale && isUpscaleGenerating && <div className="col-12">
										<div className="card-photo">
											<img src={imageToUpscale as string} alt="" />
											<div className="card-wrapper-info items-center position-center">
												<div className="card-photo-info">Upscaling...</div>
											</div>
										</div>
									</div>
									}
									{resultImage && !isUpscaleGenerating &&
										<div className="col-12">
											<label className="card-check">
												<input type="radio" name="interior" id="i1" value="0" checked />
												<div className="card-photo">
													<img src={resultImage as string} alt="Icon" />
													<div className="card-wrapper-info__right-top items-center">
														<div className="card-photo-info">Just Now</div>
													</div>
												</div>
											</label>
										</div>
									}
								</div>
							</div>
						</div>
					</aside>
					:
					null
			}


			{noRenderPopupOpened ? <Popup
				modalDialogWidth="460"
				open={noRenderPopupOpened}
				onClose={() => { setNoRenderPopupOpened(false) }}
			>
				<NoRendersPopup
					close={() => { setNoRenderPopupOpened(false) }}
				></NoRendersPopup>
			</Popup> : null}
		</>
	)
}
