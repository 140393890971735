import React, {useState, useEffect, ChangeEvent} from 'react'
import {PopupComponentType} from "./Popup";
import { Payment } from '../../helpers/payment';
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';
import { NotificationManager} from 'react-notifications';

type IProps = {
    close: any,
    confirm: ()=>void,
    fromPricing?: boolean,
} & PopupComponentType;

export interface ICard {
    exp_month?: string,
    exp_year?: string,
    id?: string | number,
    is_default?: string | number,
    last4?: string,
    stripe_id?: string,
}



export const AddCardPopup = ({close, confirm, fromPricing}: IProps) => {
    let windowLocal = window as any;

    const run = async() => {
        let payment = new Payment(localStorage.getItem("token"));
        windowLocal.payment = payment;
        await payment.init();
    }

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const [billedValue, setBilledValue] = useState('')
    const [billedNameValue, setBilledNameValue] = useState('')
    
    const updateBilled = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        setBilledValue(val)
        windowLocal?.payment.setEmail(val)
    }

    const updateBilledName = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        setBilledNameValue(val)
        windowLocal?.payment.setUserName(val)
    }

    useEffect(()=>{
        if(confirm){
            windowLocal.confirmAddCardPopup = confirm;
        }        
    }, [confirm])

    useEffect(()=>{
        run();

        const notifShow = (event:any) => {
            if(event?.detail?.status === false){
                NotificationManager.error(event?.detail?.message);
            }
        }

        document.addEventListener("openNotificationManager", notifShow);

        return () => {
            document.removeEventListener("openNotificationManager", notifShow)
        }
    }, [])


    return (
        <>
            <div className="modal__header">
                <h4 className="font-[400] mb-[12px]">{fromStrings("Add Card")}</h4>
                <p className="color-secondary opacity-70">{fromStrings("Fill in the form to add new card.")}</p>
            </div>
            <div className="card_outer">
                <div className="modal__body">
                    <div className="input-custom mt-[32px] mb-[16px]">
                        <label className="input-label mb-[6px]">{fromStrings("Email")}</label>
                        <div className="input-custom__wrap-input">
                            <input 
                                type="text" id="cardEmail" className="input-custom__input min-h-[40px]" style={{ padding: "8px 14px" }}
                                value={billedValue} onChange={updateBilled}
                            />
                        </div>
                    </div>

                    <div className="input-custom mb-[16px] mb-[16px]">
                        <label className="input-label mb-[6px]">{fromStrings("Cardholder Name")}</label>
                        <div className="input-custom__wrap-input">
                            <input
                                type="text" id="cardholderName" className="input-custom__input min-h-[40px]" style={{ padding: "8px 14px" }}
                                value={billedNameValue} onChange={updateBilledName}
                            />
                        </div>
                    </div>

                    <div className="input-custom mb-[16px]">
                        <label className="input-label mb-[6px]">{fromStrings("Card Number")}</label>
                        <div className="input-custom__wrap-input">
                            <div id="cardNumber" className="input-custom__input min-h-[40px]" style={{ padding: "11px 14px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="input-custom mb-[16px]">
                                <label className="input-label mb-[6px]">{fromStrings("Date Due")}</label>
                                <div className="input-custom__wrap-input">
                                    <div id="cardExp" className="input-custom__input min-h-[40px]" style={{ padding: "11px 14px" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-custom mb-[16px]">
                                <label className="input-label mb-[6px]">{fromStrings("CVV")}</label>
                                <div className="input-custom__wrap-input">
                                    <div id="cardCVC" className="input-custom__input min-h-[40px]" style={{ padding: "11px 14px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <label className="custom-checkbox mb-[32px]">
                        <input type="checkbox" className="custom-checkbox__input is_default_checkbox" checked />
                        <span className="custom-checkbox__input-fake">
                            <span className="icon-ico-check"></span>
                        </span>
                        <span className="custom-checkbox__label">{fromStrings("Set as main payment method")}</span>
                    </label>

                
                </div>
                <div className="modal__footer">
                    <a href="#" onClick={(e)=>{e.preventDefault(); close()}} className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                        {fromStrings('Cancel')}
                    </a>
                    {
                        fromPricing ? 
                        <input type="button" value={fromStrings("Add Card And Upgrade")} className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] cursor-pointer add-card-submit" onClick={()=>{
                            windowLocal.payment.saveCard();
                        }} /> :
                        <input type="button" value={`+ ${fromStrings('Add Card')}`} className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] cursor-pointer add-card-submit" onClick={()=>{
                            windowLocal.payment.saveCard();
                        }} />
                    }
                    
                    <a href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] cursor-pointer add-card-loading disabled hidden">
                        <svg
                            version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px" }}
                        >
                                <path fill="#101014" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
        </>
    )
}
