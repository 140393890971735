import React, { useState } from 'react';
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

interface propsType{
    close?: any,
    add?: any,
}

export const AddMemberPopup:React.FC<propsType> = ({ close, add }) => {
    const [enteredEmail, setEnteredEmail] = useState('');

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    return (
        <>
            <div className="modal__header">
                <h4 className="font-[400] mb-[12px]">{ fromStrings("Add Member") }</h4>
                <p className="color-secondary opacity-70">{ fromStrings("Fill in email to send invite") }</p>
            </div>
            <div className="modal__body">
                <div className="input-custom my-[32px]">
                    <label className="input-label mb-[6px]">{ fromStrings("Email") }</label>
                    <div className="input-custom__wrap-input">
                        <input className="input-custom__input" type="email" placeholder="" value={enteredEmail} onInput={(e: React.ChangeEvent<HTMLInputElement>)=>{ setEnteredEmail(e.target.value); }} />
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                {/* onClick={(e)=>{e.preventDefault(); close()}} */}
                <a
                    href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal=""
                    onClick={(e)=>{e.preventDefault(); close(); }}
                >
                    { fromStrings("Cancel") }
                </a>
                <a
                    href="#" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]"
                    onClick={(e)=>{e.preventDefault(); add(enteredEmail); close(); setEnteredEmail(""); }}
                >
                    { fromStrings("Send Invite") }
                </a>
            </div>
        </>
    )
}
