import React, { useState, useEffect } from 'react';

type PropsType = {
    title: string,
    content: string,
    initOpen?: Boolean,
}

const FaqItem: React.FC<PropsType> = ({ title, content, initOpen = false }) => {
    const [open, setOpen] = useState(initOpen);
    const [maxHeight, setMaxHeight] = useState(0);

    const bodyRef = React.createRef<HTMLInputElement>();    

    useEffect(()=>{
        if(bodyRef?.current?.offsetHeight){
            setMaxHeight(bodyRef?.current?.offsetHeight+5);
        }
    }, [bodyRef])

    return (
        <div className={`faq__item ${open ? 'active' : ''}`} data-spoller="">
            <div className="faq__header" data-spoller-btn="" onClick={(e)=>{
                setOpen(!open);
            }}>
                <p className="faq__title">{ title }</p>
                <div className="faq__arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 10L12 14L16 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
            </div>
            <div style={{ height: open ? maxHeight+'px' : "0px", transition: "0.3s all ease-in-out", overflow: "hidden" }}>
                <div ref={bodyRef}>
                    <p className="faq__content">{ content }</p>
                </div>
            </div>
        </div>
    );
};

export default FaqItem;