import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import { NavLink } from 'react-router-dom'

export const HeaderDropdown: FunctionComponent = () => {

    const [isOpen, setIsOpen] = useState(false)

    const handleLink = (e: React.MouseEvent) => {
        // e.preventDefault();
        setIsOpen(false);
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100);
    }

    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleClose = (e: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target as HTMLDivElement)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClose)
        } else {
            document.removeEventListener('click', handleClose)
        }

        return () => document.removeEventListener('click', handleClose)
    }, [isOpen])


    return (
        <div className="relative" ref={wrapperRef}>
            <div onClick={() => setIsOpen(!isOpen)} className={`dropdown header__dropdown ${isOpen ? 'dropdown-open' : ''}`} data-dropdown="dropdown">
                <a onClick={(e) => e.preventDefault()} href="#" className="header__link header__dropdown-button" data-role="button">
                    <span className="info">Products</span>
                    <span className="ico">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.625 8.75L7 4.375L11.375 8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </a>
            </div>

            <div className={`dropdown__body header__dropdown-body ${isOpen ? 'is-open' : ''}`} data-role="dropdown">
                <NavLink to="/render-interior-landing" onClick={handleLink} className="btn">
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.33252 16.6696V12.5012C8.33252 12.0407 8.70577 11.6675 9.1662 11.6675H10.8336" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M2.49707 8.81626L8.91508 3.31499C9.53949 2.77977 10.4609 2.77977 11.0853 3.31499L17.5033 8.81626" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M10.0002 16.6695H2.49707" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M13.021 4.97437V3.12209C13.021 2.77676 13.3009 2.49683 13.6463 2.49683H15.8062C16.1516 2.49683 16.4315 2.77676 16.4315 3.12209V7.89773" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M3.5687 7.89771V16.6694" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.4189 17.5032C15.4189 15.8917 14.1125 14.5854 12.501 14.5854C14.1125 14.5854 15.4189 13.279 15.4189 11.6675C15.4189 13.279 16.7252 14.5854 18.3367 14.5854C16.7252 14.5854 15.4189 15.8917 15.4189 17.5032Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    <span>Render Interior</span>
                </NavLink>
                <NavLink to="/upscale-landing" onClick={handleLink} className="btn">
                    <span className="ico">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2303_4067)">
                                <path d="M10.625 9.375H3.75V16.25H10.625V9.375Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16.25 14.375V15.625C16.25 15.7908 16.1842 15.9497 16.0669 16.0669C15.9497 16.1842 15.7908 16.25 15.625 16.25H13.75" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16.25 8.75V11.25" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.375 3.75H15.625C15.7908 3.75 15.9497 3.81585 16.0669 3.93306C16.1842 4.05027 16.25 4.20924 16.25 4.375V5.625" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.75 3.75H11.25" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.75 6.25V4.375C3.75 4.20924 3.81585 4.05027 3.93306 3.93306C4.05027 3.81585 4.20924 3.75 4.375 3.75H5.625" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_2303_4067">
                                    <rect width="20" height="20" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span>Upscale Image</span>
                </NavLink>
            </div>
        </div>
    )
}