import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { fetchProfile, profileFetchCards } from "./ProfileThunk";
import {RootState} from "../index";

export interface IProfile {
    name: string,
    email: string,
    is_premium: boolean,
    can_render_image: boolean,
    can_render_video?: boolean,
    can_upscale?: boolean,
    team_role: string
}
interface IAuthState {
    profile: any,
    cards: [],
    plan?: IProfilePlan,
    profileLoading:boolean
}

const initialState: IAuthState = {
    profile: null,
    cards: [],
    profileLoading: false
}
export interface IInvoice {
    date: string;
    total: number;
    status: string;
    link: string;
}
export interface IProfilePlan {
    id: number;
    title: string;
    period: string;
    next_invoice: boolean;
    billing_period: string;
    team_members: number;
    total_payment: number;
    invoices: IInvoice[];
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearProfile: (state) => {
            state.profile = null
        },
    },
    extraReducers: (builder) =>{
        builder.addCase(fetchProfile.pending, (state, action) => {            
            state.profileLoading = true;
        });
        builder.addCase(fetchProfile.fulfilled, (state, action) => {            
            state.profile = action.payload.data;
            state.plan = action.payload.data.plan;
            state.profileLoading = false;

        });
        builder.addCase(fetchProfile.rejected, (state, action) => {
            state.profile = null;
            state.plan = undefined;
            state.profileLoading = false;

        });

        builder.addCase(profileFetchCards.fulfilled, (state, action) => {            
            state.cards = action.payload.cards;
        });
        builder.addCase(profileFetchCards.rejected, (state, action) => {
            state.cards = [];
        });
    }
})

export const getProfile = (state: RootState) => state.profile.profile;
export const getProfilePlan = (state: RootState) => state.profile.plan;
export const getProfileCards = (state: RootState) => state.profile.cards;
export const getProfileLoading = (state: RootState) => state.profile.profileLoading;

export const { clearProfile } = profileSlice.actions;

export default profileSlice.reducer;