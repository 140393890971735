export function convertImageToBase64Src(fileList:FileList  , callback: (base64Data: string) => void) {
    const file = fileList[0];
  
    if (!file) {
      console.error("No file selected.");
      return;
    }
  
    const reader = new FileReader();
    
    reader.onload = function(event) {
        if(event?.target?.result){
            const base64Data = (event?.target?.result as string)?.split(",")[1];
            callback(`data:image/jpeg;base64,${base64Data}`);
        }
    }
  
    reader.readAsDataURL(file);
}
function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}