import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from "../../api/axios";
import { IGenerationOptions } from "./types";

  
export const fetchGenerationOptions = createAsyncThunk(
    'generationOptions/fetchGenerationOptions',
    async (_ , {rejectWithValue}) => {
        try{
            const res = await Api().generationOptions.getOptions();

            return res.data
        }catch(error){
            return rejectWithValue({rejectedError: 'error'})
        }
    }
)
  