import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { PopupComponentType } from "./Popup";
import { useAppSelector } from '../../hooks/storeHooks';
import { getProfile } from '../../store/profile/ProfileSlice';

type IProps = {
    close: () => void,
    children?: ReactNode
    title?: string | null,
    currentPlan?: string | null,
} & PopupComponentType



export const NoUpscalePopup = ({ close, title, children, currentPlan = "Free" }: IProps) => {
    const plans = ["Pro", "Premium", "Enterprise"]
    const profile = useAppSelector(getProfile)
    const currentPlanIndex = plans.findIndex(plan => plan === currentPlan)
    const planToUpgrade = currentPlanIndex < plans.length - 1 ? plans[currentPlanIndex + 1] : null

    return (

        <>
            <div className="modal__header">
                {title !== null ? <h4 className="font-[400] mb-[12px] no-renders-popup-title">You have run out of tokens</h4> : null}
                <button className="modal__close close-modal-btn" onClick={close}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.2">
                            <path d="M8 8L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 8L8 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </button>
            </div>
            <div className="modal__body select-popup">


                <div className='no-renders-popup px-5'>
                    {
                        planToUpgrade ?
                            <>
                                <div className="no-renders-popup-text text-[14px] color-secondary-07 mb-4">To get more tokens, please upgrade your plan.</div>

                                {
                                    profile.team_role !== "user" ?
                                        <NavLink to="/pricing" className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px]">
                                            <span className="icon mr-[10px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                    <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Upgrade to {planToUpgrade}
                                        </NavLink>
                                        :
                                        null
                                }
                            </>
                            :
                            <p className='no-renders-popup-text text-[14px] color-secondary-07 mb-4'>To get more tokens, please contact support at <a className='color-primary' href="mailto:support@designsense.ai">support@support.com.</a></p>

                    }
                </div>
            </div>
        </>
    )
}
