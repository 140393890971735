import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { fetchGeneration } from './GenerationThunk'
import { IGenerationData, IGenerationDataResponse, IGenerationLoadingModel } from './types'
import { FeatureType } from '../../types'
interface IGeneration {
    loading: boolean,
    loadingModel?: IGenerationLoadingModel,
    data: IGenerationData[],
    isNoRendersError: boolean,
    generateEndTime: {
        all_seconds: number | null,
        date: string | null,
        startFrom?: number
    },
    activeGenerationTab: FeatureType

    // allData: IGenerationData[]
}
const initialState: IGeneration = {
    data: [],
    loading: false,
    isNoRendersError: false,
    generateEndTime: {
        all_seconds: null,
        date: null,
        startFrom: 0,
    },
    activeGenerationTab: window.localStorage.getItem("feature") as FeatureType || "render",
    // allData:[]
}

export const GenerationOptionsSlice = createSlice({
    name: 'generationOptions',
    initialState,
    reducers: {
        setGenerationLoading: (state, { payload }: { payload: boolean }) => {
            state.loading = payload;
        },
        setLoadingModel: (state, action: PayloadAction<IGenerationLoadingModel>) => {
            state.loadingModel = action.payload;
        },
        setGenerateEndTime: (state, action: PayloadAction<{ all_seconds: number | null, date: string | null, startFrom?: number }>) => {
            state.generateEndTime = action.payload;
        },
        setActiveGenerationTab: (state, { payload }: { payload: FeatureType }) => {
            state.activeGenerationTab = payload;
            console.log("payload");
            console.log(payload);
            
            window.localStorage.setItem("feature", payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGeneration.pending, (state, { payload }) => {
            state.loading = true;
            state.isNoRendersError = false;
            // state.data = undefined;

        });
        builder.addCase(fetchGeneration.fulfilled, (state, { payload }) => {
            if (!payload.success && payload.premium_required) {
                state.isNoRendersError = true;
            }
            if (payload.success && state.loadingModel) {
                state.data = [{ ...payload as IGenerationDataResponse, interior: state.loadingModel?.interior }, ...state.data];
            }
            state.loading = false;
        });
        builder.addCase(fetchGeneration.rejected, (state, { payload }) => {
            state.loading = false;
        });
    }
})

// actions
export const { setLoadingModel, setGenerateEndTime, setActiveGenerationTab, setGenerationLoading, } = GenerationOptionsSlice.actions;
export const getGenerationLoading = (state: RootState) => state.generation.loading;
export const getGenerationLoadingModel = (state: RootState) => state.generation.loadingModel;
export const getGenerationData = (state: RootState) => state.generation.data;
export const getIsNoRendersError = (state: RootState) => state.generation.isNoRendersError;
export const getGenerateEndTime = (state: RootState) => state.generation.generateEndTime;
export const getActiveGenerationTab = (state: RootState) => state.generation.activeGenerationTab;

export default GenerationOptionsSlice.reducer;