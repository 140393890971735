import { useEffect } from "react";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
// import FaqItem from "../../components/UI/FaqItem"
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { getFaqPageData, getPrivacyPageData, getTermsPageData, getSelectedLanguage, setCurrentPageData } from "../../store/pagesData/PagesDataSlice";
import { fetchFaqPageData, fetchPrivacyPageData, fetchTermsPageData } from "../../store/pagesData/PagesDataThunk";
import MetaTags from 'react-meta-tags';

const Privacy = () => {
    const privacyPageData = useAppSelector(getPrivacyPageData)
    const dispatch = useAppDispatch();

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);
    
    useEffect(()=>{
        dispatch(fetchPrivacyPageData((selectedLanguage || languageFromStorage || '') as string))
    },[selectedLanguage])

    useEffect(() => {
		dispatch(setCurrentPageData(privacyPageData));
	}, [privacyPageData])
    
    return (
        <>
            <MetaTags>
                <title>{ privacyPageData?.page?.meta_title || "Design Sense AI" }</title>
                <meta name="description" content={ privacyPageData?.page?.meta_description || "" } />
            </MetaTags>

            <Header currentPageData={privacyPageData} />
            <div className="wrapper header-fixed">
                <main className="content">
                    <section className="section">
                        <div className="container container-1100" dangerouslySetInnerHTML={{__html:privacyPageData?.page?.content||''}}></div>
                    
                    </section>
                </main>

                <SiteFooter currentPageData={privacyPageData} />
            </div>
        </>
    );
};

export default Privacy;