import React, { useState, useEffect } from 'react'
import { Icon } from '../../../components/UI/Icon'
import { MyRendersFolder } from './MyRendersFolder'
import { MyRendersPhoto } from './MyRendersPhotos'
import { Popup } from '../../../components/Popups/Popup'

import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";
import { fetchMyRenders } from '../../../store/pagesData/PagesDataThunk';
import { getMyRenders, getCardsContentCount, setCardsContentCountAction, appendMyRenders, deleteRenderFromState, getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { getGenerationLoading, setGenerateEndTime, getGenerateEndTime } from '../../../store/Generation/GenerationSlice';
import { getGenerationOptionsLoading } from '../../../store/GenerationOptions/GenerationOptionsSlice';
import getI from '../../../helpers/getI';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { NotificationManager} from 'react-notifications';
import InfiniteScroll from "react-infinite-scroll-component";

import {NoRendersPopup} from "../../../components/Popups/NoRendersPopup";

import { Api } from '../../../api/axios';
import MyRendersV2 from './MyRendersV2'

export interface MyRender{
    image: string,
    title: string,
    photos: string[]
}

// export interface IFolder {
//     name:string,
//     image:string,
//     renders?:  MyRender[],
//     isRendering?: boolean,
//     rendersCurrent?: number,
//     rendersMax?: number,

// }

export interface IFolder {
    id?: string|number,
    original_image?: string,
    selected_image?: string,
    render_engine?: string,
    status?: string,
    total_renders?: number,
    title?: string|null,
    percent?: string|null,
    all_renders?: string[]
}

export const MyRenders = ({ inGeneratePage = false, percentsFromStatusReq = null as number|string|null }) => {

    // let data:IFolder[] = [
    //     {
    //         name: 'Scandinavian Living Room',
    //         image:'img/my-render-image-1.png',
    //         renders: [
    //             {
    //                 image: 'img/my-render-image-1.png',
    //                 title: 'Original',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
              
    //         ]
    //     },
    //     {
    //         name: 'Modern Kitchen',
    //         image:'img/my-render-image-2.png',
    //         renders: [
    //             {
    //                 image: 'img/my-render-image-1.png',
    //                 title: 'Original',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]

    //             },
    //             {
    //                 image: 'img/my-render-image-2.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-3.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-1.png',
    //                 title: 'Original',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-2.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-3.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //         ]

    //     },
    //     {
    //         name: 'Minimalist Bedroom',
    //         image:'img/my-render-image-3.png',
    //         renders: [
    //             {
    //                 image: 'img/my-render-image-1.png',
    //                 title: 'Original',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-2.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //             {
    //                 image: 'img/my-render-image-3.png',
    //                 title: 'Render',
    //                 photos: [
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png', 
    //                     'img/result-slider-image-1.png'
    //                 ]
    //             },
    //         ]

    //     },
    //     {
    //         name: 'Scandinavian Living Room',
    //         image:'img/my-render-image-4.png',
    //         isRendering: true,
    //         rendersCurrent: 1, 
    //         rendersMax: 3,
    //     },
    // ]
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let generationLoading = useAppSelector(getGenerationLoading);
    let generationOptionsLoading = useAppSelector(getGenerationOptionsLoading)
    let cardsContentCount = useAppSelector(getCardsContentCount);
    const matches = useMatches()
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    let [openedFolder, setOpenedFolder] = useState<IFolder|null>(null) 
    
    let [openedPhotos, setOpenedPhotos] = useState<string[]|null>(null) 
    let [openedTitle, setOpenedTitle] = useState<string|null>(null) 
    let [rendersFetchLoading, setRendersFetchLoading] = useState(false); 
    // let [seeMore, setSeeMore] = useState(false);
    let [curentRenderProgress, setCurentRenderProgress] = useState(100);

    let [popupMaxWidth, setPopupMaxWidth] = useState<number|string|null>(null);

    let generateEndTime = useAppSelector(getGenerateEndTime);

    let [itemToDelete, setItemToDelete] = useState<number|string|null>(null);

    const [upgradeToProOpened, setUpgradeToProOpened] = useState(false);
    let [upgradeToText, setUpgradeToText] = useState<string|null>(null);

    const [fetchFromPageLoading, setFetchFromPageLoading] = useState(false);

    // const [cardsContentCount, setCardsContentCount] = useState(1);
    const setCardsContentCount = (value: any) => {
        dispatch(setCardsContentCountAction(value))
    }
    
   
    let backHandler = (event?: React.MouseEvent) => {
        const keys = Object.keys(matches);
        const lastMatch = matches[keys.length - 1]
        // console.log(lastMatch)
        // if(openedFolder&&openedPhotos){
        //     event.preventDefault();
        //     setOpenedPhotos(null);
        // }else if(openedFolder){
        //     event.preventDefault();
        //     setOpenedFolder(null);
        // }

        if(event) event.preventDefault();
        setPopupMaxWidth(null);
        setOpenedPhotos(null);
        setOpenedFolder(null);
        setOpenedTitle(null);
        navigate(lastMatch.pathname);
    }

    let openFolder = (event: React.MouseEvent | null, item: IFolder) => {
        if(event) event.preventDefault(); 
        setOpenedFolder(item)
    }
    // let openPhotos = (event: React.MouseEvent, items?: string[]) => {
    let openPhotos = (event: React.MouseEvent | null, item?: any, title?: any) => {
        if(event) event.preventDefault(); 
        if(item) setOpenedPhotos([item.original_image, ...item.all_renders]);
        if(title) setOpenedTitle(title);
    }

    interface LatestRenderType {
		original_image: string,
		selected_image: string,
		title: string,
	}

	
	const dataMyRenders:IFolder[] = useAppSelector(getMyRenders);
    
    const rendersFetch = async() => {
        setRendersFetchLoading(true);
        let rendersfetchData = await dispatch(fetchMyRenders(1));
        
        // console.log('rendersfetchData:', rendersfetchData);

        if(rendersfetchData?.payload?.items?.length % 12 === 0){
            setCardsContentCount(2);
        }
        
        setRendersFetchLoading(false);
    }

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const queryId = query.get('id') || "";

    const getTime = () => {
        let finishDate = new Date(generateEndTime?.date as any) as any;
        
        let startDate = new Date() as any;      
    
        if(generateEndTime.all_seconds){
            if(generateEndTime?.startFrom && generateEndTime.startFrom > 1){
                let calculatedPercentsFromStorage = (100 + generateEndTime.startFrom) - (100 / +generateEndTime.all_seconds as number) * Math.abs((startDate.getTime() / 1000) - (finishDate.getTime() / 1000));
                
                setCurentRenderProgress(calculatedPercentsFromStorage > 100 ? 100 : calculatedPercentsFromStorage < 0 ? 0 : calculatedPercentsFromStorage); 
            } else {
                setCurentRenderProgress(
                    100 - (100 / +generateEndTime.all_seconds as number) * Math.abs((startDate.getTime() / 1000) - (finishDate.getTime() / 1000))
                );
            }
            
        }

        if(+Math.abs(Math.floor(startDate.getTime() / 1000) - (finishDate.getTime() / 1000)) <= 0){            
            // dispatch(setGenerateEndTime({
            //     all_seconds: null,
            //     date: null
            // }));

            setCurentRenderProgress(100);

            return false;
        } else {
            return true;
        }
    }

    const getImgSize = (imgSrc:string) => {
        var newImg = new Image();
    
        newImg.onload = function() {
            var width = newImg.width;
            setPopupMaxWidth(width < 1000 ? 1000 : width);
        }
    
        newImg.src = imgSrc;
    }

    const renderProcessingFromData = (dataMyRenders.length && (dataMyRenders[0].status === "processing" || dataMyRenders[0].status === "starting" || dataMyRenders[0].selected_image === ""));

    const deleteRender = async() => {
        console.log('itemToDelete:', itemToDelete);
        let res = await Api().pagesData.deleteRender(itemToDelete);
        // setTimeout(async() => {
        //     await rendersFetch();
        // }, 1000);

        if(res?.data?.success){
            if(itemToDelete){
                dispatch(deleteRenderFromState(itemToDelete));
            }

            NotificationManager.success("Render has been deleted successfully");
        } else {
            NotificationManager.error("Something went wrong");
        }

        setItemToDelete(null);
    }


    useEffect(()=>{
        if(openedPhotos?.length){
            getImgSize(openedPhotos[1]);
        }
    }, [openedPhotos])

	useEffect(()=>{
		rendersFetch();
        setCardsContentCount(1);
	}, [])

    // cardsContentCount

    const fetchFromPage = async(page:number) => {
        setFetchFromPageLoading(true);
        const res = await Api().pagesData.myRenders(page);
        if(res?.data?.items?.length){
            dispatch(appendMyRenders(res.data.items))
        }
        setFetchFromPageLoading(false);
    }

    useEffect(()=>{
        if(cardsContentCount !== 1){
            fetchFromPage(cardsContentCount as number);
        }
	}, [cardsContentCount])

	useEffect(()=>{
        if(queryId && dataMyRenders.length && dataMyRenders.find((item: any) => String(item.id) === String(queryId))){
            let findedItem = dataMyRenders.find((item: any) => String(item.id) === String(queryId)) as any;
            openFolder(null, findedItem);
            openPhotos(null, findedItem, findedItem.title); 
        }
	}, [dataMyRenders, queryId]);

    useEffect(() => {
        let interval = null as any;
        if(generateEndTime?.date){
            interval = setInterval(() => {
                let res = getTime();
                if(!res) clearInterval(interval);
            }, 100);
        }
        return () => clearInterval(interval);
    }, [generateEndTime]);

    useEffect(() => {
        let getProgressFromStorage = localStorage.getItem('currentRenderProgress');

        if(getProgressFromStorage && (+getProgressFromStorage < 99 && +curentRenderProgress === 100)){
            setCurentRenderProgress(+getProgressFromStorage);
        }

        if(+curentRenderProgress === 100 && (!getProgressFromStorage || +getProgressFromStorage > 90 )){ 
            localStorage.removeItem('currentRenderProgress');
        } else if(+curentRenderProgress !== 0 && +curentRenderProgress !== 100) {
            localStorage.setItem('currentRenderProgress', String(curentRenderProgress));
        } else if(+curentRenderProgress > 99 || (dataMyRenders[0].status !== "processing" && dataMyRenders[0].status !== "starting")){
            localStorage.removeItem('currentRenderProgress');
        }
    }, [curentRenderProgress])

    return (
        <div className="full-page-section__content">
            <div className="section-scroll-container" id="section-scroll-container">
                {   
                    !inGeneratePage ?
                    <div className="section-scroll-heading">
                        <h4 className="flex-auto">{fromStrings("My Renders")}</h4>
                        <div className="max-w-[245px] w-full">
                            {/* <select className="select">
                                <option>All interiors</option>
                                <option>Interiors 1</option>
                                <option>Interiors 2</option>
                                <option>Interiors 3</option>
                            </select> */}
                        </div>
                    </div> : null
                }
                {
                    (!rendersFetchLoading && !generationLoading) && !dataMyRenders?.length ?
                    <div className="empty-page">
                        <div className="features-item__icon">
                            <Icon src="img/feature-house.svg" alt=""/>
                            <div className="features-item__shape" style={getI(0)}></div>
                            <div className="features-item__shape" style={getI(1)}></div>
                            <div className="features-item__shape" style={getI(2)}></div>
                            <div className="features-item__shape" style={getI(3)}></div>
                            <div className="features-item__shape" style={getI(4)}></div>
                            <div className="features-item__shape" style={getI(5)}></div>
                        </div>
                        <h4 className="mb-[12px]">{fromStrings("No results yet")}</h4>
                        <p className="text-[14px] color-secondary-07">{fromStrings("Fill in the form on the left and generate your first interior.")}</p>
                    </div> : <>
                        <InfiniteScroll
                            loader
                            // key={mainFilter}
                            className={`row g-20 mb-[20px]`}
                            dataLength={dataMyRenders.length}
                            next={()=>{ setCardsContentCount(cardsContentCount as number + 1); }}
                            hasMore={true}
                            scrollableTarget="section-scroll-container"
                        >
            
                        <div className="row g-20 mb-[20px]">
                            {
                                rendersFetchLoading ? <>
                                <div className="col-sm-12">
                                    <a
                                        href="#" className="render-exzample-item skeleton"
                                        style={{ backgroundColor: "transparent" }}
                                    >
                                    </a>
                                </div>
                                </> : null 
                            }
                            {
                                (generationLoading || (dataMyRenders.length && (dataMyRenders[0].status === "processing" || dataMyRenders[0].status === "starting"))) && !rendersFetchLoading ?
                                <div className="col-xxl-3 col-lg-6 col-sm-6">
                                    <a
                                        href="#" className="render-exzample-item skeleton"
                                        style={{ backgroundColor: "#28282e" }}
                                    >
                                        <div className="overlay-content-block">
                                            <span className="rendering-label">
                                                <span className="block">{ 
                                                    // percentsFromStatusReq ? percentsFromStatusReq : 
                                                    // renderProcessingFromData && !generateEndTime?.date ? '' : `${curentRenderProgress.toFixed(0)}%`
                                                    dataMyRenders[0]?.render_engine === "stable" ? !generateEndTime?.date ? '' : `${curentRenderProgress.toFixed(0)}%` : 
                                                    percentsFromStatusReq !== null ? ((percentsFromStatusReq as string).includes('%') ? percentsFromStatusReq : `${percentsFromStatusReq}%`) : '0%'
                                                }</span>
                                                <span className="block">{ dataMyRenders[0]?.title === "Upscale" ? "upscaling" : fromStrings("rendering")}...</span>
                                            </span>
                                            <span className="latest-render-name">New {dataMyRenders[0]?.title === "Upscale" ? "Upscale" : "Render Interior"}</span>
                                        </div>
                                    </a>
                                </div> : null
                            }
                            {/* {!rendersFetchLoading ? (seeMore || !inGeneratePage ? dataMyRenders : [...dataMyRenders].slice(0, 20)).filter(itemF => itemF.status !== "processing" && itemF.status !== "starting").map((item, index)=>{ */}
                            {!rendersFetchLoading ? dataMyRenders.filter(itemF => itemF.status !== "processing" && itemF.status !== "starting").map((item, index)=>{
                                return(
                                    <div className="col-xxl-3 col-lg-6 col-sm-6" key={item?.id}>
                                        {/* <a href="#" className="render-exzample-item" onClick={(event)=>{openFolder(event, item)}}> */}
                                        <a href="#" className="render-exzample-item" onClick={(event)=>{openFolder(event, item); openPhotos(event, item, item.title); }}>
                                            
                                            {/* <Icon src={item.image} alt=""/> */}
                                            <img src={item.selected_image} alt="" />
                                            <div className="overlay-content-block">
                                                {   
                                                    (item.status === "succeeded" || item.status === "failed")
                                                    ?   <>
                                                            <span className="latest-render-label">
                                                                { item.status === "succeeded" ? <>{item.total_renders} {fromStrings("render(s)")}</> : "Failed" }
                                                            </span>
                                                            <button className="remove-render-btn" onClick={(e)=>{
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setItemToDelete(item.id as string);
                                                            }}>
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.6593 15.7526H6.34493C5.46278 15.7526 4.72936 15.0734 4.6617 14.1939L3.9375 4.7793H14.0667L13.3425 14.1939C13.2749 15.0734 12.5414 15.7526 11.6593 15.7526V15.7526Z" stroke="#FFFFFF" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M15.005 4.77945H3" stroke="#FFFFFF" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.89098 2.24707H11.1115C11.5777 2.24707 11.9556 2.62499 11.9556 3.09117V4.77938H6.04688V3.09117C6.04688 2.62499 6.42479 2.24707 6.89098 2.24707Z" stroke="#FFFFFF" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M10.4845 8.15527V12.3758" stroke="#FFFFFF" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M7.52359 8.15527V12.3758" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            </button>
                                                        </>
                                                    : item.status !== "failed" ? <span className="rendering-label">
                                                        {/* <span className="block"> {item.rendersCurrent} of {item.rendersMax}</span> */}
                                                        <span className="block">{ 
                                                            // percentsFromStatusReq ? percentsFromStatusReq : 
                                                            // renderProcessingFromData && !generateEndTime?.date ? '' : `${curentRenderProgress.toFixed(0)}%`
                                                            item?.render_engine === "stable" ? !generateEndTime?.date ? '' : `${curentRenderProgress.toFixed(0)}%` : 
                                                            percentsFromStatusReq !== null ? ((percentsFromStatusReq as string).includes('%') ? percentsFromStatusReq : `${percentsFromStatusReq}%`) : '0%'
                                                        }</span>
                                                        <span className="block">{fromStrings("rendering")}...</span>
                                                    </span> : null
                                                }
                                                <span className="latest-render-name">{item.title}</span>
                                            </div>
                                        </a>
                                    </div>
                                )
                        }) : null}
                        {/* {
                            (dataMyRenders.length % 12 === 0) && inGeneratePage ? <div className="col-xxl-3 col-lg-6 col-sm-6" style={{ opacity: '0' }}>
                                <div className="render-exzample-item"></div>
                            </div> : null
                        } */}
                        </div>
                        </InfiniteScroll>
                        {
                            fetchFromPageLoading ? <div className="d-flex justify-center">
                                <svg
                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "50px" }}
                                >
                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                    </path>
                                </svg>
                            </div> : null
                        }
                        {/* {
                            // dataMyRenders?.length > 20 && 
                            (dataMyRenders.length % 12 === 0) && inGeneratePage ?
                            <div className="d-flex justify-center">
                                <a
                                    href="#" className="btn--render-js-1 btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[200px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        // setSeeMore(prevState => !prevState);
                                        setCardsContentCount(cardsContentCount as number + 1);
                                    }}
                                >See more</a>
                            </div> : null
                        } */}
                    </>
                }
            </div>

            { openedPhotos ?
                <Popup
                    modalDialogWidth="1000"
                    popupMaxWidth={popupMaxWidth}
                    open={true}
                    onClose={backHandler as any}
                    modalContentClassName='h-full'
                >
                    <MyRendersPhoto title={openedTitle} items={openedPhotos} backHandler={backHandler} openedFolder={openedFolder} openUpgradeToPro={(text:any)=>{
                        if(text){
                            setUpgradeToText(text);
                        }
                        // backHandler(e);
                        setPopupMaxWidth(null);
                        setOpenedPhotos(null);
                        setOpenedFolder(null);
                        setOpenedTitle(null);
                        setUpgradeToProOpened(true);
                    }} />
                </Popup> : null
            }
    

            { itemToDelete ?
                <Popup
                    modalDialogWidth="1000"
                    popupMaxWidth={'400'}
                    open={true}
                    onClose={()=>{ setItemToDelete(null) }}
                >
                    <div className="modal__content">
                        <div className="modal__header mb-[32px] text-center items-center">
                            <h4 className="font-[400] mb-[12px]">{fromStrings("Delete render?")}</h4>
                            <p className="color-secondary opacity-70 text-[14px]">{fromStrings("Do you really want to delete?")}</p>
                        </div>

                        <div className="modal__footer justify-center">
                            <a 
                                href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); setItemToDelete(null)}}
                                className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal=""
                            >
                                {fromStrings("Cancel")}
                            </a>
                            <a 
                                href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); deleteRender()}}
                                className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]"
                            >
                                {fromStrings("Ok")}
                            </a>
                        </div>
                    </div>
                </Popup> : null
            }

            { upgradeToProOpened ?
                <Popup
                    modalDialogWidth="460"
                    open={upgradeToProOpened}
                    onClose={()=>{
                        setUpgradeToProOpened(false)
                        backHandler();
                        setTimeout(() => {
                            setUpgradeToText(null);
                        }, 300);
                    }} 
                >
                    <NoRendersPopup
                        upgradeToText={upgradeToText}
                        close={()=>{
                            setUpgradeToProOpened(false);
                            backHandler();
                            setTimeout(() => {
                            setUpgradeToText(null);
                        }, 300);
                        }} 
                    ><div className="no-renders-popup-text text-[14px] color-secondary-07 no-renders-popup-text--margins pt-[50px]" dangerouslySetInnerHTML={{__html: fromStrings("To access this feature, please upgrade your <br /> account.")}}>
                    </div></NoRendersPopup>
                </Popup> : null
            }
        </div>
    )

    // if(!openedFolder){
    //     return (
    //         <div className="full-page-section__content">
    //             <div className="section-scroll-container">
    //                 {   
    //                     !inGeneratePage ?
    //                     <div className="section-scroll-heading">
    //                         <h4 className="flex-auto">My Renders</h4>
    //                         <div className="max-w-[245px] w-full">
    //                             {/* <select className="select">
    //                                 <option>All interiors</option>
    //                                 <option>Interiors 1</option>
    //                                 <option>Interiors 2</option>
    //                                 <option>Interiors 3</option>
    //                             </select> */}
    //                         </div>
    //                     </div> : null
    //                 }
    //                 {
    //                     (!rendersFetchLoading && !generationLoading) && !dataMyRenders?.length ?
    //                     <div className="empty-page">
    //                         <div className="features-item__icon">
    //                             <Icon src="img/feature-house.svg" alt=""/>
    //                             <div className="features-item__shape" style={getI(0)}></div>
    //                             <div className="features-item__shape" style={getI(1)}></div>
    //                             <div className="features-item__shape" style={getI(2)}></div>
    //                             <div className="features-item__shape" style={getI(3)}></div>
    //                             <div className="features-item__shape" style={getI(4)}></div>
    //                             <div className="features-item__shape" style={getI(5)}></div>
    //                         </div>
    //                         <h4 className="mb-[12px]">No results yet</h4>
    //                         <p className="text-[14px] color-secondary-07">Fill in the form on the left and generate your first interior.</p>
    //                     </div> : <>
    //                         <div className="row g-20 mb-[20px]">
    //                             {
    //                                 rendersFetchLoading ? <>
    //                                 <div className="col-sm-12">
    //                                     <a
    //                                         href="#" className="render-exzample-item skeleton"
    //                                         style={{ backgroundColor: "transparent" }}
    //                                     >
    //                                     </a>
    //                                 </div>
    //                                 </> : null 
    //                             }
    //                             {
    //                                 (generationLoading || (dataMyRenders.length && (dataMyRenders[0].status === "processing" || dataMyRenders[0].status === "starting"))) && !rendersFetchLoading ?
    //                                 <div className="col-xxl-3 col-lg-6 col-sm-6">
    //                                     <a
    //                                         href="#" className="render-exzample-item skeleton"
    //                                         style={{ backgroundColor: "#28282e" }}
    //                                     >
    //                                         <div className="overlay-content-block">
    //                                             <span className="rendering-label">
    //                                                 <span className="block">{ curentRenderProgress.toFixed(0) }%</span>
    //                                                 <span className="block">rendering...</span>
    //                                             </span>
    //                                             <span className="latest-render-name">New Render Interior</span>
    //                                         </div>
    //                                     </a>
    //                                 </div> : null
    //                             }
    //                             {!rendersFetchLoading ? (seeMore || !inGeneratePage ? dataMyRenders : [...dataMyRenders].slice(0, 20)).filter(itemF => itemF.status !== "processing" && itemF.status !== "starting").map((item, index)=>{
    //                                 return(
    //                                     <div className="col-xxl-3 col-lg-6 col-sm-6" key={index}>
    //                                         {/* <a href="#" className="render-exzample-item" onClick={(event)=>{openFolder(event, item)}}> */}
    //                                         <a href="#" className="render-exzample-item" onClick={(event)=>{openFolder(event, item); openPhotos(event, item, item.title); }}>
    //                                             {/* <Icon src={item.image} alt=""/> */}
    //                                             <img src={item.selected_image} alt="" />
    //                                             <div className="overlay-content-block">
    //                                                 {   
    //                                                     (item.status === "succeeded")
    //                                                     ?
    //                                                     <span className="latest-render-label">{item.total_renders} render(s)</span>
    //                                                     :
    //                                                     <span className="rendering-label">
    //                                                         {/* <span className="block"> {item.rendersCurrent} of {item.rendersMax}</span> */}
    //                                                         <span className="block">{ curentRenderProgress.toFixed(0) }%</span>
    //                                                         <span className="block">rendering...</span>
    //                                                     </span>
    //                                                 }
    //                                                 <span className="latest-render-name">{item.title}</span>
    //                                             </div>
    //                                         </a>
    //                                     </div>
    //                                 )
    //                         }) : null}
    //                         </div>
    //                         {
    //                             dataMyRenders?.length > 20 && inGeneratePage ?
    //                             <div className="d-flex justify-center">
    //                                 <a
    //                                     href="#" className="btn--render-js-1 btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[200px]"
    //                                     onClick={(e)=>{
    //                                         e.preventDefault();
    //                                         setSeeMore(prevState => !prevState);
    //                                     }}
    //                                 >See {seeMore ? 'less' : 'more'}</a>
    //                             </div> : null
    //                         }
    //                     </>
    //                 }
    //             </div>
    //         </div>
    //     )
    // }else if(openedFolder && !openedPhotos){
    //     return (
    //         <MyRendersFolder item={openedFolder} backHandler={backHandler} openPhotos={openPhotos} />
    //     )
    // }else if(openedPhotos){
    //     // return <MyRendersPhoto title={openedTitle} items={openedPhotos} backHandler={backHandler} />
    //     return <Popup
    //             modalDialogWidth="1000"
    //             open={true}
    //             onClose={backHandler as any}
    //         >
    //             <MyRendersPhoto title={openedTitle} items={openedPhotos} backHandler={backHandler} />
    //         </Popup>
    // }else {
    //     //for never type function return and let render in router
    //     throw Error
    // }
}

