import { configureStore } from '@reduxjs/toolkit'

import AuthSlice from './auth/AuthSlice'
import RenderInteriorSlice from './RenderInterior/RenderInteriorSlice'
import PagesDataSlice from './pagesData/PagesDataSlice'
import GenerationOptionsSlice from './GenerationOptions/GenerationOptionsSlice'
import ProfileSlice from './profile/ProfileSlice'
import GenerationSlice from './Generation/GenerationSlice'
import upscaleSlice from './upscale/upscaleSlice'


export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        renderInterior: RenderInteriorSlice,
        pagesData: PagesDataSlice,
        generationOptions: GenerationOptionsSlice,
        profile: ProfileSlice,
        generation: GenerationSlice,
        upscale: upscaleSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch