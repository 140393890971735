import React from 'react';
import {RouterProvider} from "react-router-dom";
import {router} from "./routes/routes";
import AppContextProvider from './context/AppContextProvider';

function App() {
	return (
		<div className="App">
			<AppContextProvider>
				<RouterProvider router={router} />
			</AppContextProvider>
		</div>
	);
}

export default App;