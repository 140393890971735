export const profileApi = (instance: any) => ({
	getProfile() {
		return instance.get('/api/profile/info');
	},
	updateProfile(payload: any) {
        let instanceLocal = instance;
        instanceLocal.interceptors.request.use(function(config: any) {
            config.headers["Content-Type"] = "multipart/form-data";
            return config;
        });

		return instanceLocal.post('/api/profile/update', payload);
	},
	updatePassword(payload: any) {
		return instance.post('/api/profile/update-password', payload);
	},
	profileFetchCards() {
		return instance.get('/api/profile/cards');
	},
	setDefaultCard(id:string) {
		return instance.post('/api/stripe/set-default-card', {card_id: +id});
	},
	subscribeToPlan(payload: any) {
		return instance.post('/api/stripe/subscribe', payload);
	},
	afterSubscribe(payload: any) {
		return instance.post('/api/stripe/after-subscribe', payload);
	},
	cancelSubscribtion() {
		return instance.post('/api/stripe/cancel-subscribtion');
	},
	cancelUpscaleSubscribtion() {
		return instance.post('/api/stripe/cancel-upscale-subscribtion');
	},
	deleteCard(id:string | number) {
		return instance.post(`/api/stripe/delete-card?id=${id}`);
	},
});