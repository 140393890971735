import { useEffect } from "react";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
import FaqItem from "../../components/UI/FaqItem"
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { fetchAboutUsPageData } from "../../store/pagesData/PagesDataThunk";
import { getAboutUsPageData, getSelectedLanguage, setCurrentPageData } from "../../store/pagesData/PagesDataSlice";

const FAQ = () => {
    const getI = (sec: number) => {
        return { '--i' : `${sec}s`} as React.CSSProperties
    };
    const aboutUsPageData = useAppSelector(getAboutUsPageData)
    const dispatch = useAppDispatch();

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fetchPageData = () => {
        dispatch(fetchAboutUsPageData((selectedLanguage || languageFromStorage || '') as string))
    }

    useEffect(()=>{
        fetchPageData();
    },[selectedLanguage])
    
    useEffect(()=>{
        dispatch(setCurrentPageData(aboutUsPageData));
    },[aboutUsPageData])

    let imageArray = [
        require("../../assets/img/aboun-image.png"),
        require("../../assets/img/aboun-image-2.png"),
        require("../../assets/img/aboun-image-3.png")
    ]
    // const AboutUs = useAppSelector()
    return (
        <>
            <Header currentPageData={aboutUsPageData} />

            <div className="wrapper header-fixed">
                <main className="content">
                    <div className="container container-1100">
                        <h1 className="section-title mt-[60px] mb-[24px]">{aboutUsPageData?.page.blocks[0].data.title.value}</h1>
                        <div className="section-text text-center">
                            <p>{aboutUsPageData?.page.blocks[0].data.text.value}</p>
                        </div>
                        {aboutUsPageData?.page.blocks[0].data.items.blocks.map((item, index)=>{
                            return (
                                <section className="section">
                                    <div className="row flex items-center gx-70 gy-30">
                                        {index%2===0 && <div className="col-lg-5">
                                            <h2 className="mb-[24px]">{item.title.value}</h2>
                                            <div className="section-text">
                                                <p>{item.text.value}</p>
                                            </div>
                                        </div>}
                                        <div className="col-lg-7 relative">
                                            <div className={`slider-images-shapes ${index%2===1 ? '--right-shapes' : ''}`}>
                                                <div className="slider-images-shapes__item" style={getI(0)}></div>
                                                <div className="slider-images-shapes__item" style={getI(1)}></div>
                                                <div className="slider-images-shapes__item" style={getI(2)}></div>
                                                <div className="slider-images-shapes__item" style={getI(3)}></div>
                                                <div className="slider-images-shapes__item" style={getI(4)}></div>
                                            </div>
                                            <div className="abount-image relative">
                                                <img src={imageArray[index%3]} alt="" />
                                            </div>
                                        </div>
                                        {index%2===1 && <div className="col-lg-5">
                                            <h2 className="mb-[24px]">{item.title.value}</h2>
                                            <div className="section-text">
                                                <p>{item.text.value}</p>
                                            </div>
                                        </div>}
                                    </div>
                                </section>
                            )
                        })  }
                        {/* <section className="section">
                            <div className="row flex items-center gx-70 gy-30">
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <div className="abount-image relative">
                                        <img src={require("../../assets/img/aboun-image.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">Innovative AI Powered Design</h2>
                                    <div className="section-text">
                                        <p>Our state-of-the-art AI interior generator is at the forefront of design technology, ensuring that you receive creative and unique interior concepts tailored to your preferences.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section">
                            <div className="row flex items-center gx-70 gy-30">
                                <div className="col-lg-5">
                                    <h2 className="mb-[24px]">Expertise <br /> and Support</h2>
                                    <div className="section-text">
                                        <p>Behind the scenes, our team of design and technology experts is dedicated to ensuring a seamless experience for you. We're here to answer your questions, provide guidance, and help you make the most of our platform.</p>
                                    </div>
                                </div>
                                <div className="col-lg-7 relative">
                                    <div className="slider-images-shapes --right-shapes">
                                        <div className="slider-images-shapes__item" style={getI(0)}></div>
                                        <div className="slider-images-shapes__item" style={getI(1)}></div>
                                        <div className="slider-images-shapes__item" style={getI(2)}></div>
                                        <div className="slider-images-shapes__item" style={getI(3)}></div>
                                        <div className="slider-images-shapes__item" style={getI(4)}></div>
                                    </div>
                                    <div className="abount-image relative">
                                        <img src={require("../../assets/img/aboun-image-2.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section mb-[60px]">
                                <div className="row flex items-center gx-70 gy-30">
                                    <div className="col-lg-7 relative">
                                        <div className="slider-images-shapes">
                                            <div className="slider-images-shapes__item" style={getI(0)}></div>
                                            <div className="slider-images-shapes__item" style={getI(1)}></div>
                                            <div className="slider-images-shapes__item" style={getI(2)}></div>
                                            <div className="slider-images-shapes__item" style={getI(3)}></div>
                                            <div className="slider-images-shapes__item" style={getI(4)}></div>
                                        </div>
                                        <div className="abount-image">
                                            <img src={require("../../assets/img/aboun-image-3.png")} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 relative">
                                        <h2 className="mb-[24px]">Mobile <br /> Application</h2>
                                        <div className="section-text">
                                            <p>Visualize real-world products in your designs with ease. Our platform seamlessly integrates with partner catalogs, allowing you to experiment with actual furniture and decor items.</p>
                                        </div>
                                    </div>
                                </div>
                        </section> */}
                    </div>
                </main>

                <SiteFooter currentPageData={aboutUsPageData} />
            </div>
        </>
    );
};

export default FAQ;