export const authApi = (instance: any) => ({
	register(registerDto: any) {
		let instanceLocal = instance;
		instanceLocal.interceptors.request.use(function (config:any) {
			config.headers["Authorization"] = "Bearer 1|BDZ1gG4SC10DBXuEF5ZkbAgHtIMNnWz8zekd7aRQ";
			return config;
		});

		return instanceLocal.post('/register', registerDto);
	},
	login(loginDto: any) {
		let instanceLocal = instance;
		instanceLocal.interceptors.request.use(function (config:any) {
			config.headers["Authorization"] = "Bearer 1|BDZ1gG4SC10DBXuEF5ZkbAgHtIMNnWz8zekd7aRQ";
			return config;
		});

		return instanceLocal.post('/login', loginDto);
	},
	forgotPassword(email: string){
		let instanceLocal = instance;
		instanceLocal.interceptors.request.use(function (config:any) {
			config.headers["Authorization"] = "Bearer 1|BDZ1gG4SC10DBXuEF5ZkbAgHtIMNnWz8zekd7aRQ";
			return config;
		});

		return instanceLocal.post('/forgot-password', { email });
	},
});