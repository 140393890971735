import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { fetchUserById } from './RenderInteriorThunk'

// Define a type for the slice state
interface IRenderInterior {
    value: number
}

// Define the initial state using that type
const initialState: IRenderInterior = {
    value: 0,
}

export const RenderInteriorSlice = createSlice({
    name: 'counter',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state, action: PayloadAction<number>) => {
            state.value += action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserById.fulfilled, (state, {payload}) => {
            
        });
    }
})

// actions
export const { increment, decrement, incrementByAmount } = RenderInteriorSlice.actions

// Other code such as selectors can use the imported `RootState` type
// getters
export const selectCount = (state: RootState) => state.renderInterior.value

export default RenderInteriorSlice.reducer