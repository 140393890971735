import React from 'react';
import {useForm} from "react-hook-form";
import {loginSchema} from "../../schemas/auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {PopupComponentType, LoginDataType} from "./Popup";
import { axiosConfig } from "../../api/config";
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

export const LoginPopup = ({setPopUp, onClose, setLogined, loading}: PopupComponentType) => {
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginDataType>({
        resolver: yupResolver(loginSchema)
    });

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const onSubmit = (data: LoginDataType) => {
        setLogined && setLogined(data);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="font-[400] mb-[12px]">{ fromStrings('Welcome to Design Sense') }</h4>
                <p className="color-secondary opacity-70">{ fromStrings('Fill in the form to login') }</p>
            </div>
            <div className="modal__body">
                <a href={`${axiosConfig.baseURL}/auth/social/google`} className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] w-full my-[32px]">
                <span className="icon mr-[8px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107"></path>
                        <path d="M3.153 7.3455L6.4385 9.755C7.3275 7.554 9.4805 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.159 2 4.828 4.1685 3.153 7.3455Z" fill="#FF3D00"></path>
                        <path d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.5718 17.5742 13.3037 18.001 12 18C9.399 18 7.1905 16.3415 6.3585 14.027L3.0975 16.5395C4.7525 19.778 8.1135 22 12 22Z" fill="#4CAF50"></path>
                        <path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2"></path>
                    </svg>
                </span>
                    { fromStrings('Login with Google') }
                </a>

                <div className="input-custom mb-[16px]">
                    <label className="input-label mb-[6px]">{fromStrings("Email")}</label>
                    <div className="input-custom__wrap-input">
                        <input {...register("email")} className={`input-custom__input ${errors.email?.message && 'error'}`} type="email" />
                    </div>
                </div>

                <div className="input-custom mb-[32px]">
                    <div className="flex items-center justify-between">
                        <label className="input-label mb-[6px]">{fromStrings("Password")}</label>
                        <a href="#" onClick={(e) => {e.preventDefault(); setPopUp && setPopUp({open: true, modal: 'password'})}} className="color-primary text-[14px] mb-[6px]">{fromStrings('Forgot password?')}</a>
                    </div>
                    <div className="input-custom__wrap-input">
                        <input {...register("password")} className={`input-custom__input ${errors.password?.message && 'error'}`} type="password" />
                    </div>
                </div>

                <div className="flex items-center text-[14px] mb-[32px]">
                    <span className="color-secondary">{fromStrings("Don’t have an account yet?")}</span>
                    <a href="#" onClick={(e) => {e.preventDefault(); setPopUp && setPopUp({open: true, modal: 'register'}) }} className="color-primary ml-[12px]">{fromStrings('Sign Up')}</a>
                </div>
            </div>
            <div className="modal__footer">
                <a onClick={(e) => {e.preventDefault(); onClose && onClose() }} href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                    {fromStrings('Cancel')}
                </a>
                <button type='submit' className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                    {loading ? <svg
                        version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "22px", margin: "auto" }}
                    >
                            <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                        </path>
                    </svg> : fromStrings("Login") }
                </button>
            </div>
        </form>
    )
}
