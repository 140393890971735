import {Swiper, SwiperSlide, SwiperRef, useSwiper} from "swiper/react";
import {useRef, useState, useEffect} from "react";

// ^9.4.1

import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

interface ISliderButtonsProps{
    nextActive:boolean,
    prevActive:boolean,
    setSwiperElem?:any
}
const SliderButtons = ({nextActive, prevActive, setSwiperElem}: ISliderButtonsProps) =>{
    const swiper = useSwiper();

    useEffect(()=>{
        if(swiper && setSwiperElem){
            setSwiperElem(swiper);
        }
    },[swiper])
    
    return (<>
        <div onClick={() => swiper.slideNext()} className={`swiper-button-next ${!nextActive && 'swiper-button-disabled'}`}></div>
        <div onClick={() => swiper.slidePrev()} className={`swiper-button-prev ${!prevActive && 'swiper-button-disabled'}`}></div>
    </>)
}

export const RenderSwiper = ({children, thumbItems,tumbsHide = false, getActiveSlide, bottomBtns, setSwiperElem}: {children: React.ReactNode[], thumbItems?: any, getActiveSlide?: (value: string) => void, tumbsHide? : boolean, bottomBtns? : any, setSwiperElem?: any}) => {
    const newsListSwiperRef = useRef<SwiperRef>(null);
    const [nextActive, setNextActive] = useState<boolean>(true)
    const [prevActive, setPrevActive] = useState<boolean>(false)
    const swipeChangeHandler = (swiper: SwiperRef['swiper']) => {
        let currentIndex:number = swiper.activeIndex 
        if(currentIndex===0){
            setPrevActive(false)
        }else{
            setPrevActive(true)
        }
        if(currentIndex===(children.length-1)){
            setNextActive(false)
        }else{
            setNextActive(true)
        }
    };
   
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    const emitActiveSlide = () => {
        setTimeout(() => {
            let findActiveImg = document.querySelector('.swiper.main-render-swiper .swiper-slide.swiper-slide-active img') as any;
            let findActiveVideo = document.querySelector('.swiper.main-render-swiper .swiper-slide.swiper-slide-active video') as any;
            

            if(getActiveSlide && findActiveImg?.src && !findActiveVideo){            
                getActiveSlide(findActiveImg.src);
            } else if (getActiveSlide && findActiveVideo){
                getActiveSlide('video');
            }
        }, 310);
    }

    return (
        // max-h-[546px]
        <div className={`swiper render-results-slider h-full ${tumbsHide ? 'hide-tumbs-slider': ''}`}>
            {
                thumbsSwiper?.thumbs && !thumbsSwiper.destroyed ? 
            
            <div className="relative d-flex items-center justify-center h-full">
                <Swiper
                    ref={newsListSwiperRef}
                    // onSlideChange={swipeChangeHandler}
                    loop={true}
                    navigation={true}
                    thumbs={{ swiper:    thumbsSwiper } } 
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="main-render-swiper h-full mb-[12px]"
                    onSlideChange={() => {
                        emitActiveSlide()
                    }}
                >
                    {children.map((el :any, idx: number) => <SwiperSlide className="h-full"  key={idx}>{el}</SwiperSlide>)}
                    <SliderButtons nextActive={nextActive} prevActive={prevActive} setSwiperElem={setSwiperElem}></SliderButtons>
                </Swiper>
                { bottomBtns ? bottomBtns : null }
            </div> : null }
           
            <Swiper
                onSwiper={setThumbsSwiper as any}
                loop={true}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="thumbs-render-swiper"
                breakpoints={{
                    319: {
                        slidesPerView: 0,
                    },
                    500: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                    991: {
                        slidesPerView: 5,
                    }
                }}
            >
                {(thumbItems ? thumbItems : children).map((el :any, idx: number) => <SwiperSlide key={idx} className={`thumb-${idx}`}>{el}</SwiperSlide>)}
            </Swiper>
            
        </div> 
    );
};