import React from 'react';
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

interface IProps {
    close: ()=>void,
    confirm: ()=>void,
    title?: string,
    subtitle?: string,
    cancelBtnText?: string,
    confirmBtnText?: string,
}
export const ConfirmPopup = ({close, confirm, title, subtitle, cancelBtnText, confirmBtnText}: IProps) => {
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    return (
        <>
            <div className="modal__content">
                <div className="modal__header mb-[32px] text-center items-center">
                    { title ? <h4 className="font-[400] mb-[12px]">{ title }</h4> : null }
                    { subtitle ? <p className="color-secondary opacity-70 text-[14px]">{ subtitle }</p> : null }
                    {/* Do you really want to delete this card */}
                </div>

                <div className="modal__footer justify-center">
                    <a href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); close()}} className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                        { fromStrings(cancelBtnText ? cancelBtnText : 'Cancel') }
                    </a>
                    <a href="#" onClick={(event:React.MouseEvent)=>{event.preventDefault(); confirm()}} className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                        { fromStrings(confirmBtnText ? confirmBtnText : 'Confirm') }
                    </a>
                </div>
            </div>
        </>
    )
}
