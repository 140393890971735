import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'
import { ReactComponent as IcoInfo } from "../../../assets/img/icons/ico-info.svg"
import { ReactComponent as IcoStars } from "../../../assets/img/icons/ico-stars.svg"
import { ReactComponent as IcoDelete } from "../../../assets/img/icons/ico-delete.svg"

import { Icon } from '../../UI/Icon'
import { getMaxWidth } from '../../../helpers/skeletonHelper'
import { AppContext } from '../../../context/AppContext'
import { Popup } from '../../Popups/Popup'
import { SelectinteriorPopup } from '../../Popups/SelectinteriorPopup'
import { SelectRenderStylePopup } from '../../Popups/SelectRenderStylePopup'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { fetchGenerationOptions } from '../../../store/GenerationOptions/GenerationOptionsThunk'
import { getGenerationOptions, getGenerationOptionsLoading } from '../../../store/GenerationOptions/GenerationOptionsSlice'
import { IInteriorOrExterior, IInteriorType, IPropertyType, IRenderStyle } from '../../../store/GenerationOptions/types'
import { fetchGeneration } from '../../../store/Generation/GenerationThunk'
import { IGenerationPayload } from '../../../store/Generation/types'
import { convertImageToBase64Src } from '../../../helpers/ImageFileReader'
import { getGenerationLoading, getIsNoRendersError, setLoadingModel, setGenerateEndTime, } from '../../../store/Generation/GenerationSlice'
import { setSkeletonActive } from '../../../helpers/skeletonHelper';
import { useLocation, useNavigate } from 'react-router-dom'
import { NoRendersPopup } from '../../Popups/NoRendersPopup'
import { IProfile, getProfile } from '../../../store/profile/ProfileSlice';
import { fetchProfile } from '../../../store/profile/ProfileThunk';
import { fetchMyRenders } from '../../../store/pagesData/PagesDataThunk';
// import { getMyRendersLoading } from '../../../store/pagesData/PagesDataSlice';
import { Api } from "../../../api/axios";
import { getMyRendersLoading, getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';


export const RenderInterior = ({ changeRenderVideo }: { changeRenderVideo: any }) => {
    let [tipsActive, setTipsActive] = useState<boolean>(false);
    let [noRenderPopupOpened, setNoRenderPopupOpened] = useState<boolean>(false);
    let [noRenderProPopupOpened, setNoRenderProPopupOpened] = useState<boolean>(false);
    let [selectInteriorPopup, setSelectInteriorPopup] = useState<IInteriorType[] | false>(false);
    let [upgradeToText, setUpgradeToText] = useState<string | null>(null);

    let [selectRenderStylePopupItems, setSelectRenderStylePopupItems] = useState<IRenderStyle[]>([]);
    let [selectRenderStylePopup, setSelectRenderStylePopup] = useState<IRenderStyle[] | false>(false);

    let profile: IProfile | null = useAppSelector(getProfile);

    let generationLoading = useAppSelector(getGenerationLoading)
    let generationOptionsLoading = useAppSelector(getGenerationOptionsLoading)
    let rendersLoading = useAppSelector(getMyRendersLoading);

    const [getRenderStylesLoading, setGetRenderStylesLoading] = useState<boolean>(false);

    let inputRef = useRef<HTMLInputElement>(null);

    const getRenderStyles = async (id: string | number) => {
        setGetRenderStylesLoading(true);

        let res = await Api().generationOptions.getSelectRenderStyle(id) as any;
        setSelectRenderStylePopupItems(res?.data?.items);

        setGetRenderStylesLoading(false);

        return res?.data?.items;
    }

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value: string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const selectInteriorPopupClose = () => {
        setSelectInteriorPopup(false)
    };
    const selectRenderStylePopupClose = () => {
        setSelectRenderStylePopup(false)
    };

    const selectInteriorPopupAction = async (item: IInteriorType) => {
        setModelData((prevState) => ({
            ...prevState,
            selectInterior: item
        }));

        let renderStylesItemsRes = await getRenderStyles(item.id);

        setModelData((prevState) => {
            return {
                ...prevState,
                selectInterior: item,
                selectRenderStyle: renderStylesItemsRes.map((item: any) => item.id).includes(prevState.selectRenderStyle?.id) ? prevState.selectRenderStyle : renderStylesItemsRes[0]
            }
        });
    }

    const selectInteriorPopupConfirm = (item: IInteriorType) => {
        selectInteriorPopupAction(item);

        selectInteriorPopupClose();
    }
    const selectRenderStylePopupConfirm = (item: IRenderStyle) => {
        setModelData({
            ...modelData,
            selectRenderStyle: item
        });
        selectRenderStylePopupClose();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        if (fileInput.files) {
            convertImageToBase64Src(fileInput.files, (base64Data) => {
                setModelData({ ...modelData, image: base64Data })
            });
        }

        if (profile?.can_render_image === false) {
            updateYourProfile();
        }
    }
    const [dragActive, setDragActive] = React.useState<boolean>(false);
    const handleDrag = function (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {

            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        const fileInput = inputRef?.current as any;
        fileInput.value = null;

        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let doc = document as any;
            if (doc.querySelector('#upload-or-drag')) {
                doc.querySelector('#upload-or-drag').files = e.dataTransfer.files;
            }

            convertImageToBase64Src(e.dataTransfer.files, (base64Data) => {
                setModelData({ ...modelData, image: base64Data })
            });

            if (profile?.can_render_image === false) {
                updateYourProfile();
            }
        }
    };

    let dispatch = useAppDispatch();

    let generationOptions = useAppSelector(getGenerationOptions)

    let isNoRendersError = useAppSelector(getIsNoRendersError);

    const { data, fetchData } = useContext(AppContext);
    interface IprivacyOption {
        id: string,
        title: string,
        isPremium?: boolean
    }
    interface IModelData {
        propertyType?: IPropertyType,
        interiorOrExterior?: IInteriorOrExterior,
        selectInterior?: IInteriorType,
        selectRenderStyle?: IRenderStyle,
        numberOfRenders?: number,
        privacy?: IprivacyOption,
        image?: string,
        quality?: string,
        renderer?: string,
        renderVideo?: boolean,
    }
    let privacyOptions: IprivacyOption[] = [
        {
            id: '1',
            title: 'Public',
        },
        {
            id: '2',
            title: 'Private (Pro)',
            isPremium: true
        }
    ];
    let numberRendersOptions = [
        {
            title: "1",
            value: 1
        },
        {
            title: "4 (pro)",
            value: 4,
            isPremium: true
        },
    ]
    let qualityOptions = [
        {
            title: "Low (fast)",
            value: "low"
        },
        {
            title: "HD (pro)",
            value: "hd",
            isPremium: true
        }
    ];

    const [modelData, setModelData] = useState<IModelData>({
        privacy: privacyOptions[0],
        numberOfRenders: 1,
        quality: 'low',
        renderer: 'replica',
        renderVideo: false,
    })

    const setPropertyType = async (item: IPropertyType, initSelect = false, onlyPropertyType = false) => {
        let renderStylesItemsRes = await getRenderStyles(item.interior_or_exterior[0].type[0].id);

        // if(onlyPropertyType){
        //     setModelData((prevState)=>({
        //         ...prevState, 
        //         propertyType: initSelect && localStorage.getItem('propertyType') ? JSON.parse(localStorage.getItem('propertyType') as any) : item
        //     }));
        // } else {
        setModelData((prevState) => ({
            ...prevState,
            propertyType: initSelect && localStorage.getItem('propertyType') ? JSON.parse(localStorage.getItem('propertyType') as any) : item,
            interiorOrExterior: initSelect && localStorage.getItem('interiorOrExterior') ? JSON.parse(localStorage.getItem('interiorOrExterior') as any) : item.interior_or_exterior[0],
            selectInterior: initSelect && localStorage.getItem('selectInterior') ? JSON.parse(localStorage.getItem('selectInterior') as any) : item.interior_or_exterior[0].type[0],
            selectRenderStyle: initSelect && localStorage.getItem('selectRenderStyle') ? JSON.parse(localStorage.getItem('selectRenderStyle') as any) : prevState.selectRenderStyle?.id === renderStylesItemsRes[0]?.id ? prevState.selectRenderStyle : renderStylesItemsRes[0]
        }));
        // }
    }



    let disableGenerationButton = useMemo(() => {
        if (
            modelData.propertyType
            && modelData.interiorOrExterior
            && modelData.selectInterior
            && modelData.selectRenderStyle
            && modelData.privacy
            && modelData.numberOfRenders
            && modelData.image
            && inputRef && inputRef?.current?.files && inputRef?.current?.files[0]
            // && (profile ? profile.can_render_image : true )
        ) {
            return false
        }
        return true
    }, [modelData, inputRef, profile])


    const [numberOfRendersFocus, setNumberOfRendersFocus] = useState(false);

    const toggleContent = () => {
        const body = document.getElementsByTagName('body')[0] as any;
        body.style.overflow = null;
        document.querySelector(".page-generator-have-content")?.classList.toggle('to-close');
    }
    const navigate = useNavigate();

    const setEndDate = () => {
        if (!modelData.numberOfRenders) return;

        let endTime = new Date();
        let all_seconds = null;

        if (+modelData?.numberOfRenders === 1 && modelData.quality === 'low') {
            endTime.setSeconds(endTime.getSeconds() + 20);
            all_seconds = 20;
        }
        if (+modelData.numberOfRenders === 1 && modelData.quality === 'hd') {
            endTime.setSeconds(endTime.getSeconds() + 30);
            all_seconds = 30;
        }
        if (+modelData.numberOfRenders === 4 && modelData.quality === 'low') {
            endTime.setSeconds(endTime.getSeconds() + 80);
            all_seconds = 80;
        }
        if (+modelData.numberOfRenders === 4 && modelData.quality === 'hd') {
            endTime.setSeconds(endTime.getSeconds() + 245);
            all_seconds = 245;
        }

        dispatch(setGenerateEndTime({
            all_seconds: all_seconds,
            date: String(endTime)
        }));
    }

    const fetchGenerationHandler = async () => {
        const formData = new FormData();
        const fileInput = inputRef?.current;
        if (disableGenerationButton) return;
        if (fileInput?.files && fileInput.files[0] && modelData.image && modelData.numberOfRenders && modelData.selectInterior) {
            const file = fileInput.files[0];
            formData.append("imageFile", file, file.name);
            let image = formData.get('imageFile') as FormDataEntryValue;

            await dispatch(setLoadingModel({
                image: modelData.image,
                numberOfRenders: modelData.numberOfRenders,
                interior: modelData.selectInterior
            }))

            let payload: IGenerationPayload = {
                "property_type": String(modelData.propertyType?.id), //то что выбрали в Select room type
                "interior_or_exterior": String(modelData.interiorOrExterior?.id), //то что выбрали в Interior or exterior
                "type": String(modelData.selectInterior?.id), // то что выбрали в Select interior
                "render_style": String(modelData.selectRenderStyle?.id), //то что выбрали в Select render style
                "number_of_renders": modelData.numberOfRenders || 1, // от 1 до 4
                "image": image, //файл изображения
                "is_public": modelData.privacy ? (modelData.privacy.id === '1' ? 1 : 0) : 1,
                "quality": modelData.quality,
                "use_q": 1,
                // "renderer": modelData.renderer
            }
            navigate('/render-interior');



            if (document.body && document.body.clientWidth < 991) {
                const body = document.getElementsByTagName('body')[0] as any;
                body.style.overflow = null;
                document.querySelector(".page-generator-have-content")?.classList.add('to-close');
            }

            let fetchGenerationRes = await dispatch(fetchGeneration(payload));

            if (fetchGenerationRes?.payload?.data?.render_engine === "stable") {
                setEndDate();
            }

            await dispatch(fetchMyRenders(1));

            await dispatch(fetchProfile());

            // dispatch(setGenerateEndTime({
            //     all_seconds: null,
            //     date: null
            // }));
        }
    }

    const updateYourProfile = () => {
        setNoRenderPopupOpened(true);
    }


    const deleteImage = (event?: React.MouseEvent<HTMLOrSVGElement>) => {
        if (event) { event.preventDefault(); }
        setModelData((prevState) => ({ ...prevState, image: undefined }))
    }

    const selectOptionHandler = (field: string, event: React.ChangeEvent<HTMLSelectElement>) => {
        if (field === 'privacy') {
            let findedOption = privacyOptions.find(item => item.id === event.target.value)
            if (findedOption?.isPremium && !profile?.is_premium) {
                event.target.value = privacyOptions[0].id
                setNoRenderProPopupOpened(true);
                return
            }
            setModelData({ ...modelData, privacy: findedOption })
        } else if (field === 'numberRenders') {
            let findedOption = numberRendersOptions.find(item => item.value === +event.target.value)
            if (findedOption?.isPremium && !profile?.is_premium) {
                event.target.value = numberRendersOptions[0].value + ""
                setNoRenderProPopupOpened(true);
                return
            }
            setModelData({ ...modelData, numberOfRenders: +event.target.value })
        } else if (field === 'quality') {
            let findedOption = qualityOptions.find(item => item.value === event.target.value)
            if (findedOption?.isPremium && !profile?.is_premium) {
                event.target.value = qualityOptions[0].value + ""
                setNoRenderProPopupOpened(true);
                return
            }
            setModelData({ ...modelData, quality: event.target.value })
        } else if (field === 'renderer') {
            setModelData({ ...modelData, renderer: event.target.value })
        }
    }

    const initFetch = async () => {
        let optionsResult = await dispatch(fetchGenerationOptions()).unwrap();

        // setModelData((prevState) => {
        //     return {
        //         ...prevState,
        //         propertyType: optionsResult.property_type[1],
        //         interiorOrExterior: optionsResult.property_type[1].interior_or_exterior[0]
        //     }
        // })

        dispatch(fetchProfile());
    }


    const selectRenderVideo = (value: any) => {
        setModelData({ ...modelData, renderVideo: value });

        if (!value) {
            localStorage.removeItem('renderVideo');
        }
    }

    useEffect(() => {
        initFetch();
    }, [])

    useEffect(() => {
        if (generationOptions) {
            setPropertyType(generationOptions?.property_type[1], true);
            // setModelData((prev)=>({...prev, selectRenderStyle:generationOptions?.render_styles[0]}));
        }
    }, [generationOptions])


    useEffect(() => {
        if (generationOptions && profile) {
            if (!!localStorage.getItem('renderVideo')) {
                selectRenderVideo(true);
            }
        }
    }, [generationOptions, profile])

    useEffect(() => {
        if (modelData?.selectInterior) {
            selectInteriorPopupAction(modelData.selectInterior);
        }
    }, [modelData.interiorOrExterior])

    useEffect(() => {
        if (isNoRendersError && !profile?.can_render_image) {
            setNoRenderPopupOpened(true)
        }
    }, [isNoRendersError])

    useEffect(() => {
        setSkeletonActive(Boolean(generationLoading || generationOptionsLoading || rendersLoading))
    }, [generationLoading, generationOptionsLoading, rendersLoading]);


    useEffect(() => {
        if (modelData?.propertyType) {
            localStorage.setItem('propertyType', JSON.stringify(modelData.propertyType));
        }
    }, [modelData.propertyType])

    useEffect(() => {
        if (modelData?.interiorOrExterior) {
            localStorage.setItem('interiorOrExterior', JSON.stringify(modelData.interiorOrExterior));
        }
    }, [modelData.interiorOrExterior])

    useEffect(() => {
        if (modelData?.selectInterior) {
            localStorage.setItem('selectInterior', JSON.stringify(modelData.selectInterior));
        }
    }, [modelData.selectInterior,])

    useEffect(() => {
        if (modelData?.selectRenderStyle) {
            localStorage.setItem('selectRenderStyle', JSON.stringify(modelData.selectRenderStyle));
        }
    }, [modelData.selectRenderStyle])



    {/* skeleton */ }
    if (generationOptionsLoading) {
        return (
            <>
                <div className="aside-body pl-[16px] pt-[16px]">
                    <div className="row">
                        <div className="col-12 mb-[16px]">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:190px;")}></div>
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:54px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-card-lg"></div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:120px;")}></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-[16px]">
                                    <div className="skeleton --skeleton-input"></div>
                                </div>
                                <div className="col-6 mb-[16px]">
                                    <div className="skeleton --skeleton-input"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:100px;")}></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-[16px]">
                                    <div className="skeleton --skeleton-input"></div>
                                </div>
                                <div className="col-6 mb-[16px]">
                                    <div className="skeleton --skeleton-input"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-[16px]">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:190px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-card-md"></div>
                        </div>
                        <div className="col-6 mb-[16px]">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:190px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-card-md"></div>
                        </div>

                        <div className="col-12 mb-[16px]">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:120px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-input"></div>
                        </div>
                        <div className="col-12 mb-[16px]">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:100px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-input"></div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-group--header">
                                    <div className="skeleton --skeleton-text" style={getMaxWidth("max-width:80px;")}></div>
                                </div>
                            </div>
                            <div className="skeleton --skeleton-input"></div>
                        </div>
                    </div>
                </div>
                <div className="aside-footer px-[16px]">
                    <a href="" className="btn w-full disabled-2 btn--outline-primary 
                        text-[14px] font-[600] btn--lg-2 rounded-[300px]">
                        <div className="loader"></div>
                    </a>
                </div>
            </>
        )
    }

    return (
        <>
            {(!tipsActive)
                //  skeleton-hide
                ?
                <>
                    <div className="aside-body pl-[16px] pt-[16px]">
                        <div className="row">
                            <div className="col-12 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Upload a photo of your room")}
                                        </label>
                                        <div className="d-flex align-items-center">
                                            <div className="tooltip">
                                                <a href="#" className="tooltip-btn btn" onClick={(event) => { event.preventDefault(); setTipsActive(true) }}>
                                                    <span className="font-[500]">{fromStrings("Tips")}</span>
                                                    <span className="ico ico-20 ml-[4px]">
                                                        <IcoInfo></IcoInfo>
                                                    </span>
                                                </a>
                                            </div>
                                            {modelData ? <a
                                                href="#"
                                                className="ml-[12px] section__content-sidebar-burger section__content-sidebar-burger-1"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    toggleContent();
                                                }}
                                            >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                                                    <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                                                </svg>
                                            </a> : null}
                                        </div>
                                    </div>
                                    <form onDragEnter={handleDrag}>
                                        <label htmlFor="upload-or-drag" className={`upload-wrap ${dragActive && 'upload-wrap--dragged'}`} onClick={(event: any) => {
                                            const fileInput = inputRef?.current as any;
                                            fileInput.value = null;
                                        }}>
                                            <input accept="image/*" type="file" ref={inputRef} id="upload-or-drag" name="photo" className="upload-wrap__input"
                                                onChange={handleFileChange}
                                            />
                                            {dragActive && <div
                                                id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                                                onDragOver={handleDrag}
                                                // onDrop={handleDrop}
                                                onDrop={(event: any) => {
                                                    handleDrop(event);
                                                }}
                                            ></div>}
                                            {modelData.image ?
                                                <img
                                                    src={modelData.image} className="mb-[24px]"
                                                    alt="" style={{ maxHeight: "166px", maxWidth: "246px", borderRadius: "10px", overflow: 'hidden' }}
                                                />
                                                : null}

                                            <span className="upload-wrap__label mb-[12px]">{modelData.image ? fromStrings('Change Photo') : fromStrings('Upload a file')}</span>
                                            {modelData.image && <IcoDelete onClick={deleteImage} className="upload-wrap__delete"></IcoDelete>}
                                            <span className="upload-wrap__label-text">{fromStrings("PNG, JPG, GIF up to 10MB")}</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Select room type")}
                                        </label>
                                    </div>
                                    <div className="row">
                                        {generationOptions && generationOptions.property_type.map(item => {
                                            return (
                                                <div className="col-6 mb-[16px]" key={item.id}>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio" className="custom-radio__input" name="room" id="r1"
                                                            checked={modelData.propertyType?.id === item.id}
                                                            onChange={(e) => { setPropertyType(item, false, true); }}
                                                        />
                                                        <span className="custom-radio__input-fake">{fromStrings(item.name)}</span>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Interior or exterior")}
                                        </label>
                                    </div>
                                    <div className="row">
                                        {modelData.propertyType && modelData.propertyType.interior_or_exterior.map(item => {
                                            return (
                                                <div className="col-6 mb-[16px]" key={item.id}>
                                                    <label className="custom-radio">
                                                        <input
                                                            type="radio" className="custom-radio__input" name="interior--exterior" id="interior"
                                                            checked={modelData.interiorOrExterior?.id === item.id}
                                                            onChange={(e) => { setModelData({ ...modelData, interiorOrExterior: item, selectInterior: item.type[0] }); }}
                                                        />
                                                        <span className="custom-radio__input-fake">{fromStrings(item.name)}</span>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Select interior")}
                                        </label>
                                    </div>
                                    {modelData.selectInterior && <a href="#" className="select-decor" onClick={(event) => { event.preventDefault(); modelData.interiorOrExterior && setSelectInteriorPopup(modelData.interiorOrExterior?.type) }}>
                                        {modelData.selectInterior
                                            ? <img
                                                src={modelData.selectInterior.image}
                                                alt="" style={{ height: "120px", width: "100%", borderRadius: "10px", overflow: 'hidden' }}
                                            />
                                            :
                                            <div className="my-[20px]">
                                                <Icon src="img/icons/icon-flat-variant-square.svg" alt="" />
                                            </div>
                                        }
                                        <span className="select-decor__type">{fromStrings(modelData.selectInterior.name)} <span className="dropdown-arrow"></span></span>
                                    </a>}
                                </div>
                            </div>
                            <div className="col-6 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Select render style")}
                                        </label>
                                    </div>
                                    <a href="#" className={`select-decor ${getRenderStylesLoading ? 'pointer-events-none' : ''}`} onClick={(event) => { event.preventDefault(); generationOptions && setSelectRenderStylePopup(generationOptions.render_styles) }}>
                                        {/* {
                                            getRenderStylesLoading ? <div className="select-card-loader">
                                                <svg
                                                    version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                    viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "30px" }}
                                                >
                                                        <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"  from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                    </path>
                                                </svg>
                                            </div> : null
                                        } */}
                                        {modelData.selectRenderStyle
                                            ? <img
                                                src={modelData.selectRenderStyle.image}
                                                alt="" style={{ height: "120px", width: "100%", borderRadius: "10px", overflow: 'hidden' }}
                                            />
                                            :
                                            <div className="my-[20px]">
                                                <Icon src="img/icons/icon-home-house-real-estate-setting.svg" alt="" />
                                            </div>
                                        }
                                        <span className="select-decor__type">{fromStrings(modelData.selectRenderStyle?.name as string)} <span className="dropdown-arrow"></span></span>
                                    </a>
                                </div>
                            </div>

                            {modelData.privacy && <div className="col-12 mb-[16px]">
                                <div className="d-flex items-start">
                                    <div className="form-group w-100 flex-1">
                                        <div className="form-group--header">
                                            <label className="input-label">
                                                {fromStrings("Number of renders")}
                                            </label>
                                        </div>
                                        <select className="select" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { selectOptionHandler('numberRenders', event) }}>
                                            {numberRendersOptions.map(item => {
                                                return <option selected={item.value === modelData.numberOfRenders} key={item.value} value={item.value}>{fromStrings(item.title)}</option>
                                                // disabled={item.isPremium && !profile?.is_premium}
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group ml-[14px]">
                                        <div className="form-group--header">
                                            <label className="input-label">
                                                {fromStrings("Render video")}
                                            </label>
                                        </div>
                                        <div className='py-[8px] d-flex justify-center'>
                                            <a
                                                href="#" className={`toggle-checkbox-button ${modelData.renderVideo ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    selectRenderVideo(!modelData.renderVideo);
                                                }}
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {modelData.privacy && <div className="col-12 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Select quality")}
                                        </label>
                                    </div>
                                    <select className="select" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { selectOptionHandler('quality', event) }}>
                                        {qualityOptions.map(item => {
                                            return <option selected={item.value === modelData.quality} key={item.value} value={item.value}>{fromStrings(item.title)}</option>
                                        })}
                                    </select>
                                </div>
                            </div>}

                            {modelData.privacy && <div className="col-12 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            {fromStrings("Privacy")}
                                        </label>
                                    </div>
                                    <select key={modelData.privacy?.id} className="select" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { selectOptionHandler("privacy", event) }}>
                                        {privacyOptions.map(item => {
                                            return <option selected={item.id === modelData.privacy?.id} key={item.id} value={item.id}>{fromStrings(item.title)}</option>
                                        })}
                                    </select>
                                </div>
                            </div>}

                            {/* {modelData.renderer && <div className="col-12 mb-[16px]">
                                <div className="form-group">
                                    <div className="form-group--header">
                                        <label className="input-label">
                                            Renderer
                                        </label>
                                    </div>
                                    <select key={modelData.renderer} className="select" onChange={(event:React.ChangeEvent<HTMLSelectElement>)=>{selectOptionHandler("renderer", event)}}>
                                        {[
                                            {
                                                id: 'replica',
                                                title: 'Replica',
                                            },
                                            {
                                                id: 'stable_diffusion',
                                                title: 'Stable Diffusion'
                                            }
                                        ].map(item=>{
                                            return <option selected={item.id === modelData.renderer} key={item.id} value={item.id}>{item.title}</option>
                                        })}
                                        
                                    </select>
                                </div>
                            </div>} */}
                        </div>
                    </div>
                    <div className="aside-footer px-[16px]">
                        <a
                            href="#" onClick={(event) => {
                                event.preventDefault();
                                if (profile?.can_render_image === true) {
                                    fetchGenerationHandler();
                                } else {
                                    updateYourProfile();
                                }
                            }}
                            className={`btn--render-js-1 btn w-full btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] ${(disableGenerationButton || Boolean(generationLoading || generationOptionsLoading)) && 'disabled'}`}>
                            <span className="ico ico-20 mr-[8px]">
                                <IcoStars />
                            </span>
                            <span>
                                {fromStrings("Render Interior")}
                            </span>
                        </a>
                        {/* <a href="#" onClick={(event)=>{event.preventDefault(); fetchData()}} className="btn--render-js-2 btn w-full btn--gradient-green 
                        text-[14px] font-[600] btn--lg-2 rounded-[300px]">
                            <span className="ico ico-20 mr-[8px]">
                                <IcoStars/>
                            </span>
                            <span>
                                Render Interior
                            </span>
                        </a> */}
                    </div>
                </>
                :
                // skeleton-hide
                <div className="aside-body pl-[16px] pt-[16px]">
                    <a href="#" onClick={(event) => { event.preventDefault(); setTipsActive(false) }} className="btn btn--secondary-2 font-[600] btn--md pl-[8px] rounded-[10px] mb-[20px] mr-[20px]">
                        <span className="icon mr-[10px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6665 6.66675L8.33317 10.0001L11.6665 13.3334" stroke="currentColor"
                                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        {fromStrings("Back")}
                    </a>

                    <h4 className="mb-[12px] font-[400]">{fromStrings("Upload your current interiour")}</h4>

                    <p className="text-[#9A9A9C] text-[14px] leading-[22px] mb-[30px]">
                        {fromStrings(`Take a photo of your current room. For best results make sure it shows the entire room in a 90° straight angle facing a wall or window horizontally. Not from a corner or angled, and not a wide angle photo as it's trained on regular photos.`)}
                        <br />
                        <span className="color-primary font-[600]">{fromStrings("The AI isn't great at angled pics (yet)!")}</span>
                    </p>
                    <div className="flex items-center mb-[16px]">
                        <div className="mr-[12px]">
                            <Icon src="img/icon-chacked-icon.svg" alt='' />
                        </div>
                        <h4 className="font-[400]">{fromStrings("Good photo examples")}</h4>
                    </div>
                    <div className="row gx-7 mb-[30px]">
                        <div className="col-6 mb-[14px]">
                            <div className="photo-example">
                                <Icon src="img/good-photo-1.png" alt='' />
                                <div className="checkbox-decor">
                                    <Icon src="img/icons/icon-done-check.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-[14px]">
                            <div className="photo-example">
                                <Icon src="img/good-photo-2.jpg" alt='' />
                                <div className="checkbox-decor">
                                    <Icon src="img/icons/icon-done-check.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="photo-example">
                                <Icon src="img/good-photo-3.jpg" alt='' />
                                <div className="checkbox-decor">
                                    <Icon src="img/icons/icon-done-check.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="photo-example">
                                <Icon src="img/good-photo-4.jpg" alt='' />
                                <div className="checkbox-decor">
                                    <Icon src="img/icons/icon-done-check.svg" alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center mb-[16px]">
                        <div className="mr-[12px]">
                            <Icon src="img/icon-bad-photo.svg" alt='' />
                        </div>
                        <h4 className="font-[400]">{fromStrings("Bad photo examples")}</h4>
                    </div>

                    <div className="row gx-7 mb-[30px]">
                        <div className="col-6 mb-[14px]">
                            <div className="photo-example" style={{ borderRadius: "10px", overflow: 'hidden' }}>
                                <Icon src="img/bad-photo-1.jpg" alt='' className="w-full" />
                                <div className="closed-decor">
                                    <Icon src="img/icons/icon-delete-decor.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mb-[14px]" style={{ borderRadius: "10px", overflow: 'hidden' }}>
                            <div className="photo-example">
                                <Icon src="img/bad-photo-2.jpg" alt='' className="w-full" />
                                <div className="closed-decor">
                                    <Icon src="img/icons/icon-delete-decor.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="photo-example" style={{ borderRadius: "10px", overflow: 'hidden' }}>
                                <Icon src="img/bad-photo-3.jpg" alt='' className="w-full" />
                                <div className="closed-decor">
                                    <Icon src="img/icons/icon-delete-decor.svg" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="photo-example" style={{ borderRadius: "10px", overflow: 'hidden' }}>
                                <Icon src="img/bad-photo-4.jpg" alt='' className="w-full" />
                                <div className="closed-decor">
                                    <Icon src="img/icons/icon-delete-decor.svg" alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="text-[#9A9A9C] text-[14px] leading-[22px] mb-[30px]" dangerouslySetInnerHTML={{ __html: fromStrings(`Uploads + renders are shown on app but auto <span className="color-primary font-[600]">deleted after 15 mins.</span> To make 100% private HQ renders without deletion and watermark upgrade to Pro and you get your own private workspace.`) }}></p>

                </div>
            }


            {selectInteriorPopup && <Popup
                modalDialogWidth="800"
                open={(!!selectInteriorPopup)}
                onClose={selectInteriorPopupClose}
            >
                <SelectinteriorPopup
                    close={selectInteriorPopupClose}
                    confirm={selectInteriorPopupConfirm}
                    items={selectInteriorPopup}
                    selectedItemFromSidebar={modelData.selectInterior}
                ></SelectinteriorPopup>
            </Popup>}

            {selectRenderStylePopup && <Popup
                modalDialogWidth="800"
                open={!!selectRenderStylePopup}
                onClose={selectRenderStylePopupClose}
            >
                <SelectRenderStylePopup
                    close={selectRenderStylePopupClose}
                    confirm={selectRenderStylePopupConfirm}
                    items={selectRenderStylePopupItems}
                    selectedItemFromSidebar={modelData.selectRenderStyle}
                ></SelectRenderStylePopup>
            </Popup>}

            {noRenderPopupOpened ? <Popup
                modalDialogWidth="460"
                open={noRenderPopupOpened}
                onClose={() => { setNoRenderPopupOpened(false) }}
            >
                <NoRendersPopup
                    close={() => { setNoRenderPopupOpened(false) }}
                ></NoRendersPopup>
            </Popup> : null}

            {noRenderProPopupOpened ? <Popup
                modalDialogWidth="460"
                open={noRenderProPopupOpened}
                onClose={() => {
                    setNoRenderProPopupOpened(false);
                    setTimeout(() => {
                        setUpgradeToText(null);
                    }, 300);
                }}
            >
                <NoRendersPopup
                    upgradeToText={upgradeToText}
                    close={() => {
                        setNoRenderProPopupOpened(false);
                        setTimeout(() => {
                            setUpgradeToText(null);
                        }, 300);
                    }}
                ><div className="no-renders-popup-text text-[14px] color-secondary-07 no-renders-popup-text--margins">{fromStrings("To access this feature, please upgrade your account.")}</div></NoRendersPopup>
            </Popup> : null}
        </>

    )
}
