import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    fetchAboutUsPageData, fetchFaqPageData,
    fetchHomePageData, fetchUpscaleLandingPageData, fetchLatestRenders,
    fetchMyRenders, fetchPlans, fetchUpscalerPlans,
    fetchPricingPageData, fetchPrivacyPageData,
    fetchTermsPageData, fetchRenderStatus,
    fetchRenderInteriorPageData, fetchLatestRendersPageData,
    fetchMyTeamPageData, fetchSettingsPageData,
} from "./PagesDataThunk";
import { RootState } from "../index";
import { IAboutUsPageData, IFaqPageData, IPricing, IPrivacyPageData, ITermsPageData } from "./PagesDataTypes";

type initialStateType = {
    latestRenders: [],
    myRenders: any[],
    myRendersLoading?: boolean,
    plans: [],
    upscalerPlans: [],
    homePageData?: any,
    upsaceLandingPageData?: any,
    pricingPageData?: IPricing,
    aboutUsPageData?: IAboutUsPageData,
    faqPageData?: IFaqPageData,
    termsPageData?: ITermsPageData,
    privacyPageData?: IPrivacyPageData,
    cardsContentCount?: number,
    renderInteriorPageData?: any,
    latestRendersPageData?: any,
    myTeamPageData?: any,
    settingsPageData?: any,
    languages?: any,
    selectedLanguage?: string | null,
    currentPageData?: any,
}

const initialState: initialStateType = {
    latestRenders: [],
    myRenders: [],
    myRendersLoading: false,
    plans: [],
    upscalerPlans: [],
    cardsContentCount: 1,
    languages: [],
    selectedLanguage: '',
    currentPageData: null,
}

export const pagesDataSlice = createSlice({
    name: 'pagesData',
    initialState,
    reducers: {
        setCardsContentCountAction: (state, action: any) => {
            state.cardsContentCount = +action.payload;
        },
        appendMyRenders: (state, action: any) => {
            if (!state.myRenders.map(item => item.id).includes(action.payload[0].id)) {
                state.myRenders = [...state.myRenders, ...action.payload]
            }
        },
        setNewPrediction: (state, action: any) => {
            let copiedRenders = JSON.parse(JSON.stringify(state.myRenders));

            if (copiedRenders.find((item: any) => item.id === action.payload.id)) {
                copiedRenders.find((item: any) => item.id === action.payload.id).all_renders[action.payload.index] = action.payload.uscale_img;
                copiedRenders.find((item: any) => item.id === action.payload.id).upscaled.push(String(action.payload.index));

                state.myRenders = copiedRenders;
            }
        },
        deleteRenderFromState: (state, action) => {
            state.myRenders = state.myRenders.filter((item: any) => +item.id !== +action.payload);
        },
        setRenderUpscaleLoading: (state, action) => {
            let copiedRenders = JSON.parse(JSON.stringify(state.myRenders));

            if (copiedRenders.find((item: any) => +item.id === +action.payload.render_id)) {
                let findedItem = copiedRenders.find((item: any) => +item.id === +action.payload.render_id);

                findedItem.upscale_in_progress = [...findedItem.upscale_in_progress, action.payload.upscale_index];

                if (action.payload?.upscaled) {
                    findedItem.upscaled = [...findedItem.upscaled, ...action.payload?.upscaled]
                }

                state.myRenders = copiedRenders;
            }
        },
        updateRender: (state, action: any) => {
            let copiedRenders = JSON.parse(JSON.stringify(state.myRenders));
            let findedRender = copiedRenders.find((item: any) => item.id === action.payload.id);
            if (findedRender) {
                findedRender[action.payload.field] = action.payload.value;

                state.myRenders = copiedRenders;
            }
        },
        setSelectedLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
        setCurrentPageData: (state, action) => {
            if (action.payload) {
                state.currentPageData = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLatestRenders.fulfilled, (state, action) => {
            state.latestRenders = action.payload.items;
        });
        builder.addCase(fetchLatestRenders.rejected, (state, action) => {
            state.latestRenders = [];
        });

        builder.addCase(fetchMyRenders.pending, (state, { payload }) => {
            state.myRendersLoading = true;
        });
        builder.addCase(fetchMyRenders.fulfilled, (state, action) => {
            state.myRenders = action.payload.items;
            state.myRendersLoading = false;
        });
        builder.addCase(fetchMyRenders.rejected, (state, action) => {
            state.myRenders = [];
            state.myRendersLoading = false;
        });

        builder.addCase(fetchPlans.fulfilled, (state, action) => {
            state.plans = action.payload.plans;
        });
        builder.addCase(fetchPlans.rejected, (state, action) => {
            state.plans = [];
        });
        builder.addCase(fetchUpscalerPlans.fulfilled, (state, action) => {
            state.upscalerPlans = action.payload.plans;
        });
        builder.addCase(fetchUpscalerPlans.rejected, (state, action) => {
            state.upscalerPlans = [];
        });
        builder.addCase(fetchHomePageData.fulfilled, (state, action) => {
            state.homePageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchHomePageData.rejected, (state, action) => {
            state.homePageData = undefined;
        });
        builder.addCase(fetchUpscaleLandingPageData.fulfilled, (state, action) => {
            state.upsaceLandingPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchUpscaleLandingPageData.rejected, (state, action) => {
            state.upsaceLandingPageData = undefined;
        });
        builder.addCase(fetchPricingPageData.fulfilled, (state, action) => {
            state.pricingPageData = action.payload;
            state.languages = action.payload.languages;
            // if(action.payload?.page?.title){
            //     let doc = document as any;
            //     if(action.payload?.page?.title){ 
            //         document.title = action.payload?.page?.title;
            //     }
            //     if(action.payload?.page?.meta_description){ 
            //         doc.querySelector('meta[name="description"]').setAttribute("content", action.payload?.page?.meta_description);
            //     }
            //     if(action.payload?.page?.meta_title){ 
            //         doc.querySelector('meta[name="title"]').setAttribute("content", action.payload?.page?.meta_title);
            //     }
            // }
        });
        builder.addCase(fetchPricingPageData.rejected, (state, action) => {
            state.pricingPageData = undefined;
        });

        builder.addCase(fetchAboutUsPageData.fulfilled, (state, action) => {
            state.aboutUsPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchAboutUsPageData.rejected, (state, action) => {
            state.aboutUsPageData = undefined;
        });
        builder.addCase(fetchFaqPageData.fulfilled, (state, action) => {
            state.faqPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchFaqPageData.rejected, (state, action) => {
            state.faqPageData = undefined;
        });
        builder.addCase(fetchTermsPageData.fulfilled, (state, action) => {
            state.termsPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchTermsPageData.rejected, (state, action) => {
            state.termsPageData = undefined;
        });
        builder.addCase(fetchPrivacyPageData.fulfilled, (state, action) => {
            state.privacyPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchPrivacyPageData.rejected, (state, action) => {
            state.privacyPageData = undefined;
        });

        builder.addCase(fetchRenderInteriorPageData.fulfilled, (state, action) => {
            state.renderInteriorPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchRenderInteriorPageData.rejected, (state, action) => {
            state.renderInteriorPageData = undefined;
        });
        builder.addCase(fetchLatestRendersPageData.fulfilled, (state, action) => {
            state.latestRendersPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchLatestRendersPageData.rejected, (state, action) => {
            state.latestRendersPageData = undefined;
        });
        builder.addCase(fetchMyTeamPageData.fulfilled, (state, action) => {
            state.myTeamPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchMyTeamPageData.rejected, (state, action) => {
            state.myTeamPageData = undefined;
        });
        builder.addCase(fetchSettingsPageData.fulfilled, (state, action) => {
            state.settingsPageData = action.payload;
            state.languages = action.payload.languages;
        });
        builder.addCase(fetchSettingsPageData.rejected, (state, action) => {
            state.settingsPageData = undefined;
        });

        builder.addCase(fetchRenderStatus.fulfilled, (state, { payload }: any) => {
            if (payload.success) {

                let copied = JSON.parse(JSON.stringify(state.myRenders)) as any;
                // let copied = [...state.myRenders] as any;

                if (!copied.length) return
                const exist = copied.find((el: any) => el.render_id === payload.render_id)

                if (!exist) {
                    const newItem = copied[0] as any
                    newItem.id = payload.render_id || payload.id;
                    newItem.status = payload.status;
                    newItem.percent = payload.percent;
                    newItem.original_image = payload.original_image;
                    if (payload.predictions?.length) {
                        newItem.all_renders = payload.predictions.map((item: any) => item.url);
                        newItem.selected_image = payload.predictions[0].url;
                        newItem.total_renders = payload.predictions.length;
                    }
                    copied.unshift({ ...payload, ...newItem })
                } else {

                    copied[0].status = payload.status;
                    copied[0].percent = payload.percent;
                    copied[0].original_image = payload.original_image;

                    if (payload.predictions?.length) {
                        copied[0].all_renders = payload.predictions.map((item: any) => item.url);
                        copied[0].selected_image = payload.predictions[0].url;
                        copied[0].total_renders = payload.predictions.length;
                    }

                    // if(payload.upscale_in_progress){                    
                    //     copied.find((item:any) => +item.id === +payload.render_id).upscale_in_progress = payload.upscale_in_progress;
                    // }
                }
                
                state.myRenders = copied;
            }
        });
    }
})

export const getLatestRenders = (state: RootState) => state.pagesData.latestRenders;
export const getMyRenders = (state: RootState) => state.pagesData.myRenders;
export const getMyRendersLoading = (state: RootState) => state.pagesData.myRendersLoading;
export const getPlans = (state: RootState) => state.pagesData.plans;
export const getUpscalerPlans = (state: RootState) => state.pagesData.upscalerPlans;
export const getHomePageData = (state: RootState) => state.pagesData.homePageData;
export const getUpsaceLandingPageData = (state: RootState) => state.pagesData.upsaceLandingPageData;
export const getPricingPageData = (state: RootState) => state.pagesData.pricingPageData;
export const getAboutUsPageData = (state: RootState) => state.pagesData.aboutUsPageData;
export const getFaqPageData = (state: RootState) => state.pagesData.faqPageData;
export const getTermsPageData = (state: RootState) => state.pagesData.termsPageData;
export const getPrivacyPageData = (state: RootState) => state.pagesData.privacyPageData;
export const getRenderInteriorPageData = (state: RootState) => state.pagesData.renderInteriorPageData;
export const getLatestRendersPageData = (state: RootState) => state.pagesData.latestRendersPageData;
export const getMyTeamPageData = (state: RootState) => state.pagesData.myTeamPageData;
export const getSettingsPageData = (state: RootState) => state.pagesData.settingsPageData;
export const getCardsContentCount = (state: RootState) => state.pagesData.cardsContentCount;
export const getLanguages = (state: RootState) => state.pagesData.languages;
export const getSelectedLanguage = (state: RootState) => state.pagesData.selectedLanguage;
export const getCurrentPageData = (state: RootState) => state.pagesData.currentPageData;

export const {
    setCardsContentCountAction,
    appendMyRenders,
    setNewPrediction,
    deleteRenderFromState,
    setRenderUpscaleLoading,
    updateRender,
    setSelectedLanguage,
    setCurrentPageData,
} = pagesDataSlice.actions;

export default pagesDataSlice.reducer;