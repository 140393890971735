import React, { useEffect, useState } from 'react'
import { Upscale } from './Upscale'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { getGenerationOptionsLoading } from '../../../store/GenerationOptions/GenerationOptionsSlice'
import { getActiveGenerationTab, setActiveGenerationTab } from '../../../store/Generation/GenerationSlice'
import { RenderInterior } from './RenderInterior'
import { useLocation } from 'react-router-dom'
import { FeatureType } from '../../../types'



export const SidebarWrapper = ({ hideSidebars, changeRenderVideo }: { hideSidebars: Boolean, changeRenderVideo?: any }) => {
    const dispatch = useAppDispatch();
    const { pathname, search } = useLocation()
    const activeGenerationTab = useAppSelector(getActiveGenerationTab)
    const [activeTab, setActiveTab] = useState<FeatureType>(activeGenerationTab)

    const [modelData, setModelData] = useState({
        privacy: null,
        numberOfRenders: 1,
        quality: 'low',
        renderer: 'replica'
    })
    const generationOptionsLoading = useAppSelector(getGenerationOptionsLoading)

    const toggleContent = (e: React.MouseEvent) => {
        e.preventDefault()
        const body = document.getElementsByTagName('body')[0] as any;
        body.style.overflow = null;
        document.querySelector(".page-generator-have-content")?.classList.toggle('to-close');
    }

    const changeTab = (tabValue: FeatureType) => {
        setActiveTab(tabValue);
        dispatch(setActiveGenerationTab(tabValue));
    }

    const handleTabChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeTab(e.target.value as FeatureType)
    }

    // useEffect(() => {
    //     changeTab(pathname === "/upscale" ? "upscale" : "render");
    // }, [pathname])

    useEffect(() => {
        if (search?.includes('upscale-image')) {
            changeTab("upscale");
        }
    }, [search])


    return (
        <aside
            className={`aside aside--controls-1 ${hideSidebars ? 'hidden lg:flex' : ''}`}
        >
            {modelData ? <a
                href="#"
                className="ml-[12px] section__content-sidebar-burger section__content-sidebar-burger-2"
                onClick={toggleContent}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                    <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                </svg>
            </a> : null}

            <div className="aside-container">
                <div className="aside-header pt-[16px]">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-6 mb-[16px]">
                                <label className="custom-radio">
                                    <input type="radio" className="custom-radio__input" onChange={handleTabChange} checked={activeTab === "render"} value={"render"} name="render" id="r1-1" />
                                    <span className="custom-radio__input-fake">Render Interior</span>
                                </label>
                            </div>
                            <div className="col-6 mb-[16px]">
                                <label className="custom-radio">
                                    <input type="radio" className="custom-radio__input" onChange={handleTabChange} checked={activeTab === "upscale"} value={"upscale"} name="render" id="r2-2" />
                                    <span className="custom-radio__input-fake">Upscale Image</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTab === "render" && <RenderInterior changeRenderVideo={changeRenderVideo} />}
                {activeTab === "upscale" && <Upscale />}
            </div>
        </aside>
    )
}
