import React, { useState, useEffect } from 'react'
import { RenderSwiper } from '../../../components/UI/RenderSwiper'
import { Icon } from '../../../components/UI/Icon'
import {saveAs} from "file-saver";

import { getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../../hooks/storeHooks';
import BeforeAfterImages from "../../../components/UI/BeforeAfterImages";

interface IProps{
    items: string[],
    backHandler: (event: React.MouseEvent) => void,
    selectedItem: any
}

export const LatestRendersPhoto = ({items, backHandler, selectedItem}:IProps) => {
    const [animCopy, setAnimCopy] = useState(false)
    const [portrait, setPortrait] = useState(false)
    const [activeSlideImg, setActiveSlideImg] = useState('')

    const [localItems, setLocalItems] = useState(items);

    const findedIndex = localItems.findIndex(item => item === activeSlideImg);
    const [swiperElem, setSwiperElem] = useState<any>(null);

    const [beforeAfterActive, setBeforeAfterActive] = useState<boolean>(false);
    const [beforeAfterImagesWidth, setBeforeAfterImagesWidth] = useState<number>(0);
    const [beforeAfterImagesHeight, setBeforeAfterImagesHeight] = useState<number>(0);

    const createCopyAnim = () => {
        setAnimCopy(true)
        setTimeout(() => {
            setAnimCopy(false)
        }, 1000)
    }

    const downloadFile = (url: string, name: string) => {
        saveAs(url, name);
    }

    const shareHandler = () => {
        if (navigator.share){
            navigator.share({
                title: "Render image share",
                text: "Rendered image",
                url: activeSlideImg
            }).then(function () {
                console.log("Shareing successfull")
            })
            .catch(function () {
                console.log("Sharing failed")
            })
        }
    }

    const getImgSize = () => {
        var newImg = new Image();
    
        newImg.onload = function() {
            var width = newImg.width;
            var height = newImg.height;
            
            setPortrait(+width < +height);
            // setPopupMaxWidth(width < 1000 ? 1000 : width);
        }
    
        newImg.src = items[0];
    }

    const copyToClipboard = (text?: string) => {
        var textarea = document.createElement('textarea');
        textarea.value = activeSlideImg || '';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
        } catch (err) {

        }
        document.body.removeChild(textarea);
    }

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const openBeforeAfterActive = () => {
        let findActiveImg = document.querySelector('.swiper.main-render-swiper .swiper-slide.swiper-slide-active img') as any;
        const clientRect = findActiveImg.getBoundingClientRect();
        setBeforeAfterImagesWidth(clientRect?.width);
        setBeforeAfterImagesHeight(clientRect?.height);

        setTimeout(() => {
            setBeforeAfterActive(true) 
        }, 10);
    }

    useEffect(()=>{
        getImgSize()
    },[])

    useEffect(() => {
        if(items.length && !activeSlideImg){
            setActiveSlideImg(items[0]);
        }
    }, [items]);

    useEffect(()=>{
        swiperElem?.slideTo(1);
    },[swiperElem])

    const portraitImgStyles = { 
        maxHeight: "calc(100vh - 370px)",
        minHeight: '300px',
        width: "initial",
        margin: "0 auto",
        display: "block",
        height: '100%',
    };

    return (
        <div className="full-page-section__content latest-renders-page"  style={{ maxHeight: 'calc(100vh - 113px)' }}>
            <div className="section-scroll-container">
                <div className="section-scroll-heading">
                    <a href="#" onClick={backHandler} className="btn btn--secondary-2 font-[600] btn--md pl-[8px] rounded-[10px] mr-[20px]">
                        <span className="icon mr-[10px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6665 6.66675L8.33317 10.0001L11.6665 13.3334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        {fromStrings("Back")}
                    </a>
                    <h4 className="flex-auto">{ selectedItem?.title}</h4>
                </div>
                <div className="fullpage-slider-content" onClick={(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    if(e.target.classList.contains('fullpage-slider-content')){
                        backHandler(e);
                    }
                }}>
                    <RenderSwiper
                        getActiveSlide={(value: string)=>{
                            setActiveSlideImg(value);
                        }}
                        bottomBtns={<div className="fullpage-actions">
                            {
                                beforeAfterActive && findedIndex !== 0 ? <>
                                <a
                                    href="#" 
                                    className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px] min-w-[66px]"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setBeforeAfterActive(false);
                                    }}
                                    style={{ padding: "10px 15px" }}
                                    key={'Back-before-after'}
                                >
                                    {fromStrings("Back")}
                                </a>
                            </> : <>
                            {/* onClick={(e) => {e.preventDefault(); downloadFile(items[1], 'After_image.png')}}
                            target='_blank' */}
                            <a
                                href={activeSlideImg} download={`${selectedItem?.title}.png`} className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px]"
                                key={activeSlideImg}
                                onClick={(e)=>{
                                    e.stopPropagation();
                                }}
                            >
                                {fromStrings("Download Render")}
                            </a>
                            <a
                                href="#" onClick={(event)=>{event.preventDefault(); createCopyAnim(); copyToClipboard(); shareHandler()}}
                                className={`btn btn--secondary-2 ${!animCopy ? '' : 'pointer-events-none animated'}  rounded-[10px] copy-btn`}
                            >
                                {!animCopy && <svg className='m-[9px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0012 8.99878H17.0021C18.1071 8.99878 19.0029 9.89458 19.0029 10.9996V19.0029C19.0029 20.108 18.1071 21.0038 17.0021 21.0038H6.9979C5.89287 21.0038 4.99707 20.108 4.99707 19.0029V10.9996C4.99707 9.89458 5.89287 8.99878 6.9979 8.99878H8.99874" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12.0002 15.0013V2.99634" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.0015 5.99759L12.0003 2.99634L8.99902 5.99759" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg> }
                                <svg id="icon1" className={`test-icon ${!animCopy && 'h-0 w-0'}`} width="42" height="42" viewBox="0 0 42 42" fill="none" stroke="white" 
                                    stroke-width="3" stroke-dasharray="100">
                                    <path className="icon-path" id="check" d="M 12,22 L 22,31 L 36,13"></path>
                                </svg>
                            </a>
                            { activeSlideImg !== 'video' && findedIndex !== 0 ? <a
                                href="#" className="btn btn--secondary-2 rounded-[10px] min-h-[44px] min-w-[44px]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    openBeforeAfterActive();
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.57141L12 21.4286" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M15.4286 13.5L16.7144 12L15.4286 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.57136 10.5L7.28564 12L8.57136 13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <rect x="3.51929" y="5.75" width="16.9615" height="12.5" rx="2.25" stroke="white" stroke-width="1.5"/>
                                </svg>
                            </a> : null }
                            { activeSlideImg === 'video' || findedIndex !== 0 ? <a 
                                href="#" className="btn btn--secondary-2 rounded-[10px] min-h-[44px] min-w-[44px]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    if(swiperElem) {
                                        swiperElem?.slideTo(0);
                                    }
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2.75" y="4.75" width="18.5" height="14.5" rx="2.25" stroke="white" stroke-width="1.5"/>
                                    <path d="M9.75 13.125L7.5 10.875L9.75 8.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 15.375C16.5 14.1815 16.0259 13.0369 15.182 12.193C14.3381 11.3491 13.1935 10.875 12 10.875H7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a> : null }
                            
                            </> }
                        </div>}
                        setSwiperElem={setSwiperElem}
                    >
                        {items.map((item, index)=>{
                            return (
                                <div 
                                    key={index}
                                    // style={{ backgroundColor: "#28282E" }}
                                    style={
                                        portrait || beforeAfterActive ? {
                                            height: '100%',
                                            backgroundColor: "#28282E"
                                        } : {
                                            backgroundColor: "#28282E"
                                        }
                                    }  
                                >
                                    { index !== 0 && beforeAfterActive ? 
                                        <>
                                            <div className='show-only-big-slider' style={
                                                    portrait ? { 
                                                        ...portraitImgStyles,
                                                        maxWidth: `${beforeAfterImagesWidth}px`,
                                                        height: `${beforeAfterImagesHeight}px`,
                                                    } : {
                                                        width: "initial",
                                                        margin: "0 auto",
                                                        display: "block",
                                                        height: '100%',
                                                    }
                                                }>
                                                <BeforeAfterImages
                                                    before={{ backgroundImage: `url(${item})`, backgroundSize: `${beforeAfterImagesWidth}px 100%` }}
                                                    after={{ backgroundImage: `url(${localItems[0]})`, backgroundSize: `${beforeAfterImagesWidth}px 100%` }}
                                                />
                                            </div>
                                            <img
                                                className={`show-only-thumb-slider`}
                                                src={item} alt=""
                                                style={
                                                    portrait ? portraitImgStyles : {}
                                                }    
                                            />
                                        </>
                                        : <>
                                            <img 
                                                src={item} alt=""
                                                style={ portrait ? { 
                                                    height: "100%",
                                                    maxHeight: "calc(100vh - 370px)",
                                                    minHeight: '300px',
                                                    width: "initial",
                                                    margin: "0 auto",
                                                    display: "block",
                                                } : {}}
                                            />
                                            <div className="slider-contetn-block">
                                                <span className="slider-label">{fromStrings(index === 0 ? 'Before' : 'After')}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </RenderSwiper>
                </div>
            </div>
        </div>
    )
}
