import React, { ReactNode, useRef } from 'react'
import { ReactComponent as IcoStars } from "../../../assets/img/icons/ico-stars.svg"
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { SwiperRender } from '../../../components/UI/SwiperRender';
import { Link } from 'react-router-dom';
interface IProps {
    title: string | null,
    items: string[],
    backHandler: (event: React.MouseEvent) => void,
    // openUpgradeToPro: (event: React.MouseEvent) => void,
    openUpgradeToPro: (text: string) => void,
    openedFolder?: any,
}

const MyRendersV2 = ({ title, items, backHandler, openedFolder, openUpgradeToPro }: IProps) => {
    return (
        <div className="full-page-section__content">
            <div className="section-scroll-container">
                <div className="section-scroll-heading">
                    <a href="#" onClick={backHandler} className="btn btn--secondary-2 font-[600] btn--md pl-[8px] rounded-[10px] mr-[20px]">
                        <span className="icon mr-[10px]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6665 6.66675L8.33317 10.0001L11.6665 13.3334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        Back
                    </a>
                    <h4 className="flex-auto">{title}</h4>
                </div>
                <div className="fullpage-slider-content">
                    <div className="swiper-wrapper max-w-max mx-auto relative">
                        <SwiperRender className='swiper render-results-slider h-full'>
                            <>
                                <img src={require("../../../assets/img/after-before-1-after.png")} alt="" />
                                <div className="slider-contetn-block">
                                    <span className="slider-label">After</span>
                                </div>

                            </>
                            <>
                                <img src={require("../../../assets/img/after-before-1.png")} alt="" />
                                <div className="slider-contetn-block">
                                    <span className="slider-label">After</span>
                                </div>
                            </>
                        </SwiperRender>
                        <div className="slider-actions-block z-30">
                            <a href="" className="btn btn--secondary-2 p-[9px] rounded-[10px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2.75" y="4.75" width="18.5" height="14.5" rx="2.25" stroke="white" stroke-width="1.5" />
                                    <path d="M9.75 13.125L7.5 10.875L9.75 8.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5 15.375C16.5 14.1815 16.0259 13.0369 15.182 12.193C14.3381 11.3491 13.1935 10.875 12 10.875H7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                            <a href="" className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] ml-auto">
                                <span className="ico ico-20 sm:mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5 8.75L8.75 6.25V11.25L12.5 8.75Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V13.125C2.5 13.4702 2.77982 13.75 3.125 13.75H16.875C17.2202 13.75 17.5 13.4702 17.5 13.125V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2.5 16.25H17.5" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span className="max-sm:hidden">
                                    Render Video
                                </span>
                            </a>
                            <Link to={"/upscale"} className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px]">
                                <span className="ico ico-20 sm:mr-[8px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99791 2.49683C4.99791 4.33854 3.50491 5.83155 1.66319 5.83155C3.50491 5.83155 4.99791 7.32455 4.99791 9.16627C4.99791 7.32455 6.49092 5.83155 8.33264 5.83155C6.49092 5.83155 4.99791 4.33854 4.99791 2.49683Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1684 12.5011C14.1684 10.199 12.3021 8.33271 10 8.33271C12.3021 8.33271 14.1684 6.46645 14.1684 4.16431C14.1684 6.46645 16.0347 8.33271 18.3368 8.33271C16.0347 8.33271 14.1684 10.199 14.1684 12.5011Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14.1684 12.501V13.3347" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14.1684 4.16425V3.33057" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.33264 17.5032V18.3369" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.33264 11.6674V10.8337" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4.99793 9.16626V9.99994" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4.99793 1.66333V2.49701" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33265 11.2505C8.33265 13.0922 6.83964 14.5852 4.99792 14.5852C6.83964 14.5852 8.33265 16.0782 8.33265 17.9199C8.33265 16.0782 9.82565 14.5852 11.6674 14.5852C9.82565 14.5852 8.33265 13.0922 8.33265 11.2505Z" stroke="#101014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span className="max-sm:hidden">
                                    Upscale
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="fullpage-actions">
                    <a href="" className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px]">
                        Download Render
                    </a>
                    <a href="" className="btn btn--secondary-2 p-[9px] rounded-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0012 8.99878H17.0021C18.1071 8.99878 19.0029 9.89458 19.0029 10.9996V19.0029C19.0029 20.108 18.1071 21.0038 17.0021 21.0038H6.9979C5.89287 21.0038 4.99707 20.108 4.99707 19.0029V10.9996C4.99707 9.89458 5.89287 8.99878 6.9979 8.99878H8.99874" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12.0002 15.0013V2.99634" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M15.0015 5.99759L12.0003 2.99634L8.99902 5.99759" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </a>
                    <a href="" className="btn btn--secondary-2 p-[9px] rounded-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2.57153L12 21.4287" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                            <path d="M15.4286 13.5L16.7143 12L15.4286 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.57142 10.5L7.28571 12L8.57142 13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <rect x="3.51923" y="5.75" width="16.9615" height="12.5" rx="2.25" stroke="white" stroke-width="1.5" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MyRendersV2