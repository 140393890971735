import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from "../../api/axios";
import { IGenerationDataResponse, IGenerationDataResponseError, IGenerationPayload } from "./types";

  
export const fetchGeneration = createAsyncThunk(
    'generation/fetchGeneration',
    async (payload:IGenerationPayload , {rejectWithValue, dispatch}) => {
        try{
            const res = await Api().generation.generation(payload);

            console.log('res.data:', res.data);
            
            
            if(!res.data.success){
                return res.data
            }
            if(res.data?.predictions?.length>1){
                let res1 = await Api().generation.selectPrediction(res.data.render_id, res.data.predictions[0].id);
            }
        
            // return res.data as IGenerationDataResponse
            return res.data as any

        }catch(error){
            console.log('error:', error);
            return rejectWithValue({rejectedError: 'error'})
        }
    }
)