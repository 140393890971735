import React, { useEffect, useRef, useState } from 'react';

type PopupType = {
    children: React.ReactElement,
    modalDialogWidth?: string,
    open: boolean,
    popupMaxWidth?: any,
    onClose: () => void,
    modalContentClassName?: string

}

export type LoginDataType = {
    email: string,
    password: string,
}

export type RegisterDataType = {
    email: string,
    password: string,
    password_confirmation: string,
    name: string,
    privacy_policy: number,
    invite_hash?: string,
}

export type ForgotPasswordDataType = {
    email: string,
    password: string,
    password_confirmation: string
}

export type PopupComponentType = {
    onClose?: () => void,
    isFree?: boolean,
    loading?: boolean,
    setLogined?: (data: LoginDataType) => void,
    setRegister?: (data: RegisterDataType) => void,
    forgotSubmit?: (data: string) => void,
    resetPasswordSubmit?: (data: ForgotPasswordDataType) => void,
    setPopUp?: ({ open, modal }: { open: boolean, modal: string }) => void,
}

export const Popup: React.FC<PopupType> = ({ open, modalDialogWidth, children, onClose, popupMaxWidth = false, modalContentClassName = "" }): React.ReactElement => {

    const [isOpen, setIsOpen] = useState(false)

    const close = () => {
        setIsOpen(false)
        onClose()
    }

    useEffect(() => {
        if (open) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [open])

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]
        if (isOpen) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = null as any
        }
    }, [isOpen])

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0] as any;
        return () => {
            body.style.overflow = null;
        }
    }, [])

    return (
        <div className={`modal modal-1 ${isOpen && 'modal--show'}`}>
            <div className='modal__back-sub' onClick={close}></div>
            {popupMaxWidth !== null ? <div className={`modal__dialog modal__dialog--${popupMaxWidth ? '' : modalDialogWidth ? modalDialogWidth : '500'}`} style={popupMaxWidth ? { maxWidth: `${popupMaxWidth}px` } : {}}>
                <div className={`modal__content ${modalContentClassName}`}>
                    {children}
                </div>
            </div> : <svg
                version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{ width: "50px", position: 'absolute', left: '50%', top: '50%', transform: "translate(-50%, -50%)" }}
            >
                <path fill="#FFFFFF" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                </path>
            </svg>}
        </div>
    )
}
