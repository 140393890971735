import React, { useState } from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {emailSchema} from "../../schemas/auth";
import {PopupComponentType} from "./Popup";
import { getCurrentPageData } from '../../store/pagesData/PagesDataSlice';
import { useAppSelector } from '../../hooks/storeHooks';

export const ForgotPasswordPopup = ({setPopUp, onClose, forgotSubmit}: PopupComponentType) => {
    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{email: string}>({
        resolver: yupResolver(emailSchema)
    });

    const fromStrings = (value:string) => {
        return currentPageDataSlice?.strings?.[value] || value;
    }

    const [submited, setSubmited] = useState(false);

    const onSubmit = (data: { email: string }) => {
        // onClose && onClose()
        forgotSubmit && forgotSubmit(data.email);
        setSubmited(true);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__content">
                <div className="modal__header">
                    <h4 className="font-[400] mb-[12px]">{fromStrings('Forgot Password')}</h4>
                    { !submited ? <p className="color-secondary opacity-70">{fromStrings('Fill in email to send link')}</p> : null }
                </div>
                <div className="modal__body">
                    {
                        submited ?
                        <p className="color-secondary opacity-70 my-[32px]">{fromStrings('We have emailed your password reset link!')}</p> :
                        <div className="input-custom my-[32px]">
                            <label className="input-label mb-[6px]">{fromStrings('Email')}</label>
                            <div className="input-custom__wrap-input">
                                <input {...register("email")} className={`input-custom__input ${errors.email?.message && 'error'}`} type="email" />
                            </div>
                        </div>
                    }
                </div>
                <div className="modal__footer">
                    <a onClick={(e) => {e.preventDefault(); onClose && onClose()}} href="#" className="btn btn--secondary-2 text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px] mr-[10px]" data-close-modal="">
                        {fromStrings('Cancel')}
                    </a>
                    { !submited ? <button type='submit' className="btn btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px] min-w-[110px]">
                        {fromStrings('Send Link')}
                    </button> : null }
                </div>
            </div>
        </form>
    )
}