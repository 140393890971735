import React, { ReactNode, useState } from 'react'
import { Sidebar } from '../../components/Parts/Sidebar/Sidebar'
import { Header } from '../../components/Parts/Header/Header'
import { SidebarWrapper } from '../../components/Parts/Sidebar/SidebarWrapper'

interface AppLayoutProps {
    children: ReactNode,
    hideSidebars: Boolean,
}

export const AppLayout:React.FC<AppLayoutProps> = ({children, hideSidebars}) => {
    const [renderVideo, changeRenderVideo] = useState(false); 
    const [renderVideoClose, setRenderVideoClose] = useState(false);

    return (
        <div>
            <Header initLogined={true} />
            <div className="wrapper header-fixed">
                <main className="content">
                    { renderVideo && !renderVideoClose ? 
                        <div className="top-page-notification py-[11px] px-[30px] d-flex items-center justify-between bg-[#14141A] mt-[16px]">
                            <div>
                                <h3 className="text-[13px] text-[#9EE22E] font-[500] mb-[3px]">Render Video</h3>
                                <p className="text-[12px] text-[#FFFFFF] font-[400] opacity-40">Currently, video rendering is limited to processing only 1 image at a time with a high-quality setting. We have adjusted the setting for you.</p>
                            </div>
                            <a
                                href="#" className='rounded-full bg-[#28282E] min-w-[36px] min-h-[36px] d-flex items-center justify-center hover:opacity-70'
                                onClick={(e) => {
                                    setRenderVideoClose(true);
                                    changeRenderVideo(false);
                                }}
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_42_1216)">
                                        <path d="M14.0625 3.9375L3.9375 14.0625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.0625 14.0625L3.9375 3.9375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_42_1216">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    : null }

                    <div className="container container-full">
                        <section className="full-page-section page-generator-have-content">
                            {/* <Sidebar hideSidebars={hideSidebars} changeRenderVideo={changeRenderVideo}></Sidebar> */}
                            <SidebarWrapper hideSidebars={hideSidebars} changeRenderVideo={changeRenderVideo} />
                            {children}
                        </section>
                    </div>
                </main>
            </div>
        </div>
    )
}
