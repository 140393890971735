export const pagesDataApi = (instance: any) => ({
	latestRenders() {
		return instance.get('/api/ai/latest-renders');
	},
	myRenders(page = 1 as number) {
		return instance.get(`/api/ai/my-renders?page=${page}`);
	},
	deleteRender(id: string|number|null){
		return instance.get(`/api/ai/delete/${id}`);
	},
	fetchPlans(language = '') {
		return instance.get(`/api/ai/plans${language ? `?language=${language}` : ''}`);
	},
	fetchUpscalerPlans(language = '') {
		return instance.get(`/api/ai/plans-upscaler${language ? `?language=${language}` : ''}`);
	},

	fetchHomePageData(language = '') {
		return instance.get(`/api/ai/pages/home${language ? `?language=${language}` : ''}`);
	},
	fetchUpscaleLandingPageData(language = '') {
		return instance.get(`/api/ai/pages/upscale-landing${language ? `?language=${language}` : ''}`);
	},
	fetchPricingPageData(language = '') {
		return instance.get(`/api/ai/pages/pricing${language ? `?language=${language}` : ''}`);
	},
	fetchAboutUsPageData(language = ''){
		return instance.get(`/api/ai/pages/about-us${language ? `?language=${language}` : ''}`);
	},
	fetchFaqPageData(language = ''){
		return instance.get(`/api/ai/pages/faq${language ? `?language=${language}` : ''}`);
	},
	fetchTermsPageData(language = ''){
		return instance.get(`/api/ai/pages/terms${language ? `?language=${language}` : ''}`);
	},
	fetchPrivacyPageData(language = ''){
		return instance.get(`/api/ai/pages/privacy${language ? `?language=${language}` : ''}`);
	},

	fetchRenderInteriorPageData(language = ''){
		return instance.get(`/api/ai/pages/render-interior${language ? `?language=${language}` : ''}`);
	},
	fetchLatestRendersPageData(language = ''){
		return instance.get(`/api/ai/pages/latest-renders${language ? `?language=${language}` : ''}`);
	},
	fetchMyTeamPageData(language = ''){
		return instance.get(`/api/ai/pages/my-team${language ? `?language=${language}` : ''}`);
	},
	fetchSettingsPageData(language = ''){
		return instance.get(`/api/ai/pages/settings${language ? `?language=${language}` : ''}`);
	},
});