import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api } from '../../api/axios';

export const fetchLatestRenders = createAsyncThunk(
    '/api/ai/latest-renders',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.latestRenders();

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchMyRenders = createAsyncThunk(
    '/api/ai/my-renders',
    async (page:number, { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.myRenders(page || 1);

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPlans = createAsyncThunk(
    '/api/ai/plans',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchPlans(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpscalerPlans = createAsyncThunk(
    '/api/ai/plans-upscaler',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchUpscalerPlans(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchHomePageData = createAsyncThunk(
    '/api/ai/pages/home',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchHomePageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpscaleLandingPageData = createAsyncThunk(
    '/api/ai/pages/upscale-landing',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchUpscaleLandingPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPricingPageData = createAsyncThunk(
    '/api/ai/pages/pricing',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchPricingPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchAboutUsPageData = createAsyncThunk(
    '/api/ai/pages/about-us',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchAboutUsPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchFaqPageData = createAsyncThunk(
    '/api/ai/pages/faq',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchFaqPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchTermsPageData = createAsyncThunk(
    '/api/ai/pages/terms',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchTermsPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchPrivacyPageData = createAsyncThunk(
    '/api/ai/pages/privacy',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchPrivacyPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchRenderInteriorPageData = createAsyncThunk(
    '/api/ai/pages/render-interior',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchRenderInteriorPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchLatestRendersPageData = createAsyncThunk(
    '/api/ai/pages/latest-renders',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchLatestRendersPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchMyTeamPageData = createAsyncThunk(
    '/api/ai/pages/my-team',
    async (language:string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchMyTeamPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);
export const fetchSettingsPageData = createAsyncThunk(
    '/api/ai/pages/settings',
    async (language: string = '', { rejectWithValue }) => {
        try {
            const res = await Api().pagesData.fetchSettingsPageData(language || '');

            return res.data;
        } catch (err: any) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchRenderStatus = createAsyncThunk(
    'generation/fetchRenderStatus',
    async (id:number|string, {rejectWithValue, dispatch}) => {
        try{
            let res = await Api().generation.getRenderStatus(id);
        
            return res.data as any
        }catch(error){
            return rejectWithValue({rejectedError: 'error'})
        }
    }
)