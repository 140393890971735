import { useEffect, useState } from "react";
import { Header } from "../../components/Parts/Header/Header";
import SiteFooter from "../../components/Parts/SiteFooter/SiteFooter";
import FaqItem from "../../components/UI/FaqItem"
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { getFaqPageData, getSelectedLanguage, setCurrentPageData } from "../../store/pagesData/PagesDataSlice";
import { fetchFaqPageData } from "../../store/pagesData/PagesDataThunk";
import MetaTags from 'react-meta-tags';

const FAQ = () => {
    const [loading, setLoading] = useState(false);

    const faqPageData = useAppSelector(getFaqPageData)
    const dispatch = useAppDispatch();

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const fetchPage = async() => {
        setLoading(true);
        await dispatch(fetchFaqPageData((selectedLanguage || languageFromStorage || '') as string))
        setLoading(false);
    }
    
    useEffect(()=>{
        fetchPage();
    },[selectedLanguage]);

    useEffect(() => {
		dispatch(setCurrentPageData(faqPageData));
	}, [faqPageData])

    return (
        <>
            <MetaTags>
                <title>{ faqPageData?.page?.meta_title || "Design Sense AI" }</title>
                <meta name="description" content={ faqPageData?.page?.meta_description || "" } />
            </MetaTags>

            <Header currentPageData={faqPageData} />

            <div className="wrapper header-fixed">
                <main className="content">
                    <section className="section">
                        {
                            loading ? 
                            <div className="container container-1100">
                                <h2 className="mb-[24px] text-center"><span className="skeleton" style={{ color: 'transparent' }}>Loading...</span></h2>
                                <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]"><span className="skeleton" style={{ color: 'transparent' }}>Loading...</span></p>

                                <div className="faq-wrapper">
                                    {new Array(6).fill('').map((item, index)=>{
                                        return (
                                            <div className="skeleton mb-[8px]" style={{ height: "56px" }} key={index}></div>
                                        )
                                    })}
                                </div>
                            </div> :
                            <div className="container container-1100">
                                <h2 className="mb-[24px] text-center">{faqPageData?.page.blocks[0].data.title.value}</h2>
                                <p className="text-[18px] text-center color-secondary opacity-70 mb-[60px]">{faqPageData?.page.blocks[0].data.text.value}</p>

                                <div className="faq-wrapper">
                                    {faqPageData?.faqs.map((item, index)=>{
                                        return (
                                            <FaqItem
                                                initOpen={index===0}
                                                title={item.question}
                                                content={item.answer}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </section>
                </main>

                <SiteFooter currentPageData={faqPageData} />
            </div>
        </>
    );
};

export default FAQ;