import React from 'react';
import ReactDOM from 'react-dom/client';
import 'nouislider/distribute/nouislider.css';
import 'react-tooltip/dist/react-tooltip.css'

import "./assets/css/plagins/swiper-bundle.min.css";
import "./assets/css/bootstrap-grid.css";
import "./assets/fonts/stylesheet.css";
import "./assets/css/reset.css";
import "./assets/css/global.css";
import "./assets/css/components/components.css";
import "./assets/css/main-alpha.css";
import "./assets/css/main-bravo.css";
import "./assets/css/main-charlie.css";
import "./assets/css/main-delta.css";
import "./assets/css/media-alpha.css";
import "./assets/css/media-bravo.css";
import "./assets/css/media-charlie.css";
import "./assets/css/media-delta.css";
import 'react-notifications/lib/notifications.css';
import "./assets/css/main-bravo-25-04-24.css";
import "./assets/css/main-charlie-25-04-24.css";
import "./assets/css/main-delta-25-04-24.css";

/* @ts-ignore */
import "./index.css"
import {store} from './store'
import { Provider } from 'react-redux'

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
	// <React.StrictMode>
	// </React.StrictMode>
	<Provider store={store}>
		<App />
	</Provider>
);
