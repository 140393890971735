import React, { useState, useEffect } from 'react'
import { Icon } from '../../UI/Icon'
import { AppRoutes } from '../../../routes/routes'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { Popup, LoginDataType, RegisterDataType, ForgotPasswordDataType } from "../../Popups/Popup";
import { LoginPopup } from "../../Popups/LoginPopup";
import { SignUpPopup } from "../../Popups/SignUpPopup";
import { ForgotPasswordPopup } from "../../Popups/ForgotPasswordPopup";
import { ResetPasswordPopup } from "../../Popups/ResetPasswordPopup";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { login, register, forgotPassword } from '../../../store/auth/AuthThunk';
import { getAuthToken, clearToken } from '../../../store/auth/AuthSlice';
import { getProfile, getProfileLoading } from '../../../store/profile/ProfileSlice';
import { fetchProfile } from '../../../store/profile/ProfileThunk';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { HeaderDropdown } from "./HeaderDropdown";

import { LanguageSelect } from "./LanguageSelect";
// test
import { getCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { getActiveGenerationTab } from '../../../store/Generation/GenerationSlice';

type PropsType = {
    setLoginModal?: any,
    initLogined?: Boolean,
    onSuccessLogin?: () => void,
    currentPageData?: any,
    loginRedirectUrl?: string | null,
}

export const Header: React.FC<PropsType> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeGenerationTab = useAppSelector(getActiveGenerationTab)

    const { lang } = useParams();
    const lang_prefix = lang ? `/${lang}` : '';

    const authToken = useAppSelector(getAuthToken);
    const profile = useAppSelector(getProfile);
    const profileLoading = useAppSelector(getProfileLoading);

    const currentPageDataSlice = useAppSelector(getCurrentPageData);

    const logined = localStorage.getItem('token') || authToken;

    const [loginLoading, setLoginLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [mobNavOpen, setMobNavOpen] = useState(false);
    // const [logined, setLogined] = useState(initLogined);

    const isPremium = profile && activeGenerationTab === "upscale" ? profile?.upscale_plan : profile?.is_premium

    const [popUp, setPopUp] = useState({ open: false, modal: '' })


    const closeModal = () => {
        setPopUp({ open: false, modal: '' })
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    }

    const fromStrings = (value: string) => {
        return (props?.currentPageData || currentPageDataSlice)?.strings?.[value] || value;
    }

    const setLoginedData = async (data: LoginDataType) => {
        setLoginLoading(true);
        let result: any = await dispatch(login(data));
        setLoginLoading(false);

        if (result?.payload?.success !== false) {
            closeModal();
            props.onSuccessLogin && props.onSuccessLogin();
            navigate(props.loginRedirectUrl || '/render-interior');
        } else {
            if (result?.payload?.errors) {
                NotificationManager.error(result?.payload?.errors[0]);
                return false;
            }
        }
    }

    const setRegister = async (data: RegisterDataType) => {
        setRegisterLoading(true);
        let result: any = await dispatch(register(data)).unwrap();

        setRegisterLoading(false);

        if (result?.success === false) {
            if (result?.errors) {
                NotificationManager.error(result?.errors[0]);
                return false;
            }
        }

        if (result?.payload?.success !== false) {
            closeModal();
            props.onSuccessLogin && props.onSuccessLogin();
            navigate('/render-interior');
        } else {
            if (result?.payload?.errors) {
                NotificationManager.error(result?.payload?.errors[0]);
            }
        }
    }

    const logout = () => {
        dispatch(clearToken());

        localStorage.removeItem('token');
        localStorage.removeItem('last_login');
        navigate("/");
    }

    const forgotSubmit = async (email: string) => {
        await dispatch(forgotPassword(email));
        // closeModal();
    }

    useEffect(() => {
        if (window.location?.href.includes('email_verified')) {
            NotificationManager.success("Your account has been verified successfully");
        }

        if (window.location?.href.includes('invite_hash')) {
            setPopUp({ open: true, modal: 'register' })
            return
        }
        if (window.location?.href.includes('email') && window.location?.href.includes('token')) {
            setPopUp({ open: true, modal: 'reset-password' });
            return
        }

        if (window.location?.href.includes('token')) {
            const query = new URLSearchParams(window.location.search);
            const token = query.get('token') || '';

            localStorage.setItem('token', token);
            localStorage.setItem('last_login', new Date().toLocaleDateString());
            navigate("/render-interior");
            return
        }

        if (localStorage.getItem('last_login')) {
            let date1 = new Date(localStorage.getItem('last_login') as string);
            let date2 = new Date();
            let timeDiff = Math.abs(date2.getTime() - date1.getTime());
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (+diffDays > 30) {
                // logout();
            }
        } else {
            if (
                !window.location?.href.includes("/reset-password/") &&
                !window.location?.href.includes("/pricing") &&
                !window.location?.href.includes("/about-us") &&
                !window.location?.href.includes("/faq") &&
                !window.location?.href.includes("/terms") &&
                !window.location?.href.includes("/privacy") &&
                !window.location?.href.includes("/delete-account") &&
                !window.location?.href.includes("/home-page") &&
                !window.location?.href.includes("/upscale-landing") &&
                !window.location?.href.includes("/render-interior-landing") &&
                !window.location?.href.includes("/delete-account")
            ) {
                logout();
            } else if (window.location?.href.includes("/reset-password/")) {
                setPopUp({ open: true, modal: 'reset-password' });
            }
        }

    }, [])

    useEffect(() => {
        if (logined) dispatch(fetchProfile());
    }, [logined])

    useEffect(() => {
        if (props.setLoginModal) {
            setPopUp({ open: true, modal: 'login' });
        }
    }, [props.setLoginModal])

    return (
        <>
            <header className={`header ${logined ? '' : 'header-short'}`}>
                <div className={`container ${logined ? 'container-full' : ''}`}>
                    <div className="header__body">
                        <NavLink to={lang_prefix + "/"} className="header-logo">
                            <div className="header-logo__icon">
                                <Icon src="img/sense-logo.svg" alt="" />
                            </div>
                            <div className="header-logo__text">Design Sense</div>
                        </NavLink>
                        <div className="header-nav">
                            {
                                logined ? <>
                                    <nav className={`header__menu ${mobNavOpen ? 'active' : ''}`}>
                                        <ul className="header__list">
                                            {AppRoutes.filter(item => item.headerName).map(item => {
                                                return (
                                                    <li key={item.path}>
                                                        <NavLink to={String(lang_prefix + item.path)} className="header__link" onClick={() => { scrollToTop(); }}>
                                                            {item.icon && <span className="header__link-ico">{item.icon}</span>}
                                                            {fromStrings(item.headerName as string)}
                                                        </NavLink>
                                                    </li>
                                                )
                                            })}

                                            <li>
                                                <LanguageSelect />
                                            </li>

                                            {profile?.team_role !== "user" && !isPremium && !profileLoading ?
                                                <li>
                                                    <NavLink to={lang_prefix + "/pricing"} className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px]">
                                                        <span className="icon mr-[10px]">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                                <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {fromStrings('Upgrade to PRO')}
                                                    </NavLink>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </nav>
                                    <div className="user-avatar ml-[16px]">
                                        <Link to={lang_prefix + '/settings'}>
                                            {/* <Icon src="img/user-36x36.png" alt=""/> */}
                                            {profile?.avatar ? <img src={profile?.avatar} alt="" /> :
                                                <div className={`avatar-placeholder ${!profile ? 'skeleton' : ''}`}>
                                                    {profile?.name[0]}
                                                </div>
                                            }
                                        </Link>
                                    </div>
                                </> : <>
                                    <nav className={`header__menu ${mobNavOpen ? 'active' : ''}`}>
                                        <ul className="header__list">
                                            <li>
                                                <HeaderDropdown />
                                            </li>
                                            <li>
                                                <NavLink to={lang_prefix + "/pricing"} className="header__link" onClick={() => {
                                                    scrollToTop();
                                                }}>{fromStrings("Pricing")}</NavLink></li>
                                            <li>
                                                <NavLink to={lang_prefix + "/about-us"} className="header__link" onClick={() => {
                                                    scrollToTop();
                                                }}>{fromStrings("About Us")}</NavLink></li>
                                            <li>
                                                <NavLink to={lang_prefix + "/faq"} className="header__link" onClick={() => {
                                                    scrollToTop();
                                                }}>{fromStrings("FAQ")}</NavLink></li>
                                            <li>
                                                <a href="#" className="header__link" onClick={(e) => { e.preventDefault(); setPopUp({ open: true, modal: 'login' }); }}>{fromStrings("Login")}</a>
                                            </li>
                                            <li>
                                                <a href="#" className="btn btn--gradient-green text-[15px] font-[600] btn--md rounded-[300px]"
                                                    onClick={(e) => { e.preventDefault(); setPopUp({ open: true, modal: 'register' }); }}
                                                >
                                                    {fromStrings("Start Now")}
                                                </a>
                                            </li>
                                            <li>
                                                <LanguageSelect />
                                            </li>
                                        </ul>
                                    </nav>
                                </>
                            }

                            <div className={`header__burger ml-[16px] ${mobNavOpen ? 'active' : ''}`} onClick={() => {
                                setMobNavOpen(!mobNavOpen)
                            }}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Popup open={popUp.open} onClose={() => closeModal()}>
                <>
                    {popUp.modal === 'login' && <LoginPopup onClose={() => closeModal()} setPopUp={setPopUp} setLogined={setLoginedData} loading={loginLoading} />}
                    {popUp.modal === 'register' && <SignUpPopup onClose={() => closeModal()} setPopUp={setPopUp} setRegister={setRegister} loading={registerLoading} />}
                    {popUp.modal === 'password' && <ForgotPasswordPopup onClose={() => closeModal()} setPopUp={setPopUp} forgotSubmit={forgotSubmit} />}
                    {popUp.modal === 'reset-password' && <ResetPasswordPopup onClose={() => closeModal()} setPopUp={setPopUp} />}
                </>
            </Popup>

            <NotificationContainer />
        </>
    )
}
