import React from "react";

type IconType = {
    src: string,
    alt: string,
    className?: string
}
export const Icon: React.FC<IconType> = ({ src, alt, className }) => {
    const imgPath = require(`../../assets/${src}`);
    return (
        <img
            src={imgPath}
            className={className}
            alt={alt || 'Icon'}
        />
    )
}
