import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '../../../components/UI/Icon'
import getI from '../../../helpers/getI'
import { setSkeletonActive } from '../../../helpers/skeletonHelper'
import { AppContext } from '../../../context/AppContext'
import { RenderSwiper } from '../../../components/UI/RenderSwiper'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { increment } from '../../../store/RenderInterior/RenderInteriorSlice'
import { getGenerationData, getGenerationLoading, getGenerationLoadingModel, getIsNoRendersError, setGenerateEndTime, getGenerateEndTime, getActiveGenerationTab, } from '../../../store/Generation/GenerationSlice'
import { getGenerationOptionsLoading } from '../../../store/GenerationOptions/GenerationOptionsSlice'
import { IGenerationData, IPredictions, ISelectedPrediction } from '../../../store/Generation/types'
import { IInteriorType } from '../../../store/GenerationOptions/types'
import { IProfile, IProfilePlan, getProfile } from '../../../store/profile/ProfileSlice'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { fetchMyRenders, fetchRenderStatus, fetchRenderInteriorPageData } from '../../../store/pagesData/PagesDataThunk';
import { getMyRenders, getMyRendersLoading, getRenderInteriorPageData, getSelectedLanguage, setCurrentPageData } from '../../../store/pagesData/PagesDataSlice';
import { MyRenders } from "../MyRenders/MyRenders";

import { saveAs } from "file-saver";

import MetaTags from 'react-meta-tags';

import { Api } from '../../../api/axios';

export interface IFolder {
    id: string | number,
    original_image?: string,
    selected_image?: string,
    render_engine?: string,
    status?: string,
    total_renders?: number,
    title?: string | null,
    all_renders?: string[] | any
}


export const RenderInterior = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // let {loading, data} = useContext(AppContext)
    // let data = useAppSelector(getGenerationData);
    let pageData = useAppSelector(getRenderInteriorPageData);
    let upscaleSidebarActive = useAppSelector(getActiveGenerationTab);

    const fromStrings = (value: string) => {
        return pageData?.strings?.[value] || value;
    }

    let generationLoading = useAppSelector(getGenerationLoading);
    let generationOptionsLoading = useAppSelector(getGenerationOptionsLoading)
    let generationLoadingModel = useAppSelector(getGenerationLoadingModel);
    let profile: IProfile | null = useAppSelector(getProfile);
    let [noRenders, setNoRenders] = useState<boolean>(false);
    let noRendersError = useAppSelector(getIsNoRendersError);
    let generateEndTime = useAppSelector(getGenerateEndTime);

    const languageFromStorage = localStorage.getItem('language');
    const selectedLanguage = useAppSelector(getSelectedLanguage);

    const dataMyRenders: IFolder[] = useAppSelector(getMyRenders);

    const rendersLoading = useAppSelector(getMyRendersLoading);

    let [percentsFromStatusReq, setPercentsFromStatusReq] = useState<number | string | null>(null);

    const dataMyRendersMapped = dataMyRenders?.map(item => item?.all_renders?.map((item2: string) => {
        return {
            img: item2,
            title: item?.title,
            id: item?.id
        }
    })).flat();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const queryId = query.get('id') || "";
    //  && dataMyRenders[0]?.render_engine !== "stable"
    const renderProcessingFromData = (dataMyRenders.length && (dataMyRenders[0].status === "processing" || dataMyRenders[0].status === "starting" || dataMyRenders[0].selected_image === "") && (dataMyRenders[0].status !== "failed" && dataMyRenders[0].status !== "succeeded"));

    const checkIfHaveRenders = async () => {

        if (renderProcessingFromData) {
            let copied = { ...dataMyRenders[0] } as any;

            let statusRes = await dispatch(fetchRenderStatus(copied.id)).unwrap() as any;

            const statusResFormated = statusRes?.data ? statusRes.data : statusRes;

            setPercentsFromStatusReq(statusRes?.percent);

            const renderVideo = localStorage.getItem('renderVideo');

            if (statusResFormated?.status === "succeeded" && statusResFormated?.predictions?.length) {
                if (!!renderVideo) {
                    localStorage.removeItem('renderVideo');
                    await Api().generation.generateVideo(copied.id, 0);
                }

                return true;
            }
        }

        return false;
    }

    // {
    //     id: renderItem.id,
    //     title: renderItem.title,
    //     all_renders: renderItem.all_renders as []
    // }



    const [mobOpenRightSidebar, setMobOpenRightSidebar] = useState(false);
    const [currentNumberOfRenders, setCurrentNumberOfRenders] = useState(1);
    const currentNumberOfRendersTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const setTimer = (current: number, max: number) => {
        currentNumberOfRendersTimer.current = setTimeout(() => {
            setCurrentNumberOfRenders(current + 1)
            if (current < max - 1) {
                setTimer(current + 1, max)
            }
        }, 7000)
    }

    const [selectedSidebarFilter, setSelectedSidebarFilter] = useState('');
    const [sidebarSeeMore, setSidebarSeeMore] = useState(false);

    const toggleContent = () => {
        document.querySelector(".page-generator-have-content")?.classList.toggle('to-close');
    }
    const dataHistory = useAppSelector(getGenerationData);
    const dataHistoryParsed = useMemo(() => {
        let res: ISelectedPrediction[] = []
        if (dataHistory?.length) dataHistory.forEach(generation => {
            if (generation?.predictions?.length) generation?.predictions.forEach(prediction => {
                let newPrediction: ISelectedPrediction = {
                    id: prediction.id,
                    afterImage: prediction.url,
                    beforeImage: generation.original_image,
                    interior: generation.interior,
                    generationData: generation
                };
                res.push(newPrediction);
            })
        })
        return res
    }, [dataHistory])
    const [selectedPrediction, setSelectedPrediction] = useState<ISelectedPrediction | null | any>(null);

    const interiorFilters = useMemo(() => {
        return [
            {
                id: 'all',
                name: 'All interiors',
                image: ''
            },
            ...dataHistory.map(generation => generation.interior)
        ]
    }, [dataHistory])
    const [selectedInteriorFilter, setSelectedInteriorFilter] = useState<IInteriorType>(interiorFilters[0])

    const dataHistoryParsedFiltered = useMemo(() => {
        return dataHistoryParsed.filter((item) => (selectedInteriorFilter.id === 'all' ? true : item.interior.id === selectedInteriorFilter.id))
    }, [dataHistoryParsed, selectedInteriorFilter])

    const shareHandler = () => {
        if (navigator.share) {
            navigator.share({
                title: "Render image share",
                text: "Rendered image",
                url: selectedPrediction?.afterImage
            }).then(function () {
                console.log("Shareing successfull")
            })
                .catch(function () {
                    console.log("Sharing failed")
                })
        }
    }

    const copyToClipboard = (text?: string) => {
        var textarea = document.createElement('textarea');
        textarea.value = selectedPrediction?.afterImage || '';
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
        } catch (err) {

        }
        document.body.removeChild(textarea);
    }

    const downloadFile = (url: string, name: string) => {
        saveAs(url, name);
    }

    const [animCopy, setAnimCopy] = useState(false)

    const createCopyAnim = () => {
        setAnimCopy(true)
        setTimeout(() => {
            setAnimCopy(false)
        }, 1000)
    }

    useEffect(() => {
        if (noRendersError) {
            setNoRenders(true)
        }
    }, [noRendersError])

    useEffect(() => {
        if (profile && !profile.can_render_image) {
            setNoRenders(true)
        }
    }, [profile]);

    useEffect(() => {
        setCurrentNumberOfRenders(1)
        if (currentNumberOfRendersTimer.current) {
            clearTimeout(currentNumberOfRendersTimer.current)
        }
        if (generationLoadingModel?.numberOfRenders && generationLoadingModel?.numberOfRenders > 1) {
            setTimer(1, generationLoadingModel.numberOfRenders)
        }
    }, [generationLoadingModel])

    useEffect(() => {
        setSelectedPrediction(dataHistoryParsed[0])
    }, [dataHistoryParsed])

    useEffect(() => {
        let interval = null as any;

        // && !generateEndTime?.date

        if (renderProcessingFromData) {

            interval = setInterval(async () => {
                let checkRes = await checkIfHaveRenders();
                if (checkRes) {
                    await dispatch(fetchMyRenders(1))
                    clearInterval(interval);
                    setPercentsFromStatusReq(null);
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
            setPercentsFromStatusReq(null);

            // console.log('setGenerateEndTime 4');

            // dispatch(setGenerateEndTime({
            //     all_seconds: null,
            //     date: null
            // }));
        }
    }, [renderProcessingFromData, generationLoading])

    useEffect(() => {
        const setEndDate = (startFrom = 0) => {
            let endTime = new Date();
            let all_seconds = null;

            endTime.setSeconds(endTime.getSeconds() + (startFrom > 0 ? (((100 - +startFrom) * 20) / 100) : 20));
            all_seconds = (startFrom > 0 ? (((100 - +startFrom) * 20) / 100) : 20);

            dispatch(setGenerateEndTime({
                all_seconds: all_seconds,
                date: String(endTime),
                startFrom,
            }));
        }

        if (!generateEndTime?.date && dataMyRenders?.length && dataMyRenders[0]?.render_engine === "stable" && dataMyRenders[0]?.status !== "succeeded" && dataMyRenders[0]?.status !== "failed") {
            if (localStorage.getItem('currentRenderProgress')) {
                let currentRenderProgressFromStorage = localStorage.getItem('currentRenderProgress') as string;

                if (+currentRenderProgressFromStorage > 0 && +currentRenderProgressFromStorage < 100) {
                    setEndDate(+currentRenderProgressFromStorage);
                }
            } else {
                setEndDate();
            }
        }
    }, [dataMyRenders])

    useEffect(() => {
        dispatch(fetchRenderInteriorPageData((selectedLanguage || languageFromStorage || '') as string));
    }, [selectedLanguage])

    useEffect(() => {
        dispatch(setCurrentPageData(pageData));
    }, [pageData])

    useEffect(() => {
        dispatch(fetchMyRenders(1));
    }, [])

    return (
        <>
            <MetaTags>
                <title>{pageData?.page?.meta_title || "Design Sense AI"}</title>
                <meta name="description" content={pageData?.page?.meta_description || ""} />
            </MetaTags>

            {/* main content no data */}
            {/* {(!selectedPrediction && (!generationLoading || !generationOptionsLoading) && !noRenders && !dataMyRenders?.length) ? <div className="full-page-section__content">
                <div className="section-scroll-container">
                    <div className="empty-page">
                        <div className="features-item__icon">
                            <Icon src="img/feature-house.svg" alt=""/>
                            <div className="features-item__shape" style={getI(0)}></div>
                            <div className="features-item__shape" style={getI(1)}></div>
                            <div className="features-item__shape" style={getI(2)}></div>
                            <div className="features-item__shape" style={getI(3)}></div>
                            <div className="features-item__shape" style={getI(4)}></div>
                            <div className="features-item__shape" style={getI(5)}></div>
                        </div>
                        <h4 className="mb-[12px]">No results yet</h4>
                        <p className="text-[14px] color-secondary-07">Fill in the form on the left and generate your first interior.</p>
                    </div>
                </div>
            </div> : null} */}
            {
            }
            {(noRenders && (!generationLoading || !generationOptionsLoading) && (upscaleSidebarActive === "upscale" ? !profile?.can_upscale : !profile?.can_render_image)) && (!queryId && dataMyRenders.length) && !renderProcessingFromData ?
                <div className="full-page-section__content">
                    <div className="section-scroll-container">
                        <div className="empty-page">
                            <div className="features-item__icon">
                                <Icon src="img/house-cleaning-star.svg" alt="" />
                                <div className="features-item__shape" style={getI(0)}></div>
                                <div className="features-item__shape" style={getI(1)}></div>
                                <div className="features-item__shape" style={getI(2)}></div>
                                <div className="features-item__shape" style={getI(3)}></div>
                                <div className="features-item__shape" style={getI(4)}></div>
                                <div className="features-item__shape" style={getI(5)}></div>
                            </div>
                            <h4 className="mb-[12px]">{fromStrings('Get More with Premium!')}</h4>
                            <div className="no-renders-popup-text text-[14px] color-secondary-07 mt-10" dangerouslySetInnerHTML={{ __html: fromStrings(`Hi there! You've reached your limit for <br/> the <span className='fw-600'>1 free image</span> generation on your basic plan.`) }}></div>
                            <div className="no-renders-popup-text text-[14px] color-secondary-07 mt-10" dangerouslySetInnerHTML={{ __html: fromStrings(`Unlock unlimited image generations and more amazing <br/> features by upgrading today!`) }}></div>
                            <NavLink to={"/pricing"} className="btn btn--gradient-blue text-[15px] font-[600] btn--md rounded-[300px]">
                                <span className="icon mr-[10px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M18.648 5.74218C18.4684 5.59458 18.2514 5.49965 18.0211 5.46793C17.7908 5.43621 17.5562 5.46894 17.3433 5.56249L13.3902 7.32031L11.0933 3.17968C10.9836 2.98637 10.8245 2.8256 10.6324 2.71376C10.4402 2.60192 10.2219 2.543 9.99959 2.543C9.77728 2.543 9.55894 2.60192 9.36681 2.71376C9.17468 2.8256 9.01563 2.98637 8.90584 3.17968L6.60897 7.32031L2.65585 5.56249C2.44296 5.46728 2.20768 5.43352 1.97662 5.46503C1.74555 5.49654 1.5279 5.59206 1.34828 5.7408C1.16866 5.88953 1.03423 6.08555 0.960186 6.30669C0.886142 6.52783 0.875438 6.76528 0.929283 6.99218L2.91366 15.4531C2.9516 15.6169 3.02241 15.7713 3.12178 15.907C3.22116 16.0426 3.34703 16.1566 3.49178 16.2422C3.68776 16.3595 3.91182 16.4216 4.14022 16.4219C4.25125 16.4217 4.3617 16.4059 4.46834 16.375C8.08539 15.375 11.906 15.375 15.523 16.375C15.8533 16.4618 16.2045 16.4141 16.4996 16.2422C16.6452 16.1577 16.7718 16.044 16.8713 15.9082C16.9708 15.7724 17.0411 15.6174 17.0777 15.4531L19.0699 6.99218C19.1231 6.76521 19.1119 6.52788 19.0373 6.30699C18.9628 6.08611 18.8279 5.89047 18.648 5.74218ZM15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                        <path d="M15.859 15.1719C12.0223 14.1094 7.96906 14.1094 4.13241 15.1719L2.14803 6.71093L6.10116 8.46093C6.38481 8.58988 6.70675 8.60679 7.00235 8.50826C7.29795 8.40972 7.54535 8.20304 7.69491 7.92968L9.99959 3.78906L12.3043 7.92968C12.4538 8.20304 12.7012 8.40972 12.9968 8.50826C13.2924 8.60679 13.6144 8.58988 13.898 8.46093L17.8512 6.71093L15.859 15.1719Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                {fromStrings('Upgrade to Pro')}
                            </NavLink>
                        </div>
                    </div>
                </div> : ((!selectedPrediction) || (selectedPrediction && !generationLoading) || (generationLoading)) ?
                    <MyRenders inGeneratePage={true} percentsFromStatusReq={percentsFromStatusReq} /> : null}
            {/* main content loading */}
            {(false && (generationLoading || generationOptionsLoading)) && <div className="full-page-section__content">
                <div className="section-scroll-container">
                    <div className="rendering-animation-block">
                        <div className="rendering-animation-block__image">
                            {/* <Icon src="img/rendering-animation-1.png" alt=""/> */}
                            {generationLoadingModel
                                ? <img className='render-img-fix' src={generationLoadingModel?.image}></img>
                                : <Icon src="img/rendering-animation-1.png" alt="" />
                            }
                            <div className="features-item__shape" style={getI(0)}></div>
                            <div className="features-item__shape" style={getI(1)}></div>
                            <div className="features-item__shape" style={getI(2)}></div>
                        </div>
                        <div className="rendering-animation-block__title">Rendering {currentNumberOfRenders} of {generationLoadingModel?.numberOfRenders}</div>
                        <p>Your photo is rendering now. </p>
                        <p>Please wait for some time.</p>
                    </div>
                </div>
            </div>}
            {/* main content no renders */}


            {/* main content data */}
            {(false && (selectedPrediction && !generationLoading && !noRenders)) && <div className={`full-page-section__content page-generator-have-content`}>
                <div className="section-scroll-container">
                    <div className="d-flex align-items-center justify-center">
                        <div
                            className="mr-[auto] mb-[16px] section__content-sidebar-burger"
                            onClick={() => { toggleContent() }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                                <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                            </svg>
                        </div>
                        <p className="fullpage-title text-center">{fromStrings("Render results")}</p>
                        <div
                            className="ml-[auto] mb-[16px] section__content-sidebar-burger"
                            onClick={() => { setMobOpenRightSidebar(!mobOpenRightSidebar) }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                                <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                            </svg>
                        </div>
                    </div>
                    <div className="fullpage-slider-content">
                        <RenderSwiper key={selectedPrediction?.id}>
                            {[
                                <div key="1">
                                    {/* <Icon src="img/result-slider-image-1.png" alt=""/> */}
                                    <img src={selectedPrediction?.afterImage} alt="" />
                                    <div className="slider-contetn-block">
                                        <span className="slider-label">{fromStrings("After")}</span>
                                    </div>
                                </div>,
                                <div key="2">
                                    <img src={selectedPrediction?.beforeImage} alt="" />
                                    <div className="slider-contetn-block">
                                        <span className="slider-label">{fromStrings("Before")}</span>
                                    </div>
                                </div>,
                            ]}
                        </RenderSwiper>

                    </div>
                    <div className="fullpage-actions">
                        {/* onClick={(e) => {e.preventDefault(); downloadFile(selectedPrediction.afterImage, 'After_image.png')}} */}
                        {/* target='_blank' */}
                        <a href={selectedPrediction.afterImage} download="After_image.png" className="btn btn--secondary-2 font-[600] btn--lg-2 rounded-[10px]">
                            {fromStrings("Download Render")}
                        </a>
                        <a href="#" onClick={(event) => { event.preventDefault(); createCopyAnim(); copyToClipboard(); shareHandler() }} className={`btn btn--secondary-2 ${!animCopy ? '' : 'pointer-events-none animated'}  rounded-[10px] copy-btn`}>
                            {!animCopy && <svg className='m-[9px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0012 8.99878H17.0021C18.1071 8.99878 19.0029 9.89458 19.0029 10.9996V19.0029C19.0029 20.108 18.1071 21.0038 17.0021 21.0038H6.9979C5.89287 21.0038 4.99707 20.108 4.99707 19.0029V10.9996C4.99707 9.89458 5.89287 8.99878 6.9979 8.99878H8.99874" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12.0002 15.0013V2.99634" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.0015 5.99759L12.0003 2.99634L8.99902 5.99759" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>}
                            <svg id="icon1" className={`test-icon ${!animCopy && 'h-0 w-0'}`} width="42" height="42" viewBox="0 0 42 42" fill="none" stroke="white"
                                stroke-width="3" stroke-dasharray="100">
                                <path className="icon-path" id="check" d="M 12,22 L 22,31 L 36,13"></path>
                            </svg>
                        </a>



                    </div>
                </div>
            </div>}



            {/* sidebar no data */}
            {(!generationLoading && !generationOptionsLoading && !rendersLoading && !dataMyRenders.length) && <div className='aside aside--controls-2 skeleton-hide'>
                <div className="aside-container">
                    <div className="aside-header px-[16px] pt-[16px]">
                        <div className="row mb-[-16px]">
                            <div className="col-12 mb-[16px]">
                                <div className="form-group">
                                    <select className="select">
                                        <option>{fromStrings('All interiors')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="aside-body pl-[16px] pt-[25px]">
                        <div className="empty-page">
                            <div className="features-item__icon">
                                <Icon src="img/ico-greed.svg" alt="" />
                                <div className="features-item__shape" style={getI(0)}></div>
                                <div className="features-item__shape" style={getI(1)}></div>
                                <div className="features-item__shape" style={getI(2)}></div>
                                <div className="features-item__shape" style={getI(3)}></div>
                                <div className="features-item__shape" style={getI(4)}></div>
                                <div className="features-item__shape" style={getI(5)}></div>
                            </div>
                            <h4 className="mb-[12px]">{fromStrings('Renders Gallery')}</h4>
                            <p className="text-[14px] color-secondary-07">
                                {fromStrings('Here you can view all renders that you generate.')}
                            </p>
                        </div>
                    </div>
                    <div className="aside-footer px-[16px]">
                        <a href="#" className="close-mobile btn btn--secondary-2 font-[600] btn--lg-2 
                        rounded-[300px] w-full text-[14px]">
                            <span>{fromStrings('Back')}</span>
                        </a>
                    </div>
                </div>
            </div>}
            {/* sidebar data */}
            {/* {( !!dataHistoryParsed.length && !generationLoading && !generationOptionsLoading )&&
            <aside className={`aside aside--controls-2 ${mobOpenRightSidebar ? 'active' : false}`}>
                <div className="aside-container">
                    <div className="aside-header px-[16px] pt-[16px]">
                        <div className="row mb-[-16px]">
                            <div className="col-12 mb-[16px]">
                                <div className="form-group flex-row align-items-center">
                                    <select className="select" onChange={(event:React.ChangeEvent<HTMLSelectElement>)=>{setSelectedInteriorFilter(interiorFilters.find(item=>item.id===(event.target.value==='all'?'all':+event.target.value))||interiorFilters[0])}}>
                                        {interiorFilters.map(item=>(<option key={item.id} value={item.id}>{item.name}</option>))}
                                    </select>
                                    <div
                                        className="ml-[12px] section__content-sidebar-burger" 
                                        onClick={()=>{setMobOpenRightSidebar(!mobOpenRightSidebar)}}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                            <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5"/>
                                            <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {(!!dataMyRenders.length && !generationLoading && !generationOptionsLoading && !rendersLoading) ?
                <aside className={`aside aside--controls-2  ${mobOpenRightSidebar ? 'active' : false}`}>
                    <div className="aside-container">
                        <div className="aside-header px-[16px] pt-[16px]">
                            <div className="row mb-[-16px]">
                                <div className="col-12 mb-[16px]">
                                    <div className="form-group flex-row align-items-center">
                                        {/* <select 
                                        className="select"
                                        onChange={(event:React.ChangeEvent<HTMLSelectElement>)=>{
                                            setSelectedInteriorFilter(interiorFilters.find(item=>item.id===(event.target.value==='all'?'all':+event.target.value))||interiorFilters[0])
                                        }}
                                    >
                                        {interiorFilters.map(item=>(<option key={item.id} value={item.id}>{item.name}</option>))}
                                    </select> */}
                                        <select
                                            className="select"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                setSelectedSidebarFilter(event.target.value)
                                            }}
                                        >
                                            <option value={''}>{fromStrings('All interiors')}</option>
                                            {(sidebarSeeMore ? dataMyRendersMapped : [...dataMyRendersMapped].slice(0, 10)).map(itemMap => itemMap.title).filter(function (item, pos, self) {
                                                return self.indexOf(item) == pos;
                                            }).map((item, itemIndex) => (
                                                <option key={itemIndex} value={item}>{fromStrings(item)}</option>
                                            ))}
                                        </select>
                                        <div
                                            className="ml-[12px] section__content-sidebar-burger"
                                            onClick={() => { setMobOpenRightSidebar(!mobOpenRightSidebar) }}
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" stroke="#FFFFFF" strokeWidth="1.5" />
                                                <path d="M3.75 7C3.75 5.20507 5.20507 3.75 7 3.75H9.25V20.25H7C5.20507 20.25 3.75 18.7949 3.75 17V7Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="1.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aside-body pl-[16px] mt-[76px]">
                            <div className="row">
                                {false && dataHistoryParsedFiltered.map((item) => {
                                    return (
                                        <div className="col-12 mb-[16px]" key={item.id}>
                                            {/* <label className="card-check">
                                            <input type="radio" checked={item.id===selectedPrediction?.id} onChange={()=>{setSelectedPrediction(item)}} name="interior" id="i1"/>
                                            <div className="card-photo">
                                                <span className="ico ico-28 ico-success">
                                                    <Icon src={"img/icons/ico-success--primary.svg"} alt=""/>
                                                </span>
                                                <img src={item.afterImage} alt=""/>
                                                <div className="card-wrapper-info items-center">
                                                    <div className="card-photo-info">
                                                        {item.interior.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </label> */}
                                            <NavLink to={`/render-interior?id=${item.generationData.render_id}`} className="card-check">
                                                <div className="card-photo">
                                                    <span className="ico ico-28 ico-success">
                                                        <Icon src={"img/icons/ico-success--primary.svg"} alt="" />
                                                    </span>
                                                    <img src={item.afterImage} alt="" />
                                                    <div className="card-wrapper-info items-center">
                                                        <div className="card-photo-info">
                                                            {item.interior.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                })
                                }

                                {
                                    (sidebarSeeMore ? dataMyRendersMapped : [...dataMyRendersMapped].slice(0, 10)).filter(itemF => selectedSidebarFilter ? itemF.title === selectedSidebarFilter : true).map((generatedItem, generatedItemIndex) => {
                                        return <div className="col-12 mb-[16px]" key={generatedItem.id + '-' + generatedItemIndex}>
                                            <NavLink to={`/render-interior?id=${generatedItem.id}`} className="card-check">
                                                <div className="card-photo">
                                                    <span className="ico ico-28 ico-success">
                                                        <Icon src={"img/icons/ico-success--primary.svg"} alt="" />
                                                    </span>
                                                    <img src={generatedItem.img} alt="" />
                                                    <div className="card-wrapper-info items-center">
                                                        <div className="card-photo-info">
                                                            {generatedItem.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        {
                            dataMyRendersMapped.length > 10 ?
                                <div className="aside-footer px-[16px]">
                                    <a
                                        href="#" className="btn--render-js-1 btn w-full btn--gradient-green text-[14px] font-[600] btn--lg-2 rounded-[300px]"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSidebarSeeMore(prevState => !prevState);
                                        }}
                                    >{fromStrings(`See ${sidebarSeeMore ? 'less' : 'more'}`)}</a>
                                </div> : null
                        }
                    </div>
                </aside>
                : <div className={`aside aside--controls-2 ${!generationLoading && "skeleton-show"}`}>
                    <div className="aside-container">
                        <div className="aside-header px-[16px] pt-[16px]">
                            <div className="row mb-[-16px]">
                                <div className="col-12 mb-[16px]">
                                    <div className="skeleton --skeleton-input"></div>
                                </div>
                            </div>
                        </div>
                        <div className="aside-body pl-[16px] mt-[76px]">
                            <div className="row">
                                {new Array(10).fill(undefined).map((val, idx) => {
                                    return (<div className="col-12 mb-[16px]" key={idx}>
                                        <div className="skeleton --skeleton-card-md-2"></div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
